import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
  SubHeader,
  SubHeaderTitle,
} from '@campoint/odi-ui';
import { VxModelsLogo } from '@campoint/odi-ui-icons';
import {
  Container,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import VXFeedUSPInteraction from '../../assets/images/shared/VX-Feed-USP-Interaction.svg';
import VXFeedUSPPosting from '../../assets/images/shared/feed-preview.svg';
import VXFeedUSPTipping from '../../assets/images/shared/payout.svg';
import { FluentPageLayout } from '../../components/Layout/FluentPageLayout';
import { LayoutCenteringVStack } from '../../components/Layout/LayoutCenteringVStack';
import { SectionCenterContainer } from '../../components/Layout/SectionCenterContainer';
import { FetchMoreIndicator } from '../../components/shared/FetchMoreIndicator/FetchMoreIndicator';
import ProfileHeader from '../../components/shared/ProfileHeader/ProfileHeader';
import { ProfileMenuSwitch } from '../../components/shared/ProfileMenuSwitch/ProfileMenuSwitch';
import {
  USPModal,
  USPModalSteps,
} from '../../components/shared/USPModal/USPModal';
import { UpdateTaxInformationExpiredHintSlot } from '../../components/shared/UpdateTaxInformationExpiredHintSlot/UpdateTaxInformationExpiredHintSlot';
import { ModelPostTypesEnum } from '../../generated/feed';
import { useAccountStatus } from '../../provider/AccountStatusProvider/AccountStatusProvider';
import { useCreateFeedPostContext } from '../../provider/FeedPostCreateModalProvider';
import { useFeedPostEditModalContext } from '../../provider/FeedPostEditModalProvider';
import { useFeedService } from '../../provider/FeedService/FeedService';
import {
  UploadJobEntityType,
  useUploadManager,
} from '../../provider/UploadManagerProvider';
import { useModelProfile } from '../ModelProfileV2Page/provider/ModelProfileProvider';
import { FeedScroller } from './FeedScroller/FeedScroller';
import { FeedFilterToolButtons } from './components/FeedFilterToolButtons/FeedFilterToolButtons';
import { FeedStillBlockedCard } from './components/post/component/FeedBlockedCard/FeedStillBlockedCard/FeedStillBlockedCard';
import { NoPostsBlockedCard } from './components/post/component/FeedBlockedCard/NoPostsBlockedCard/NoPostsBlockedCard';
import { NoPostsIfFilteredBlockedCard } from './components/post/component/FeedBlockedCard/NoPostsIfFilteredBlockedCard/NoPostsIfFilteredBlockedCard';
import { CreateFeedPostSection } from './section/CreateFeedPostSection/CreateFeedPostSection';
import { FeedPostStatesSection } from './section/FeedPostStatusSection/FeedPostStatesSection';

export const FeedPage: React.FC = () => {
  const { t } = useTranslation(['general', 'feed']);
  const isMobileViewport = useBreakpointValue({ base: true, lg: false });
  const {
    isAllowedToPostOnFeed,
    isAccountStatusLoading,
    actions: { refresh: triggerAccountStatusRefresh },
  } = useAccountStatus();
  const feedPostEditCtx = useFeedPostEditModalContext();
  const feedPostCreateCtx = useCreateFeedPostContext();

  const modelProfileCtx = useModelProfile();
  const isContentLoading =
    modelProfileCtx.isInitDataLoading || isAccountStatusLoading;

  const fields = modelProfileCtx?.mediaCollection?.fields ?? {};
  const { modelPictureTitleLandscape, modelPictureFSK16Avatar } = fields;

  const uploadManagerCtx = useUploadManager();

  const {
    loadMorePosts,
    triggerFeedRefresh,
    feedIsLoading,
    registerRefresh,
    action,
    error,
    feedFilters,
    setFilters,
    totalFeedPostsCount,
  } = useFeedService();

  const { registerOnReset } = feedPostCreateCtx.action;
  React.useEffect(
    () =>
      registerOnReset((postType) => {
        /**
         * Add 1 to the post count for a newly created text post since it is immediately available
         * otherwise one of the previously loaded posts would be lost during the refetch.
         */
        triggerFeedRefresh(postType === ModelPostTypesEnum.Text ? 1 : 0);
      }),
    [registerOnReset, triggerFeedRefresh]
  );

  // Refresh Account Status
  React.useEffect(() => {
    triggerAccountStatusRefresh();
  }, [triggerAccountStatusRefresh]);

  React.useEffect(() => {
    if (feedPostEditCtx.isSucessfullySubmitted) {
      triggerFeedRefresh();
      feedPostEditCtx.action.setIsSucessfullySubmitted(false);
    }
  });

  React.useEffect(() => {
    return uploadManagerCtx.action.registerOnJobFinished((jobHandle) => {
      if (jobHandle.entity.type === UploadJobEntityType.POST) {
        triggerFeedRefresh();
      }
    });
  }, [uploadManagerCtx.action, triggerFeedRefresh]);

  // Poll for updates with given interval
  React.useEffect(() => {
    const interval = setInterval(triggerFeedRefresh, 60_000);
    return () => clearInterval(interval);
  }, [triggerFeedRefresh]);

  const {
    isOpen: uspModalIsOpen,
    onOpen: openUspModal,
    onClose: closeUspModal,
  } = useDisclosure();

  const username =
    modelProfileCtx?.aboutMeCollection?.fields?.modelUsername?.value ?? '';

  const createFeedPostSection = (
    <CreateFeedPostSection
      image={
        isMobileViewport
          ? null
          : modelPictureFSK16Avatar?.value?.image.src ?? ''
      }
      imageAlt={'Avatar Image'}
      heading={isMobileViewport ? null : t('feed:heading.DeineVXFeedBeitrage')}
      text={
        isMobileViewport
          ? t('feed:text.HiUsernameWasMachstDuGerade', {
              username: username,
            })
          : t('feed:text.WasMachstDuGerade')
      }
      textProps={isMobileViewport ? { color: 'gray.900' } : {}}
      primaryButtonText={t('feed:button.BeitragErstellen')}
      isBordered={true}
    />
  );

  const uspSteps: USPModalSteps[] = [
    {
      image: { src: VXFeedUSPPosting },
      heading: t('feed:heading.Posting'),
      text: t(
        'feed:text.LadeFotosNachrichtenOderKurzeClipsHochUndMacheDeineFollowerHeissAufMXX'
      ),
    },
    {
      image: { src: VXFeedUSPTipping },
      heading: t('feed:heading.Tipping'),
      text: t(
        'feed:text.ErweitereDeineVerdienstmoglichkeitenDurchDasTippingAufDeineBeitrage'
      ),
    },
    {
      image: { src: VXFeedUSPInteraction },
      heading: t('feed:heading.Interaktion'),
      text: t(
        'feed:text.ReagiereSchnellUndEinfachAufKundenreaktionenWieLikesOderKommentare'
      ),
    },
  ];

  const isFeedEmpty = React.useMemo(
    () => totalFeedPostsCount === 0,
    [totalFeedPostsCount]
  );
  const hasFilteresSet = React.useMemo(
    () => feedFilters.length > 0,
    [feedFilters.length]
  );
  const isFeedUnfilteredEmpty = isFeedEmpty && !hasFilteresSet;
  const isFeedFilteredEmpty = isFeedEmpty && hasFilteresSet;

  return (
    <>
      <USPModal
        isOpen={uspModalIsOpen}
        onClose={closeUspModal}
        closeButtonText={t('feed:button.BringMichZumFeed')}
        continueButtonText={t('button.Weiter')}
        steps={uspSteps}
      />
      <FluentPageLayout isContentLoading={isContentLoading}>
        <SubHeader>
          <Container px={0} maxW={'container.xl'}>
            <SubHeaderTitle p={0}>{t('feed:heading.Feed')}</SubHeaderTitle>
          </Container>
        </SubHeader>
        <VStack
          maxW={'container.xl'}
          marginX={'auto'}
          alignItems={'stretch'}
          spacing={{ base: '4', lg: '8' }}
        >
          <ProfileHeader
            modelPictureTitleLandscape={modelPictureTitleLandscape}
            modelPictureFSK16Avatar={modelPictureFSK16Avatar}
            username={username}
          />
          <ProfileMenuSwitch />
          <UpdateTaxInformationExpiredHintSlot />
          {!isAllowedToPostOnFeed ? (
            <LayoutCenteringVStack outerStackProps={{ mb: '10' }}>
              <FeedStillBlockedCard />
            </LayoutCenteringVStack>
          ) : (
            <>
              {isFeedUnfilteredEmpty ? (
                <LayoutCenteringVStack>
                  <NoPostsBlockedCard openUspModal={openUspModal} />
                </LayoutCenteringVStack>
              ) : (
                <LayoutCenteringVStack>
                  {createFeedPostSection}
                </LayoutCenteringVStack>
              )}
              <FeedPostStatesSection
                unguardedDeleteClickHandler={action.deletePost}
                registerRefresh={registerRefresh}
              />
              <Section
                aria-busy={feedIsLoading}
                bg={{
                  base: 'transparent',
                  md: isFeedUnfilteredEmpty ? 'transparent' : 'surface',
                }}
              >
                {isFeedUnfilteredEmpty ? null : (
                  <SectionHeader
                    bg={{ base: 'surface', md: 'unset' }}
                    mb={{ base: '4', md: 'unset' }}
                    pb={{ base: '4', md: '4', xl: '4' }}
                    pt={{ base: '4', md: '8', xl: '8' }}
                  >
                    <SectionCenterContainer>
                      <SectionTitleRow>
                        <SectionIcon as={VxModelsLogo} />
                        <SectionTitle>{t('feed:heading.Feed')}</SectionTitle>
                        {!isFeedUnfilteredEmpty && (
                          <FeedFilterToolButtons
                            feedFilters={feedFilters}
                            setFeedFilters={setFilters}
                          />
                        )}
                      </SectionTitleRow>
                      {isFeedFilteredEmpty && (
                        <LayoutCenteringVStack>
                          <NoPostsIfFilteredBlockedCard />
                        </LayoutCenteringVStack>
                      )}
                    </SectionCenterContainer>
                  </SectionHeader>
                )}
                <SectionBody
                  px={{ base: '0', md: '4' }}
                  py={{ base: '0', md: '4', xl: '4' }}
                >
                  <SectionCenterContainer>
                    {!isFeedEmpty && <FeedScroller />}
                    <FetchMoreIndicator
                      mt={{ base: '8', lg: '12' }}
                      loading={feedIsLoading}
                      error={error}
                      onIsVisible={loadMorePosts}
                    />
                  </SectionCenterContainer>
                </SectionBody>
              </Section>
            </>
          )}
        </VStack>
      </FluentPageLayout>
    </>
  );
};
