import { FormManagerPaperlessForm, FormStatusEnum, ModelReleaseFormLanguageEnum, useInitModelReleaseFormMutation } from '../../../generated/graphql';
import * as React from 'react';
import { useHistory } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ENV from '../../../environments/environment';
import { routes } from '../../../routes/routesConfig';
import { windowOpen } from '../../../utils/utils';
import { issueChakraToast } from '../../../components/Layout/ChakraToastContainer';
import Logger from '../../../utils/Logger';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../../components/Layout/ResponsiveModal';
import { Button, Heading, Icon, Img, Text, VStack } from '@chakra-ui/react';
import image from '../../../assets/images/wizard/model-release-form-welcome.svg';
import { EnumSelectFieldHookForm } from '../../../components/shared/FormElements/EnumSelectFieldHookForm/EnumSelectFieldHookForm';
import * as icons from '@campoint/odi-ui-icons';


const fieldName = {
  mrfLanguage: 'mrfLanguage',
} as const;

const isHandled = (modelReleaseForm: FormManagerPaperlessForm | null | undefined) =>
  (modelReleaseForm?.status && [FormStatusEnum.Incomplete].includes(modelReleaseForm?.status)) ?? false;


type ModelReleaseFormModalProps = {
  isOpen: boolean;
  onClose: () => void;
  createdActor: boolean;
  userId: number;
  defaultLang?: ModelReleaseFormLanguageEnum | undefined;
  modelReleaseForm?: FormManagerPaperlessForm | null | undefined;
};
export const ModelReleaseFormModal: React.FC<ModelReleaseFormModalProps> = (
  {
    isOpen,
    onClose,
    createdActor,
    userId,
    defaultLang = ModelReleaseFormLanguageEnum.De,
    modelReleaseForm,
  },
) => {
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const hookForm = useForm({
    defaultValues: { [fieldName.mrfLanguage]: defaultLang },
    mode: 'all',
  });
  const { t } = useTranslation(['language', 'mrfModal']);

  const getOrigin = () => window.location?.origin ?? ENV.HOST;

  const [addMRFMutation] = useInitModelReleaseFormMutation({});

  const displayLangSelector = !isHandled(modelReleaseForm);

  const toPaperlessOrCopyLink = React.useCallback(
    async (modelReleaseForm: FormManagerPaperlessForm | null | undefined, copyLink: boolean) => {
      setLoading(true);
      try {
        const resolveUrl = async (modelReleaseForm: FormManagerPaperlessForm | null | undefined) => {

          // check if the model release form is already in a handled status and return the form url
          if (isHandled(modelReleaseForm)) {
            return modelReleaseForm?.formUrl;
          }

          // otherwise call mutation to create a new model release form and return this form url
          const lang = hookForm.getValues(fieldName.mrfLanguage) ?? defaultLang;
          const resp = await addMRFMutation({
            variables: {
              userId,
              lang,
              redirectUrl: copyLink ? `${getOrigin()}${routes.home.path}` : `${getOrigin()}${routes.documentsActors.path}?actorAdded=true`,
            },
          });

          return resp.data?.documents?.initModelReleaseForm.formUrl;
        };

        const url = await resolveUrl(modelReleaseForm);

        // exit if no url is available
        if (!url) {
          return;
        }

        if (!copyLink) {
          // Open the model release form in a new tab
          windowOpen(url, '_self');
        } else {
          // Copy the link to the clipboard and redirect to the actors page
          await navigator.clipboard.writeText(url);
          history.push({ pathname: routes.documentsActors.path });
          issueChakraToast({ status: 'success', description: t('mrfModal:toast.LinkInZwischenablageKopiert') });
        }

      } catch (error) {
        Logger.error(error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    },
    [addMRFMutation, history, hookForm, t, userId, defaultLang],
  );

  const extendedOnClose = React.useCallback(() => {
    if (createdActor) {
      history.replace(`${routes.documentsActors.path}?actorAdded=true`);
    } else {
      onClose();
    }
  }, [createdActor, history, onClose]);

  return (
    <ResponsiveModal isOpen={isOpen} onClose={extendedOnClose}>
      <ResponsiveModalOverlay onClick={extendedOnClose} />
      <ResponsiveModalContent
        maxW={'567px'}
        bgColor={'surface'}
        color={'coldGray.900'}
      >
        <ResponsiveModalStickyHeaderBox
          bg={'transparent'}
          shadow={'transparent'}
        >
          <ResponsiveModalCloseButton onClick={extendedOnClose} />
        </ResponsiveModalStickyHeaderBox>
        <FormProvider {...hookForm}>
          <form>
            <ResponsiveModalBodyBox bg={'transparent'} mt={'20px'}>
              <VStack alignItems={'stretch'} textAlign={'center'}>
                <Img
                  src={image}
                  alt={t('mrfModal:img.HandschlagNachVertragsunterzeichnung')}
                  maxH={'150px'}
                />
                <Heading size={'lg'}>
                  {t('mrfModal:text.ModelReleaseFormAusfullen')}
                </Heading>
                <Heading size={'sm'}>
                  {t('mrfModal:text.DuHastFolgendeOptionen')}
                </Heading>
                <Text
                  color={'gray.500'}
                  fontWeight={'700'}
                  fontSize={'sm'}
                  lineHeight={'20px'}
                >
                  {t('mrfModal:text.ModelReleaseFormAusfullen')}
                </Text>
                <Text
                  color={'gray.500'}
                  fontSize={'sm'}
                  lineHeight={'20px'}
                  marginBottom={'15px'}
                  mb={3}
                >
                  {t(
                    'mrfModal:text.NutzeDieseOptionWennDasFormularFurDichIstDuKannstEsDirektAusfullenUnXX',
                  )}
                </Text>
                <Text
                  color={'gray.500'}
                  fontSize={'sm'}
                  lineHeight={'10px'}
                  mb={3}
                >
                  {t('mrfModal:text.Oder')}
                </Text>
                <Text
                  color={'gray.500'}
                  fontWeight={'700'}
                  fontSize={'sm'}
                  lineHeight={'20px'}
                >
                  {t('mrfModal:text.MrfLinkKopieren')}
                </Text>
                <Text
                  color={'gray.500'}
                  fontSize={'sm'}
                  lineHeight={'20px'}
                  mb={8}
                >
                  {t(
                    'mrfModal:text.NutzeDieseOptionWennDasFormularFurEineAnderePersonIstDieDasFormularAXX',
                  )}
                </Text>
                {displayLangSelector && <EnumSelectFieldHookForm
                  label={t(
                    'mrfModal:text.InWelcherSpracheSollDasModelReleaseFormErstelltWerden',
                  )}
                  name={fieldName.mrfLanguage}
                  isDisabled={false}
                  mb={6}
                >
                  <option value={ModelReleaseFormLanguageEnum.De}>
                    {t('language:de')}
                  </option>
                  <option value={ModelReleaseFormLanguageEnum.En}>
                    {t('language:en')}
                  </option>
                </EnumSelectFieldHookForm>}
                <Button
                  isLoading={loading}
                  alignSelf={'center'}
                  onClick={() => toPaperlessOrCopyLink(modelReleaseForm, false)}
                  mb={2}
                  variant={'solid'}
                >
                  {t('mrfModal:button.ModelReleaseFormAusfullen')}
                </Button>
                <Button
                  alignSelf={'center'}
                  variant={'link'}
                  rightIcon={<Icon as={icons.FilterNone} boxSize={'icon.sm'} />}
                  onClick={() => toPaperlessOrCopyLink(modelReleaseForm, true)}
                >
                  {t('mrfModal:button.MRFLinkKopieren')}
                </Button>
              </VStack>
            </ResponsiveModalBodyBox>
          </form>
        </FormProvider>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};