import { Maybe } from 'graphql/jsutils/Maybe';
import i18n from 'i18next';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  ApiLangEnum,
  AuthUser,
  AuthUserPermissionEnum,
  GetUserQuery,
  LoginWithTokenMutation,
  SecurityRoleEnum,
  useGetUserLazyQuery,
  useLoginWithTokenMutation,
} from '../generated/graphql';
import useIsMounted from '../hooks/useIsMounted';
import { getBrowserLanguage, getQueryLanguage, setUserLanguage } from '../i18n';
import { LocalStorageKeys } from '../types';
import { LocalStorage, noop } from '../utils';
import Logger from '../utils/Logger';
import { gtag } from '../utils/gtag';

export type AuthState = {
  actions: {
    logout: () => Promise<void>;
    login: (
      accessToken: string,
      refreshToken: string,
      isRegisterLogin?: boolean
    ) => Promise<void>;
    loginWithToken: (token: string) => Promise<void>;
  };
  /** Currently logged in user or null */
  authUser: AuthUser | null;
  accessToken?: string | null;
  /** Shorthand whether we currently have an authUser */
  readonly isAuthenticated: boolean;
  /** Whether an admin is logged in. Alternatively you can also look at the  */
  readonly isVXAdminLoggedIn: boolean;
  readonly isMasterAccount?: boolean;
  waitingForAuthStatus: boolean;
  hasPermission: null | ((permissionType: AuthUserPermissionEnum) => boolean);
};

const extractAdminLoginSuccessfulData = (
  loginData?: LoginWithTokenMutation | null
) => {
  const authData = loginData?.auth;
  return authData?.loginWithToken?.__typename === 'AuthResultSuccessful'
    ? authData.loginWithToken
    : null;
};

const setAccessToken = (accessToken: string) => {
  if (accessToken !== 'null') {
    LocalStorage.add(LocalStorageKeys.ACCESS_TOKEN, accessToken);
  }
};

const setRefreshToken = (refreshToken: string) => {
  if (refreshToken !== 'null') {
    LocalStorage.add(LocalStorageKeys.REFRESH_TOKEN, refreshToken);
  }
};

const removeAccessToken = () => {
  LocalStorage.remove(LocalStorageKeys.ACCESS_TOKEN);
};

const removeRefreshToken = () => {
  LocalStorage.remove(LocalStorageKeys.REFRESH_TOKEN);
};

const getUserSegmentation = (userId: number) => {
  if (isNaN(userId) || !userId) {
    return 'undefined';
  }

  return userId % 2;
};

const checkPresenceOfTokens = () => {
  const accessToken = LocalStorage.getString(LocalStorageKeys.ACCESS_TOKEN);
  const refreshToken = LocalStorage.getString(LocalStorageKeys.REFRESH_TOKEN);
  return !!accessToken || !!refreshToken;
};

export const initAuthStateValues: AuthState = {
  actions: {
    logout: () => Promise.resolve(),
    login: () => Promise.resolve(),
    loginWithToken: () => Promise.resolve(),
  },
  authUser: null,
  accessToken: null,
  get isAuthenticated() {
    return this.authUser !== null;
  },
  get isVXAdminLoggedIn() {
    return this.authUser?.roles.includes(SecurityRoleEnum.VxUserAdmin) ?? false;
  },
  waitingForAuthStatus: true,
  hasPermission: null,
};

export const authContext = React.createContext<AuthState>(initAuthStateValues);

export const AuthProvider: React.FC<{
  children?: React.ReactNode | ((userId: Maybe<string>) => React.ReactNode);
}> = ({ children }) => {
  const isMounted = useIsMounted();
  const [performLoginWithToken] = useLoginWithTokenMutation();
  const [authUser, setAuthUser] = React.useState(initAuthStateValues.authUser);
  const [isQueryLanguageSet, setIsQueryLanguageSet] = React.useState(false);
  const [isFirstLogin, setIsFirstLogin] = React.useState(false);
  const [waitingForAuthStatus, setWaitingForAuthStatus] = React.useState(
    initAuthStateValues.waitingForAuthStatus
  );
  const accessToken = LocalStorage.getString(LocalStorageKeys.ACCESS_TOKEN);

  const history = useHistory();
  const { search } = useLocation();

  const isVXAdminLoggedIn =
    authUser?.roles.includes(SecurityRoleEnum.VxUserAdmin) ?? false;

  const [performGetUser, { client: authClient }] = useGetUserLazyQuery({
    onCompleted: async (data: GetUserQuery) => {
      const user = data.auth?.user;
      const userLang = data.account.language;
      if (user) {
        if (userLang && !isQueryLanguageSet) {
          await setUserLanguage(userLang.toLowerCase());
        }

        let gTagUser: any = {
          userSegmentation: getUserSegmentation(+user.userId),
          user_id: user.userId,
        };

        if (isVXAdminLoggedIn) {
          gTagUser = { ...gTagUser, isCampointAdmin: true };
        }

        gtag('set', gTagUser);

        gtag('event', 'login');

        setAuthUser(user);

        // Add a minimum waiting time, to have the loadingPage not flash
        await new Promise((resolve) => setTimeout(resolve, 250));
        if (!isMounted()) {
          // Stop in case there is no more provider to update
          return;
        }

        setWaitingForAuthStatus(false);

        if (isFirstLogin) {
          history.replace({
            search: 'isFirstLogin=true',
          });
          setIsFirstLogin(false);
        }
      } else {
        logout();
      }
    },
    onError: (error) => {
      Logger.error(error);
      logout();
    },
  });

  const logout: AuthState['actions']['logout'] = React.useCallback(async () => {
    // Local storage needs to be changed before updating the auth user
    removeAccessToken();
    removeRefreshToken();
    setAuthUser(null);
    // Add a minimum waiting time, to prevent loading screen from flashing
    await new Promise((resolve) => setTimeout(resolve, 250));
    // Clear the cached GraphQL data
    authClient?.clearStore();

    // After logout, fallback to the browser detected language
    await i18n.changeLanguage(getBrowserLanguage() ?? 'en');

    // only after language change can we clear the waiting here,
    // as the cookie banner language waits for this to decide which language to show
    setWaitingForAuthStatus(false);

    // clear gtag user
    gtag('set', {
      userSegmentation: null,
      isCampointAdmin: null,
      user_id: null,
    });
  }, [authClient]);

  /**
   * Only set the token and get the auth user. Is needed for the login with name and password.
   * The loginWithToken method executes an additional login request, which was already sent during the manual login.
   */
  const login = React.useCallback(
    async (
      accessToken: string,
      refreshToken: string,
      isRegisterLogin?: boolean
    ) => {
      // Set the new access token in the local storage
      setAccessToken(accessToken);
      // Set the new refresh token in the local storage
      setRefreshToken(refreshToken);
      setIsFirstLogin(isRegisterLogin ?? false);
      // Get the auth user
      await performGetUser();
    },
    [performGetUser]
  );

  /**
   * Login with a token. A new token is always generated during this process.
   * Can be used to generate a new token with the refresh token and then log in automatically.
   */
  const loginWithToken = React.useCallback(
    async (token: string) => {
      try {
        // Get the new access token
        const authData = await performLoginWithToken({
          variables: {
            token: token,
          },
        });

        const loginSuccessfulData = extractAdminLoginSuccessfulData(
          authData.data
        );

        if (!loginSuccessfulData || !loginSuccessfulData.accessToken) {
          // If an attempt was made to log in with an admin token and this did not work, the currently secured jwt token and the user should be deleted
          await logout();
          return;
        }
        // Set the new access token in the local storage
        setAccessToken(loginSuccessfulData.accessToken);
        // Set the new refresh token in the local storage
        setRefreshToken(loginSuccessfulData.refreshToken);
        // Get the auth user
        await performGetUser();
      } catch (error) {
        await logout().catch(noop);
      }

      // Delete the token
      const queryParams = new URLSearchParams(search);
      queryParams.delete('token');
      history.replace({
        search: queryParams.toString(),
      });
    },
    [performGetUser, logout, performLoginWithToken, search, history]
  );

  // Auto login logic (User auto login and admin login)
  React.useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const loginQueryToken = queryParams.get('token');

    if (getQueryLanguage()) {
      setIsQueryLanguageSet(true);
    }

    // Do nothing if a user is already logged in
    if (authUser !== null) {
      return;
    }

    if (loginQueryToken) {
      loginWithToken(loginQueryToken).then();
    } else if (checkPresenceOfTokens()) {
      // only try to get user, when there are tokens present in local storage
      performGetUser().then();
    } else {
      // in case no tokens were found in local storage logout aiming to show login page
      logout().then();
    }
  }, [
    performGetUser,
    setWaitingForAuthStatus,
    logout,
    loginWithToken,
    history,
    authUser,
    search,
  ]);

  const hasPermission = React.useCallback(
    (permissionType: AuthUserPermissionEnum) => {
      const hasGivenPermission = authUser?.permissions.includes(permissionType);

      return hasGivenPermission ?? false;
    },
    [authUser]
  );

  const actions = React.useMemo(() => {
    return { logout, login, loginWithToken };
  }, [logout, login, loginWithToken]);

  const context = React.useMemo(() => {
    const isMasterAccount = authUser?.roles.includes(
      SecurityRoleEnum.VxUserMaster
    );
    return {
      authUser,
      accessToken,
      isAuthenticated: authUser !== null,
      isVXAdminLoggedIn,
      isMasterAccount,
      waitingForAuthStatus,
      hasPermission,
      actions,
    };
  }, [
    authUser,
    hasPermission,
    isVXAdminLoggedIn,
    accessToken,
    waitingForAuthStatus,
    actions,
  ]);

  return (
    <authContext.Provider key={authUser?.userId} value={context}>
      {typeof children === 'function' ? children(authUser?.userId) : children}
    </authContext.Provider>
  );
};

export const useAuth = () => React.useContext(authContext);
export const useLang = (defaultLang = ApiLangEnum.De) =>
  useAuth().authUser?.lang || defaultLang;
