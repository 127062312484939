import {
  Button,
  Heading,
  Img,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import mrfImage from '../../../assets/images/wizard/model-release-form-welcome.svg';
import { MRFModal } from '../../../pages/Home/section/TaskSection/MRFModal';
import {
  ChakraUniversalCard,
  UniversalCardSectionWithBorder,
} from '../cards/UniversalCard/UniversalCard';

export const ModelReleaseFormCard: React.FC<{
  onClick?: () => void;
  isStudio?: boolean;
  userId: number;
}> = ({ isStudio = false, userId }) => {
  const { t } = useTranslation(['payoutDocuments']);
  const {
    isOpen: isMRFModalOpen,
    onClose: onCloseMRFModal,
    onOpen: onOpenMRFModal,
  } = useDisclosure();

  return (
    <UniversalCardSectionWithBorder>
      <ChakraUniversalCard
        cardBody={
          <>
            <Img
              src={mrfImage}
              alt={t(
                'payoutDocuments:imgAlt.IllustrationVonZweiPersonenDieSichDieHandGebenImHintergrundSchwebtEiXX'
              )}
            />
            <VStack mt={'4'} spacing={'4'}>
              <Heading size={'xl'} p={0} textAlign={'center'}>
                {t('payoutDocuments:heading.ModelReleaseFormAusfuellen')}
              </Heading>
              <Text textAlign={'center'} color={'gray.500'} p={0}>
                {!isStudio
                  ? t(
                      'payoutDocuments:text.UmDeineInhalteVeroffentlichenZuKonnenBenotigenWirDeinEinverstandnisDXX'
                    )
                  : t(
                      'payoutDocuments:text.UmDeineInhalteVeroffentlichenZuKonnenBenotigenWirDasEinverstandnisDeXX'
                    )}
              </Text>
            </VStack>
          </>
        }
        cardFooter={
          <Button
            shadow={'none'}
            variant={'solid'}
            children={t('payoutDocuments:button.ModelReleaseFormAusfuellen')}
            onClick={onOpenMRFModal}
          />
        }
      />
      <MRFModal
        isOpen={isMRFModalOpen}
        onClose={onCloseMRFModal}
        userId={userId}
      />
    </UniversalCardSectionWithBorder>
  );
};
