import { Maybe } from 'graphql/jsutils/Maybe';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../../components/Layout/ChakraToastContainer';
import { FeatureName, useFeature } from '../../flags';
import {
  DocumentsFinancialRealPersonFragment,
  FinanceServiceCollectionFragment,
  FinancesPayoutMethodEnum,
  FinancesPayoutStatusEnum,
  GetFinanceServiceDocument,
  InputProfileCollectionPayoutV1,
  OldFinancesInvoiceFragment,
  ProfileFieldValidationSetFragment,
  ProfileStatusEnum,
  useCancelManualPayoutMutation,
  useGetFinanceServiceQuery,
  useGetInvoicesLazyQuery,
  useRequestManualPayoutMutation,
  useUpdatePayoutCollectionMutation,
} from '../../generated/graphql';
import { createContext } from '../../hooks/useContext';
import { useDefaultApolloErrorHandler } from '../../hooks/useDefaultApolloErrorHandler';
import { prepareYearBuckets } from '../../pages/FinancePage/utils/prepareYearBuckets';
import { extractPayoutV1ValidationErrors } from '../../utils/extractPayoutV1ValidationErrors';
import { extractFromUnion } from '../../utils/extractor';
import { isDefined, parseISODateStamp } from '../../utils/utils';
import { useAuth } from '../AuthProvider';
import { useMinuteStamp } from '../MinuteStampProvider';
import { useToday } from '../TodayProvider';

export interface FinanceServiceProviderProps {
  children?: React.ReactNode;
}

export interface FinanceServiceContextAction {
  requestWithdrawal: () => void;
  cancelWithdrawal: () => void;
  triggerRefetch: () => void;
  triggerInvoiceRefetch: () => void;
  updatePayoutCollection: (
    data: InputProfileCollectionPayoutV1
  ) => Promise<ProfileFieldValidationSetFragment | null>;
}

interface IPayoutStatus {
  payableAmount: number;
  isFirstPayout: boolean;
  nextPaymentDate: Date | null;
  payoutStatus: FinancesPayoutStatusEnum | null;
  minimalPayableAmount: number;
  hasEnoughAmountForPayout: boolean;
  isAccountFromGermany: boolean;
  isAccountFromEU: boolean;
  isAccountFromCH: boolean;
  isCompany: boolean;
  showTin: boolean;
  billingFromDate: Date | null;
  billingEndDate: Date | null;
}

interface IPayoutAddressStatus {
  isPayoutAddressUpdateVisible: boolean;
  isPayoutAddressUpdateEditAllowed: boolean;
  isPayoutAddressUpdatePending: boolean;
  isPayoutAddressUpdateRejected: boolean;
  payoutAddressUpdateRejectReason: Maybe<string>;
  isPayoutAddressFromCompany: boolean;
}

interface IPayoutMethodStatus {
  forcedPayoutMethod: FinancesPayoutMethodEnum | null;
  isPayoutMethodActiveTransactionTypeUnallowd: boolean;
  isPayoutMethodBanktransferDisabled: boolean;
  isPayoutMethodPaxumPreselected: boolean;
  isPayoutMethodPaxumDisabled: boolean;
  isPayoutMethodUpdateVisible: boolean;
  isPayoutMethodUpdateEditAllowed: boolean;
  isPayoutMethodUpdatePending: boolean;
  isPayoutMethodUpdateRejected: boolean;
  payoutMethodUpdateRejectReason: Maybe<string>;
}

interface IPayoutTaxStatus {
  isPayoutTaxUpdateVisible: boolean;
  isPayoutTaxUpdateEditAllowed: boolean;
  isPayoutTaxUpdatePending: boolean;
  isPayoutTaxUpdateRejected: boolean;
  payoutTaxUpdateRejectReason: Maybe<string>;
  isPayoutTaxDocumentUploadRequiredWhenTaxable: boolean;
  isPayoutTaxDocumentExpired: boolean;
  isPayoutTaxDocumentExpiringSoon: boolean;
  daysTillPayoutTaxDocumentExpires: Maybe<number>;
}

interface IPayoutWizardStatus {
  isPayoutWizardV1Active: boolean;
  isPayoutWizardV1Finished: boolean;
  payoutWizardV1Status: Maybe<ProfileStatusEnum>;
  isPayoutWizardV2Active: boolean;
  isPayoutWizardV2Finished: boolean;
  payoutWizardV2Status: Maybe<ProfileStatusEnum>;
  isPayoutWizardActive: boolean;
  isPayoutWizardFinished: boolean;
  payoutWizardStatus: Maybe<ProfileStatusEnum>;
}

interface IPayoutInvoices {
  invoiceList: OldFinancesInvoiceFragment[];
  invoiceYearBuckets: number[];
  currentYear: number;
}

interface IPayoutAffiliateStatus {
  startdate: string;
  enddate: string;
  totalSharing: number;
}

export interface FinanceServiceContext
  extends IPayoutStatus,
    IPayoutWizardStatus,
    IPayoutInvoices,
    IPayoutMethodStatus,
    IPayoutAddressStatus,
    IPayoutTaxStatus {
  loading: boolean;
  invoiceDataLoading: boolean;
  originatorUserId?: Maybe<number>;
  financialDocuments?: Maybe<DocumentsFinancialRealPersonFragment>;
  fields: FinanceServiceCollectionFragment['fields'];
  values: FinanceServiceCollectionFragment['values'];
  action: FinanceServiceContextAction;
  affiliateStatus: IPayoutAffiliateStatus;
}
export const [, useFinanceService, financeServiceContext] =
  createContext<FinanceServiceContext>({
    name: 'FinanceServiceContext',
    errorMessage:
      'useFinanceService: `FinanceServiceContext` is undefined. Seems you forgot to wrap component within the Provider',
    strict: true,
  });

export const FinanceServiceProvider: React.FC<FinanceServiceProviderProps> = ({
  children,
}) => {
  const payoutV2WizardFlag = useFeature(FeatureName.wizardPayoutV2);
  const documentFinanceEditAllowedFlag = useFeature(
    FeatureName.documentFinanceEditAllowed
  );

  const { todayDayStamp } = useToday();
  const { minuteStamp } = useMinuteStamp();
  const { t } = useTranslation(['general']);
  const { isAuthenticated, isMasterAccount } = useAuth();

  const { onQueryError, onMutationError } = useDefaultApolloErrorHandler();

  const firstOfMonth = React.useMemo(() => {
    const today = DateTime.local();
    return today.startOf('month').toISODate();
  }, []);

  const lastOfMonth = React.useMemo(() => {
    const today = DateTime.local();
    return today.endOf('month').toISODate();
  }, []);

  const { data, loading, refetch } = useGetFinanceServiceQuery({
    onError: onQueryError,
    skip: !isAuthenticated,
    variables: {
      affiliateStartDate: firstOfMonth,
      affiliateEndDate: lastOfMonth,
    },
  });

  const [getInvoicesQuery, { data: invoiceData, loading: invoiceDataLoading }] =
    useGetInvoicesLazyQuery({
      onError: onQueryError,
    });

  const { fields, values } = React.useMemo(() => {
    const collection = extractFromUnion(
      data?.profile?.collection,
      'ProfileCollectionPayoutV1'
    );

    return {
      fields: collection?.fields ?? {},
      values: collection?.values ?? {},
    };
  }, [data]);

  const isAccountFromCH = data?.account?.isAccountFromCH ?? false;
  const isAccountFromGermany = data?.account?.isAccountFromGermany ?? true;
  // For some reason data?.account?.isAccountFromEU comes as false when data?.account?.isAccountFromGermany is true
  const isAccountFromEU =
    isAccountFromGermany || (data?.account?.isAccountFromEU ?? true);
  const isCompany = data?.account?.originator?.address?.isCompany ?? false;
  const showTin = data?.payment?.taxData?.showTin ?? false;
  const isAllowedToSeeFinanceUpdateSections = isMasterAccount ?? true;
  const expirationDateOfLastAcceptedTaxDocument =
    data?.account?.expirationDateOfLastAcceptedTaxDocument ?? null;

  /**
   * # General Payout Status
   */
  //#region    mappedPayoutStatusData

  const mappedPayoutStatusData = React.useMemo<IPayoutStatus>(() => {
    const payoutStatusInfo = data?.finances?.payoutStatus;

    // used for IncomeStatsTile and PriorToPayoutSetupSection
    const payableAmount = payoutStatusInfo?.payableAmount ?? 0;

    // used for NextPayoutCardSection
    const minimalPayableAmount = payoutStatusInfo?.minimalPayableAmount ?? 50;

    return {
      payableAmount,
      isAccountFromGermany,
      isAccountFromEU,
      isAccountFromCH,
      showTin,
      isCompany,
      isFirstPayout: payoutStatusInfo?.isFirstPayout ?? true,
      nextPaymentDate: parseISODateStamp(payoutStatusInfo?.payoutDate),
      payoutStatus: payoutStatusInfo?.status ?? null,
      minimalPayableAmount,
      hasEnoughAmountForPayout: payableAmount > minimalPayableAmount,
      billingFromDate: parseISODateStamp(payoutStatusInfo?.billingFromDate),
      billingEndDate: parseISODateStamp(payoutStatusInfo?.billingEndDate),
    };
  }, [
    data?.finances?.payoutStatus,
    isAccountFromCH,
    isAccountFromEU,
    isAccountFromGermany,
    isCompany,
    showTin,
  ]);
  //#endregion mappedPayoutStatusData

  /**
   * # Payout Wizard
   * related sates and data mapping
   */
  //#region    mappedWizardData

  const mappedWizardData: IPayoutWizardStatus = React.useMemo(() => {
    const payoutWizardV1 = extractFromUnion(
      data?.payoutWizardV1,
      'TourPayoutV1'
    );
    const isPayoutWizardV1Active = payoutWizardV1?.active ?? false;
    const isPayoutWizardV1Finished =
      payoutWizardV1?.status === ProfileStatusEnum.Accepted ||
      (!isPayoutWizardV1Active &&
        !!payoutWizardV1?.status &&
        payoutWizardV1?.status !== ProfileStatusEnum.Incomplete) ||
      !!(payoutWizardV1?.lastVerified ?? false) ||
      false;

    const payoutWizardV1Status = payoutWizardV1?.status ?? null;

    const payoutWizardV2 = extractFromUnion(
      data?.payoutWizardV2,
      'TourPayoutV2'
    );
    const isPayoutWizardV2Active = payoutWizardV2?.active ?? false;
    const payoutWizardV2Status = payoutWizardV2?.status ?? null;
    const isPayoutWizardV2Finished =
      payoutWizardV2Status === ProfileStatusEnum.Accepted ||
      (!isPayoutWizardV2Active &&
        !!payoutWizardV2Status &&
        payoutWizardV2Status !== ProfileStatusEnum.Incomplete) ||
      false;

    return {
      isPayoutWizardV1Active,
      isPayoutWizardV1Finished,
      payoutWizardV1Status,
      isPayoutWizardV2Active,
      isPayoutWizardV2Finished,
      payoutWizardV2Status,
      isPayoutWizardActive: payoutV2WizardFlag
        ? isPayoutWizardV2Active
        : isPayoutWizardV1Active,
      isPayoutWizardFinished: payoutV2WizardFlag
        ? isPayoutWizardV2Finished
        : isPayoutWizardV1Finished,
      payoutWizardStatus: payoutV2WizardFlag
        ? payoutWizardV2Status
        : payoutWizardV1Status,
    };
  }, [data?.payoutWizardV1, data?.payoutWizardV2, payoutV2WizardFlag]);
  //#endregion mappedWizardData

  /**
   * # Payout Method
   * related sates and data mapping
   */
  //#region    mappedPayoutMethodState

  const mappedPayoutMethodState = React.useMemo<IPayoutMethodStatus>(() => {
    let forcedPayoutMethod = null;
    if (isAccountFromGermany) {
      forcedPayoutMethod = FinancesPayoutMethodEnum.Bank;
    }
    if (!isAccountFromEU && !isAccountFromGermany) {
      forcedPayoutMethod = FinancesPayoutMethodEnum.Paxum;
    }

    const isPayoutMethodBanktransferDisabled = !isAccountFromEU;
    const isPayoutMethodPaxumDisabled = !!isAccountFromGermany;
    const isPayoutMethodPaxumPreselected = !isAccountFromGermany;

    const payoutTransactionType = extractFromUnion(
      fields?.payoutTransactionType,
      'ProfileFieldTypeEnum'
    );

    const relevantFieldStatues =
      payoutTransactionType?.value === FinancesPayoutMethodEnum.Bank
        ? [
            fields?.payoutBankaccountOwner?.status,
            fields?.payoutIban?.status,
            fields?.payoutIban?.status,
          ]
        : [fields?.payoutPaxumEmailAccount?.status];

    const isPayoutMethodUpdatePending = relevantFieldStatues.some(
      (status) => status === ProfileStatusEnum.Pending
    );
    const isPayoutMethodUpdateRejected = relevantFieldStatues.some(
      (status) => status === ProfileStatusEnum.Rejected
    );

    const isPayoutMethodActiveTransactionTypeUnallowd =
      !!forcedPayoutMethod &&
      payoutTransactionType?.value !== forcedPayoutMethod;

    const payoutMethodUpdateRejectReason =
      payoutTransactionType?.value === FinancesPayoutMethodEnum.Bank
        ? fields?.payoutBankaccountOwner?.rejectReason ?? null
        : fields?.payoutPaxumEmailAccount?.rejectReason ?? null;

    return {
      forcedPayoutMethod,
      isPayoutMethodActiveTransactionTypeUnallowd,
      isPayoutMethodBanktransferDisabled,
      isPayoutMethodPaxumDisabled,
      isPayoutMethodPaxumPreselected,
      isPayoutMethodUpdateVisible:
        mappedWizardData.isPayoutWizardFinished &&
        isAllowedToSeeFinanceUpdateSections,
      isPayoutMethodUpdateEditAllowed: documentFinanceEditAllowedFlag,
      isPayoutMethodUpdatePending,
      isPayoutMethodUpdateRejected,
      payoutMethodUpdateRejectReason,
    };
  }, [
    documentFinanceEditAllowedFlag,
    fields,
    isAllowedToSeeFinanceUpdateSections,
    isAccountFromGermany,
    isAccountFromEU,
    mappedWizardData,
  ]);
  //#endregion mappedPayoutMethodState

  /**
   * # Address
   * related sates and data mapping
   */
  //#region    mappedPayoutAddressState

  const mappedPayoutAddressState = React.useMemo<IPayoutAddressStatus>(() => {
    const isPayoutAddressUpdatePending =
      fields?.payoutAddressDocument?.status === ProfileStatusEnum.Pending;
    const isPayoutAddressUpdateRejected =
      fields?.payoutAddressDocument?.status === ProfileStatusEnum.Rejected;
    const payoutAddressUpdateRejectReason =
      fields?.payoutAddressDocument?.rejectReason ?? null;

    const isPayoutAddressFromCompany = values?.payoutAddressIsCompany ?? false;

    return {
      isPayoutAddressFromCompany,
      isPayoutAddressUpdateVisible:
        mappedWizardData.isPayoutWizardFinished &&
        isAllowedToSeeFinanceUpdateSections,
      isPayoutAddressUpdateEditAllowed: documentFinanceEditAllowedFlag,
      isPayoutAddressUpdatePending,
      isPayoutAddressUpdateRejected,
      payoutAddressUpdateRejectReason,
    };
  }, [
    documentFinanceEditAllowedFlag,
    fields,
    values,
    isAllowedToSeeFinanceUpdateSections,
    mappedWizardData,
  ]);
  //#endregion mappedPayoutAddressState

  /**
   * # Tax
   * related sates and data mapping
   */
  //#region    mappedPayoutTaxState

  const mappedPayoutTaxState = React.useMemo<IPayoutTaxStatus>(() => {
    const isPayoutTaxUpdatePending =
      fields?.payoutTaxDocument?.status === ProfileStatusEnum.Pending;
    const isPayoutTaxUpdateRejected =
      fields?.payoutTaxDocument?.status === ProfileStatusEnum.Rejected;
    const payoutTaxUpdateRejectReason =
      fields?.payoutTaxDocument?.rejectReason ?? null;

    const isPayoutTaxDocumentUploadRequiredWhenTaxable = isAccountFromGermany;
    const isPayoutTaxUpdateVisible =
      mappedWizardData.isPayoutWizardFinished &&
      isAccountFromEU &&
      isAllowedToSeeFinanceUpdateSections;

    const currentMinute = DateTime.fromISO(minuteStamp);
    const expireTime = DateTime.fromISO(
      expirationDateOfLastAcceptedTaxDocument
    );
    const timeDifference = expireTime.diff(currentMinute, 'days');
    const daysTillPayoutTaxDocumentExpires = Math.ceil(timeDifference.days);

    const isPayoutTaxUpdateEditAllowed = documentFinanceEditAllowedFlag;

    return {
      isPayoutTaxUpdateVisible,
      isPayoutTaxUpdateEditAllowed,
      isPayoutTaxUpdatePending,
      isPayoutTaxUpdateRejected,
      payoutTaxUpdateRejectReason,
      isPayoutTaxDocumentUploadRequiredWhenTaxable,
      isPayoutTaxDocumentExpired:
        isPayoutTaxUpdateEditAllowed &&
        isPayoutTaxUpdateVisible &&
        expireTime < currentMinute,
      isPayoutTaxDocumentExpiringSoon:
        isPayoutTaxUpdateEditAllowed &&
        isPayoutTaxUpdateVisible &&
        daysTillPayoutTaxDocumentExpires > 0 &&
        daysTillPayoutTaxDocumentExpires <= 31,
      daysTillPayoutTaxDocumentExpires:
        !isPayoutTaxUpdateEditAllowed || !isPayoutTaxUpdateVisible
          ? null
          : daysTillPayoutTaxDocumentExpires,
    };
  }, [
    documentFinanceEditAllowedFlag,
    fields,
    minuteStamp,
    expirationDateOfLastAcceptedTaxDocument,
    isAllowedToSeeFinanceUpdateSections,
    isAccountFromGermany,
    isAccountFromEU,
    mappedWizardData,
  ]);
  //#endregion mappedPayoutTaxState

  /**
   * # Invoice
   * related sates and data mapping
   */
  //#region    mappedInvoiceData

  /**
   *
   */
  const mappedInvoiceData = React.useMemo<IPayoutInvoices>(() => {
    const todayDate = DateTime.fromISO(todayDayStamp);

    const invoices = invoiceData?.finances?.invoices;
    const signUpDate = parseISODateStamp(invoiceData?.account?.signupDate);
    const minInvoicePayoutDate = parseISODateStamp(invoices?.minDate);
    const maxInvoicePayoutDate = parseISODateStamp(invoices?.maxDate);
    const invoiceList: OldFinancesInvoiceFragment[] =
      invoices?.invoices?.filter(isDefined) ?? [];
    const currentYear = todayDate.year;
    const invoiceYearBuckets = prepareYearBuckets(
      todayDate,
      signUpDate,
      minInvoicePayoutDate,
      maxInvoicePayoutDate
    );

    return {
      invoiceList,
      invoiceYearBuckets,
      currentYear,
    };
  }, [invoiceData, todayDayStamp]);

  //#endregion mappedInvoiceData

  //#region    action

  const [requestWithdrawal] = useRequestManualPayoutMutation({
    onError: onMutationError,
    refetchQueries: [GetFinanceServiceDocument],
  });

  const [cancelWithdrawal] = useCancelManualPayoutMutation({
    onError: onMutationError,
    refetchQueries: [GetFinanceServiceDocument],
  });

  const [updatePayoutCollectionFn] = useUpdatePayoutCollectionMutation({
    onError: onMutationError,
    onCompleted: (data) => {
      const errors = extractPayoutV1ValidationErrors(data);

      if (!errors || !errors.hasErrors) {
        issueChakraToast({
          status: 'success',
          description: t('general:toast.AnderungenWurdenGespeichert'),
        });
      }
    },
    refetchQueries: [GetFinanceServiceDocument],
  });

  const updatePayoutCollection = React.useCallback(
    async (data: InputProfileCollectionPayoutV1) => {
      const result = await updatePayoutCollectionFn({
        variables: { data },
      });
      return extractPayoutV1ValidationErrors(result.data);
    },
    [updatePayoutCollectionFn]
  );

  const triggerRefetch = React.useCallback(() => {
    refetch().then();
  }, [refetch]);

  const triggerInvoiceRefetch = React.useCallback(() => {
    getInvoicesQuery().then();
  }, [getInvoicesQuery]);

  const action = React.useMemo<FinanceServiceContextAction>(() => {
    return {
      requestWithdrawal,
      cancelWithdrawal,
      triggerRefetch,
      triggerInvoiceRefetch,
      updatePayoutCollection,
    };
  }, [
    requestWithdrawal,
    cancelWithdrawal,
    triggerRefetch,
    triggerInvoiceRefetch,
    updatePayoutCollection,
  ]);

  const affiliateStatus = React.useMemo<IPayoutAffiliateStatus>(() => {
    const affiliate = data?.affiliate;
    return {
      startdate: firstOfMonth,
      enddate: lastOfMonth,
      totalSharing: affiliate?.detailed.totalSharing ?? 0,
    };
  }, [data?.affiliate, firstOfMonth, lastOfMonth]);

  //#endregion action

  //#region    context

  const context = React.useMemo<FinanceServiceContext>(() => {
    return {
      loading,
      ...mappedPayoutStatusData,
      ...mappedWizardData,
      ...mappedPayoutAddressState,
      ...mappedPayoutMethodState,
      ...mappedPayoutTaxState,
      invoiceDataLoading,
      ...mappedInvoiceData,
      originatorUserId: data?.account?.originator?.userId,
      financialDocuments: data?.account?.originator?.financialDocuments ?? null,
      fields,
      values,
      action,
      affiliateStatus,
    };
  }, [
    loading,
    mappedPayoutStatusData,
    mappedWizardData,
    mappedPayoutAddressState,
    mappedPayoutMethodState,
    mappedPayoutTaxState,
    invoiceDataLoading,
    mappedInvoiceData,
    data,
    fields,
    values,
    action,
    affiliateStatus,
  ]);

  //#endregion context

  return (
    <financeServiceContext.Provider value={context}>
      {children}
    </financeServiceContext.Provider>
  );
};
