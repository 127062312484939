import { UseDisclosureReturn, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';

import { issueChakraToast } from '../../../components/Layout/ChakraToastContainer';
import {
  ContestFragment,
  InputVideo,
  Maybe,
  VideoFragment,
  useGetContestVideoByRequestIdQuery,
} from '../../../generated/graphql';
import { createContext } from '../../../hooks/useContext';
import { ContestVideoUploadModal } from '../../../pages/Home/section/ContestSection/ContestVideoUploadModal';
import { MediaContext } from '../../MediaProvider';
import { useUploadyService } from '../VideoLibraryUploadyProvider/VideoLibraryUploadyProvider';
import { TusUploadEntry } from '../VideoLibraryUploadyProvider/videoUploadEnhancer';

export interface IMediaAlbumEdits {
  input?: InputVideo;
  thumbnail16ReplacementUploadId?: string;
  thumbnail16Replacement?: MediaContext['media'];
  thumbnail18ReplacementUploadId?: string;
  thumbnail18Replacement?: MediaContext['media'];
  errors?: any;
}

interface VideoContestModalContext {
  video: Maybe<VideoFragment> | undefined;
  videoLoading: boolean;
  editingUpload?: TusUploadEntry | null | undefined;
  editingUploadId?: string | null;
  contest?: ContestFragment | null;
  setContest?: React.Dispatch<React.SetStateAction<ContestFragment | null>>;
  unsubmittedEdits: IMediaAlbumEdits;
  actions: {
    setUnsubmittedEdits: React.Dispatch<React.SetStateAction<IMediaAlbumEdits>>;
  };
  videoModalDisclosure: UseDisclosureReturn;
  onModalClose: () => void;
}

export const [, useContestVideoModalContext, videoContestModalContext] =
  createContext<VideoContestModalContext>({
    name: 'videoContestModalContext',
    errorMessage:
      'useContestVideoModalContext: `videoContestModalContext` is undefined. Seems you forgot to wrap component within the Provider',
    strict: true,
  });

export const VideoContestModalProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const videoModalDisclosure = useDisclosure();
  const isOpen = videoModalDisclosure.isOpen;
  const { contestUploads } = useUploadyService();
  const [shouldFetch, setShouldFetch] = React.useState(false);
  const [currentContestUpload, setCurrentContestUpload] =
    React.useState<TusUploadEntry | null>(null);
  const [contest, setContest] = React.useState<ContestFragment | null>(null);
  const [video, setVideo] = React.useState<Maybe<VideoFragment>>(null);

  const [unsubmittedEdits, setUnsubmittedEdits] =
    React.useState<IMediaAlbumEdits>({});

  const actions: VideoContestModalContext['actions'] = React.useMemo(() => {
    return {
      setUnsubmittedEdits,
    };
  }, []);

  React.useEffect(() => {
    if (isOpen && currentContestUpload?.progress === 100) {
      setShouldFetch(true);
    }
  }, [currentContestUpload?.progress, isOpen]);

  React.useEffect(() => {
    if (isOpen && contestUploads) {
      const currentUpload = contestUploads[0] ?? null;
      setCurrentContestUpload(() => currentUpload);
    }
  }, [contestUploads, currentContestUpload, isOpen]);

  const videoIdQuery = useGetContestVideoByRequestIdQuery({
    variables: {
      requestId: currentContestUpload?.uploadRequestId ?? '',
    },
    skip: !isOpen || !shouldFetch || !!video,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
    onCompleted: (data) => {
      const videoById = data.media.videos.videoById;
      if (videoById) {
        setVideo(videoById);
      }
    },
  });

  const videoLoading = videoIdQuery.loading;

  const onModalClose = React.useCallback(() => {
    setCurrentContestUpload(null);
    setShouldFetch(false);
    setContest(null);
    videoModalDisclosure.onClose();
  }, [videoModalDisclosure]);

  React.useEffect(() => {
    if (!video || !currentContestUpload || !isOpen) return;
    if (video?.rejectionReason || currentContestUpload?.rejectionReason) {
      onModalClose();
      issueChakraToast({
        description:
          video.rejectionReason ?? currentContestUpload?.rejectionReason,
        status: 'error',
      });
    }
  }, [
    currentContestUpload,
    currentContestUpload?.rejectionReason,
    isOpen,
    onModalClose,
    video,
    videoModalDisclosure,
  ]);

  const context: VideoContestModalContext = React.useMemo(() => {
    return {
      video,
      videoLoading: videoLoading,
      editingUpload: currentContestUpload,
      editingUploadId: currentContestUpload?.uploadRequestId,
      unsubmittedEdits,
      actions,
      videoModalDisclosure,
      onModalClose,
      contest,
      setContest,
    };
  }, [
    video,
    videoLoading,
    currentContestUpload,
    unsubmittedEdits,
    actions,
    videoModalDisclosure,
    onModalClose,
    contest,
  ]);

  return (
    <videoContestModalContext.Provider value={context}>
      {children}
      <ContestVideoUploadModal />
    </videoContestModalContext.Provider>
  );
};
