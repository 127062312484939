import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Spacer,
  Switch,
  Text,
  VStack,
  chakra,
  useDisclosure,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { DateTime } from 'luxon';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import { EnumSelectFieldHookForm } from '../../../../../components/shared/FormElements/EnumSelectFieldHookForm/EnumSelectFieldHookForm';
import { HTipCard } from '../../../../../components/shared/cards/TipCard/TipCard';
import {
  ConfirmPlannedVideoStatusChangeDialog,
  ConfirmPlannedVideoStatusChangeDialogProps,
} from '../../../../../components/shared/dialogs/ConfirmPlannedVideoStatusChangeDialog/ConfirmPlannedVideoStatusChangeDialog';
import {
  VideoFragment,
  VideoReleasedStatusEnum,
  VideoStatusEnum,
  VideoTypeEnum,
} from '../../../../../generated/graphql';
import { useFlagsProviderV2 } from '../../../../../provider/FlagsProviderV2';
import { coinsToEuro } from '../../../../../utils/coinsToEuro';
import { getFormattedAmount } from '../../../../../utils/formattedAmount';
import { useVideoLibraryUploadListEntryScheduableTimeframe } from '../../../utils/scheduableTimeframe';
import {
  DateControl,
  DateRangePickerControl,
} from '../../DateControl/DateControl';
import {
  DiscountSliderControl,
  PriceSliderControlCoin,
  PriceSliderControlParent,
} from '../../PriceSliderControl/PriceSliderControl';
import { videoLibraryFieldName } from '../EntryModalContentBox';

interface VideoPriceCoinsConfig {
  min: number;
  max: number;
  steps: number[];
}

export const EntryPriceSettingsTab: React.FC<{
  video?: Maybe<VideoFragment> | undefined;
  contestId?: Maybe<number>;
}> = ({ video, contestId }) => {
  const { register, resetField } = useFormContext();
  const scheduableTimeframe =
    useVideoLibraryUploadListEntryScheduableTimeframe();
  const { t } = useTranslation(['videoEditModal', 'contest', 'feed']);
  const locale = useIntl().locale;
  const { coinsEnabled } = useFlagsProviderV2();

  const plannedStatusChangeDisclosure = useDisclosure();

  const currentSelectedOption: VideoReleasedStatusEnum = useWatch({
    name: videoLibraryFieldName.releasedStatus,
  });

  const [previousReleasedStatus, setPreviousReleasedStatus] =
    React.useState<VideoReleasedStatusEnum>(currentSelectedOption);

  const isPlannedReleaseDateInPast = React.useMemo(() => {
    const todayDate = DateTime.local();
    const plannedReleaseDate = DateTime.fromISO(video?.plannedReleaseDate);
    return todayDate > plannedReleaseDate;
  }, [video]);

  const isPlannedSelected =
    currentSelectedOption === VideoReleasedStatusEnum.Planned;

  const onConfirmStatusChange = () => {
    plannedStatusChangeDisclosure.onClose();
  };

  const onDiscardStatusChange = () => {
    plannedStatusChangeDisclosure.onClose();
    resetField(videoLibraryFieldName.releasedStatus);
  };

  const confirmStatusChangeDialogOptions: ConfirmPlannedVideoStatusChangeDialogProps =
    {
      statusToChangeTo: currentSelectedOption,
      confirmChange: onConfirmStatusChange,
      discardChange: onDiscardStatusChange,
      isOpen: plannedStatusChangeDisclosure.isOpen,
      onClose: plannedStatusChangeDisclosure.onClose,
    };

  const handleStatusChange = () => {
    const isVideoRatingPending = video?.status === VideoStatusEnum.Rating;

    // Using allIDCardsUploaded in order to check if the video has been initially edited
    // as the value is always undefined/false when the video has not been edited
    const hasVideoBeenInitiallyEdited = video?.allIDCardsUploaded;

    const plannedToOnlineOrOffline =
      previousReleasedStatus === VideoReleasedStatusEnum.Planned &&
      currentSelectedOption !== VideoReleasedStatusEnum.Planned;

    /**
     * Modal will open if
     * 1.the video is has been approved by support
     * 2.the planned release date is in the future
     * 3.the video has been initially edited
     * 4.the user has changed the status from planned to something else
     * */
    const willModalOpen =
      !isVideoRatingPending &&
      !isPlannedReleaseDateInPast &&
      hasVideoBeenInitiallyEdited &&
      plannedToOnlineOrOffline;

    if (willModalOpen) {
      plannedStatusChangeDisclosure.onOpen();
    }
  };
  // Ignoring ESLint warning as `handleStatusChange` is defined outside `useEffect` and doesn't depend on state/props.
  // It won't change across re-renders, hence won't cause the effect to re-run.
  React.useEffect(() => {
    handleStatusChange();
    setPreviousReleasedStatus(currentSelectedOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedOption]);

  React.useEffect(() => {
    if (!isPlannedSelected) {
      resetField(videoLibraryFieldName.publicationScheduledFor);
    }
  }, [isPlannedSelected, resetField]);
  const isPriceAndDiscountHidden = video?.type === VideoTypeEnum.VideoHotclip;

  const isPlannedSelectable = () => {
    if (isPriceAndDiscountHidden) {
      return false;
    }
    if (!video?.isPlannedReleaseDateEditable) {
      return false;
    }
    return true;
  };

  const isOfflineSelectable = () => {
    if (video?.status === VideoStatusEnum.New) {
      return false;
    }
    if (!isPlannedReleaseDateInPast) {
      return false;
    }
    return true;
  };

  const isDiscountChecked = useWatch({
    name: videoLibraryFieldName.discountEnabled,
  });

  const priceConf: VideoPriceCoinsConfig = {
    min: 0.49,
    max: 19.99,
    steps: [
      0.49, 0.99, 1.49, 1.99, 2.49, 2.99, 3.49, 3.99, 4.49, 4.99, 5.49, 5.99,
      6.49, 6.99, 7.49, 7.99, 8.49, 8.99, 9.49, 9.99, 10.49, 10.99, 11.49,
      11.99, 12.49, 12.99, 13.49, 13.99, 14.49, 14.99, 15.49, 15.99, 16.49,
      16.99, 17.49, 17.99, 18.49, 18.99, 19.49, 19.99,
    ],
  };

  const coinsConf: VideoPriceCoinsConfig = React.useMemo(() => {
    return {
      min: 3,
      max: video?.priceCoinsMax ?? 99,
      steps: Array.from(
        { length: (video?.priceCoinsMax ?? 99) - 2 },
        (_, i) => i + 3
      ),
    };
  }, [video?.priceCoinsMax]);

  const priceCoins = useWatch({ name: videoLibraryFieldName.price });
  const discountCoins = useWatch({ name: videoLibraryFieldName.discount });

  const coinsDiscountConf: VideoPriceCoinsConfig = React.useMemo(() => {
    return {
      min: 1,
      max: priceCoins - 1,
      steps: Array.from({ length: priceCoins - 1 }, (_, i) => i + 1),
    };
  }, [priceCoins]);

  return (
    <VStack alignItems={'stretch'} w={'full'} gap={6}>
      <FormControl hidden={isPriceAndDiscountHidden}>
        <FormLabel
          fontSize={'md'}
          fontWeight={'medium'}
          lineHeight={'5'}
          letterSpacing={'normal'}
          color={'coldGray.900'}
        >
          {t('videoEditModal:inputs.heading.Preis')}
        </FormLabel>
        <PriceSliderControlParent
          name={videoLibraryFieldName.price}
          steps={coinsEnabled ? coinsConf.steps : priceConf.steps}
        />
        {coinsEnabled && (
          <Text
            fontSize={'12px'}
            lineHeight={'16px'}
            color={'darkSteel'}
            fontWeight={'light'}
          >
            <Trans
              t={t}
              i18nKey={'feed:text.EntsprichtCaXEuro'}
              components={{
                price: (
                  <chakra.span fontWeight={'medium'}>
                    {`${getFormattedAmount(
                      coinsToEuro({ coins: Number(priceCoins) }),
                      locale
                    )}`}
                  </chakra.span>
                ),
              }}
            />
          </Text>
        )}
      </FormControl>
      <Box>
        {!!contestId && (
          <HTipCard
            text={t(
              'contest:text.NachEndeDesWettbewerbsWirdDeinVideoAutomatischEinShopvideoDanachKannXX'
            )}
          />
        )}
        <FormControl hidden={isPriceAndDiscountHidden || !!contestId}>
          <Divider />
          <HStack pt={6}>
            <FormLabel
              fontSize={'md'}
              fontWeight={'medium'}
              lineHeight={'5'}
              letterSpacing={'normal'}
              color={'coldGray.900'}
            >
              {t('videoEditModal:inputs.heading.Rabatt')}
            </FormLabel>
            <Spacer />
            <Switch
              isChecked={isDiscountChecked}
              size={'lg'}
              {...register(videoLibraryFieldName.discountEnabled)}
            />
          </HStack>
        </FormControl>
        <Box hidden={!isDiscountChecked}>
          {coinsEnabled ? (
            <>
              <PriceSliderControlCoin
                name={videoLibraryFieldName.discount}
                steps={coinsDiscountConf.steps}
              />
              <Text
                fontSize={'12px'}
                lineHeight={'16px'}
                color={'darkSteel'}
                fontWeight={'light'}
                pb={4}
              >
                <Trans
                  t={t}
                  i18nKey={'feed:text.GesamtbetragEntsprichtCaXEuro'}
                  components={{
                    price: (
                      <chakra.span fontWeight={'medium'}>
                        {`${getFormattedAmount(
                          coinsToEuro({
                            coins: Number(priceCoins - discountCoins),
                          }),
                          locale
                        )}`}
                      </chakra.span>
                    ),
                  }}
                />
              </Text>
            </>
          ) : (
            <DiscountSliderControl
              name={videoLibraryFieldName.discount}
              step={5}
              min={5}
              max={80}
            />
          )}
          <DateRangePickerControl
            name={videoLibraryFieldName.discountDateRange}
            label={t('videoEditModal:inputs.heading.RabattGultigkeit')}
            spacingProps={{
              pt: 2,
            }}
          />
        </Box>
      </Box>
      <VStack alignItems={'stretch'} w={'full'} gap={6} hidden={!!contestId}>
        <Divider />
        <FormControl>
          <EnumSelectFieldHookForm
            label={''}
            name={videoLibraryFieldName.releasedStatus}
            isDisabled={false}
          >
            <option value={VideoReleasedStatusEnum.Online}>
              {t('videoEditModal:inputs.selectField.Online')}
            </option>
            {isOfflineSelectable() && (
              <option value={VideoReleasedStatusEnum.Offline}>
                {t('videoEditModal:inputs.selectField.Offline')}
              </option>
            )}
            {isPlannedSelectable() && (
              <option value={VideoReleasedStatusEnum.Planned}>
                {t('videoEditModal:inputs.selectField.VideoEinplanen')}
              </option>
            )}
          </EnumSelectFieldHookForm>
        </FormControl>
        <Box hidden={!isPlannedSelected}>
          <DateControl
            isDisabled={!isPlannedSelected}
            name={videoLibraryFieldName.publicationScheduledFor}
            label={t('videoEditModal:inputs.heading.Veroeffentlichungstermin')}
            helperText={t(
              'videoEditModal:inputs.text.NachFreigabeDesSupportsWirdDeinVideoAutomZumGewahltenZeitpunktVeroffeXX'
            )}
            inputProps={{
              min: scheduableTimeframe.min,
              max: scheduableTimeframe.max,
            }}
          />
        </Box>
        <ConfirmPlannedVideoStatusChangeDialog
          {...confirmStatusChangeDialogOptions}
        />
      </VStack>
    </VStack>
  );
};
