import { Edit } from '@campoint/odi-ui-icons';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  SpacerProps,
  forwardRef,
} from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DatePicker from '../../../../components/shared/DatePicker/DatePicker';
import { FormControlHeaderStack } from '../../../../components/shared/FormElements/FormControlHeaderStack/FormControlHeaderStack';
import { ClearableElement } from '../ClearableElement/ClearableElement';

export const DateControl: React.FC<{
  name: string;
  label: React.ReactNode;
  helperText?: React.ReactNode;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  inputProps?: InputProps;
}> = ({ name, label, helperText, isDisabled, isReadOnly, inputProps }) => {
  const { register, getFieldState, setFocus, formState, setValue } =
    useFormContext();
  const { error, isTouched } = getFieldState(name, formState);

  const value = useWatch({ name });
  const hasValue = !!value;

  return (
    <FormControl
      isInvalid={!!error && isTouched}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
    >
      <FormControlHeaderStack>
        <FormLabel
          fontSize={'md'}
          fontWeight={'medium'}
          lineHeight={'5'}
          letterSpacing={'normal'}
          color={'coldGray.900'}
        >
          {label}
        </FormLabel>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControlHeaderStack>
      <InputGroup>
        <Input
          {...inputProps}
          type={'datetime-local'}
          {...register(name)}
          sx={{
            '::-webkit-datetime-edit': {
              visibility: hasValue ? 'unset' : 'hidden',
            },
          }}
        />
        {!isDisabled && hasValue && (
          <InputRightElement>
            <ClearableElement
              onClick={() => {
                setValue(name, null, { shouldTouch: true });
                setFocus(name);
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>

      {error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const DateRangePickerControl: React.FC<{
  isDisabled?: boolean;
  name: string;
  label: React.ReactNode;
  labelProps?: FormLabelProps;
  spacingProps?: SpacerProps;
}> = ({ name, label, labelProps, isDisabled, spacingProps }) => {
  const { t } = useTranslation(['videoEditModal', 'general']);
  const { setValue, setFocus } = useFormContext();
  const [startDate, endDate] = useWatch({ name });
  return (
    <FormControl isDisabled={isDisabled} {...spacingProps}>
      <FormControlHeaderStack>
        <FormLabel {...labelProps}>{label}</FormLabel>
      </FormControlHeaderStack>
      <DatePicker
        selectsRange={true as any}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setValue(name, update, { shouldTouch: true });
        }}
        disabled={isDisabled}
        isClearable={false}
        customInput={
          <ClearableDatePickerInput
            isClearShown={!!startDate || !!endDate}
            onClear={() => {
              setValue(name, [null, null], { shouldTouch: true });
              setFocus(name);
            }}
          />
        }
        popperPlacement="top-end"
        dateFormat={'dd.MM.yyyy'}
        placeholderText={t('videoEditModal:inputs.text.RabattzeitraumEingeben')}
      />
    </FormControl>
  );
};

const ClearableDatePickerInput = forwardRef<
  InputProps & {
    isClearShown: boolean;
    onClear: () => void;
  },
  'input'
>(({ isClearShown, onClear, ...inputProps }, ref) => {
  return (
    <InputGroup>
      <Input {...inputProps} ref={ref} />
      {isClearShown ? (
        <InputRightElement>
          <ClearableElement onClick={() => onClear()} />
        </InputRightElement>
      ) : (
        <InputRightElement pointerEvents={'none'}>
          <Icon as={Edit} boxSize={'icon.md'} color={'primary.300'} />
        </InputRightElement>
      )}
    </InputGroup>
  );
});
