import { usePrevious } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  DocumentFragment,
  DocumentStatusEnum,
  ExtendedProfileFieldTypeUploadDocumentFragment,
  MediaPictureFragment,
  MediaPictureInListFragment,
  MediaPictureStatusEnum,
  ProfileStatusEnum,
} from '../generated/graphql';
import { createContext } from '../hooks/useContext';
import { documentStatus } from '../pages/DocumentPages/utils/helper';
import Logger from '../utils/Logger';
import { loadPicture } from '../utils/media/loadPicture';

type Media = {
  readonly blob: Blob | null;
  readonly src: string | null;
  readonly filename: string | null;
  readonly duration?: number | null;
  readonly thumbnail?: Blob | null;
  readonly key: string;
  size?: {
    width: number;
    height: number;
  };
};

export type MediaContext = {
  readonly isInitialMediaLoading: boolean;
  readonly initialSrc: string | null;
  readonly initialSrcSet?: string | null;
  readonly rejectionReason?: string;
  readonly currentSrc: string | null;
  readonly hasReplacement: boolean;
  readonly status?: MediaPictureStatusEnum | DocumentStatusEnum.Missing;
  readonly isEditable: boolean;
  readonly media: Media & { umpId?: number; noMetadata?: boolean };
  readonly action: {
    readonly setReplacement: (
      media: Media & { umpId?: number; noMetadata?: boolean }
    ) => void;
    readonly clearReplacement: () => void;
  };
};
export const [, useMedia, mediaContext] = createContext<MediaContext>({
  name: 'MediaContext',
  errorMessage:
    'useMedia: `MediaContext` is undefined. Seems you forgot to wrap component within the Provider',
  strict: true,
});
type MediaProviderOptions = {
  initialSrc?: string;
  initialSrcSet?: string;
  initialSize?: { width: number; height: number } | undefined;
  initialFilename?: string;
  status?: MediaPictureStatusEnum | DocumentStatusEnum.Missing;
  rejectionReason?: string;
  isEditable?: boolean;
  initialReplacement?: Media;
  onReplacement?: (media: Media) => void;
  children?: React.ReactNode;
};

const Provider: React.FC<MediaProviderOptions> = ({
  initialSrc = null,
  initialSrcSet = null,
  initialSize = null,
  initialFilename = null,
  status,
  rejectionReason,
  isEditable = true,
  initialReplacement,
  onReplacement,
  children,
}) => {
  const [initialMediaSize, setInitialMediaSize] = React.useState<
    { width: number; height: number } | undefined
  >(initialSize ?? undefined);
  const initialMedia = React.useMemo(
    () => ({
      src: initialSrc,
      srcSet: initialSrcSet,
      filename: initialFilename,
      blob: null,
      key: uuidv4(),
      size: initialMediaSize,
    }),
    [initialSrc, initialSrcSet, initialFilename, initialMediaSize]
  );
  const [media, setMedia] = React.useState<
    Media & { umpId?: number; noMetadata?: boolean }
  >(initialReplacement ?? initialMedia);
  const previousMedia = usePrevious(media);

  const [isInitialMediaLoading, setIsInitialMediaLoading] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (
      media !== initialMedia &&
      media !== previousMedia &&
      media !== initialReplacement
    ) {
      onReplacement?.(media);
    }
  }, [media, initialMedia, previousMedia, onReplacement, initialReplacement]);

  React.useEffect(() => {
    let isSubscribed = true;
    if (!initialReplacement && !!initialSrc && !initialSize) {
      setIsInitialMediaLoading(true);
      loadPicture(initialSrc)
        .catch((error) => Logger.error(error))
        .then((result) => {
          if (!isSubscribed || !result) {
            return;
          }
          const size = { width: result.width, height: result.height };
          setInitialMediaSize({ width: result.width, height: result.height });
          setMedia((prevState) => {
            if (!!result && prevState.src === initialSrc) {
              return {
                ...prevState,
                size,
              };
            }
            return prevState;
          });
        })
        .finally(() => {
          if (!isSubscribed) {
            return;
          }
          setIsInitialMediaLoading(false);
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [
    initialSrc,
    initialSize,
    initialReplacement,
    setInitialMediaSize,
    setMedia,
  ]);

  const setReplacement = React.useCallback(
    (media: Media & { umpId?: number; noMetadata?: boolean }) => {
      setMedia((prevState) => {
        if (prevState?.src && prevState?.src !== initialSrc) {
          // Clear object url, when replacing here
          // silenty fails when it is a normal src, which is fine
          URL.revokeObjectURL(prevState.src);
        }

        return { ...media, umpId: media.umpId, noMetadata: media.noMetadata };
      });
    },
    [setMedia, initialSrc]
  );

  const clearReplacement = React.useCallback(() => {
    setMedia(initialMedia);
  }, [setMedia, initialMedia]);

  // clear any selected replacements, when a new initialMedia is seen.
  // Moslty relevant after uploading a new media file and receiving it back from the BE
  React.useEffect(() => {
    if (!initialReplacement) {
      clearReplacement();
    }
  }, [clearReplacement, initialReplacement]);

  const context: MediaContext = {
    isInitialMediaLoading,
    initialSrc,
    initialSrcSet,
    currentSrc: media.src ?? initialSrc ?? null,
    rejectionReason,
    media,
    status,
    isEditable,
    hasReplacement: !!media.src && media.src !== initialSrc,
    action: {
      setReplacement,
      clearReplacement,
    },
  };

  return <mediaContext.Provider value={context} children={<>{children}</>} />;
};

const FromMediaPicture: React.FC<
  Partial<MediaProviderOptions> & {
    ignoreSrcSet?: boolean;
    picture: Maybe<MediaPictureFragment> | Maybe<MediaPictureInListFragment>;
  }
> = ({ picture, ignoreSrcSet = false, ...props }) => (
  <Provider
    initialSrc={picture?.image?.src ?? undefined}
    initialSrcSet={
      ignoreSrcSet ? undefined : picture?.image?.srcset ?? undefined
    }
    status={picture?.status}
    rejectionReason={picture?.rejectReason ?? undefined}
    {...props}
  />
);

const FromDocument: React.FC<
  Partial<MediaProviderOptions> & {
    isRequired?: boolean;
    document: Maybe<DocumentFragment>;
  }
> = ({ isRequired = false, document, ...props }) => (
  <Provider
    initialSrc={document?.url ?? undefined}
    initialFilename={document?.filename ?? undefined}
    initialSrcSet={undefined}
    status={
      isRequired && !document
        ? DocumentStatusEnum.Missing
        : documentStatus(document?.status ?? undefined)
    }
    rejectionReason={document?.rejectionReason ?? undefined}
    {...props}
  />
);

const FromUploadDocument: React.FC<
  Partial<MediaProviderOptions> & {
    isRequired?: boolean;
    document?: Maybe<ExtendedProfileFieldTypeUploadDocumentFragment>;
  }
> = ({ isRequired = false, document, ...props }) => {
  const status = React.useMemo(() => {
    if (!document?.status) {
      return DocumentStatusEnum.Missing;
    }
    switch (document?.status) {
      case ProfileStatusEnum.Accepted:
        return MediaPictureStatusEnum.Ok;
      case ProfileStatusEnum.Rejected:
        return MediaPictureStatusEnum.Rejected;
      case ProfileStatusEnum.Pending:
        return MediaPictureStatusEnum.Pending;

      default:
        return DocumentStatusEnum.Missing;
    }
  }, [document]);

  return (
    <Provider
      initialSrc={document?.value?.url ?? undefined}
      initialFilename={document?.value?.filename ?? undefined}
      initialSrcSet={undefined}
      rejectionReason={document?.rejectReason ?? undefined}
      status={status}
      {...props}
    />
  );
};

const FromIdentityProofDocument: React.FC<
  Partial<MediaProviderOptions> & {
    isRequiredButMissing?: boolean;
    pickedReplacement?: MediaContext['media'] | null;
    document?: Maybe<DocumentFragment>;
  }
> = ({ isRequiredButMissing, pickedReplacement, document, ...props }) => {
  const hasDocumentReplacement = !!pickedReplacement?.src;
  return (
    <Provider
      initialSrc={pickedReplacement?.src ?? document?.url}
      initialFilename={document?.filename ?? undefined}
      status={
        hasDocumentReplacement
          ? undefined
          : isRequiredButMissing
          ? DocumentStatusEnum.Missing
          : documentStatus(document?.status ?? undefined)
      }
      rejectionReason={
        hasDocumentReplacement
          ? undefined
          : document?.rejectionReason ?? undefined
      }
      {...props}
    />
  );
};

/**
 * Handles the state of a media file that can be replaced
 *
 * Can take an initialSrc and status and exposes actions,
 * that can override the initial or empty media file by a file picker for example
 *
 * @see MediaInputProvider
 */
export const MediaProvider = Object.assign(
  Provider,
  // Component also acting as namespace for sub/convenience components
  // https://github.com/typescript-cheatsheets/react/issues/165#issuecomment-549965553
  {
    /**
     * infers props from single `picture:MediaPicture` like prop
     */
    FromMediaPicture,
    /**
     * infers props from single `document:DocumentFragment` like prop
     */
    FromDocument,

    /**
     * infers props from single `document:ExtendedProfileFieldTypeUploadDocument` like prop
     */
    FromUploadDocument,

    /**
     * infers props from single `document:DocumentFragment` like prop
     */
    FromIdentityProofDocument,
  }
);
