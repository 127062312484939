import { Circle, Divider, HStack, Text, Wrap } from '@chakra-ui/react';

import { VideoLibraryEntryModalContent } from '../../VideoLibraryVideoUploadListEntryModal';

export const VideoLibraryEntryModalNavbar: React.FC<{
  tabActive: number;
  setTabActive: (newTab: number) => void;
  tabContent: VideoLibraryEntryModalContent[];
}> = ({ tabActive, setTabActive, tabContent }) => {
  return (
    <Wrap gap={2} alignSelf={'start'} w={'full'} p={4} pt={0}>
      {tabContent?.map((item, idx) => {
        const navBarColor = idx === tabActive ? 'gray.900' : 'gray.300';
        const navBarTextColor = idx === tabActive ? 'gray.900' : 'gray.300';
        const showDivider = idx < tabContent.length - 1;
        return (
          <HStack
            key={item.title}
            gap={2}
            color={navBarColor}
            flexGrow={showDivider ? 1 : 0}
          >
            <HStack onClick={() => setTabActive(idx)} cursor={'pointer'}>
              <Circle border={'1px'} size="16px" borderColor={navBarColor}>
                <Text fontSize={'12px'} fontWeight={'medium'}>
                  {idx + 1}
                </Text>
              </Circle>
              <Text fontSize={'12px'} fontWeight={700} color={navBarTextColor}>
                {item.title}
              </Text>
            </HStack>
            {showDivider && (
              <Divider
                flexGrow={1}
                w={'full'}
                border={'1px'}
                color={'darkSteel'}
              />
            )}
          </HStack>
        );
      })}
    </Wrap>
  );
};
