import React from 'react';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import { Redirect, Route } from 'react-router-dom';

import { FeatureName, useFeature } from '../flags';
import { AuthUserPermissionEnum } from '../generated/graphql';
import AccountPage from '../pages/AccountPage/AccountPage';
import { ActorsDocumentsPage } from '../pages/DocumentPages/ActorsDocumentsPage/ActorsDocumentsPage';
import { CreateActorPage } from '../pages/DocumentPages/CreateActorPage/CreateActorPage';
import { DocumentsPageSubRouter } from '../pages/DocumentPages/DocumentsPageSubRouter/DocumentsPageSubRouter';
import { PayoutDocumentsPage } from '../pages/DocumentPages/PayoutDocumentsPage/PayoutDocumentsPage';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import FeatureFlagPage from '../pages/FeatureFlagPage/FeatureFlagPage';
import FeatureRequestPage from '../pages/FeatureRequestPage/FeatureRequestPage';
import { FeedPage } from '../pages/FeedPage/FeedPage';
import { FeedPostPage } from '../pages/FeedPage/FeedPostPage/FeedPostPage';
import FinancePage from '../pages/FinancePage/FinancePage';
import HelpCenterPage from '../pages/HelpCenterPage/HelpCenterPage';
import IncomeSourcesPage from '../pages/IncomeSourcesPage/IncomeSourcesPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import { LogoutPage } from '../pages/LogoutPage/LogoutPage';
import { ModelProfileParent } from '../pages/ModelProfileV2Page/ModelProfileParent/ModelProfileParent';
import { ModelProfileProvider } from '../pages/ModelProfileV2Page/provider/ModelProfileProvider';
import PasswordResetPage from '../pages/PasswordResetPage/PasswordResetPage';
import PasswordResetVerifyPage from '../pages/PasswordVerifyPage/PasswordResetVerifyPage';
import RedirectPage from '../pages/RedirectPage/RedirectPage';
import SignUpPage from '../pages/SignUpPage/SignUpPage';
import StatisticEditPage from '../pages/StatisticEditPage/StatisticEditPage';
import StatisticsPage from '../pages/StatisticsPage/StatisticsPage';
import { StatisticsPageProvider } from '../pages/StatisticsPage/provider/StatisticsPageProvider';
import TipsPage from '../pages/TipsPage/TipsPage';
import VideoLibraryPage from '../pages/VideoLibraryPage/VideoLibraryPage';
import VxLiveChatPage from '../pages/VxLivePage/ChatPage/VxLiveChatPage';
import { HouseRulesPage } from '../pages/legal/HouseRulesPage/HouseRulesPage';
import { ImprintPage } from '../pages/legal/ImprintPage/ImprintPage';
import { PrivacyPolicyPage } from '../pages/legal/PrivacyPolicyPage/PrivacyPolicyPage';
import { TermsAndConditionsPage } from '../pages/legal/TermsAndConditionsPage/TermsAndConditionsPage';
import { useAuth } from '../provider/AuthProvider';
import { FeedPostEditModalProvider } from '../provider/FeedPostEditModalProvider';
import { useFlagsProviderV2 } from '../provider/FlagsProviderV2';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import HomeRouter from './nestedRoutes/HomeRouter';
import VxLiveRouter from './nestedRoutes/VxLiveRouter';
import { routes } from './routesConfig';

const Router: React.FC = () => {
  const feedFlag = useFeature(FeatureName.feed);
  const documentFlag = useFeature(FeatureName.document);
  const videoLibraryFlag = useFeature(FeatureName.videoLibrary);
  const { coinsEnabled } = useFlagsProviderV2();
  const { authUser } = useAuth();
  const financeViewPermission = authUser?.permissions.includes(
    AuthUserPermissionEnum.FinanceView
  );
  return (
    <CacheSwitch>
      <Route path="/" exact>
        <Redirect to={routes.home.path} />
      </Route>

      {/* Error */}
      <Route path={routes.error.path} exact>
        <ErrorPage />
      </Route>

      {/* Logout wich can be prevented by history block */}
      <ProtectedRoute
        routeConfig={routes.logout}
        path={routes.logout.path}
        exact
      >
        <LogoutPage />
      </ProtectedRoute>

      {/* Email Verified */}
      <ProtectedRoute
        routeConfig={routes.emailVerified}
        path={routes.emailVerified.path}
        exact
      >
        <Redirect to={routes.home.path} />
      </ProtectedRoute>

      {/* Login */}
      <ProtectedRoute routeConfig={routes.login} path={routes.login.path} exact>
        <LoginPage />
      </ProtectedRoute>

      {/* Legal: PrivacyNoticePage */}
      <ProtectedRoute
        routeConfig={routes.privacyPolicy}
        path={routes.privacyPolicy.path}
        exact
      >
        <PrivacyPolicyPage />
      </ProtectedRoute>

      {/* Legal: TermsAndConditionsPage */}
      <ProtectedRoute
        routeConfig={routes.termsAndConditions}
        path={routes.termsAndConditions.path}
        exact
      >
        <TermsAndConditionsPage />
      </ProtectedRoute>

      {/* Legal: House Rules */}
      <ProtectedRoute
        routeConfig={routes.houseRules}
        path={routes.houseRules.path}
        exact
      >
        <HouseRulesPage />
      </ProtectedRoute>

      {/* Legal: Impressum */}
      <ProtectedRoute
        routeConfig={routes.imprint}
        path={routes.imprint.path}
        exact
      >
        <ImprintPage />
      </ProtectedRoute>

      {/* Reset Password Verify */}
      <ProtectedRoute
        routeConfig={routes.passwordResetVerify}
        path={routes.passwordResetVerify.path}
      >
        <PasswordResetVerifyPage />
      </ProtectedRoute>

      {/* Reset Password */}
      <ProtectedRoute
        routeConfig={routes.passwordReset}
        path={routes.passwordReset.path}
        exact
      >
        <PasswordResetPage />
      </ProtectedRoute>

      {/* Sign Up */}
      <ProtectedRoute
        routeConfig={routes.register}
        path={routes.register.path}
        exact
      >
        <SignUpPage />
      </ProtectedRoute>

      {/* Navigation Bar */}
      {/* Home */}
      <ProtectedRoute routeConfig={routes.home} path={routes.home.path}>
        <HomeRouter />
      </ProtectedRoute>

      {/* FeedPostDetailPage */}
      {feedFlag === true && (
        <ProtectedRoute
          routeConfig={routes.feedPost}
          path={routes.feedPost.path}
        >
          <FeedPostEditModalProvider>
            <FeedPostPage />
          </FeedPostEditModalProvider>
        </ProtectedRoute>
      )}

      {/* FeedPage */}
      {feedFlag && (
        <ProtectedRoute routeConfig={routes.feed} path={routes.feed.path}>
          <FeedPostEditModalProvider>
            <ModelProfileProvider>
              <FeedPage />
            </ModelProfileProvider>
          </FeedPostEditModalProvider>
        </ProtectedRoute>
      )}

      {/* Model Profile */}
      <ProtectedRoute
        routeConfig={routes.profileV2}
        path={routes.profileV2.path}
      >
        <ModelProfileParent />
      </ProtectedRoute>

      {/* VxLive Chat page so it stays cached and doesn't always reload*/}
      <CacheRoute path={routes.vxLiveChat.path} exact>
        <VxLiveChatPage />
      </CacheRoute>
      {/* Other VxLive routes*/}
      <ProtectedRoute routeConfig={routes.vxLive} path={routes.vxLive.path}>
        <VxLiveRouter />
      </ProtectedRoute>

      {/* Finance */}
      <ProtectedRoute routeConfig={routes.finance} path={routes.finance.path}>
        {financeViewPermission ? (
          <FinancePage />
        ) : (
          <Redirect to={routes.home.path} />
        )}
      </ProtectedRoute>

      {/* Routes in MenuSheet */}

      {/* Account */}
      <ProtectedRoute
        routeConfig={routes.account}
        path={routes.account.path}
        exact
      >
        <AccountPage />
      </ProtectedRoute>

      {documentFlag && (
        <ProtectedRoute
          routeConfig={routes.documentsActorsCreate}
          path={routes.documentsActorsCreate.path}
          exact
        >
          <CreateActorPage />
        </ProtectedRoute>
      )}

      {documentFlag && (
        <ProtectedRoute
          routeConfig={routes.documentsActorsDetail}
          path={routes.documentsActorsDetail.path}
          exact
        >
          <DocumentsPageSubRouter />
        </ProtectedRoute>
      )}

      {/* Actor Documents */}
      {documentFlag && (
        <ProtectedRoute
          routeConfig={routes.documentsActors}
          path={routes.documentsActors.path}
          exact
        >
          <ActorsDocumentsPage />
        </ProtectedRoute>
      )}

      {/* Payout Documents */}
      {documentFlag && (
        <ProtectedRoute
          routeConfig={routes.documentsPayout}
          path={routes.documentsPayout.path}
          exact
        >
          <PayoutDocumentsPage />
        </ProtectedRoute>
      )}

      {/* Tips */}
      <ProtectedRoute routeConfig={routes.tips} path={routes.tips.path} exact>
        <TipsPage />
      </ProtectedRoute>

      {/* Income Sources */}
      <ProtectedRoute
        routeConfig={routes.incomeSources}
        path={routes.incomeSources.path}
        exact
      >
        <IncomeSourcesPage />
      </ProtectedRoute>

      {/* Help */}
      <ProtectedRoute routeConfig={routes.help} path={routes.help.path} exact>
        <HelpCenterPage />
      </ProtectedRoute>

      {/* Feature Flags */}
      <ProtectedRoute
        routeConfig={routes.featureFlag}
        path={routes.featureFlag.path}
        exact
      >
        <FeatureFlagPage />
      </ProtectedRoute>

      {/* Feature Request Page */}
      {coinsEnabled === true && (
        <ProtectedRoute
          routeConfig={routes.featureRequest}
          path={routes.featureRequest.path}
          exact
        >
          <FeatureRequestPage />
        </ProtectedRoute>
      )}

      {/* Statistics Page */}
      {coinsEnabled === true && (
        <ProtectedRoute
          routeConfig={routes.statistics}
          path={routes.statistics.path}
          exact
        >
          <StatisticsPageProvider>
            <StatisticsPage />
          </StatisticsPageProvider>
        </ProtectedRoute>
      )}

      {/* Statistic Edit Page */}
      <ProtectedRoute
        routeConfig={routes.statisticEdit}
        path={routes.statisticEdit.path}
        exact
      >
        <StatisticEditPage />
      </ProtectedRoute>

      {/* Video Library Page */}
      {videoLibraryFlag === true && (
        <ProtectedRoute
          routeConfig={routes.videoLibrary}
          path={routes.videoLibrary.path}
          exact
        >
          <VideoLibraryPage />
        </ProtectedRoute>
      )}

      {/* Redirect page */}
      <ProtectedRoute
        routeConfig={routes.redirect}
        path={routes.redirect.path}
        exact
      >
        <RedirectPage />
      </ProtectedRoute>

      <Redirect to={routes.login.path} />
    </CacheSwitch>
  );
};

export default Router;
