import { ApolloError } from '@apollo/client';
import getAbortEnhancer from '@rpldy/abort';
import retryEnhancer from '@rpldy/retry-hooks';
import TusUploady from '@rpldy/tus-uploady';
import { composeEnhancers } from '@rpldy/uploady';
import * as React from 'react';

import { VideoFragment, VideoTypeEnum } from '../../../generated/graphql';
import { useBeforeUnload } from '../../../hooks/useBeforeUnload';
import { createContext } from '../../../hooks/useContext';
import { useQueryParamState } from '../../../hooks/useQueryParamState';
import { routes } from '../../../routes/routesConfig';
import { LocalStorageKeys } from '../../../types';
import { useAuth } from '../../AuthProvider';
import { useNavigationBlock } from '../../NavigationBlockProvider';
import {
  TusUploadEntry,
  useTusVideoUploadEnhancer,
} from './videoUploadEnhancer';
import { useTusVideoUploadHistoryEnhancer } from './videoUploadHistoryEnhancer';
import { tusVideoUploadLogEnhancer } from './videoUploadLogEnhancer';

interface UploadyServiceContext {
  allUploads: TusUploadEntry[];
  uploads: TusUploadEntry[];
  feedUploads: TusUploadEntry[];
  contestUploads: TusUploadEntry[];
  videos: VideoFragment[];
  initialLoading: boolean;
  videosLoading: boolean;
  videosError: ApolloError | undefined;
  unusedTotalCount: number;
  unusedMaxCount: number;
  isUploadAllowed: boolean;
  isUploading: boolean;
  loadMore: () => void;
  onDelete: (albumId: number, hideToast?: boolean) => Promise<void>;
  tabsAction: {
    tabIds: string[];
    tabId: string | null;
    setTabId: (newState: string | null) => void;
  };
  tusUploadDataLoading: boolean;
}

export const [, useUploadyService, uploadyServiceContext] =
  createContext<UploadyServiceContext>({
    name: 'UploadyServiceContext',
    errorMessage:
      'useUploadyService: `uploadyServiceContext` is undefined. Seems you forgot to wrap component within the Provider',
    strict: true,
  });

export const unusedTabId = 'unused';
export const videosTabId = 'videos';
export const photosTabId = 'photos';

export const UploadyServiceProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const authCtx = useAuth();
  const tabIds = React.useMemo(() => {
    return [unusedTabId, videosTabId, photosTabId];
  }, []);
  const [tabId, setTabId] = useQueryParamState('tabId');
  const unusedMaxCount = 200;

  // get information about the current uploads
  const [
    tusVideoUploadEnhancer,
    allUploads,
    filterTusUploads,
    hasUnfinishedUploads,
    tusUploadDataLoading,
  ] = useTusVideoUploadEnhancer(tabId ?? unusedTabId, setTabId);

  const videoLibraryTusUploads = React.useMemo(() => {
    return allUploads.filter(
      (upload) => upload.type === VideoTypeEnum.VideoShop
    );
  }, [allUploads]);

  const feedTusUploads = React.useMemo(() => {
    return allUploads.filter(
      (upload) => upload.type === VideoTypeEnum.VideoFeed
    );
  }, [allUploads]);

  const contestUploads = React.useMemo(() => {
    return allUploads.filter(
      (upload) => upload.type === VideoTypeEnum.VideoContest
    );
  }, [allUploads]);

  // get information about uploaded videos
  const [
    tusVideoUploadHistoryEnhancer,
    videos,
    initialLoading,
    videosLoading,
    videosError,
    totalItems,
    loadMore,
    onDelete,
  ] = useTusVideoUploadHistoryEnhancer(hasUnfinishedUploads, filterTusUploads);

  //check for unfinished uploads before leaving page
  useBeforeUnload(
    React.useMemo(() => {
      return hasUnfinishedUploads;
    }, [hasUnfinishedUploads])
  );

  const block = useNavigationBlock();
  React.useEffect(() => {
    return block.action.registerDirtyFlag(hasUnfinishedUploads, () => {}, [
      routes.logout.path,
    ]);
  }, [block.action, hasUnfinishedUploads]);

  /**
   * This enhancer allows:
   * 1.logging of upload events
   * 2.handling of current uploads
   * 3.handling of past uploads
   * 4.enables retries for uploads
   */
  const enhancer = React.useMemo(() => {
    return composeEnhancers(
      tusVideoUploadLogEnhancer,
      tusVideoUploadEnhancer,
      tusVideoUploadHistoryEnhancer,
      retryEnhancer,
      getAbortEnhancer()
    );
  }, [tusVideoUploadEnhancer, tusVideoUploadHistoryEnhancer]);

  const context: UploadyServiceContext = React.useMemo(() => {
    return {
      allUploads,
      uploads: videoLibraryTusUploads,
      feedUploads: feedTusUploads,
      contestUploads: contestUploads,
      videos,
      initialLoading,
      videosLoading,
      videosError,
      unusedTotalCount: totalItems,
      unusedMaxCount,
      isUploadAllowed: totalItems < unusedMaxCount,
      isUploading: videoLibraryTusUploads.length !== 0,
      loadMore,
      onDelete,
      tabsAction: {
        tabIds,
        tabId,
        setTabId,
      },
      tusUploadDataLoading,
    };
  }, [
    allUploads,
    videoLibraryTusUploads,
    feedTusUploads,
    contestUploads,
    videos,
    initialLoading,
    videosLoading,
    videosError,
    totalItems,
    loadMore,
    onDelete,
    tabIds,
    tabId,
    setTabId,
    tusUploadDataLoading,
  ]);

  return (
    <TusUploady
      key={authCtx.authUser?.userId}
      autoUpload={false}
      multiple={false}
      accept="video/*"
      enhancer={enhancer}
      forgetOnSuccess={true}
      chunkSize={64_000_000}
      // Mitigate Request Header Upload-Meta not set by disabling parallel option
      // todo: investigate cause of missing header
      parallel={4}
      destination={{
        headers: {
          // might expire during an upload
          Authorization: `Bearer ${localStorage.getItem(
            LocalStorageKeys.ACCESS_TOKEN
          )}`,
        },
      }}
      storagePrefix={`Uploady_${authCtx?.authUser?.userId}_`}
    >
      <uploadyServiceContext.Provider value={context}>
        {children}
      </uploadyServiceContext.Provider>
    </TusUploady>
  );
};
export const UserWrappedUploadyProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const authCtx = useAuth();

  return (
    <UploadyServiceProvider
      children={children}
      key={authCtx.authUser?.userId}
    />
  );
};
