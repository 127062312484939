import {
  ApiLangEnum,
  DocumentDetailPageMrfQuery,
  DocumentDetailPageMrfQueryVariables,
  ModelReleaseFormLanguageEnum,
  ModelReleaseFormRequestContextEnum,
  useDocumentDetailPageMrfQuery,
} from '../generated/graphql';
import * as ApolloReactHooks from '../graphql/ApolloHooksWithContext';
import * as Apollo from '@apollo/client';
import { Maybe } from 'graphql/jsutils/Maybe';

export const ensureModelReleaseFormLanguageEnum = (apiLang: Maybe<ModelReleaseFormLanguageEnum> | Maybe<ApiLangEnum>) => {
  switch (apiLang) {
    case ApiLangEnum.De:
    case ModelReleaseFormLanguageEnum.De:
    case 'de':
      return ModelReleaseFormLanguageEnum.De;

    case ModelReleaseFormLanguageEnum.En:
    case ApiLangEnum.En:
    case 'en':
      return ModelReleaseFormLanguageEnum.En;

    default:
      // default to german
      return ModelReleaseFormLanguageEnum.De;
  }
};


type ModelReleaseFormQueryHookOptions = Omit<ApolloReactHooks.QueryHookOptions<DocumentDetailPageMrfQuery, DocumentDetailPageMrfQueryVariables>, 'variables'> & {lang?: ModelReleaseFormLanguageEnum | ApiLangEnum | null};
type ModelReleaseFormQueryResult = Omit<Apollo.QueryResult<DocumentDetailPageMrfQuery, DocumentDetailPageMrfQueryVariables>, 'data'> & {
  modelReleaseForm: DocumentDetailPageMrfQuery['documents']['modelReleaseForm'] | undefined
};

export const useModelReleaseForm = (
  userId: number,
  options: ModelReleaseFormQueryHookOptions,
): ModelReleaseFormQueryResult => {
  const {lang, ...restOptions} = options;
  const { data, ...rest } = useDocumentDetailPageMrfQuery({
    ...restOptions,
    variables: {
      userId,
      lang: lang ? ensureModelReleaseFormLanguageEnum(lang) : ModelReleaseFormLanguageEnum.De, // @todo allow null,
      requestContext: ModelReleaseFormRequestContextEnum.Model, // @todo: remove requestContext from call and handle/decide it in the backend
    },
  });
  const modelReleaseForm = data?.documents?.modelReleaseForm;

  return { modelReleaseForm, ...rest };
};





