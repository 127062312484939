import { ApolloQueryResult } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { issueChakraToast } from '../components/Layout/ChakraToastContainer';
import { useCreateFeedContestPostMutation } from '../generated/feed';
import {
  GetPhotoLibraryUploadListEntryQuery,
  useGetPhotoLibraryUploadListEntryQuery,
  useVideoLibraryDeletePhotoMutation,
} from '../generated/graphql';
import { useBeforeUnload } from '../hooks/useBeforeUnload';
import { ContestPhotoUploadModal } from '../pages/Home/section/ContestSection/ContestPhotoUpload';
import { LocalStorageKeys } from '../types';
import { LocalStorage } from '../utils';
import { useContest } from './ContestProvider';
import { MediaContext } from './MediaProvider';
import { MAPPING_PICTURE_TYPE, useMediaUpload } from './MediaUploadProvider';

type ContestPhotoProviderType = {
  photoAlbumData: GetPhotoLibraryUploadListEntryQuery | undefined;
  photoAlbumLoading: boolean;
  refetchPhotoAlbum: () => Promise<
    ApolloQueryResult<GetPhotoLibraryUploadListEntryQuery>
  >;
  pendingUploads: MediaContext['media'][];
  pendingDeletions: number[];
  setPendingUploads: React.Dispatch<
    React.SetStateAction<MediaContext['media'][]>
  >;
  setPendingDeletions: React.Dispatch<React.SetStateAction<number[]>>;
  submit: (uploads?: MediaContext['media'][], deletions?: number[]) => void;
  abort: () => void;
  isWorking: boolean;
  photoModalDisclosure: ReturnType<typeof useDisclosure>;
};

export const initValues: ContestPhotoProviderType = {
  photoAlbumData: undefined,
  photoAlbumLoading: false,
  refetchPhotoAlbum: () =>
    Promise.resolve(
      {} as ApolloQueryResult<GetPhotoLibraryUploadListEntryQuery>
    ),
  pendingUploads: [],
  pendingDeletions: [],
  setPendingUploads: () => {},
  setPendingDeletions: () => {},
  submit: () => {},
  abort: () => {},
  isWorking: false,
  photoModalDisclosure: undefined as any,
};

export const contestPhotoContext =
  React.createContext<ContestPhotoProviderType>(initValues);

export const ContestPhotoProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { photoContest, refetchPhotoContest } = useContest();
  const { t } = useTranslation(['general']);

  // create album using feed
  const [createFeedContestPost, { loading: createLoading }] =
    useCreateFeedContestPostMutation();

  // get pictures
  const {
    loading: photoAlbumLoading,
    data: photoAlbumData,
    refetch: refetchPhotoAlbum,
  } = useGetPhotoLibraryUploadListEntryQuery({
    variables: {
      albumId: photoContest?.photoalbum?.albumId ?? 0,
      first: 100,
    },
    skip: photoContest?.photoalbum?.albumId === undefined,
    notifyOnNetworkStatusChange: true,
  });

  // upload logic
  const [pendingUploads, setPendingUploads] = React.useState<
    MediaContext['media'][]
  >([]);
  const { action, isUploading } = useMediaUpload();

  // delete logic
  const [pendingDeletions, setPendingDeletions] = React.useState<number[]>([]);
  const [deletePhoto] = useVideoLibraryDeletePhotoMutation();

  // submit logic
  const [photoSubmitIsWorking, setPhotoSubmitIsWorking] = React.useState(false);
  useBeforeUnload(photoSubmitIsWorking);
  const abort = () => {
    setPendingUploads([]);
    setPendingDeletions([]);
    setPhotoSubmitIsWorking(false);
    refetchPhotoContest();
    refetchPhotoAlbum();
  };
  const photoModalDisclosure = useDisclosure();
  const submit = async (
    uploads: MediaContext['media'][] = pendingUploads,
    deletions: number[] = pendingDeletions
  ) => {
    if (!photoContest) return;

    // upload
    setPhotoSubmitIsWorking(true);
    if (uploads.length > 0) {
      await createFeedContestPost({
        variables: {
          contestId: (photoContest.id ?? 0).toString(),
        },
      })
        .then(async (result) => {
          for (const media of uploads) {
            await action.upload(
              media.blob ?? undefined,
              media.filename ?? undefined,
              result.data?.vxmodels.createPhotoContextPost.uploadUrl,
              MAPPING_PICTURE_TYPE.contest,
              photoContest.photoalbum?.albumId
            );
          }
        })
        .catch((e) => {
          issueChakraToast({
            status: 'error',
            title: t('general:toast.DatenKonntenNichtGespeichertWerden'),
          });
          abort();
        });
    }

    // delete
    for (const deleteId of deletions) {
      await deletePhoto({
        variables: {
          albumId: photoContest.photoalbum?.albumId ?? 0,
          pictureId: deleteId,
        },
      }).catch((e) => {
        issueChakraToast({
          status: 'error',
          title: t('general:toast.DatenKonntenNichtGespeichertWerden'),
        });
        abort();
      });
    }

    LocalStorage.add(
      LocalStorageKeys.PARTICIPATED_IN_PHOTO_CONTSEST_ID,
      photoContest.id
    );
    photoModalDisclosure.onClose();
    abort(); // reset
  };

  return (
    <contestPhotoContext.Provider
      value={{
        photoAlbumData,
        photoAlbumLoading,
        refetchPhotoAlbum,
        pendingUploads,
        pendingDeletions,
        setPendingUploads,
        setPendingDeletions,
        submit: submit,
        abort: abort,
        isWorking:
          photoSubmitIsWorking ||
          isUploading ||
          photoAlbumLoading ||
          createLoading,
        photoModalDisclosure,
      }}
    >
      <ContestPhotoUploadModal />
      {children}
    </contestPhotoContext.Provider>
  );
};

export function usePhotoContest() {
  return React.useContext(contestPhotoContext);
}
