import { Section, SectionBody, SectionDescription, SectionHeader, SectionTitle, SectionTitleRow, SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import { Button, Container, Divider, FormControl, FormErrorIcon, FormErrorMessage, HStack, Icon, IconButton, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { issueChakraToast } from '../../../components/Layout/ChakraToastContainer';
import { FluentPageLayout } from '../../../components/Layout/FluentPageLayout';
import { LayoutCenteringVStack } from '../../../components/Layout/LayoutCenteringVStack';
import { LayoutHintsVStack } from '../../../components/Layout/LayoutHintsVStack';
import { ScrollToTargetBlock } from '../../../components/Layout/ScrollToTargetBlock';
import { SectionCenterContainer } from '../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../components/Layout/UrlFragmentScrollToTarget';
import {
  HeadingWithOptionalToggleAndGuideDot,
} from '../../../components/shared/HeadingWithOptionalToggleAndGuideDot/HeadingWithOptionalToggleAndGuideDot';
import {
  UpdateTaxInformationExpiredHintSlot,
} from '../../../components/shared/UpdateTaxInformationExpiredHintSlot/UpdateTaxInformationExpiredHintSlot';
import { DocumentSideEnum, DocumentStatusEnum, DocumentTypeEnum, ModelReleaseFormRequestContextEnum } from '../../../generated/graphql';
import useHistoryWithStorage from '../../../hooks/useHistoryWithStorage';
import { useDocumentDetailContext } from '../../../provider/DocumentDetailProvider/DocumentDetailProvider';
import { MediaFlowProvider } from '../../../provider/MediaFlowProvider';
import { MediaInputProvider } from '../../../provider/MediaInputProvider';
import { MediaPropertiesProvider } from '../../../provider/MediaPropertiesProvider';
import { MediaContext, MediaProvider } from '../../../provider/MediaProvider';
import { useNavigationBlock } from '../../../provider/NavigationBlockProvider';
import { routes } from '../../../routes/routesConfig';
import Logger from '../../../utils/Logger';
import { AgeVerificationCard } from '../ActorsDocumentsPage/components/AgeVerificationCard/AgeVerificationCard';
import { IdentityMediaFlow } from '../ActorsDocumentsPage/components/IdentityMediaFlow/IdentityMediaFlow';
import { IdentityProofPreviewBox } from '../ActorsDocumentsPage/components/IdentityProofPreviewBox/IdentityProofPreviewBox';
import { ModelReleaseFormCard } from '../../../components/shared/ModelReleaseFormCard/ModelReleaseFormCard';
import { useDocumentUpload } from '../CreateActorPage/hooks/useDocumentUpload';
import { DocumentDetailModelReleaseFormSection } from '../components/DocumentDetailModelReleaseFormSection';
import { useModelReleaseForm } from '../../../utils/modelReleaseForm';

const MissingDocumentErrorMessage: React.FC<{
  isShown: boolean;
}> = ({ isShown }) => {
  const { t } = useTranslation(['document']);

  return (
    <FormControl isInvalid={isShown}>
      <FormErrorMessage>
        <FormErrorIcon />
        {t('document:text.BitteLadeDasFehlendeDokumentHoch')}
      </FormErrorMessage>
    </FormControl>
  );
};

const MissingDocumentErrorMessage2: React.FC<{
  error?: string | null;
}> = ({ error }) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormErrorMessage>
        <FormErrorIcon />
        {error}
      </FormErrorMessage>
    </FormControl>
  );
};

const MissingDocumentNewMessage: React.FC<{}> = () => {
  const { t } = useTranslation(['general']);
  return (
    <HStack
      mt={1}
      lineHeight={'20px'}
      fontSize={'14px'}
      spacing={1}
      alignItems={'center'}
      flexShrink={1}
      maxW={'full'}
      color={'caribbeanGreen.500'}
    >
      <Icon as={icons.Schedule} boxSize={'16px'} />
      <Text
        textOverflow={'ellipsis'}
        overflow={'hidden'}
        flexShrink={1}
        whiteSpace={'nowrap'}
        minW={'0'}
      >
        {t('general:status.Neu')}
      </Text>
    </HStack>
  );
};

export const DocumentDetailPage: React.FC = () => {
  const params = useParams<{ userId: string }>();
  const parsedUserId = Number(params.userId);
  const userIdNumber = isNaN(parsedUserId) ? -1 : parsedUserId;

  const history = useHistoryWithStorage();
  const { t } = useTranslation([
    'document',
    'realPersonDetail',
    'general',
    'realPersonList',
    'payoutDocuments',
  ]);
  const {
    action: { registerDirtyFlag },
  } = useNavigationBlock();

  const uploadDoc = useDocumentUpload();

  const {
    requestContext,
    isOriginatorAndActor,
    mappedAgeVerificationData,
    ageVerificationDataLoading,
    ageVerficicationDataRefetch,
    idShotRequired,
  } = useDocumentDetailContext();

  const { modelReleaseForm } = useModelReleaseForm(userIdNumber, { fetchPolicy: 'cache-first' });

  const idShotRequiredForPerson = idShotRequired(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [
    identityProofFrontOrEntireDocument,
    setIdentityProofFrontOrEntireDocument,
  ] = React.useState<null | MediaContext['media']>(null);
  const [
    identityProofFrontOrEntireDocumentError,
    setIdentityProofFrontOrEntireDocumentError,
  ] = React.useState<null | string>(null);
  const hasIdentityProofFrontOrEntireDocumentReplacement =
    !!identityProofFrontOrEntireDocument?.blob;
  const [identityProofBackDocument, setIdentityProofBackDocument] =
    React.useState<null | MediaContext['media']>(null);
  const [identityProofBackDocumentError, setIdentityProofBackDocumentError] =
    React.useState<null | string>(null);
  const hasIdentityProofBackDocumentReplacement =
    !!identityProofBackDocument?.blob;
  const [idShotDocument, setIdShotDocument] = React.useState<
    null | MediaContext['media']
  >(null);
  const [idShotDocumentError, setIdShotDocumentError] = React.useState<
    null | string
  >(null);
  //id shot only required when originator is also actor
  //https://campoint.atlassian.net/browse/VXM-4129?focusedCommentId=58145
  const hasIdShotDocumentReplacement = isOriginatorAndActor
    ? !!idShotDocument?.blob
    : false;

  const isDirty =
    !!hasIdentityProofFrontOrEntireDocumentReplacement ||
    !!hasIdentityProofBackDocumentReplacement ||
    !!hasIdShotDocumentReplacement;

  const isMissingRequiredIdentityProofFrontOrEntireReplacement =
    !hasIdentityProofFrontOrEntireDocumentReplacement &&
    (mappedAgeVerificationData.isIdentityProofFrontRequiredButMissing ||
      mappedAgeVerificationData.documents?.identityProofFront?.status ===
      DocumentStatusEnum.Rejected);

  const isMissingRequiredIdentityProofBackReplacement =
    !hasIdentityProofBackDocumentReplacement &&
    (mappedAgeVerificationData.isIdentityProofBackRequiredButMissing ||
      mappedAgeVerificationData.documents?.identityProofBack?.status ===
      DocumentStatusEnum.Rejected);

  const isMissingRequiredIdShotReplacement =
    !hasIdShotDocumentReplacement &&
    (mappedAgeVerificationData.isIdShotRequiredButMissing ||
      mappedAgeVerificationData.documents?.idShot?.status ===
      DocumentStatusEnum.Rejected) &&
    isOriginatorAndActor;

  const isFormSubmitDisabled =
    !isDirty ||
    isMissingRequiredIdShotReplacement ||
    isMissingRequiredIdentityProofBackReplacement ||
    isMissingRequiredIdentityProofFrontOrEntireReplacement;

  const spacing = { base: 4, lg: 8 };

  const issueFailedSubmitToast = React.useCallback(() => {
    issueChakraToast({
      status: 'error',
      description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
    });
  }, [t]);

  const issueSuccessSubmitToast = React.useCallback(() => {
    issueChakraToast({
      status: 'success',
      description: t('general:toast.AnderungenWurdenGespeichert'),
    });
  }, [t]);

  const onValidSubmit = React.useCallback(async () => {
    if (userIdNumber === -1) {
      issueFailedSubmitToast();
      return;
    }

    try {
      setIsSubmitting(true);
      setIdentityProofFrontOrEntireDocumentError(null);
      setIdentityProofBackDocumentError(null);
      setIdShotDocumentError(null);

      const hasBack = !!identityProofBackDocument?.blob;

      const uploadResponse0 = !hasIdentityProofFrontOrEntireDocumentReplacement
        ? undefined
        : await uploadDoc({
          documentToUpload: identityProofFrontOrEntireDocument,
          variables: {
            userId: userIdNumber,
            documentType: DocumentTypeEnum.IdentityProof,
            side: !hasBack
              ? DocumentSideEnum.EntireDocument
              : DocumentSideEnum.Frontside,
          },
        });

      const uploadResponse1 = !hasIdentityProofBackDocumentReplacement
        ? undefined
        : await uploadDoc({
          documentToUpload: identityProofBackDocument,
          variables: {
            userId: userIdNumber,
            documentType: DocumentTypeEnum.IdentityProof,
            side: DocumentSideEnum.Backside,
          },
        });

      let uploadResponse2 = undefined;
      if (isOriginatorAndActor) {
        uploadResponse2 = !hasIdShotDocumentReplacement
          ? undefined
          : await uploadDoc({
            documentToUpload: idShotDocument,
            variables: {
              userId: userIdNumber,
              documentType: DocumentTypeEnum.IdShot,
              side: DocumentSideEnum.EntireDocument,
            },
          });
      }

      if (uploadResponse0?.message) {
        setIdentityProofFrontOrEntireDocumentError(uploadResponse0.message);
        issueFailedSubmitToast();
        return;
      }

      if (hasBack && uploadResponse1?.message) {
        setIdentityProofBackDocumentError(uploadResponse1.message);
        issueFailedSubmitToast();
        return;
      }

      if (uploadResponse2?.message) {
        setIdShotDocumentError(uploadResponse2.message);
        issueFailedSubmitToast();
        return;
      }

      await ageVerficicationDataRefetch();
      setIdentityProofFrontOrEntireDocument(null);
      setIdentityProofBackDocument(null);
      setIdShotDocument(null);
      issueSuccessSubmitToast();
    } catch (error) {
      Logger.error(error);
      issueFailedSubmitToast();
    } finally {
      setIsSubmitting(false);
    }
  }, [
    userIdNumber,
    issueFailedSubmitToast,
    identityProofBackDocument,
    hasIdentityProofFrontOrEntireDocumentReplacement,
    uploadDoc,
    identityProofFrontOrEntireDocument,
    hasIdentityProofBackDocumentReplacement,
    isOriginatorAndActor,
    ageVerficicationDataRefetch,
    issueSuccessSubmitToast,
    hasIdShotDocumentReplacement,
    idShotDocument,
  ]);

  React.useEffect(() => {
    return registerDirtyFlag(isDirty);
  }, [registerDirtyFlag, isDirty]);

  const isOriginatorOrActor =
    requestContext !== ModelReleaseFormRequestContextEnum.Model;

  return (
    <FluentPageLayout isContentLoading={ageVerificationDataLoading}>
      <SubHeader>
        <Container px={0} maxW={'container.xl'}>
          <HStack>
            {isOriginatorOrActor && (
              <IconButton
                variant="unstyled"
                aria-label={'BackButtonEditPage'}
                icon={<Icon as={icons.ChevronLeft} boxSize="6" />}
                onClick={() => {
                  history.push(routes.documentsActors.path);
                }}
              />
            )}

            <SubHeaderTitle>{mappedAgeVerificationData.name}</SubHeaderTitle>
          </HStack>
        </Container>
      </SubHeader>
      <Container maxW="container.xl" p={0}>
        <VStack w={'full'} spacing={spacing} mb={spacing}>
          <LayoutHintsVStack>
            <UpdateTaxInformationExpiredHintSlot
              outerStackProps={{ pt: spacing }}
            />
            {!mappedAgeVerificationData.isAgeVerificationWizardFinished && (
              <LayoutCenteringVStack outerStackProps={{ pt: spacing }}>
                <AgeVerificationCard />
              </LayoutCenteringVStack>
            )}
          </LayoutHintsVStack>
          {mappedAgeVerificationData.isAgeVerificationWizardFinished && (
            <Section>
              <SectionHeader>
                <SectionCenterContainer>
                  <SectionTitleRow>
                    <SectionTitle>
                      {t('document:heading.Altersverifizierung')}
                    </SectionTitle>
                  </SectionTitleRow>
                  <SectionDescription>
                    {t(
                      'document:text.MitDiesenAngabenVersicherstDuDasDuMind18JahreAltBist',
                    )}
                  </SectionDescription>
                </SectionCenterContainer>
              </SectionHeader>
              <SectionDivider isWidthRestricted />
              <SectionBody>
                <SectionCenterContainer>
                  <VStack
                    divider={<Divider />}
                    spacing={8}
                    alignItems={'stretch'}
                  >
                    <MediaPropertiesProvider.ForIdentityProofFrontOrEntireDocument>
                      <MediaProvider.FromIdentityProofDocument
                        document={
                          mappedAgeVerificationData.documents
                            ?.identityProofFront
                        }
                        isRequiredButMissing={
                          mappedAgeVerificationData.isIdentityProofFrontRequiredButMissing
                        }
                        pickedReplacement={identityProofFrontOrEntireDocument}
                        isEditable={!isSubmitting}
                      >
                        <MediaFlowProvider>
                          <ScrollToTargetBlock
                            id={UrlFragment.IdentityProofFrontOrEntireDocument}
                          >
                            <HeadingWithOptionalToggleAndGuideDot
                              heading={t(
                                'realPersonDetail:heading.LadeEinFotoDeinerAusweisVorderseiteHoch',
                              )}
                              isRequired={
                                mappedAgeVerificationData.isIdentityProofFrontRequired
                              }
                              isRequiredButMissing={
                                (mappedAgeVerificationData.isIdentityProofFrontRequiredButMissing &&
                                  !identityProofFrontOrEntireDocument?.blob) ||
                                !!identityProofFrontOrEntireDocumentError
                              }
                            />
                            <MissingDocumentErrorMessage
                              isShown={
                                mappedAgeVerificationData.isIdentityProofFrontRequiredButMissing &&
                                !identityProofFrontOrEntireDocument?.blob
                              }
                            />
                            <MissingDocumentErrorMessage2
                              error={identityProofFrontOrEntireDocumentError}
                            />
                            {hasIdentityProofFrontOrEntireDocumentReplacement && (
                              <MissingDocumentNewMessage />
                            )}

                            <IdentityProofPreviewBox
                              isUploading={isSubmitting}
                            />

                            <MediaInputProvider accept={'DEFAULT_FOR_PICTURE'}>
                              <IdentityMediaFlow
                                skipSelfUpload={true}
                                onMediaPick={(media) => {
                                  setIdentityProofFrontOrEntireDocument(media);
                                  setIdentityProofFrontOrEntireDocumentError(
                                    null,
                                  );
                                }}
                              />
                            </MediaInputProvider>
                          </ScrollToTargetBlock>
                        </MediaFlowProvider>
                      </MediaProvider.FromIdentityProofDocument>
                    </MediaPropertiesProvider.ForIdentityProofFrontOrEntireDocument>
                    <MediaPropertiesProvider.ForIdentityProofBackDocument>
                      <MediaProvider.FromIdentityProofDocument
                        document={
                          mappedAgeVerificationData.documents?.identityProofBack
                        }
                        isRequiredButMissing={
                          mappedAgeVerificationData.isIdentityProofBackRequiredButMissing
                        }
                        pickedReplacement={identityProofBackDocument}
                        isEditable={!isSubmitting}
                      >
                        <MediaFlowProvider>
                          <ScrollToTargetBlock
                            id={UrlFragment.IdentityProofBackDocument}
                          >
                            <HeadingWithOptionalToggleAndGuideDot
                              heading={t(
                                'realPersonDetail:heading.LadeEinFotoDeinerAusweisRuckseiteHoch',
                              )}
                              isRequired={
                                mappedAgeVerificationData.isIdentityProofBackRequired
                              }
                              isRequiredButMissing={
                                (mappedAgeVerificationData.isIdentityProofBackRequiredButMissing &&
                                  !hasIdentityProofBackDocumentReplacement) ||
                                !!identityProofBackDocumentError
                              }
                            />
                            <MissingDocumentErrorMessage
                              isShown={
                                mappedAgeVerificationData.isIdentityProofBackRequiredButMissing &&
                                !identityProofBackDocument?.blob
                              }
                            />
                            <MissingDocumentErrorMessage2
                              error={identityProofBackDocumentError}
                            />

                            {hasIdentityProofBackDocumentReplacement && (
                              <MissingDocumentNewMessage />
                            )}

                            <IdentityProofPreviewBox
                              isUploading={isSubmitting}
                            />

                            <MediaInputProvider accept={'DEFAULT_FOR_PICTURE'}>
                              <IdentityMediaFlow
                                skipSelfUpload={true}
                                onMediaPick={(media) => {
                                  setIdentityProofBackDocument(media);
                                  setIdentityProofBackDocumentError(null);
                                }}
                              />
                            </MediaInputProvider>
                          </ScrollToTargetBlock>
                        </MediaFlowProvider>
                      </MediaProvider.FromIdentityProofDocument>
                    </MediaPropertiesProvider.ForIdentityProofBackDocument>
                    {!mappedAgeVerificationData.documents?.idShot &&
                    !idShotRequiredForPerson ? null : (
                      <MediaPropertiesProvider.ForIdShotDocument>
                        <MediaProvider.FromIdentityProofDocument
                          document={mappedAgeVerificationData.documents?.idShot}
                          isRequiredButMissing={
                            mappedAgeVerificationData.isIdShotRequiredButMissing
                          }
                          pickedReplacement={idShotDocument}
                          isEditable={!isSubmitting}
                        >
                          <MediaFlowProvider>
                            <ScrollToTargetBlock
                              id={UrlFragment.IdShotDocument}
                            >
                              <HeadingWithOptionalToggleAndGuideDot
                                heading={t('realPersonDetail:heading.IDShot')}
                                isRequired={true}
                                isRequiredButMissing={
                                  (mappedAgeVerificationData.isIdShotRequiredButMissing &&
                                    !idShotDocument?.blob) ||
                                  !!idShotDocumentError
                                }
                              />
                              <Text
                                textStyle={'bodySm'}
                                color={'onSurface.mediumEmphasis'}
                              >
                                {t(
                                  'realPersonDetail:text.EinFotoVonDirAufDemDuDeinenAusweisGutLesbarNebenDeinGesichtHaltst',
                                )}
                              </Text>
                              <MissingDocumentErrorMessage
                                isShown={
                                  mappedAgeVerificationData.isIdShotRequiredButMissing &&
                                  !idShotDocument?.blob
                                }
                              />
                              <MissingDocumentErrorMessage2
                                error={idShotDocumentError}
                              />

                              {hasIdShotDocumentReplacement && (
                                <MissingDocumentNewMessage />
                              )}

                              <IdentityProofPreviewBox
                                isUploading={isSubmitting}
                              />

                              <MediaInputProvider
                                accept={'DEFAULT_FOR_PICTURE'}
                                capture={'user'}
                              >
                                <IdentityMediaFlow
                                  skipSelfUpload={true}
                                  onMediaPick={(media) => {
                                    setIdShotDocument(media);
                                    setIdShotDocumentError(null);
                                  }}
                                />
                              </MediaInputProvider>
                            </ScrollToTargetBlock>
                          </MediaFlowProvider>
                        </MediaProvider.FromIdentityProofDocument>
                      </MediaPropertiesProvider.ForIdShotDocument>
                    )}
                  </VStack>
                  <Button
                    mt={4}
                    variant={'solid'}
                    isLoading={isSubmitting}
                    alignSelf={'center'}
                    onClick={() => onValidSubmit()}
                    isDisabled={isFormSubmitDisabled}
                  >
                    {t('realPersonDetail:button.AnderungenSpeichern')}
                  </Button>
                </SectionCenterContainer>
              </SectionBody>
            </Section>
          )}


          {mappedAgeVerificationData.isAgeVerificationWizardFinished &&
            !modelReleaseForm && (
              <LayoutCenteringVStack>
                <ModelReleaseFormCard userId={userIdNumber} />
              </LayoutCenteringVStack>
            )}
          {modelReleaseForm && (
            <DocumentDetailModelReleaseFormSection userId={userIdNumber} />
          )}

        </VStack>
      </Container>
    </FluentPageLayout>
  );
};
