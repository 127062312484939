import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

import {
  GetAllNotificationsDocument,
  GetImportantNotificationsDocument,
  GetNewsNotificationsDocument,
  GetNotificationsCountDocument,
  NotificationFragment,
  NotificationTypeDisplayEnum,
  useMarkReadMutation,
} from '../../../../generated/notifications';
import useHistoryWithStorage from '../../../../hooks/useHistoryWithStorage';
import { useNewsCenter } from '../../../../provider/NewsCenterProvider/NewsCenterProvider';
import Logger from '../../../../utils/Logger';
import { gtag } from '../../../../utils/gtag';
import { getGtagEventParamsFromNotification } from '../NewsCenterPopper';
import {
  getExternalURLFromUri,
  getPathFromUri,
  showNotificationDetails,
} from '../utils';
import { NotificationItem } from './NotificationItem';

// todo: have this value used responsive switching to 50px on mobile once the layout does

type NotificationListItemWrapperProps = BoxProps & {
  notification: NotificationFragment;
  listItemComponent?: React.FC<{ notification: NotificationFragment }>;
};

export const NotificationListItemWrapper: React.FC<
  NotificationListItemWrapperProps
> = ({ notification, listItemComponent = NotificationItem, ...boxProps }) => {
  const { setSelectedNotification, onClose, onOpenAnnouncement } =
    useNewsCenter();
  const history = useHistoryWithStorage();

  const [markReadMutation] = useMarkReadMutation({
    variables: {
      id: notification.id,
    },
    // Cause queries to refetch after marking notifiaction as read
    // todo: might be refactored to be encapsulated in the NewsCenterProvider
    refetchQueries: [
      GetNotificationsCountDocument,
      GetImportantNotificationsDocument,
      GetAllNotificationsDocument,
      GetNewsNotificationsDocument,
    ],
    onError: (error) => Logger.error(error),
    onCompleted: () => {
      gtag(
        'event',
        'Notification_MarkAsRead',
        getGtagEventParamsFromNotification(notification)
      );
    },
  });

  const onClick = React.useCallback(() => {
    const gtagData = getGtagEventParamsFromNotification(notification);
    gtag('event', 'Click_Notification', gtagData);
    if (notification.readAt == null) {
      markReadMutation();
    }

    //--choose where to go next--
    if (!notification.uri) return;

    if (showNotificationDetails(notification.uri)) {
      setSelectedNotification(notification);
    } else {
      // Open new tab when external link is detected
      const externalUrl = getExternalURLFromUri(notification.uri);
      if (externalUrl) {
        window.open(externalUrl.href, '_blank');
        gtag('event', 'Click_Notification_External', {
          ...gtagData,
          externalUrl,
        });
        return;
      }

      const targetPath = getPathFromUri(notification.uri);

      if (targetPath) {
        onClose();
        history.push(targetPath);
        gtag('event', 'Click_Notification_Target', { ...gtagData, targetPath });
      }
    }
  }, [
    notification,
    setSelectedNotification,
    markReadMutation,
    onClose,
    history,
  ]);

  const onClickAnnouncement = React.useCallback(() => {
    const gtagData = getGtagEventParamsFromNotification(notification);
    gtag('event', 'Click_Notification', gtagData);
    if (notification.readAt == null) {
      markReadMutation();
    }

    setSelectedNotification(notification);

    //open the announcement modal
    onOpenAnnouncement();
  }, [
    notification,
    setSelectedNotification,
    onOpenAnnouncement,
    markReadMutation,
  ]);

  return (
    <Box
      as={'li'}
      w={'full'}
      cursor={'pointer'}
      onClick={
        notification.display === NotificationTypeDisplayEnum.Modal
          ? onClickAnnouncement
          : onClick
      }
      {...boxProps}
    >
      {listItemComponent({ notification })}
    </Box>
  );
};
