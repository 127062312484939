import { Box, useDisclosure } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React from 'react';

import { AbsoluteFullCenterLoadingSpinner } from '../../../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import {
  ResponsiveModal,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../../../components/Layout/ResponsiveModal';
import { ConfirmChangesDialog } from '../../../../components/shared/dialogs/ConfirmChangesDialog/ConfirmChangesDialog';
import { useContestVideoModalContext } from '../../../../provider/VideoLibraryProvider/VideoLibraryEntryModalProvider/VideoContestModalProvider';
import { VideoModalContentBox } from '../../../VideoLibraryPage/components/EntryModalContent/EntryModalContentBox';

export type VideoLibraryEntryModalContent = {
  title: string;
  content: React.ReactNode;
};

export const ContestVideoUploadModal: React.FC = () => {
  const {
    actions,
    unsubmittedEdits,
    video,
    videoLoading,
    videoModalDisclosure,
    editingUpload,
    onModalClose,
    contest,
  } = useContestVideoModalContext();

  const {
    isOpen: isConfirmChangesDialogOpen,
    onOpen,
    onClose: closeConfirmChangesDialog,
  } = useDisclosure();

  return (
    <ResponsiveModal
      isOpen={videoModalDisclosure.isOpen}
      onClose={() => {
        if (isEmpty(unsubmittedEdits) && !editingUpload?.uploadRequestId) {
          onModalClose();
        } else {
          onOpen();
        }
      }}
      isVCentered={false}
      preferredSize="lg"
    >
      <ConfirmChangesDialog
        isOpen={isConfirmChangesDialogOpen}
        navigateBack={() => {
          actions.setUnsubmittedEdits({});
          closeConfirmChangesDialog();
          onModalClose();
        }}
        onClose={() => {
          closeConfirmChangesDialog();
          onModalClose();
        }}
      />
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalStickyHeaderBox bg={'surface'} minH={'56px'}>
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        {(!videoLoading && video) || !!editingUpload ? (
          <VideoModalContentBox
            uploadedVideo={video}
            uploadingVideo={editingUpload}
            contest={contest}
            onContestModalClose={onModalClose}
          />
        ) : (
          <Box w={'full'} minH={'300px'}>
            <AbsoluteFullCenterLoadingSpinner />
          </Box>
        )}
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
