import { Close } from '@campoint/odi-ui-icons';
import {
  Icon,
  IconButton,
  IconButtonProps,
  forwardRef,
} from '@chakra-ui/react';

export const ClearableElement = forwardRef<Partial<IconButtonProps>, 'button'>(
  (props, ref) => (
    <IconButton
      ref={ref}
      me={2}
      size="sm"
      aria-label="clear"
      variant="ghost"
      color="onSurface.mediumEmphasis"
      colorScheme="gray"
      icon={<Icon as={Close} boxSize="icon.md" />}
      {...props}
    />
  )
);
