import { useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ConfirmChangesDialog } from '../components/shared/dialogs/ConfirmChangesDialog/ConfirmChangesDialog';
import { useBeforeUnload } from '../hooks/useBeforeUnload';
import { createContext } from '../hooks/useContext';
import { noop } from '../utils';

interface NavigationBlockContext {
  readonly isDirty: boolean;
  readonly onOmitChangesTriggerCount: number;
  readonly action: {
    registerDirtyFlag: (
      isDirty: boolean,
      onOmitChanges?: () => void,
      onlyBlockRoutes?: string[]
    ) => void;
    showConfirmChangesDialog: (onOmitChanges: () => void) => void;
  };
}

export const [, useNavigationBlock, navigationBlockContext] =
  createContext<NavigationBlockContext>({
    errorMessage:
      'useNavigationBlock: `NavigationBlockContext` is undefined. Seems you forgot to wrap component within the Provider',
    name: 'NavigationBlockContext',
  });

export const NavigationBlockProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const existingContext = React.useContext(navigationBlockContext);
  if (existingContext) {
    throw new Error("NavigationBlockProvider can't be nested");
  }

  const history = useHistory();
  const [isDirty, setIsDirty] = React.useState(false);
  const dirtyStatesSet = React.useRef(
    new Set<{ onOmitChanges?: () => void }>()
  );
  const blockedRoutes = React.useRef(
    new Map<{ onOmitChanges?: () => void }, string[] | undefined>()
  );
  const [onOmitChangesTriggerCount, setOnOmitChangesTriggerCount] =
    React.useState(0);

  const registerDirtyFlag = React.useCallback(
    (
      isDirty: boolean,
      onOmitChanges?: () => void,
      onlyBlockRoutes?: string[]
    ) => {
      const uniqueObject = { onOmitChanges };
      if (isDirty) {
        dirtyStatesSet.current.add(uniqueObject);
        blockedRoutes.current.set(uniqueObject, onlyBlockRoutes);
        setIsDirty(dirtyStatesSet.current.size > 0);
      }

      return () => {
        dirtyStatesSet.current.delete(uniqueObject);
        blockedRoutes.current.delete(uniqueObject);
        setIsDirty(dirtyStatesSet.current.size > 0);
      };
    },
    [setIsDirty]
  );

  useBeforeUnload(isDirty);

  const [modalNavigateBackCallback, setModalNavigateBackCallback] =
    useState<() => void>(noop);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const showConfirmChangesDialog = React.useCallback(
    (onOmitChanges: () => void) => {
      setModalNavigateBackCallback(() => {
        return () => {
          dirtyStatesSet.current.forEach((entry) => entry?.onOmitChanges?.());
          onOmitChanges();
          dirtyStatesSet.current.clear();
          blockedRoutes.current.clear();
          setOnOmitChangesTriggerCount((count) => count + 1);
          setIsDirty(dirtyStatesSet.current.size > 0);
          onClose();
        };
      });
      onOpen();
    },
    [onOpen, onClose, setOnOmitChangesTriggerCount, setIsDirty]
  );

  const historyUnblock = React.useRef<() => void>(noop);

  // Blocks navigation to another page if unsaved changes exist
  useEffect(() => {
    historyUnblock.current = history.block(({ pathname }): any => {
      const routeIsBlocked = Array.from(blockedRoutes.current.values()).some(
        (routes) => routes?.includes(pathname) ?? true // if there are no entries, default to blocking
      );

      if (isDirty && pathname !== history.location.pathname && routeIsBlocked) {
        showConfirmChangesDialog(() => {
          historyUnblock.current();
          history.push(pathname);
        });
        return false;
      }

      return true;
    });

    return () => {
      historyUnblock.current();
    };
  }, [isDirty, showConfirmChangesDialog, history]);

  const context = React.useMemo<NavigationBlockContext>(
    () => ({
      isDirty,
      onOmitChangesTriggerCount,
      action: {
        showConfirmChangesDialog,
        registerDirtyFlag,
      },
    }),
    [
      isDirty,
      onOmitChangesTriggerCount,
      showConfirmChangesDialog,
      registerDirtyFlag,
    ]
  );

  return (
    <navigationBlockContext.Provider value={context}>
      <ConfirmChangesDialog
        isOpen={isOpen}
        navigateBack={modalNavigateBackCallback}
        onClose={onClose}
      />
      {children}
    </navigationBlockContext.Provider>
  );
};
