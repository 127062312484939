import { Section, SectionBody, SectionHeader, SectionTitle, SectionTitleRow, SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import { Button, Container, Divider, Icon, List, StackProps, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { issueChakraToast } from '../../../components/Layout/ChakraToastContainer';
import { FluentPageLayout } from '../../../components/Layout/FluentPageLayout';
import { SectionCenterContainer } from '../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../components/Layout/SectionDivider';
import { useGetActorsDocumentsPageQuery } from '../../../generated/graphql';
import { routes } from '../../../routes/routesConfig';
import { ActorListItem } from './components/ActorListItem/ActorListItem';
import { OriginatorListItem } from './components/OriginatorListItem/OriginatorListItem';

const RealPersonList: React.FC<StackProps> = (props) => (
  <VStack as={List} divider={<Divider />} alignItems={'stretch'} {...props} />
);

export const ActorsDocumentsPage: React.FC = () => {
  const location = useLocation();
  const params = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const actorAdded = params.get('actorAdded');
  const { t } = useTranslation(['realPersonList']);

  const { data, loading } = useGetActorsDocumentsPageQuery();

  const history = useHistory();
  const navigateToCreateActor = () => {
    history.replace(routes.documentsActorsCreate.path);
  };

  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

  // Issue a toast when there's already a maximum of five actors in pending
  const issuePendingActorsToast = () => {
    issueChakraToast({
      status: 'error',
      description: t(
        'realPersonList:toast.BitteWarteDiePrufungDerBisherigenDrehpartnerAbBevorDuNeueHinzufugst',
      ),
    });
  };

  const mappedData = React.useMemo(() => {
    const originator = data?.account.originator;
    const firstActor = data?.account.actors?.[0];
    const hostUserId = data?.account.hostUserId;

    const userId = originator?.userId ?? firstActor?.userId ?? hostUserId;
    const hasAtLeastOneRealPerson = !!originator || !!firstActor;
    const hasOnlyFirstActor =
      !!firstActor && !originator && data?.account.actors?.length === 1;
    const isAllowedToAddNewActor = data?.account.isAllowedToAddNewActor;

    // Filter out the originator to avoid duplicates
    const actors = (data?.account.actors ?? []).filter((actor) => actor?.userId !== originator?.userId);

    return {
      isAllowedToAddNewActor,
      hostUserId,
      userId,
      shouldRedirectToSingleUser: !hasAtLeastOneRealPerson,
      hasOnlyFirstActor,
      firstActor,
      originator,
      actors,
    };
  }, [data]);

  React.useEffect(() => {
    if (params.size > 0 && actorAdded) {
      params.delete('actorAdded');
      window.history.pushState('', '', routes.documentsActors.path);
      setTimeout(() => {
        issueChakraToast({
          status: 'success',
          description: t('realPersonList:toast.PersonHinzugefugt'),
        });
      }, 1000);
    }
  }, [actorAdded, params, t]);

  return (
    <FluentPageLayout isContentLoading={loading}>
      {mappedData.shouldRedirectToSingleUser && mappedData.userId && (
        <Redirect
          to={routes.documentsActorsDetail.path.replace(
            ':userId',
            mappedData.userId.toString(),
          )}
        />
      )}
      <SubHeader>
        <Container px={0} maxW={'container.xl'}>
          <SubHeaderTitle>
            {t('realPersonList:heading.PersonenUndDokumente')}
          </SubHeaderTitle>
        </Container>
      </SubHeader>
      <Container maxW="container.xl" p={0} mb={8}>
        <VStack
          spacing={0}
          pb={{ base: 0, lg: 8 }}
          alignItems={'stretch'}
          bg={'surface'}
        >
          {!mappedData.hasOnlyFirstActor && !mappedData.originator ? null : (
            <Section>
              <SectionHeader>
                <SectionCenterContainer>
                  <SectionTitleRow>
                    <SectionTitle>
                      {t('realPersonList:heading.Admin')}
                    </SectionTitle>
                  </SectionTitleRow>
                </SectionCenterContainer>
              </SectionHeader>
              <SectionDivider isWidthRestricted />
              <SectionBody>
                <SectionCenterContainer>
                  <RealPersonList>
                    {mappedData.originator && (
                      <OriginatorListItem originator={mappedData.originator} />
                    )}
                    {mappedData.hasOnlyFirstActor && mappedData.firstActor && (
                      <ActorListItem actor={mappedData.firstActor} />
                    )}
                  </RealPersonList>
                </SectionCenterContainer>
              </SectionBody>
            </Section>
          )}
          {mappedData.hasOnlyFirstActor ||
          !mappedData.actors ||
          mappedData.actors?.length <= 0 ? null : (
            <Section>
              <SectionHeader>
                <SectionCenterContainer>
                  <SectionTitleRow>
                    <SectionTitle>
                      {t('realPersonList:heading.WeiterePersonen')}
                    </SectionTitle>
                  </SectionTitleRow>
                </SectionCenterContainer>
              </SectionHeader>
              <SectionDivider isWidthRestricted />
              <SectionBody>
                <SectionCenterContainer>
                  <RealPersonList>
                    {mappedData.actors.map((actor) =>
                      !actor ? null : (
                        <ActorListItem key={actor.userId} actor={actor} />
                      ),
                    )}
                  </RealPersonList>
                </SectionCenterContainer>
              </SectionBody>
            </Section>
          )}
          <VStack py={4}>
            <Button
              onClick={
                mappedData.isAllowedToAddNewActor
                  ? navigateToCreateActor
                  : () => {
                    issuePendingActorsToast();
                    setIsButtonDisabled(true);
                  }
              }
              alignSelf={'center'}
              rightIcon={<Icon as={icons.Add} boxSize={'icon.md'} />}
              isDisabled={isButtonDisabled}
            >
              {t('realPersonList:button.PersonHinzufugen')}
            </Button>
          </VStack>
        </VStack>
      </Container>
    </FluentPageLayout>
  );
};
