import { Divider, VStack } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TagCloudControl } from '../../../../../components/shared/FormElements/TagCloudControl/TagCloudControl';
import { TextAreaWithMaxCharCount } from '../../../../../components/shared/FormElements/TextAreaWithMaxCharCount/TextAreaWithMaxCharCount';
import { ClearableInputControl } from '../../../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import { VideoFragment } from '../../../../../generated/graphql';
import { VideoLibraryUploadListEntryPreviewPictureControl } from '../../../VideoLibraryUploadListEntryPreviewPictureControl';
import { videoLibraryFieldName } from '../EntryModalContentBox';

export const EntryModalTitleDescriptionTab: React.FC<{
  video: Maybe<VideoFragment>;
  contestId?: Maybe<number>;
}> = ({ video, contestId }) => {
  const { t } = useTranslation(['videoEditModal']);
  const maxTags = 10;
  const tags = (
    <TagCloudControl
      label={t('videoEditModal:inputs.heading.Tags', {
        tagCount: `(${
          useWatch({ name: videoLibraryFieldName.tags })?.length ?? 0
        }/${maxTags})`,
      })}
      isDisabled={
        (useWatch({ name: videoLibraryFieldName.tags })?.length ?? 0) >= maxTags
      }
      name={videoLibraryFieldName.tags}
    />
  );

  return (
    <VStack alignItems={'stretch'} w={'full'} divider={<Divider />} gap={4}>
      <VStack alignItems={'stretch'} w={'full'} gap={6}>
        <ClearableInputControl
          label={t('videoEditModal:inputs.heading.Titel')}
          name={videoLibraryFieldName.title}
          maxLength={60}
          placeholder={t('videoEditModal:inputs.text.TitelEingeben')}
        />
        <TextAreaWithMaxCharCount
          label={t('videoEditModal:inputs.heading.Beschreibung')}
          name={videoLibraryFieldName.description}
          maxCharacterCount={2000}
          textAriaProps={{
            rows: 3,
            placeholder: t('videoEditModal:inputs.text.BeschreibeDeinVideo'),
          }}
        />
      </VStack>
      <VideoLibraryUploadListEntryPreviewPictureControl video={video} />
      {!contestId && tags}
    </VStack>
  );
};
