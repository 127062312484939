import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { HelpLink } from '../HelpLink/HelpLink';
import {
  FormManagerPaperlessForm,
  FormStatusEnum,
} from '../../../generated/graphql';
import { useActiveApiLanguage } from '../../../hooks/useActiveApiLanguage';
import { externalRoutes } from '../../../routes/routesConfig';
import { useWizardMRF } from '../../../pages/Wizard/ModelReleaseForm/WizardMRFContext';
import { DocumentPickerPendingMessage } from '../../../pages/DocumentPages/DocumentDetailPage/components/DocumentPickerPendingMessage/DocumentPickerPendingMessage';
import { DocumentPickerRejectedMessage } from '../../../pages/DocumentPages/DocumentDetailPage/components/DocumentPickerRejectedMessage/DocumentPickerRejectedMessage';

export const ModelReleaseFormDocumentView: React.FC<{
  doc: Maybe<FormManagerPaperlessForm>;
  onRejected?: () => void;
}> = ({ doc, onRejected }) => {
  const lang = useActiveApiLanguage();
  const { t } = useTranslation(['document', 'general', 'payoutDocuments']);

  const docStatus = doc?.status;

  const borderColor = React.useMemo(() => {
    switch (docStatus) {
      case FormStatusEnum.Rejected:
        return 'error.500';
      case FormStatusEnum.Pending:
        return 'warning.500';
      default:
        return 'gray.300';
    }
  }, [docStatus]);

  const background = React.useMemo(() => {
    switch (docStatus) {
      default:
        return 'surface';
    }
  }, [docStatus]);

  const wizardMrf = useWizardMRF();
  const navigateToWizardOrEmailVerification = () => wizardMrf.setVisible(true);

  return (
    <>
      <VStack alignItems={'stretch'}>
        <Box
          p={4}
          border={'1px'}
          borderColor={borderColor}
          background={background}
          borderRadius={'xl'}
        >
          <HStack>
            <Icon as={icons.AttachFile} boxSize={'icon.md'} />
            <Text flexGrow={1} textOverflow={'ellipsis'}>
              {doc?.userDocument?.filename}
            </Text>
            <IconButton
              flexShrink={0}
              icon={<Icon as={icons.Download} boxSize={'icon.md'} />}
              as={Link}
              href={doc?.userDocument?.url}
              rel={'noreferrer'}
              target={'_blank'}
              aria-label={t('document:button.namedHerunterladen', {
                named: t('document:named.ModelReleaseForm'),
              })}
            />
          </HStack>
        </Box>
        {(() => {
          switch (docStatus) {
            case FormStatusEnum.Rejected:
              return (
                <VStack gap={4}>
                  <DocumentPickerRejectedMessage
                    alignSelf={'start'}
                    message={t('general:status.Abgelehnt')}
                    modalHeading={t(
                      'document:heading.DeinNeuesModelReleaseFormWurdeAbgelehnt'
                    )}
                    modalTextSlot={
                      <Text>
                        {t('document:text.GrundReason', {
                          reason: doc?.userDocument?.rejectionReason ?? '',
                        })}
                      </Text>
                    }
                    modalHelpLinkSlot={
                      <HelpLink
                        href={externalRoutes.vxModelsZendeskOverview(lang)}
                      >
                        {t(
                          'document:text.WiesoWurdeMeinModelReleaseFormAbgelehnt'
                        )}
                      </HelpLink>
                    }
                  />
                  <Button
                    variant={'solid'}
                    onClick={() => {
                      if (onRejected) {
                        onRejected?.();
                      } else {
                        navigateToWizardOrEmailVerification();
                      }
                    }}
                  >
                    {t('payoutDocuments:button.ModelReleaseFormAusfuellen')}
                  </Button>
                </VStack>
              );
            case FormStatusEnum.Pending:
              return (
                <DocumentPickerPendingMessage
                  message={t('general:status.InPrufung')}
                  modalHeading={t(
                    'document:heading.DeinNeuesModelReleaseFormWirdGepruft'
                  )}
                  modalTextSlot={
                    <Text>
                      {t(
                        'document:text.DeineAltenAngabenBleibenErhaltenSolangeDeineAnderungenNichtAkzeptierXX'
                      )}
                    </Text>
                  }
                  modalHelpLinkSlot={
                    <HelpLink
                      href={externalRoutes.vxModelsZendeskOverview(lang)}
                    >
                      {t(
                        'document:text.WieLangeDauertDieUberprufungMeinesModelReleaseForm'
                      )}
                    </HelpLink>
                  }
                />
              );
            default:
              return null;
          }
        })()}
      </VStack>
    </>
  );
};
