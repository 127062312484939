import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import {
  CheckBox,
  Info,
  Movie,
  Paid,
  PaymentsOutlined,
  Statistics,
  ThumbUp,
  VolumeUp,
  VxLive,
  VxModelsLogo,
} from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CircularProgressLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  ModalHeader,
  Text,
  TextProps,
  VStack,
  chakra,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { DateTime } from 'luxon';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import exclusiveSharingImg from '../../../../assets/images/statistics/exclusive-sharing.svg';
import { AbsoluteFullCenterLoadingSpinner } from '../../../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalProps,
} from '../../../../components/Layout/ResponsiveModal';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import {
  GetSharingStatisticsDocument,
  SharingStepFragment,
  SharingTypeEnum,
  useRequestNextSharingLevelMutation,
} from '../../../../generated/graphql';
import { useActiveApiLanguage } from '../../../../hooks/useActiveApiLanguage';
import { externalRoutes } from '../../../../routes/routesConfig';
import {
  getFormattedAmount,
  getFormattedCoins,
} from '../../../../utils/formattedAmount';
import { ErrorModalContent } from '../../../FeedPage/components/modal/component/ErrorModalContent';
import { useSharingContext } from '../../provider/SharingContext';
import {
  SharingInfoModalProvider,
  useSharingInfoModal,
} from './SharingInfoModalProvider';
import {
  SharingSectionDesktop,
  SharingSectionMobile,
} from './SharingSectionContainer';

export const STEP_TILE_WIDTH = 270;
export const STEP_TILE_HEIGHT = 257;

enum SharingStatus {
  ApplyForNextStep = 'ApplyForNextStep',
  ApplyForSecondLevel = 'ApplyForSecondLevel',
  ApplyForThirdLevel = 'ApplyForThirdLevel',
  AutomaticallyNextLevel = 'AutomaticallyNextLevel',
  RejectedForNextLevel = 'RejectedForNextLevel',
  RequestedForNextLevel = 'RequestedForNextLevel',
  LockedForNextLevel = 'LockedForNextLevel',
  HighestLevelReached = 'HighestLevelReached',
  ExclusiveSharing = 'ExclusiveSharing',
}

export const DetailedStatisticsSharingParent: React.FC = () => {
  const [sharingStatus, setSharingStatus] =
    React.useState<SharingStatus | null>(null);
  const { sharingStatistics, loading } = useSharingContext();
  const {
    currentStep,
    currentCoins,
    currentMax,
    isStepVerificationRequested,
    isStepVerificationRejected,
    isAllowedForSecondStep,
    isAllowedForThirdStep,
    lockedStep,
    isPremium,
    steps,
  } = sharingStatistics;

  const rejectedForNextLevel = isStepVerificationRejected;
  const requestedNextLevel = isStepVerificationRequested;
  const lockedForNextLevel = lockedStep
    ? (currentStep ?? 0) + 1 >= lockedStep
    : false;
  const highestStepReached =
    (currentMax === steps?.at(steps?.length - 1)?.stepVolume &&
      (currentCoins ?? 0) >= (currentMax ?? 0)) ||
    //quickfix for hard coded sharing & !isPremium
    steps?.at(steps?.length - 1)?.stepVolume === 0;

  const canApplyForNextLevel = !rejectedForNextLevel && !lockedForNextLevel;

  const needsToApplyForSecondStep =
    currentStep === 1 ? !isAllowedForSecondStep : false;
  const needsToApplyForThirdStep =
    currentStep === 2 ? !isAllowedForThirdStep : false;

  const needsToApplyForNextStep =
    !highestStepReached && !steps?.at(currentStep ?? 0)?.isStepAllreadyEnabled;

  React.useEffect(() => {
    if (loading) return;

    if (rejectedForNextLevel) {
      setSharingStatus(SharingStatus.RejectedForNextLevel);
    } else if (requestedNextLevel) {
      setSharingStatus(SharingStatus.RequestedForNextLevel);
    } else if (lockedForNextLevel) {
      setSharingStatus(SharingStatus.LockedForNextLevel);
    } else if (isPremium) {
      setSharingStatus(SharingStatus.ExclusiveSharing);
    } else if (highestStepReached) {
      setSharingStatus(SharingStatus.HighestLevelReached);
    } else if (canApplyForNextLevel && needsToApplyForSecondStep) {
      setSharingStatus(SharingStatus.ApplyForSecondLevel);
    } else if (canApplyForNextLevel && needsToApplyForThirdStep) {
      setSharingStatus(SharingStatus.ApplyForThirdLevel);
    } else if (needsToApplyForNextStep) {
      setSharingStatus(SharingStatus.ApplyForNextStep);
    } else {
      setSharingStatus(SharingStatus.AutomaticallyNextLevel);
    }
  }, [
    loading,
    isPremium,
    lockedForNextLevel,
    needsToApplyForSecondStep,
    needsToApplyForThirdStep,
    rejectedForNextLevel,
    requestedNextLevel,
    canApplyForNextLevel,
    highestStepReached,
    needsToApplyForNextStep,
  ]);

  return (
    <DetailedStatisticsSharing
      sharingStatus={sharingStatus}
      loading={loading ?? false}
      steps={steps}
      currentStep={currentStep}
    />
  );
};

type DetailedStatisticsSharingProps = {
  sharingStatus: Maybe<SharingStatus>;
  loading: boolean;
  steps: Maybe<SharingStepFragment[]>;
  currentStep: Maybe<number>;
};

const DetailedStatisticsSharing: React.FC<DetailedStatisticsSharingProps> = ({
  sharingStatus,
  loading,
  steps,
  currentStep,
}) => {
  const isDesktopViewport = useBreakpointValue({ base: false, sm: true });
  const isExclusive = sharingStatus === SharingStatus.ExclusiveSharing;
  const needsToApplyForNextStepAndCanApply =
    (sharingStatus === SharingStatus.ApplyForNextStep &&
      steps?.at(currentStep ?? 0)?.isStepVerificationEnabled) ??
    false;

  const adjustedSteps = React.useMemo(() => {
    if (isExclusive && steps) {
      return steps.slice(0, 1);
    }
    if (sharingStatus === SharingStatus.LockedForNextLevel && steps) {
      return steps.slice(0, currentStep ?? steps.length);
    }

    return steps;
  }, [isExclusive, sharingStatus, steps, currentStep]);

  const currentStepSharingItem = React.useMemo(() => {
    switch (sharingStatus) {
      case SharingStatus.ApplyForNextStep:
        return (
          <ApplyForNextStepSection
            canApply={needsToApplyForNextStepAndCanApply}
          />
        );
      case SharingStatus.ApplyForSecondLevel:
        return <ApplyForSecondLevelSection />;
      case SharingStatus.ApplyForThirdLevel:
        return <ApplyForThirdLevelSection />;
      case SharingStatus.AutomaticallyNextLevel:
        return <AutomaticallyNextLevelSection />;
      case SharingStatus.RejectedForNextLevel:
      case SharingStatus.RequestedForNextLevel:
        return <RejectedOrRequestedForNextLevelSection />;
      case SharingStatus.LockedForNextLevel:
      case SharingStatus.HighestLevelReached:
        return <LockedForNextLevelSection />;
      case SharingStatus.ExclusiveSharing:
        return <ExclusiveModelSharingBox />;
      default:
        return <></>;
    }
  }, [needsToApplyForNextStepAndCanApply, sharingStatus]);

  const currentDate = DateTime.now().toFormat('MM / yyyy');
  return (
    <Section>
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={PaymentsOutlined} />
            <SectionTitle flex={1}>Sharing</SectionTitle>
            <Text
              alignSelf={'center'}
              color={'darkSteel'}
              fontWeight={'medium'}
              fontSize={'xs'}
            >
              {currentDate}
            </Text>
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted={true} />
      <SectionBody>
        <SharingInfoModalProvider>
          <SectionCenterContainer spacing={4}></SectionCenterContainer>
          <SectionCenterContainer spacing={4}>
            {loading ? (
              <AbsoluteFullCenterLoadingSpinner />
            ) : isDesktopViewport ? (
              <SharingSectionDesktop
                currentStepSharingItem={currentStepSharingItem}
                steps={adjustedSteps}
                currentStep={currentStep ?? 1}
              />
            ) : (
              <SharingSectionMobile
                currentStepSharingItem={currentStepSharingItem}
                steps={adjustedSteps}
                currentStep={currentStep ?? 1}
              />
            )}
          </SectionCenterContainer>
        </SharingInfoModalProvider>
      </SectionBody>
    </Section>
  );
};

const LevelSectionSharingHeader: React.FC<{
  text?: string;
  textProps?: TextProps;
  hasInfo?: boolean;
}> = ({ text, textProps, hasInfo = true }) => {
  const { t } = useTranslation(['statisticsPage']);
  const locale = useIntl().locale;
  const { sharingStatistics } = useSharingContext();
  const { currentStepFactor } = sharingStatistics;
  const { onInfoModalOpen } = useSharingInfoModal();

  return (
    <HStack
      minH={8}
      position={'relative'}
      align={'center'}
      justifyContent={'center'}
      w={'full'}
    >
      <Text
        pt={'6px'}
        pb={'4px'}
        fontWeight={'extrabold'}
        fontSize={'14px'}
        lineHeight={'16px'}
        color={'surface'}
        textAlign={'center'}
        noOfLines={1}
        {...textProps}
      >
        {text ??
          t('text.VerdienstPro10VXC035', {
            factor: getFormattedAmount(currentStepFactor ?? 0, locale),
          })}
      </Text>
      {hasInfo && (
        <IconButton
          position={'absolute'}
          aria-label={'Info'}
          variant={'unstyled'}
          height={'unset'}
          width={'unset'}
          right={'0'}
          onClick={onInfoModalOpen}
        >
          <Icon
            p={0}
            as={Info}
            boxSize={'icon.sm'}
            color={'white'}
            bg={'transparent'}
          />
        </IconButton>
      )}
    </HStack>
  );
};

const ApplyForNextStepSection: React.FC<{ canApply: boolean }> = ({
  canApply,
}) => {
  const { t } = useTranslation(['statisticsPage']);
  const { sharingStatistics } = useSharingContext();
  const { sharingCategory } = sharingStatistics;

  const [requestNextSharingLevel, { loading: requestNextLevelLoading }] =
    useRequestNextSharingLevelMutation();
  const onRequestNextLevel = () => {
    if (!sharingCategory) return;

    try {
      const type =
        sharingCategory === 'SHOP'
          ? SharingTypeEnum.Shop
          : SharingTypeEnum.Chat;
      const date = DateTime.now();
      requestNextSharingLevel({
        variables: { type, date },
        refetchQueries: [GetSharingStatisticsDocument],
      });

      issueChakraToast({
        status: 'success',
        description: t('toast.FreischaltungErfolgreichBeantragt'),
      });
    } catch (error) {
      issueChakraToast({
        status: 'error',
        description: t('toast.FreischaltungNichtMoglich'),
      });
    }
  };

  return (
    <VStack
      gap={0}
      minH={STEP_TILE_HEIGHT}
      minW={STEP_TILE_WIDTH}
      w={'full'}
      px={'1px'}
      pb={'1px'}
      bgGradient={'linear(to-r, primary.from, primary.to)'}
      borderRadius={'8px'}
    >
      <LevelSectionSharingHeader />
      <AuthorizedForNextLevelBox
        button={
          <Button
            isDisabled={false}
            isLoading={requestNextLevelLoading}
            onClick={() => {
              if (!canApply) {
                issueChakraToast({
                  status: 'error',
                  description: t('toast.FreischaltungNichtMoglich'),
                });
                return;
              }
              onRequestNextLevel();
            }}
            variant={'outline'}
            fontWeight={'bold'}
            fontSize={'md'}
            lineHeight={'24px'}
          >
            {t('button.FurNachsteStufeBewerben')}
          </Button>
        }
      />
    </VStack>
  );
};

const ApplyForSecondLevelSection: React.FC = () => {
  const { t } = useTranslation(['statisticsPage']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { sharingStatistics } = useSharingContext();
  const { sharingCategory } = sharingStatistics;

  const [requestNextSharingLevel, { loading: requestNextLevelLoading }] =
    useRequestNextSharingLevelMutation();
  const onRequestNextLevel = () => {
    if (!sharingCategory) return;

    try {
      const type =
        sharingCategory === 'SHOP'
          ? SharingTypeEnum.Shop
          : SharingTypeEnum.Chat;
      const date = DateTime.now();
      requestNextSharingLevel({
        variables: { type, date },
        refetchQueries: [GetSharingStatisticsDocument],
      });
      if (isOpen) onClose();
      issueChakraToast({
        status: 'success',
        description: t('toast.FreischaltungErfolgreichBeantragt'),
      });
    } catch (error) {
      if (isOpen) onClose();
      issueChakraToast({
        status: 'error',
        description: t('toast.FreischaltungNichtMoglich'),
      });
    }
  };

  return (
    <VStack
      gap={0}
      minH={STEP_TILE_HEIGHT}
      minW={STEP_TILE_WIDTH}
      px={'1px'}
      pb={'1px'}
      bgGradient={'linear(to-r, primary.from, primary.to)'}
      borderRadius={'8px'}
    >
      <LevelSectionSharingHeader />
      <AuthorizedForNextLevelBox
        button={
          <Button
            isDisabled={false}
            isLoading={requestNextLevelLoading}
            onClick={onOpen}
            variant={'outline'}
            fontWeight={'bold'}
            fontSize={'md'}
            lineHeight={'24px'}
          >
            {t('button.FurNachsteStufeBewerben')}
          </Button>
        }
      />
      <DetailedStatisticsSharingModalStepOne
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onRequestNextLevel={onRequestNextLevel}
      />
    </VStack>
  );
};

const ApplyForThirdLevelSection: React.FC = () => {
  const { t } = useTranslation(['statisticsPage']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { sharingStatistics } = useSharingContext();
  const { sharingCategory } = sharingStatistics;

  const [requestNextSharingLevel, { loading: requestNextLevelLoading }] =
    useRequestNextSharingLevelMutation();
  const onRequestNextLevel = () => {
    if (!sharingCategory) return;

    try {
      const type =
        sharingCategory === 'SHOP'
          ? SharingTypeEnum.Shop
          : SharingTypeEnum.Chat;
      const date = DateTime.now();
      requestNextSharingLevel({
        variables: { type, date },
        refetchQueries: [GetSharingStatisticsDocument],
      });
      if (isOpen) onClose();
      issueChakraToast({
        status: 'success',
        description: t('toast.FreischaltungErfolgreichBeantragt'),
      });
    } catch (error) {
      if (isOpen) onClose();
      issueChakraToast({
        status: 'error',
        description: t('toast.FreischaltungNichtMoglich'),
      });
    }
  };

  return (
    <VStack
      gap={0}
      minH={STEP_TILE_HEIGHT}
      minW={STEP_TILE_WIDTH}
      px={'1px'}
      pb={'1px'}
      bgGradient={'linear(to-r, primary.from, primary.to)'}
      borderRadius={'8px'}
    >
      <LevelSectionSharingHeader />
      <AuthorizedForNextLevelBox
        button={
          <Button
            isDisabled={false}
            isLoading={requestNextLevelLoading}
            onClick={onOpen}
            variant={'outline'}
            fontWeight={'bold'}
            fontSize={'md'}
            lineHeight={'24px'}
          >
            {t('button.FurNachsteStufeBewerben')}
          </Button>
        }
      />
      <DetailedStatisticsSharingModalStepTwo
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onRequestNextLevel={onRequestNextLevel}
      />
    </VStack>
  );
};

const AutomaticallyNextLevelSection: React.FC = () => {
  const { t } = useTranslation(['statisticsPage']);
  return (
    <VStack
      gap={0}
      minH={STEP_TILE_HEIGHT}
      minW={STEP_TILE_WIDTH}
      px={'1px'}
      pb={'1px'}
      bgGradient={'linear(to-r, primary.from, primary.to)'}
      borderRadius={'8px'}
    >
      <LevelSectionSharingHeader />
      <AuthorizedForNextLevelBox
        button={
          <HStack gap={1}>
            <Icon as={CheckBox} color={'lime.500'} />
            <Text fontSize={'14px'} fontWeight={'normal'} lineHeight={'20px'}>
              {t('text.AutomAufstiegBeiZielerreichung')}
            </Text>
          </HStack>
        }
      />
    </VStack>
  );
};

const RejectedOrRequestedForNextLevelSection: React.FC = () => {
  const { t } = useTranslation(['statisticsPage']);
  const { sharingStatistics } = useSharingContext();
  const { isStepVerificationRequested, stepVerificatonRejectedReason } =
    sharingStatistics;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const buttonText = isStepVerificationRequested
    ? t('button.InPrufung')
    : t('button.FreischaltungNichtMoglich');

  return (
    <VStack
      gap={0}
      minH={STEP_TILE_HEIGHT}
      minW={STEP_TILE_WIDTH}
      px={'1px'}
      pb={'1px'}
      bgGradient={'linear(to-r, primary.from, primary.to)'}
      borderRadius={'8px'}
    >
      <LevelSectionSharingHeader />
      <AuthorizedForNextLevelBox
        button={
          <Button
            onClick={() => {
              if (isStepVerificationRequested) return;
              onOpen();
            }}
            isLoading={false}
            variant={'unstyled'}
            fontWeight={'bold'}
            fontSize={'md'}
            lineHeight={'24px'}
            color={isStepVerificationRequested ? 'warning.500' : 'primary.500'}
          >
            {buttonText}
          </Button>
        }
      />
      <SharingStepRejectedModal
        onClose={onClose}
        isOpen={isOpen}
        rejectionReason={stepVerificatonRejectedReason ?? undefined}
      />
    </VStack>
  );
};

interface SharingStepRejectedModalProps
  extends Omit<ResponsiveModalProps, 'children'> {
  rejectionReason?: string;
}

export const SharingStepRejectedModal: React.FC<
  SharingStepRejectedModalProps
> = ({ rejectionReason, onClose, ...modalProps }) => {
  const { t } = useTranslation(['statisticsPage', 'general', 'feed']);
  const deletePostButton = (
    <Button
      variant={'ghost'}
      children={t('general:button.close')}
      onClick={() => {
        onClose();
      }}
    />
  );

  const footerContent = deletePostButton;

  return (
    <ResponsiveModal
      preferredSize="sm"
      preferredSizeBreakpoint="sm"
      onClose={onClose}
      {...modalProps}
    >
      <ResponsiveModalOverlay />
      <ErrorModalContent
        heading={t('statisticsPage:button.FreischaltungNichtMoglich')}
        text={
          rejectionReason ??
          t(
            'feed:text.DasKannVieleGrundeHabenKontaktiereDenSupportUmNachvollziehenZuKonnenXX'
          )
        }
        footerContent={footerContent}
      />
    </ResponsiveModal>
  );
};

const LockedForNextLevelSection: React.FC = () => {
  const { t } = useTranslation(['statisticsPage']);
  const { sharingStatistics } = useSharingContext();
  const { progressPercent } = sharingStatistics;
  const { currentCoins } = sharingStatistics;

  return (
    <VStack
      gap={0}
      minW={STEP_TILE_WIDTH}
      minH={STEP_TILE_WIDTH}
      px={'1px'}
      pb={'1px'}
      bgGradient={'linear(to-r, primary.from, primary.to)'}
      borderRadius={'8px'}
    >
      <LevelSectionSharingHeader />
      <VStack
        bgColor={'surface'}
        w={'full'}
        flex={1}
        borderRadius={'0px 0px 8px 8px'}
        py={4}
        px={{ base: 2, md: 4 }}
      >
        <VStack flex={1} gap={4} justifyContent={'center'}>
          <VStack color={'caribbeanGreen.500'}>
            <Icon as={Statistics} boxSize={'icon.md'} />
            <Text fontSize={'14px'} fontWeight={'normal'} lineHeight={'18px'}>
              {t('text.MaximaleStufeErreicht')}
            </Text>
          </VStack>
          <VStack gap={1}>
            <Text fontWeight={'medium'} fontSize={'25px'} lineHeight={'26px'}>
              {currentCoins} VXCoins
            </Text>
            <Text fontWeight={'light'} fontSize={'16px'} lineHeight={'18px'}>
              {t('text.UmsatzDiesenMonat')}
            </Text>
          </VStack>
        </VStack>
        <Text
          fontWeight={'light'}
          fontSize={'10px'}
          lineHeight={'12px'}
          letterSpacing={'0.03em'}
          color={'darkSteel'}
        >
          {t('text.EntsprichtXXSharing', {
            sharing: progressPercent,
          }).toUpperCase()}
        </Text>
      </VStack>
    </VStack>
  );
};

export const InfoLevelSection: React.FC<{
  step: SharingStepFragment;
  currentStep: number;
}> = ({ step, currentStep }) => {
  const locale = useIntl().locale;
  const { t } = useTranslation(['statisticsPage']);
  const isNextLevelInfo = step.step > currentStep;
  const headerText = isNextLevelInfo
    ? t('text.NachsteStufe')
    : t('text.VorherigeStufe');
  return (
    <VStack
      opacity={1}
      gap={0}
      minW={'270px'}
      minH={'257px'}
      px={'1px'}
      pb={'1px'}
      bgGradient={'linear(to-r, primary.from, primary.to)'}
      borderRadius={'8px'}
    >
      <LevelSectionSharingHeader
        text={`${headerText} ${step.step}`}
        textProps={{ fontSize: '13px' }}
      />
      <VStack
        bgColor={'surface'}
        w={'full'}
        flex={1}
        borderRadius={'0px 0px 8px 8px'}
        py={4}
        px={{ base: 2, md: 4 }}
      >
        <VStack flex={1} gap={0} justifyContent={'center'}>
          <VStack gap={1}>
            <Text fontWeight={'light'} fontSize={'16px'} lineHeight={'18px'}>
              {t('text.VerdienstPro')}
            </Text>
            <Text fontWeight={'medium'} fontSize={'25px'} lineHeight={'26px'}>
              {t('text.TenVXFactor', {
                factor: getFormattedAmount(step.stepFactor ?? 0, locale),
              })}
            </Text>
            {isNextLevelInfo ? (
              <Text fontWeight={'light'} fontSize={'16px'} lineHeight={'18px'}>
                {t('text.BenotigtMinXXVXC', { minCoins: step.stepVolume })}
              </Text>
            ) : (
              <Text fontWeight={'light'} fontSize={'16px'} lineHeight={'18px'}>
                {t('text.ZielErreicht')}
              </Text>
            )}
          </VStack>
        </VStack>
        <Text
          fontWeight={'light'}
          fontSize={'10px'}
          lineHeight={'12px'}
          letterSpacing={'0.03em'}
          color={'darkSteel'}
        >
          {t('text.EntsprichtXXSharing', {
            sharing: step.stepPercent,
          }).toUpperCase()}
        </Text>
      </VStack>
    </VStack>
  );
};

const AuthorizedForNextLevelBox: React.FC<{ button: React.ReactNode }> = ({
  button,
}) => {
  const { t } = useTranslation(['statisticsPage']);
  const { sharingStatistics } = useSharingContext();
  const { nextStepMax, currentCoins, currentStep, progressPercent } =
    sharingStatistics;

  const coinsLeftInStep =
    nextStepMax && currentCoins ? nextStepMax - currentCoins : nextStepMax ?? 0;
  const coinsLeftInStepPercent =
    currentCoins && nextStepMax ? (currentCoins / nextStepMax) * 100 : 0;
  return (
    <VStack
      bgColor={'surface'}
      w={'full'}
      flex={1}
      borderRadius={'0px 0px 8px 8px'}
      gap={6}
      py={4}
      px={{ base: 2, md: 4 }}
    >
      <HStack flex={1} w={'full'}>
        <VStack alignItems={'flex-end'}>
          <Box w={'50px'} h={'50px'}>
            <CircularProgress
              value={coinsLeftInStepPercent}
              color={'caribbeanGreen.200'}
            >
              <CircularProgressLabel>
                <Icon as={VxModelsLogo} color={'steel'} boxSize={'icon.md'} />
              </CircularProgressLabel>
            </CircularProgress>
          </Box>
        </VStack>
        <VStack
          flex={1}
          alignItems={'flex-start'}
          justifyContent={'center'}
          h={'full'}
          w={'full'}
          gap={'5px'}
        >
          <Text fontWeight={'light'} fontSize={'12px'} lineHeight={'12px'}>
            {t('text.StufeXX', { step: currentStep })}
          </Text>
          <HStack>
            <Text fontWeight={'semibold'} fontSize={'25px'} lineHeight={'22px'}>
              <Trans
                t={t}
                i18nKey={'text.AktuellXXVonXXCoins'}
                values={{ current: getFormattedCoins(currentCoins ?? 0) }}
                components={{
                  maxCoins: (
                    <chakra.span
                      fontWeight={'light'}
                      fontSize={'md'}
                      lineHeight={'22px'}
                    >
                      {getFormattedCoins(nextStepMax ?? 0)} VXC
                    </chakra.span>
                  ),
                }}
              />
            </Text>
          </HStack>
          <Text fontWeight={'light'} fontSize={'14px'} lineHeight={'16px'}>
            {coinsLeftInStep <= 0 ? (
              t('text.ZielErreicht')
            ) : (
              <Trans
                t={t}
                i18nKey={'text.NurXXCoinsZurNachstenStufe'}
                values={{ current: currentCoins }}
                components={{
                  coins: (
                    <chakra.span
                      color={'caribbeanGreen.200'}
                      fontWeight={'bold'}
                      fontSize={'14px'}
                      lineHeight={'16px'}
                    >
                      {getFormattedCoins(coinsLeftInStep)} VXCoins
                    </chakra.span>
                  ),
                }}
              />
            )}
          </Text>
        </VStack>
      </HStack>
      {button}
      <Text
        fontWeight={'light'}
        fontSize={'12px'}
        lineHeight={'12px'}
        letterSpacing={'0.03em'}
        color={'darkSteel'}
      >
        {t('text.EntsprichtXXSharing', {
          sharing: progressPercent,
        }).toUpperCase()}
      </Text>
    </VStack>
  );
};

type DetailedStatisticsSharingModalProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onRequestNextLevel: () => void;
};

const DetailedStatisticsSharingModalStepOne: React.FC<
  DetailedStatisticsSharingModalProps
> = ({ isOpen, onClose, onRequestNextLevel }) => {
  const lang = useActiveApiLanguage();
  const { t } = useTranslation(['statisticsPage']);
  const [isChecked, setIsChecked] = React.useState(false);
  const { sharingStatistics, sharingModalCriteria } = useSharingContext();
  const { countOfVideos, profileCompleteness } = sharingModalCriteria;
  const {
    currentCoins,
    nextStepMax,
    isStepVerificationEnabled,
    sharingCategory,
  } = sharingStatistics;
  const sharingType =
    sharingCategory === 'SHOP' ? SharingTypeEnum.Shop : SharingTypeEnum.Chat;
  const coinsLeftInStep =
    nextStepMax && currentCoins ? nextStepMax - currentCoins : nextStepMax ?? 0;
  const coinsLeftInStepPercent =
    currentCoins && nextStepMax ? (currentCoins / nextStepMax) * 100 : 0;

  const profileCompletenessMax = 100;
  const profileCompletenessLeft =
    profileCompletenessMax - (profileCompleteness ?? 0);
  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      preferredSize="xl"
      isVCentered={false}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ModalHeader>
          <ResponsiveModalCloseButton />
        </ModalHeader>
        <ResponsiveModalBodyBox bg={'surface'} p={'unset'} display={'flex'}>
          <VStack p={4} gap={4} flex={1}>
            <VStack gap={1}>
              <Text
                fontWeight={'semibold'}
                fontSize={'md'}
                lineHeight={'20px'}
                textAlign={'center'}
              >
                {t(
                  'text.HerzlichenGluckwunschDuKannstDichJetztFurSharingStufe2Bewerben'
                )}
              </Text>
              <Text
                textAlign={'center'}
                fontWeight={'light'}
                fontSize={'14px'}
                lineHeight={'19px'}
                color={'gray.900'}
              >
                {t(
                  'text.DuQualifizierstDichMitDeinerPerformanceErstmaligFurSharingStufe2VXApXX'
                )}
              </Text>
            </VStack>
            {/* Level Progress */}
            <HStack
              border={'1px'}
              borderRadius={'8px'}
              borderColor={'steel'}
              px={3}
              py={4}
              w={'full'}
            >
              <Box w={'50px'} h={'50px'}>
                <CircularProgress
                  value={coinsLeftInStepPercent}
                  color={'caribbeanGreen.200'}
                >
                  <CircularProgressLabel>
                    <Icon as={VxModelsLogo} color={'steel'} />
                  </CircularProgressLabel>
                </CircularProgress>
              </Box>
              <VStack alignItems={'flex-start'} h={'full'} flex={1}>
                <HStack>
                  <Text
                    fontWeight={'semibold'}
                    lineHeight={'22px'}
                    fontSize={'xl'}
                  >
                    <Trans
                      t={t}
                      i18nKey={'text.AktuellXXVonXXCoins'}
                      values={{ current: currentCoins }}
                      components={{
                        maxCoins: (
                          <chakra.span
                            fontWeight={'light'}
                            lineHeight={'22px'}
                            fontSize={'13px'}
                          >
                            {nextStepMax} VXC
                          </chakra.span>
                        ),
                      }}
                    />
                  </Text>
                </HStack>
                <Text fontWeight={'light'} fontSize={'xs'} lineHeight={'14px'}>
                  {coinsLeftInStep <= 0 ? (
                    t('text.ZielErreicht')
                  ) : (
                    <Trans
                      t={t}
                      i18nKey={'text.NurXXCoinsZurNachstenStufe'}
                      values={{ current: currentCoins }}
                      components={{
                        coins: (
                          <chakra.span
                            color={'caribbeanGreen.200'}
                            fontWeight={'bold'}
                            fontSize={'xs'}
                            lineHeight={'14px'}
                          >
                            {coinsLeftInStep} VXCoins
                          </chakra.span>
                        ),
                      }}
                    />
                  )}
                </Text>
              </VStack>
            </HStack>
            {/* Profile Progress */}
            <HStack
              border={'1px'}
              borderRadius={'8px'}
              borderColor={'steel'}
              px={3}
              py={4}
              w={'full'}
            >
              <Box w={'50px'} h={'50px'}>
                <CircularProgress
                  value={profileCompleteness}
                  color={'caribbeanGreen.200'}
                >
                  <CircularProgressLabel>
                    <Icon as={VxModelsLogo} color={'steel'} />
                  </CircularProgressLabel>
                </CircularProgress>
              </Box>
              <VStack alignItems={'flex-start'} h={'full'} flex={1}>
                <HStack>
                  <Text
                    fontWeight={'semibold'}
                    lineHeight={'22px'}
                    fontSize={'xl'}
                  >
                    <Trans
                      t={t}
                      i18nKey={'text.AktuellXXVonXXModelprofil'}
                      values={{ current: profileCompleteness }}
                      components={{
                        maxPercent: (
                          <chakra.span
                            fontWeight={'light'}
                            lineHeight={'22px'}
                            fontSize={'13px'}
                          >
                            {profileCompletenessMax} % {t('text.Modelprofil')}
                          </chakra.span>
                        ),
                      }}
                    />
                  </Text>
                </HStack>
                <Text fontWeight={'light'} fontSize={'xs'} lineHeight={'14px'}>
                  {profileCompletenessLeft > 0 ? (
                    <Trans
                      t={t}
                      i18nKey={'text.NurXXPercentZurVollstandigkeit'}
                      components={{
                        percent: (
                          <chakra.span
                            color={'caribbeanGreen.200'}
                            fontWeight={'bold'}
                            fontSize={'xs'}
                            lineHeight={'14px'}
                          >
                            {profileCompletenessLeft} %
                          </chakra.span>
                        ),
                      }}
                    />
                  ) : (
                    t('text.ZielErreicht')
                  )}
                </Text>
              </VStack>
            </HStack>
            {/* 4 Videos */}
            {sharingType === SharingTypeEnum.Shop && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Box w={'50px'} h={'50px'}>
                  <CircularProgress
                    value={countOfVideos}
                    color={'caribbeanGreen.200'}
                    min={0}
                    max={4}
                  >
                    <CircularProgressLabel>
                      <Icon as={VxModelsLogo} color={'steel'} />
                    </CircularProgressLabel>
                  </CircularProgress>
                </Box>
                <VStack alignItems={'flex-start'} h={'full'} flex={1}>
                  <HStack>
                    <Text
                      fontWeight={'semibold'}
                      lineHeight={'22px'}
                      fontSize={'xl'}
                    >
                      <Trans
                        t={t}
                        i18nKey={'text.AktuellXXVonXXVideos'}
                        values={{ current: countOfVideos }}
                        components={{
                          maxVideos: (
                            <chakra.span
                              fontWeight={'light'}
                              lineHeight={'22px'}
                              fontSize={'13px'}
                            >
                              4 Shop-Videos
                            </chakra.span>
                          ),
                        }}
                      />
                    </Text>
                  </HStack>
                  <Text
                    fontWeight={'light'}
                    fontSize={'xs'}
                    lineHeight={'14px'}
                  >
                    {(countOfVideos ?? 0) < 4 ? (
                      <Trans
                        t={t}
                        i18nKey={'text.NochXXVideosImShopVeroffentlichen'}
                        components={{
                          remaining: (
                            <chakra.span
                              color={'caribbeanGreen.200'}
                              fontWeight={'bold'}
                              fontSize={'xs'}
                              lineHeight={'14px'}
                            >
                              {4 - (countOfVideos ?? 0)}
                            </chakra.span>
                          ),
                        }}
                      />
                    ) : (
                      t('text.ZielErreicht')
                    )}
                  </Text>
                </VStack>
              </HStack>
            )}
            {/* HD Videos */}
            {sharingType === SharingTypeEnum.Shop && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Icon boxSize={'icon.md'} as={Movie} />
                <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                  {t('text.MeineVideosSindInHD')}
                </Text>
              </HStack>
            )}
            {/* HD Streaming */}
            {sharingType === SharingTypeEnum.Chat && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Icon boxSize={'icon.md'} as={VxLive} />
                <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                  {t('text.IchStreameInHD')}
                </Text>
              </HStack>
            )}
            {/* Sound Up */}
            {sharingType === SharingTypeEnum.Chat && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Icon boxSize={'icon.md'} as={VolumeUp} />
                <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                  {t('text.IchSendeImmerMitTon')}
                </Text>
              </HStack>
            )}
            {/* Profilstandards */}
            <HStack
              border={'1px'}
              borderRadius={'8px'}
              borderColor={'steel'}
              px={3}
              py={4}
              w={'full'}
            >
              <Icon boxSize={'icon.md'} as={VxModelsLogo} />
              <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                <Trans
                  t={t}
                  i18nKey={'text.MeinProfilEntsprichtDenVISITXProfilstandards'}
                  components={{
                    underline: (
                      <Link
                        isExternal
                        href={
                          externalRoutes.helpCenter(lang) +
                          '/articles/12421556043666-Wie-sieht-ein-optimales-Modelprofil-aus'
                        }
                      >
                        {t('text.Profilstandarts')}
                      </Link>
                    ),
                  }}
                />
              </Text>
            </HStack>
            {/* Approval */}
            <HStack alignItems={'start'}>
              <Checkbox
                pt={1}
                onChange={(e) => setIsChecked(e.target.checked)}
                isChecked={isChecked}
                isDisabled={!isStepVerificationEnabled}
                _disabled={{
                  '.chakra-checkbox__control': {
                    borderColor: 'gray.200',
                  },
                }}
              />
              <Text color={isStepVerificationEnabled ? 'black' : 'gray.200'}>
                {t('text.IchErfulleDieseKriterienUndBeantrageDieNachsteStufe')}
              </Text>
            </HStack>
            <Button
              onClick={onRequestNextLevel}
              variant={'solid'}
              isDisabled={!isChecked}
            >
              {t('button.Bewerben')}
            </Button>
            <Text
              textAlign={'center'}
              color={'gray.500'}
              fontSize={'14px'}
              lineHeight={'19px'}
              mt={4}
            >
              {t(
                'text.SofernDuDieAnforderungenErfullstWirdFurDeineAbrechnungDasJeweilsHoheXX'
              )}
            </Text>
          </VStack>
        </ResponsiveModalBodyBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};

type DetailedStatisticsSharingModalStepTwoProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onRequestNextLevel: () => void;
};

const DetailedStatisticsSharingModalStepTwo: React.FC<
  DetailedStatisticsSharingModalStepTwoProps
> = ({ isOpen, onClose, onRequestNextLevel }) => {
  const lang = useActiveApiLanguage();
  const { t } = useTranslation(['statisticsPage']);
  const [isChecked, setIsChecked] = React.useState(false);
  const { sharingStatistics } = useSharingContext();
  const {
    currentCoins,
    nextStepMax,
    isStepVerificationEnabled,
    sharingCategory,
  } = sharingStatistics;
  const sharingType =
    sharingCategory === 'SHOP' ? SharingTypeEnum.Shop : SharingTypeEnum.Chat;
  const coinsLeftInStep =
    nextStepMax && currentCoins ? nextStepMax - currentCoins : nextStepMax ?? 0;
  const coinsLeftInStepPercent =
    currentCoins && nextStepMax ? (currentCoins / nextStepMax) * 100 : 0;

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      preferredSize="xl"
      isVCentered={false}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ModalHeader>
          <ResponsiveModalCloseButton />
        </ModalHeader>
        <ResponsiveModalBodyBox bg={'surface'} p={'unset'} display={'flex'}>
          <VStack p={4} gap={4} flex={1}>
            <VStack gap={1}>
              <Text
                fontWeight={'semibold'}
                fontSize={'md'}
                lineHeight={'20px'}
                textAlign={'center'}
              >
                {t(
                  'statisticsPage:text.GluckwunschZurQualifizierungFurSharingStufe3DuKannstDichJetztBewerbXX'
                )}
              </Text>
              <Text
                textAlign={'center'}
                fontWeight={'light'}
                fontSize={'14px'}
                lineHeight={'19px'}
                color={'gray.900'}
              >
                {t(
                  'text.DuQualifizierstDichMitDeinerSteigendenPerformanceErstmaligFurSharingXX'
                )}
              </Text>
            </VStack>
            {/* Level Progress */}
            <HStack
              border={'1px'}
              borderRadius={'8px'}
              borderColor={'steel'}
              px={3}
              py={4}
              w={'full'}
            >
              <Box w={'50px'} h={'50px'}>
                <CircularProgress
                  value={coinsLeftInStepPercent}
                  color={'caribbeanGreen.200'}
                >
                  <CircularProgressLabel>
                    <Icon as={VxModelsLogo} color={'steel'} />
                  </CircularProgressLabel>
                </CircularProgress>
              </Box>
              <VStack alignItems={'flex-start'} h={'full'} flex={1}>
                <HStack>
                  <Text
                    fontWeight={'semibold'}
                    lineHeight={'22px'}
                    fontSize={'xl'}
                  >
                    <Trans
                      t={t}
                      i18nKey={'text.AktuellXXVonXXCoins'}
                      values={{ current: currentCoins }}
                      components={{
                        maxCoins: (
                          <chakra.span
                            fontWeight={'light'}
                            lineHeight={'22px'}
                            fontSize={'13px'}
                          >
                            {nextStepMax} VXC
                          </chakra.span>
                        ),
                      }}
                    />
                  </Text>
                </HStack>
                <Text fontWeight={'light'} fontSize={'xs'} lineHeight={'14px'}>
                  {coinsLeftInStep <= 0 ? (
                    t('text.ZielErreicht')
                  ) : (
                    <Trans
                      t={t}
                      i18nKey={'text.NurXXCoinsZurNachstenStufe'}
                      values={{ current: currentCoins }}
                      components={{
                        coins: (
                          <chakra.span
                            color={'caribbeanGreen.200'}
                            fontWeight={'bold'}
                            fontSize={'xs'}
                            lineHeight={'14px'}
                          >
                            {coinsLeftInStep} VXCoins
                          </chakra.span>
                        ),
                      }}
                    />
                  )}
                </Text>
              </VStack>
            </HStack>
            {/* Platforms */}
            {/* {sharingType === SharingTypeEnum.Chat && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Icon boxSize={'icon.md'} as={VxLive} />
                <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                  {t('text.IchSendeAufMax5AnderenPlattformen')}
                </Text>
              </HStack>
            )} */}
            {/* Profilstandards */}
            {sharingType === SharingTypeEnum.Shop && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Icon boxSize={'icon.md'} as={VxModelsLogo} />
                <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                  <Trans
                    t={t}
                    i18nKey={
                      'text.MeinProfilEntsprichtDenVISITXProfilstandards'
                    }
                    components={{
                      underline: (
                        <Link
                          isExternal
                          href={
                            externalRoutes.helpCenter(lang) +
                            '/articles/12421556043666-Wie-sieht-ein-optimales-Modelprofil-aus'
                          }
                        >
                          {t('text.Profilstandarts')}
                        </Link>
                      ),
                    }}
                  />
                </Text>
              </HStack>
            )}
            {/* Price Chat */}
            {sharingType === SharingTypeEnum.Chat && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Icon boxSize={'icon.md'} as={Paid} />
                <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                  {t(
                    'text.IchSendeAufAnderenPlattformenNichtZuEinemGunstigerenPreis'
                  )}
                </Text>
              </HStack>
            )}
            {/* Price Shop */}
            {sharingType === SharingTypeEnum.Shop && (
              <HStack
                border={'1px'}
                borderRadius={'8px'}
                borderColor={'steel'}
                px={3}
                py={4}
                w={'full'}
              >
                <Icon boxSize={'icon.md'} as={Movie} />
                <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                  {t('text.IchVerkaufeMeineVideosBeiVisitXZumBestenPreis')}
                </Text>
              </HStack>
            )}
            {/* Thumb Up */}
            <HStack
              border={'1px'}
              borderRadius={'8px'}
              borderColor={'steel'}
              px={3}
              py={4}
              w={'full'}
            >
              <Icon boxSize={'icon.md'} as={ThumbUp} />
              <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                {t('text.MeineSocialMediaLinksSindImProfilHinterlegt')}
              </Text>
            </HStack>
            {/* Fair-Play Regeln */}
            {/* <HStack
              border={'1px'}
              borderRadius={'8px'}
              borderColor={'steel'}
              px={3}
              py={4}
              w={'full'}
            >
              <Icon boxSize={'icon.md'} as={Forum} />
              <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
                {t('text.IchBeachteInJederSessionDieChatFairPlayRegeln')}
              </Text>
            </HStack> */}
            {/* Approval */}
            <HStack alignItems={'start'}>
              <Checkbox
                pt={1}
                onChange={(e) => setIsChecked(e.target.checked)}
                isChecked={isChecked}
                isDisabled={!isStepVerificationEnabled}
                _disabled={{
                  '.chakra-checkbox__control': {
                    borderColor: 'gray.200',
                  },
                }}
              />
              <Text color={isStepVerificationEnabled ? 'black' : 'gray.200'}>
                {t('text.IchErfulleDieseKriterienUndBeantrageDieNachsteStufe')}
              </Text>
            </HStack>
            <Button
              onClick={onRequestNextLevel}
              variant={'solid'}
              isDisabled={!isChecked}
            >
              {t('button.Bewerben')}
            </Button>
            <Text
              textAlign={'center'}
              color={'gray.500'}
              fontSize={'14px'}
              lineHeight={'19px'}
              mt={4}
            >
              {t(
                'text.SofernDuDieAnforderungenErfullstWirdFurDeineAbrechnungDasJeweilsHoheXX'
              )}
            </Text>
          </VStack>
        </ResponsiveModalBodyBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};

const ExclusiveModelSharingBox: React.FC = () => {
  const { t } = useTranslation(['statisticsPage']);
  const { sharingStatistics } = useSharingContext();
  const { progressPercent, currentStepFactor } = sharingStatistics;

  const locale = useIntl().locale;
  return (
    <VStack gap={4} h={'full'}>
      <VStack
        gap={0}
        minW={STEP_TILE_WIDTH}
        minH={STEP_TILE_WIDTH}
        px={'1px'}
        pb={'1px'}
        bgGradient={'linear(to-r, primary.from, primary.to)'}
        borderRadius={'8px'}
      >
        <LevelSectionSharingHeader
          text={t('text.VertraglichVereinbartesSharing')}
          hasInfo={false}
        />
        <VStack
          bgColor={'surface'}
          w={'full'}
          flex={1}
          borderRadius={'0px 0px 8px 8px'}
          spacing={4}
          py={4}
          px={{ base: 2, md: 4 }}
        >
          <VStack flex={1} textAlign={'center'} spacing={4}>
            <Text fontWeight={'light'} fontSize={'md'} lineHeight={'14px'}>
              {t('text.HerzlichenGluckwunschDuBist')}
            </Text>
            <Image
              src={exclusiveSharingImg}
              alt={t('text.DuBistHighPerformer')}
              w={'102px'}
              h={'68px'}
              objectFit={'cover'}
            />
          </VStack>
          <VStack>
            <Text fontWeight={'light'} fontSize={'16px'} lineHeight={'18px'}>
              {t('text.VerdienstPro')}
            </Text>
            <Text fontWeight={'medium'} fontSize={'25px'} lineHeight={'26px'}>
              {t('text.TenVXFactor', {
                factor: getFormattedAmount(currentStepFactor ?? 0, locale),
              })}
            </Text>
          </VStack>
          <Text
            fontWeight={'light'}
            fontSize={'10px'}
            lineHeight={'12px'}
            letterSpacing={'0.03em'}
            color={'darkSteel'}
          >
            {t('text.EntsprichtXXSharingImVereinbartenVertragszeitraum', {
              sharing: progressPercent ?? 0,
            }).toUpperCase()}
          </Text>
        </VStack>
      </VStack>
    </VStack>
  );
};
