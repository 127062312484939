import { Alert, Button, FormControl, FormErrorIcon, FormErrorMessage, HStack, Spacer, useDisclosure, VStack } from '@chakra-ui/react';
import Markdown from '../../../components/shared/Markdown/Markdown';
import { FormStatusEnum } from '../../../generated/graphql';
import { ModelReleaseFormDocumentView } from '../../../components/shared/ModelReleaseFormDocumentView/ModelReleaseFormDocumentView';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Section, SectionBody, SectionDescription, SectionHeader, SectionTitle } from '@campoint/odi-ui';
import { SectionCenterContainer } from '../../../components/Layout/SectionCenterContainer';
import { ScrollToTargetInline } from '../../../components/Layout/ScrollToTargetInline';
import { UrlFragment } from '../../../components/Layout/UrlFragmentScrollToTarget';
import { GuideDot } from '../../../components/shared/GuideDot/GuideDot';
import { useLang } from '../../../provider/AuthProvider';
import { ensureModelReleaseFormLanguageEnum, useModelReleaseForm } from '../../../utils/modelReleaseForm';
import { ModelReleaseFormModal } from './ModelReleaseFormModal';

type DocumentDetailModelReleaseFormSectionProps = {
  userId: number;
};

export const DocumentDetailModelReleaseFormSection: React.FC<DocumentDetailModelReleaseFormSectionProps> = ({ userId }) => {

  const lang = ensureModelReleaseFormLanguageEnum(useLang());

  const { t } = useTranslation(['document', 'payoutDocuments']);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const buildErrorMessageByStatus = (status: FormStatusEnum): string|undefined => {
    switch (status) {
      case FormStatusEnum.Expired:
        return t('document:text.DasDokumentIstAbgelaufen');
      case FormStatusEnum.Rejected:
        return t('document:text.DasDokumentWurdeAbgelehnt');
      case FormStatusEnum.Incomplete:
        return t('document:text.BitteLadeDasFehlendeDokumentHoch');
      default:
        return undefined;
    }
  };

  const { modelReleaseForm } = useModelReleaseForm(userId, { fetchPolicy: 'cache-first' });

  // get notice from MRF user document
  const notice = modelReleaseForm?.userDocument?.notice;
  const rejectionReason = modelReleaseForm?.userDocument?.rejectionReason;

  // check if valid MRF exists
  const status = modelReleaseForm?.status ?? FormStatusEnum.Incomplete;
  const validMrfStates = [FormStatusEnum.Accepted, FormStatusEnum.Pending, FormStatusEnum.Draft, FormStatusEnum.Complete];
  const isValidMrf = validMrfStates.includes(status);
  const errorMessage = buildErrorMessageByStatus(status);

  return (
    <>
      <Section>
        <SectionHeader>
          <SectionCenterContainer>
            <ScrollToTargetInline id={UrlFragment.MrfDocument} />
            <HStack>
              <SectionTitle>
                {t('document:heading.ModelReleaseForm')}
              </SectionTitle>
              {!isValidMrf && (
                <>
                  <Spacer />
                  <GuideDot bg={'error.500'} isShown={true} />
                </>
              )}
            </HStack>
            <SectionDescription>
              {t(
                'document:text.MitDeinerUnterschriftBestatigstDuDieRechteAnAllenInhaltenSowieDieZusXX',
              )}
            </SectionDescription>
            <FormControl isInvalid={!isValidMrf}>
              <FormErrorMessage>
                <FormErrorIcon />
                { errorMessage }
              </FormErrorMessage>
            </FormControl>
          </SectionCenterContainer>
        </SectionHeader>
        <SectionBody>
          <SectionCenterContainer>
            <VStack w={'full'} alignItems={'stretch'}>
              {
                // show notice, if available
                notice && (<Alert status="error" mb={4}> <Markdown markdown={notice} /> </Alert>)
              }
              {
                // show notice, if available
                rejectionReason && (<Alert status="error" mb={4}> <Markdown markdown={`**${t('document:text.Ablehnungsgrund')}**\n\n${rejectionReason}`} /> </Alert>)
              }
              {
                // show MRF document view, if valid MRF exists, else show button to open MRF modal
                isValidMrf
                  ? (
                    <ModelReleaseFormDocumentView doc={modelReleaseForm} onRejected={onOpen} />
                  )
                  : (
                    <Button
                      alignSelf={'center'}
                      shadow={'none'}
                      children={t('payoutDocuments:button.ModelReleaseFormAusfuellen')}
                      onClick={onOpen}
                    />
                  )
              }
            </VStack>
          </SectionCenterContainer>
        </SectionBody>
      </Section>
      <ModelReleaseFormModal
        isOpen={isOpen}
        onClose={onClose}
        createdActor={false}
        userId={userId}
        defaultLang={lang}
        modelReleaseForm={modelReleaseForm}
      />
    </>
  );
};
