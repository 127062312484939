import { SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import { AssignmentInd, Email, Info, Phone } from '@campoint/odi-ui-icons';
import {
  Box,
  Center,
  Container,
  Divider,
  HStack,
  Heading,
  Image,
  ImageProps,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import profilePicturePlaceholderFSK16 from '../../assets/images/imageUpload/fsk16-placeholder.svg';
import { FluentPageLayout } from '../../components/Layout/FluentPageLayout';
import { LayoutHintsVStack } from '../../components/Layout/LayoutHintsVStack';
import ScrollToLastKnownPositionOnMount from '../../components/Layout/ScrollToLastKnownPositionOnMount';
import { ActionTile } from '../../components/shared/ActionTile/ActionTile';
import { UpdateTaxInformationExpiredHintSlot } from '../../components/shared/UpdateTaxInformationExpiredHintSlot/UpdateTaxInformationExpiredHintSlot';
import { LargeIconCardDot } from '../../components/shared/cards/LargeIconCard/LargeIconCard';
import { FeatureName, useFeature } from '../../flags';
import { useGetHelpCenterPageDataQuery } from '../../generated/graphql';
import { useActiveApiLanguage } from '../../hooks/useActiveApiLanguage';
import { useAccountStatus } from '../../provider/AccountStatusProvider/AccountStatusProvider';
import { useAuth } from '../../provider/AuthProvider';
import { externalRoutes, routes } from '../../routes/routesConfig';
import { noop } from '../../utils';
import { extractFromUnion } from '../../utils/extractor';
import { VXMChatbot } from './Chatbot/VXMChatbot';

const HelpCenterPage: React.FC = () => {
  const { t } = useTranslation(['helpPage', 'home']);
  const { data: supportData, loading } = useGetHelpCenterPageDataQuery();
  const history = useHistory();
  const { authUser, isMasterAccount } = useAuth();
  const { version } = require('./../../../package.json');
  const lang = useActiveApiLanguage();
  const { hasSetUsername } = useAccountStatus();
  const chatbotFlag = useFeature(FeatureName.chatbot);

  const hotlineCode = supportData?.app?.hotlineCode ?? null;
  const phoneNumber = supportData?.support?.tel ?? '';
  const supportMail = supportData?.support?.mailto ?? '';
  const profileCollection = extractFromUnion(
    supportData?.profile?.collection,
    'ProfileCollectionAccountV1'
  );
  const username =
    authUser?.username ??
    profileCollection?.values.accountContactEmailAdress ??
    '';
  const modelId = authUser?.userId ?? '';
  const accounter = supportData?.account.keyAccounter;
  const mediaCollection = extractFromUnion(
    supportData?.profile?.mediaCollection,
    'ProfileCollectionMobileMediaV1'
  );
  const modelPictureFSK16Avatar =
    mediaCollection?.values?.modelPictureFSK16Avatar?.image.src;

  // support.available is an array of all support instances that are currently
  // online. Therefor checking if array.length is the easiest solution here
  const supportAvailable =
    supportData?.support?.available &&
    supportData?.support?.available.length > 0;
  const helpCenterUrl =
    supportData?.helpcenter?.url ?? externalRoutes.helpCenter(lang);

  const openPhone = () => {
    window.open(`tel:${phoneNumber}`, '_self');
  };
  const openHelpCenter = () => {
    window.open(helpCenterUrl, '_blank');
  };
  const openMail = (email: string) => {
    window.open(`mailto:${email}`, '_blank');
  };
  const requestCallbackMail = (email: Maybe<string>) => {
    window.open(
      `mailto:${email}?subject=@${username} hat einen Rückruf angefragt.`
    );
  };

  const spacing = { base: 4, lg: 8 };

  return (
    <FluentPageLayout isContentLoading={loading}>
      {chatbotFlag && (
        <VXMChatbot
          modelPictureFSK16Avatar={
            modelPictureFSK16Avatar ?? profilePicturePlaceholderFSK16
          }
        />
      )}
      <Box>
        <ScrollToLastKnownPositionOnMount identifier={'/helpcenter'} />
        <SubHeader>
          <Container px={0} maxW={'container.xl'}>
            <SubHeaderTitle p={0}>{t('helpPage:headline')}</SubHeaderTitle>
          </Container>
        </SubHeader>
        <Container maxW="container.lg" p={0}>
          <LayoutHintsVStack>
            <UpdateTaxInformationExpiredHintSlot
              outerStackProps={{ pt: spacing }}
            />
          </LayoutHintsVStack>
          <HStack justifyContent={'center'} mt={12} mx={4} spacing={4}>
            <HelpCenterPageAvatar
              onClick={() => {
                history.push(routes.profileV2.path);
              }}
              src={modelPictureFSK16Avatar}
            />
            <VStack alignItems={'start'} flexGrow={0} overflow={'hidden'}>
              <Text fontSize={'xl'} wordBreak={'break-all'}>
                {!hasSetUsername ? (
                  t('home:headline.WillkommenbeiVXModels')
                ) : (
                  <>
                    {t('helpPage:profile.welcome')}
                    <chakra.span fontWeight={'700'}>{username}</chakra.span>
                  </>
                )}
              </Text>

              <Text fontSize={'lg'}>
                {t('helpPage:profile.modelid', { userid: modelId })}
              </Text>
            </VStack>
          </HStack>
          <VStack spacing={spacing} w={'full'} my={8}>
            {accounter && (
              <>
                <Box px={4} w={'full'}>
                  <ActionTile
                    id={'0'}
                    title={accounter.name}
                    subtitle={t('helpPage:cards.accounter')}
                    icon={AssignmentInd}
                    description={accounter.phoneNumber}
                    subDescription={accounter.email}
                    onClick={noop}
                  />
                </Box>

                {accounter.email && (
                  <Box px={4} w={'full'}>
                    <ActionTile
                      id={'1'}
                      title={t('helpPage:cards.callback')}
                      icon={Phone}
                      description={t('helpPage:cards.freeCallbackService')}
                      onClick={() => requestCallbackMail(accounter.email)}
                    />
                  </Box>
                )}
              </>
            )}
            <Box px={4} w={'full'}>
              <ActionTile
                id={'2'}
                title={t('helpPage:cards.helpCenter')}
                icon={Info}
                onClick={openHelpCenter}
              />
            </Box>
            <Box px={4} w={'full'}>
              <ActionTile
                id={'3'}
                title={t('helpPage:cards.callUs')}
                icon={Phone}
                description={phoneNumber}
                onClick={openPhone}
                isAvailableIndicator={
                  <LargeIconCardDot
                    bg={
                      supportAvailable
                        ? 'success.highEmphasis'
                        : 'error.highEmphasis'
                    }
                  />
                }
              />
            </Box>
            <Box px={4} w={'full'}>
              <ActionTile
                id={'4'}
                title={t('helpPage:cards.contactUs')}
                icon={Email}
                onClick={() => openMail(supportMail)}
                description={supportMail}
              />
            </Box>
          </VStack>

          {hotlineCode && isMasterAccount && (
            <VStack mt={8} mb={4} spacing={4}>
              <Heading
                children={t('helpPage:supportCode.headline')}
                as={'h3'}
                size={'none'}
                textStyle={'headingMd'}
                color={'gray.500'}
              />
              <Text
                children={hotlineCode}
                fontWeight={'bold'}
                fontSize={'xl'}
                color={'coldGray.900'}
              />
              <Text
                children={t('helpPage:supportCode.subtext')}
                textStyle={'bodySm'}
                fontWeight={'medium'}
                color={'gray.300'}
              />
            </VStack>
          )}

          <Divider mt={8} mb={4} />

          <Box
            mb={4}
            onClick={() => {
              history.push(routes.featureFlag.path);
            }}
          >
            <Center>Version {version}</Center>
          </Box>
        </Container>
      </Box>
    </FluentPageLayout>
  );
};

export default HelpCenterPage;

const HelpCenterPageAvatar: React.FC<ImageProps> = ({
  src,
  onClick,
  ...rest
}) => {
  return (
    <Image
      src={src ?? profilePicturePlaceholderFSK16}
      onClick={onClick}
      w={'80px'}
      h={'80px'}
      borderRadius={'full'}
      boxSize={'80px'}
      userSelect={'none'}
      draggable={false}
      {...rest}
    />
  );
};
