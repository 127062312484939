import { Box, useDisclosure } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React from 'react';

import { AbsoluteFullCenterLoadingSpinner } from '../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import {
  ResponsiveModal,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../components/Layout/ResponsiveModal';
import { ConfirmChangesDialog } from '../../components/shared/dialogs/ConfirmChangesDialog/ConfirmChangesDialog';
import { useBeforeUnload } from '../../hooks/useBeforeUnload';
import { useVideoLibraryEntryModalContext } from '../../provider/VideoLibraryProvider/VideoLibraryEntryModalProvider/VideoLibraryEntryModalProvider';
import { VideoModalContentBox } from './components/EntryModalContent/EntryModalContentBox';

export type VideoLibraryEntryModalContent = {
  title: string;
  content: React.ReactNode;
};

export const VideoLibraryVideoUploadListEntryModal: React.FC = () => {
  const { isOpen, actions, video, videoLoading, unsubmittedEdits } =
    useVideoLibraryEntryModalContext();

  const {
    isOpen: isConfirmChangesDialogOpen,
    onOpen,
    onClose,
  } = useDisclosure();

  useBeforeUnload(isOpen && !isEmpty(unsubmittedEdits));

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={() => {
        if (isEmpty(unsubmittedEdits)) {
          actions.closeModal();
        } else {
          onOpen();
        }
      }}
      isVCentered={false}
      preferredSize="lg"
    >
      <ConfirmChangesDialog
        isOpen={isConfirmChangesDialogOpen}
        navigateBack={() => {
          actions.setUnsubmittedEdits({});
          onClose();
          actions.closeModal();
        }}
        onClose={onClose}
      />
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalStickyHeaderBox bg={'surface'} minH={'56px'}>
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        {!videoLoading && video ? (
          <VideoModalContentBox
            uploadedVideo={video}
            //we dont use this flow here
            // uploadingVideo={uploadingVideo}
          />
        ) : (
          <Box w={'full'} minH={'300px'}>
            <AbsoluteFullCenterLoadingSpinner />
          </Box>
        )}
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
