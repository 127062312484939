import * as React from 'react';

import { FeatureName, useFeature } from '../../../flags';
import {
  InputVideo,
  Maybe,
  VideoFragment,
  useGetVideoLibraryVideoUploadListEntryQuery,
} from '../../../generated/graphql';
import { createContext } from '../../../hooks/useContext';
import { VideoLibraryVideoUploadListEntryModal } from '../../../pages/VideoLibraryPage/VideoLibraryVideoUploadListEntryModal';
import { MediaContext } from '../../MediaProvider';

export interface IMediaAlbumEdits {
  input?: InputVideo;
  thumbnail16ReplacementUploadId?: string;
  thumbnail16Replacement?: MediaContext['media'];
  thumbnail18ReplacementUploadId?: string;
  thumbnail18Replacement?: MediaContext['media'];
  errors?: any;
}

interface VideoLibraryEntryModalContext {
  isOpen: boolean;
  video: Maybe<VideoFragment> | undefined;
  videoLoading: boolean;
  albumId: number | null;
  unsubmittedEdits: IMediaAlbumEdits;
  actions: {
    openModal: (albumId: number | null) => void;
    closeModal: () => void;
    setUnsubmittedEdits: React.Dispatch<React.SetStateAction<IMediaAlbumEdits>>;
  };
}

export const [
  ,
  useVideoLibraryEntryModalContext,
  videoLibraryEntryModalContext,
] = createContext<VideoLibraryEntryModalContext>({
  name: 'videoLibraryEntryModalContext',
  errorMessage:
    'useVideoLibraryEntryModalContext: `videoLibraryEntryModalContext` is undefined. Seems you forgot to wrap component within the Provider',
  strict: true,
});

export const VideoLibraryEntryModalProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const videoLibraryFeatureFlag = useFeature(FeatureName.videoLibrary);
  /**
   * Modal handling
   */
  const [albumId, setAlbumId] = React.useState<number | null>(null);

  /**
   * Get Upload List Entry data
   */
  const { data, loading } = useGetVideoLibraryVideoUploadListEntryQuery({
    variables: {
      albumId: albumId ?? undefined,
    },
    skip: !albumId || !videoLibraryFeatureFlag,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const video: Maybe<VideoFragment> | undefined = React.useMemo(() => {
    return data?.media.videos.videoById;
  }, [data?.media.videos.videoById]);

  /**
   * Update Upload List Entry handling
   */

  const [unsubmittedEdits, setUnsubmittedEdits] =
    React.useState<IMediaAlbumEdits>({});

  const actions: VideoLibraryEntryModalContext['actions'] =
    React.useMemo(() => {
      return {
        setUnsubmittedEdits,
        openModal: (albumId) => setAlbumId(albumId),
        closeModal: () => setAlbumId(null),
      };
    }, []);

  const context: VideoLibraryEntryModalContext = React.useMemo(() => {
    return {
      isOpen: !!albumId,
      video,
      videoLoading: loading,
      albumId,
      unsubmittedEdits,
      actions,
    };
  }, [albumId, video, loading, unsubmittedEdits, actions]);

  return (
    <videoLibraryEntryModalContext.Provider value={context}>
      {children}
      <VideoLibraryVideoUploadListEntryModal />
    </videoLibraryEntryModalContext.Provider>
  );
};
