import { ApolloError } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';
import * as React from 'react';

import {
  GetAllNotificationsQuery,
  GetImportantNotificationsQuery,
  GetNewsNotificationsQuery,
  NotificationFragment,
  NotificationTypeDisplayEnum,
  NotificationsFilterPresetEnum,
  useGetAllNotificationsQuery,
  useGetImportantNotificationsQuery,
  useGetNewsNotificationsQuery,
  useGetNotificationsCountQuery,
} from '../../generated/notifications';
import { useActiveApiLanguage } from '../../hooks/useActiveApiLanguage';
import { createContext } from '../../hooks/useContext';
import Logger from '../../utils/Logger';
import { useAuth } from '../AuthProvider';
import { NewsCenterAnnouncementModal } from './NewsCenterAnnouncementModal';

const pollInterval = 5 * 60 * 1000; // 5 minutes

type NotificationsQuery =
  | GetNewsNotificationsQuery
  | GetImportantNotificationsQuery
  | GetAllNotificationsQuery;

export type NewsCenterNotification = {
  cursor: string;
  node: NotificationFragment;
};

export type NewsCenterNotificationNode = {
  [node: string]: NotificationFragment;
};

export type NewsCenterContext = {
  selectedNotification: NotificationFragment | null;
  setSelectedNotification: React.Dispatch<
    React.SetStateAction<NotificationFragment | null>
  >;
  hasUnreadNotifications: boolean;
  hasImportantUnreadNotifications: boolean;
  hasUnreadNewsNotifications: boolean;
  allCountDataRefetch: any;
  importantCountDataRefetch: any;
  newsCountDataRefetch: any;
  importantNotificationEdges: NewsCenterNotification[];
  importantLoading: boolean;
  importantFetchMore: any;
  importantHasNextPage: boolean;
  importantError: ApolloError | undefined;
  allNotificationEdges: NewsCenterNotification[];
  allLoading: boolean;
  allFetchMore: any;
  allHasNextPage: boolean;
  allError: ApolloError | undefined;
  newsNotificationEdges: NewsCenterNotification[];
  newsLoading: boolean;
  newsFetchMore: any;
  newsHasNextPage: boolean;
  newsError: ApolloError | undefined;
  listTabIndex: number;
  setListTabIndex: React.Dispatch<React.SetStateAction<number>>;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
  onOpenAnnouncement: () => void;
};

export const [, useNewsCenter, newsCenterContext] =
  createContext<NewsCenterContext>({
    name: 'NewsCenterContext',
    strict: true,
    errorMessage:
      'useNewsCenter: `context` is undefined. Seems you forgot to wrap component within the Provider',
  });

const onError = (error: ApolloError): void => {
  Logger.error(error);
};

export const NewsCenterProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const {
    isOpen: isAnnouncementOpen,
    onOpen: onOpenAnnouncement,
    onClose: onCloseAnnouncement,
  } = useDisclosure();

  const [modalNotifications, setModalNotifications] = React.useState<
    NotificationFragment[]
  >([]);

  React.useEffect(() => {
    if (modalNotifications.length > 0 && !isAnnouncementOpen) {
      // todo sort by date
      setSelectedNotification(modalNotifications[0]);
      onOpenAnnouncement();
    }
  }, [modalNotifications, isAnnouncementOpen, onOpenAnnouncement]);

  const lang = useActiveApiLanguage();

  const { isAuthenticated } = useAuth();

  const [isInitialDelayDone, setIsInitialDelayDone] = React.useState(false);

  // To mitigate showing notifications of previously logged in users:
  // initially prevent queries to run until a delay is through
  // todo: Investigate route cause of cancelled requests and uncleared caches
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsInitialDelayDone(true);
    }, 250);
    return () => clearTimeout(timeoutId);
  }, [setIsInitialDelayDone]);

  const skip = !isAuthenticated || !isInitialDelayDone;

  const [selectedNotification, setSelectedNotification] =
    React.useState<NotificationFragment | null>(null);

  const [listTabIndex, setListTabIndex] = React.useState<number>(0);

  const onCompleted = (data: NotificationsQuery): void => {
    // look into completed data and add modal notifications
    const nextModalNotifications = (
      (data?.notifications?.pagination?.edges as {
        cursor: string;
        node: NotificationFragment;
      }[]) ?? []
    )
      .filter(
        (edge) =>
          edge.node.readAt === null &&
          edge.node.display === NotificationTypeDisplayEnum.Modal
      )
      .map((edge) => edge.node);

    setModalNotifications([...modalNotifications, ...nextModalNotifications]);
  };

  const {
    data: dataImportant,
    loading: importantLoading,
    fetchMore: importantFetchMore,
    error: importantError,
  } = useGetImportantNotificationsQuery({
    variables: { lang: lang, count: 8, cursor: '' },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval,
    notifyOnNetworkStatusChange: false,
    skip,
    onError,
    onCompleted,
  });

  const { importantNotificationEdges, importantHasNextPage } = React.useMemo(
    () => ({
      importantNotificationEdges: dataImportant?.notifications?.pagination
        ?.edges as NewsCenterNotification[],
      importantHasNextPage:
        dataImportant?.notifications?.pagination?.pageInfo.hasNextPage ?? false,
    }),
    [dataImportant]
  );

  const {
    data: dataAll,
    loading: allLoading,
    fetchMore: allFetchMore,
    error: allError,
  } = useGetAllNotificationsQuery({
    variables: { lang: lang, count: 8, cursor: '' },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval,
    notifyOnNetworkStatusChange: false,
    skip,
    onError,
  });

  const { allNotificationEdges, allHasNextPage } = React.useMemo(() => {
    return {
      allNotificationEdges: dataAll?.notifications?.pagination
        ?.edges as NewsCenterNotification[],
      allHasNextPage:
        dataAll?.notifications?.pagination?.pageInfo.hasNextPage ?? false,
    };
  }, [dataAll]);

  const {
    data: dataNews,
    loading: newsLoading,
    fetchMore: newsFetchMore,
    error: newsError,
  } = useGetNewsNotificationsQuery({
    variables: { lang: lang, count: 8, cursor: '' },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    pollInterval,
    notifyOnNetworkStatusChange: true,
    skip,
    onError,
    onCompleted,
  });

  const { newsNotificationEdges, newsHasNextPage } = React.useMemo(
    () => ({
      newsNotificationEdges: dataNews?.notifications?.pagination
        ?.edges as NewsCenterNotification[],
      newsHasNextPage:
        dataNews?.notifications?.pagination?.pageInfo.hasNextPage ?? false,
    }),
    [
      dataNews?.notifications?.pagination?.edges,
      dataNews?.notifications?.pagination?.pageInfo.hasNextPage,
    ]
  );

  const { data: newsCountData, refetch: newsCountDataRefetch } =
    useGetNotificationsCountQuery({
      variables: { preset: NotificationsFilterPresetEnum.NewsCenterUnread },
      skip,
      onError,
    });

  const hasUnreadNewsNotifications = React.useMemo(() => {
    return (newsCountData?.notifications?.pagination?.totalCount ?? 0) > 0;
  }, [newsCountData?.notifications?.pagination?.totalCount]);

  const { data: importantCountData, refetch: importantCountDataRefetch } =
    useGetNotificationsCountQuery({
      variables: { preset: NotificationsFilterPresetEnum.ImportantUnread },
      skip,
      onError,
    });

  const hasImportantUnreadNotifications = React.useMemo(() => {
    return (importantCountData?.notifications?.pagination?.totalCount ?? 0) > 0;
  }, [importantCountData]);

  const { data: allCountData, refetch: allCountDataRefetch } =
    useGetNotificationsCountQuery({
      variables: { preset: NotificationsFilterPresetEnum.AllUnread },
      skip,
      onError,
    });

  const hasUnreadNotifications = React.useMemo(
    () => (allCountData?.notifications?.pagination?.totalCount ?? 0) > 0,
    [allCountData]
  );

  const context = React.useMemo<NewsCenterContext>(
    () => ({
      isOpen,
      selectedNotification,
      setSelectedNotification,
      listTabIndex,
      setListTabIndex,
      importantNotificationEdges,
      importantLoading,
      importantFetchMore,
      importantHasNextPage,
      importantError,
      allNotificationEdges,
      allFetchMore,
      allLoading,
      allHasNextPage,
      allError,
      newsNotificationEdges,
      newsFetchMore,
      newsLoading,
      newsHasNextPage,
      newsError,
      hasUnreadNotifications,
      allCountDataRefetch,
      hasImportantUnreadNotifications,
      importantCountDataRefetch,
      hasUnreadNewsNotifications,
      newsCountDataRefetch,
      onOpen,
      onClose,
      onToggle,
      onOpenAnnouncement,
    }),
    [
      isOpen,
      selectedNotification,
      listTabIndex,
      importantNotificationEdges,
      importantLoading,
      importantFetchMore,
      importantHasNextPage,
      importantError,
      allNotificationEdges,
      allFetchMore,
      allLoading,
      allHasNextPage,
      allError,
      newsNotificationEdges,
      newsFetchMore,
      newsLoading,
      newsHasNextPage,
      newsError,
      hasUnreadNotifications,
      allCountDataRefetch,
      hasImportantUnreadNotifications,
      importantCountDataRefetch,
      hasUnreadNewsNotifications,
      newsCountDataRefetch,
      onOpen,
      onClose,
      onToggle,
      onOpenAnnouncement,
    ]
  );

  return (
    <newsCenterContext.Provider value={context}>
      {children}
      <NewsCenterAnnouncementModal
        isOpen={isAnnouncementOpen}
        onClose={() => {
          onCloseAnnouncement();
          setSelectedNotification(null);
          setModalNotifications([]);
        }}
        selectedNotification={selectedNotification}
      />
    </newsCenterContext.Provider>
  );
};
