import { AspectRatio, Button, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Maybe } from 'graphql/jsutils/Maybe';
import { DateTime } from 'luxon';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { ResponsiveModalBodyBox } from '../../../../components/Layout/ResponsiveModal';
import { MandatoryErrorMessageForCheckBox } from '../../../../components/shared/ErrorMessageWithIcon/ErrorMessageWithIcon';
import { PreventImplicitFormSubmitOnEnter } from '../../../../components/shared/FormElements/PreventImplicitFormSubmitOnEnter/PreventImplicitFormSubmitOnEnter';
import { GradientSpinner } from '../../../../components/shared/GradientSpinner/GradientSpinner';
import VideoPlayer from '../../../../components/shared/VideoPlayer/VideoPlayer';
import {
  ContestFragment,
  GetContestDataDocument,
  GetVideoLibraryVideoUploadListDocument,
  GetVideoLibraryVideoUploadListEntryDocument,
  InputLangText,
  InputVideo,
  LanguagesEnum,
  VideoFragment,
  VideoReleasedStatusEnum,
  VideoTypeEnum,
  useGetVideoLibraryPreviewPictureUploadUrlLazyQuery,
  useVideoLibraryUploadListEntryMutation,
} from '../../../../generated/graphql';
import { useFlagsProviderV2 } from '../../../../provider/FlagsProviderV2';
import { useVideoLibraryEntryModalContext } from '../../../../provider/VideoLibraryProvider/VideoLibraryEntryModalProvider/VideoLibraryEntryModalProvider';
import { useUploadyService } from '../../../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/VideoLibraryUploadyProvider';
import { TusUploadEntry } from '../../../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/videoUploadEnhancer';
import { LocalStorageKeys } from '../../../../types';
import { LocalStorage } from '../../../../utils';
import Logger from '../../../../utils/Logger';
import { uploadImage } from '../../../../utils/media';
import {
  createDateValidationSchema,
  createStringValidationSchema,
} from '../../../../utils/validation';
import { VideoLibraryEntryModalContent } from '../../VideoLibraryVideoUploadListEntryModal';
import { useVideoLibraryUploadListEntryScheduableTimeframe } from '../../utils/scheduableTimeframe';
import { VideoLibraryEntryModalNavbar } from '../EntryModalNavbar/EntryModalNavbar';
import { EntryPriceSettingsTab } from './EntryModalPriceSettingsTab/EntryModalPriceSettingsTab';
import { EntryModalSettingsConfirmTab } from './EntryModalSettingsConfirmTab/EntryModalSettingsConfirmTab';
import { EntryModalTitleDescriptionTab } from './EntryModalTitleDescriptionTab/EntryModalTitleDescriptionTab';

export const videoLibraryFieldName = {
  title: 'title',
  description: 'description',
  tags: 'tags',
  price: 'price',
  discountEnabled: 'discountEnabled',
  discount: 'discount',
  discountDateRange: 'discountDateRange',
  online: 'online',
  publicationScheduledFor: 'publicationScheduledFor',
  confirmedOwndershipOfRequiredDocuments:
    'confirmedOwndershipOfRequiredDocuments',
  confirmedPublicationConsend: 'confirmedPublicationConsend',
  releasedStatus: 'releasedStatus',
  //...
} as const;

const videoLibraryFieldNameByTab = {
  0: [
    videoLibraryFieldName.title,
    videoLibraryFieldName.description,
    videoLibraryFieldName.tags,
  ],
  1: [
    videoLibraryFieldName.price,
    videoLibraryFieldName.discountEnabled,
    videoLibraryFieldName.discount,
    videoLibraryFieldName.discountDateRange,
    videoLibraryFieldName.online,
    videoLibraryFieldName.publicationScheduledFor,
    videoLibraryFieldName.releasedStatus,
  ],
  2: [
    videoLibraryFieldName.confirmedOwndershipOfRequiredDocuments,
    videoLibraryFieldName.confirmedPublicationConsend,
  ],
} as const;

//* Info: In video library the video is only editable AFTER upload -> uploadedVideo
//* Info: Currently for contest videos the video is editable DURING upload -> uploadingVideo
export const VideoModalContentBox: React.FC<{
  uploadedVideo?: Maybe<VideoFragment>;
  uploadingVideo?: Maybe<TusUploadEntry>;
  contest?: Maybe<ContestFragment>;
  onContestModalClose?: () => void;
}> = ({ uploadedVideo, uploadingVideo, contest, onContestModalClose }) => {
  const { t } = useTranslation(['videoEditModal', 'general']);
  const { coinsEnabled } = useFlagsProviderV2();

  const { actions, unsubmittedEdits } = useVideoLibraryEntryModalContext();

  const scheduableTimeframe =
    useVideoLibraryUploadListEntryScheduableTimeframe();
  const initialValues = React.useMemo(() => {
    const setCorrectReleasedStatusField = (
      releasedStatus: VideoReleasedStatusEnum | null | undefined,
      isDateEditable: boolean | null | undefined
    ): VideoReleasedStatusEnum => {
      if (!releasedStatus) return VideoReleasedStatusEnum.Online;

      if (
        isDateEditable &&
        releasedStatus === VideoReleasedStatusEnum.Planned
      ) {
        return VideoReleasedStatusEnum.Planned;
      }
      return releasedStatus;
    };
    const formattedPlannedDate = uploadedVideo?.plannedReleaseDate
      ? DateTime.fromISO(uploadedVideo!.plannedReleaseDate).toISO({
          includeOffset: false,
        })
      : scheduableTimeframe.min;

    return {
      [videoLibraryFieldName.title]:
        uploadedVideo?.textCollection?.visitxTitles?.find(
          (title) => title?.lang?.toLowerCase() === 'de'
        )?.text ?? '',
      [videoLibraryFieldName.description]:
        uploadedVideo?.textCollection?.visitxDescriptions?.find(
          (description) => description?.lang?.toLowerCase() === 'de'
        )?.text ?? '',

      [videoLibraryFieldName.tags]:
        uploadedVideo?.tagCollection?.map((entry) => entry?.value) ?? [],
      [videoLibraryFieldName.price]: coinsEnabled
        ? uploadedVideo?.priceCoins && uploadedVideo?.priceCoins > 3
          ? uploadedVideo?.priceCoins
          : 3
        : uploadedVideo?.price ?? 0.49,
      [videoLibraryFieldName.discount]: coinsEnabled
        ? uploadedVideo?.discountCoins ?? 1
        : uploadedVideo?.discountPercent ?? 5,
      [videoLibraryFieldName.discountEnabled]: coinsEnabled
        ? uploadedVideo?.discountCoins ?? false
        : uploadedVideo?.discountPercent ?? false,
      [videoLibraryFieldName.discountDateRange]: [
        uploadedVideo?.discountStart
          ? new Date(uploadedVideo!.discountStart)
          : undefined,
        uploadedVideo?.discountEnd
          ? new Date(uploadedVideo!.discountEnd)
          : undefined,
      ],
      [videoLibraryFieldName.online]: true,
      [videoLibraryFieldName.publicationScheduledFor]: formattedPlannedDate,
      [videoLibraryFieldName.releasedStatus]: setCorrectReleasedStatusField(
        uploadedVideo?.releasedStatus,
        uploadedVideo?.isPlannedReleaseDateEditable
      ),
      [videoLibraryFieldName.confirmedOwndershipOfRequiredDocuments]:
        uploadedVideo?.allIDCardsUploaded ?? false,
      [videoLibraryFieldName.confirmedPublicationConsend]:
        uploadedVideo?.allModelReleaseFormsUploaded ?? false,
    };
  }, [uploadedVideo, scheduableTimeframe.min, coinsEnabled]);

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      [videoLibraryFieldName.title]: createStringValidationSchema({
        minLength: 1,
        maxLength: 60,
        isOptional: false,
      }),
      [videoLibraryFieldName.description]: createStringValidationSchema({
        maxLength: 2000,
        isOptional: false,
      }),

      [videoLibraryFieldName.publicationScheduledFor]:
        createDateValidationSchema({
          isOptional: false,
          minDate: scheduableTimeframe.min,
          maxDate: scheduableTimeframe.max,
        })
          .when(
            [videoLibraryFieldName.releasedStatus],
            (releasedStatus, schema) =>
              releasedStatus !== VideoReleasedStatusEnum.Planned
                ? Yup.date()
                : schema
          )
          .typeError(
            t(
              'general:field.error.UngultigesDatumBitteNutzeDasKalenderIconAufDerRechtenSeite'
            )
          ),
      [videoLibraryFieldName.confirmedOwndershipOfRequiredDocuments]:
        Yup.boolean()
          .isTrue(() => <MandatoryErrorMessageForCheckBox />)
          .required(),
      [videoLibraryFieldName.confirmedPublicationConsend]: Yup.boolean()
        .isTrue(() => <MandatoryErrorMessageForCheckBox />)
        .required(),
    });
  }, [scheduableTimeframe.max, scheduableTimeframe.min, t]);

  const hookForm = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  React.useEffect(() => {
    hookForm.reset(initialValues, {
      keepDirtyValues: true,
      keepValues: true,
    });
  }, [hookForm, initialValues]);

  const videoJsOptions = {
    sources: [
      {
        src: uploadedVideo?.url,
        type: 'video/mp4',
      },
    ],
    poster: uploadedVideo?.previewPicture18?.image?.src,
  };

  /**
   * Handle Entry Update
   */

  const [updateUserVideoMutation, { loading: updateVideoLoading }] =
    useVideoLibraryUploadListEntryMutation({
      refetchQueries: [
        GetVideoLibraryVideoUploadListEntryDocument,
        GetContestDataDocument,
      ],
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    });
  const { onDelete } = useUploadyService();
  const [getPreviewPictureUrl] =
    useGetVideoLibraryPreviewPictureUploadUrlLazyQuery();

  const onSubmit = React.useCallback(
    async (data: any) => {
      if (!uploadedVideo?.albumId) return;

      const uploadPreviewPictures = async ({
        albumId,
      }: {
        albumId: number;
      }) => {
        let initialResponse: {
          previewPicture16Id: Maybe<number>;
          previewPicture18Id: Maybe<number>;
        } = {
          previewPicture16Id: null,
          previewPicture18Id: null,
        };

        const previewBlob16 = unsubmittedEdits?.thumbnail16Replacement;
        const previewBlob18 = unsubmittedEdits?.thumbnail18Replacement;
        if (!previewBlob16 && !previewBlob18) return initialResponse;
        const uploadUrlResponse = await getPreviewPictureUrl({
          variables: {
            albumId: albumId,
          },
        });
        const uploadUrl =
          uploadUrlResponse?.data?.media?.videos?.pictureUploadUrl;

        if (!uploadUrl) return initialResponse;

        if (previewBlob16?.blob) {
          const { id } = await uploadImage(`${uploadUrl}`, previewBlob16.blob);
          initialResponse.previewPicture16Id = id ?? null;
        }
        if (previewBlob18?.blob) {
          const resp18 = await uploadImage(`${uploadUrl}`, previewBlob18.blob);
          initialResponse.previewPicture18Id = resp18.id ?? null;
        }
        return initialResponse;
      };

      const thumbnail16 = unsubmittedEdits?.thumbnail16Replacement;
      const { previewPicture16Id, previewPicture18Id } =
        await uploadPreviewPictures({
          albumId: uploadedVideo.albumId,
        });

      //If the upload didnt work we wont get an id back
      if (thumbnail16) {
        if (!previewPicture16Id && !thumbnail16.umpId) {
          issueChakraToast({
            description: t(
              'videoEditModal:toast.DasAb16VorschaubildIstEinDuplikatBitteNutzeEinAnderesBild'
            ),
            status: 'error',
          });
          return;
        }
      }
      //If the upload didnt work we wont get an id back
      const thumbnail18 = unsubmittedEdits?.thumbnail18Replacement;
      if (thumbnail18) {
        if (!previewPicture18Id && !thumbnail18.umpId) {
          issueChakraToast({
            description: t(
              'videoEditModal:toast.DasAb18VorschaubildIstEinDuplikatBitteNutzeEinAnderesBild'
            ),
            status: 'error',
          });
          return;
        }
      }

      const langs = [LanguagesEnum.De, LanguagesEnum.En, LanguagesEnum.Es];

      const isVideoFree = uploadedVideo?.type === VideoTypeEnum.VideoHotclip;

      // Sending the same data for all languages since sending empty strings for
      // other languages is not allowed by the mutation
      const titles: InputLangText[] = [
        {
          lang: LanguagesEnum.De,
          text: data[videoLibraryFieldName.title],
        },
        {
          lang: LanguagesEnum.En,
          text: data[videoLibraryFieldName.title],
        },
        {
          lang: LanguagesEnum.Es,
          text: data[videoLibraryFieldName.title],
        },
      ];
      const descriptions: InputLangText[] = langs.map((lang) => {
        return { lang: lang, text: data[videoLibraryFieldName.description] };
      });

      const price = isVideoFree ? null : data.price;
      const discount = !data.discountEnabled ? null : data.discount;

      try {
        const updateData: InputVideo = {
          albumId: uploadedVideo?.albumId ?? 0,
          titles: titles,
          descriptions: descriptions,
          tags: data.tags,
          priceCoins: coinsEnabled ? price : null,
          price: coinsEnabled ? null : price,
          discountCoins: coinsEnabled ? discount : null,
          discount: coinsEnabled ? null : discount,
          discountStart: data.discountEnabled
            ? data.discountDateRange?.[0] ?? null
            : null,
          discountEnd: data.discountEnabled
            ? data.discountDateRange?.[1] ?? null
            : null,
          releasedStatus: data.releasedStatus,
          plannedReleaseDate:
            data.releasedStatus === VideoReleasedStatusEnum.Planned &&
            !!data.publicationScheduledFor
              ? new Date(data.publicationScheduledFor)
              : null,
          previewPictureId16: previewPicture16Id ?? thumbnail16?.umpId,
          previewPictureId18: previewPicture18Id ?? thumbnail18?.umpId,
          allIDCardsUploaded: data.confirmedOwndershipOfRequiredDocuments,
          allModelReleaseFormsUploaded: data.confirmedPublicationConsend,
          contestId: contest?.id,
        };

        if (contest?.id && uploadedVideo?.albumId && contest?.video?.albumId) {
          await onDelete(contest?.video?.albumId, true);
        }

        updateUserVideoMutation({
          variables: {
            data: updateData,
          },
          onCompleted: () => {
            actions.setUnsubmittedEdits({});
            actions.closeModal();
            issueChakraToast({
              description: t('general:toast.AnderungenWurdenGespeichert'),
              status: 'success',
            });
            if (contest?.id) {
              LocalStorage.add(
                LocalStorageKeys.PARTICIPATED_IN_VIDEO_CONTSEST_ID,
                contest?.id
              );
            }
          },
          refetchQueries: [GetVideoLibraryVideoUploadListDocument],
          awaitRefetchQueries: true,
        });
      } catch (e) {
        Logger.error(e);
        issueChakraToast({
          description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
          status: 'error',
        });
      }
    },
    [
      uploadedVideo?.albumId,
      uploadedVideo?.type,
      unsubmittedEdits?.thumbnail16Replacement,
      unsubmittedEdits?.thumbnail18Replacement,
      getPreviewPictureUrl,
      t,
      coinsEnabled,
      contest?.id,
      contest?.video?.albumId,
      updateUserVideoMutation,
      onDelete,
      actions,
    ]
  );

  /**
   * Handle navigation in modal
   */
  const [tabActive, setTabActive] = React.useState(0);

  const tabContent: VideoLibraryEntryModalContent[] = [
    {
      title: t('videoEditModal:navBar.Details'),
      content: (
        <EntryModalTitleDescriptionTab
          video={uploadedVideo}
          contestId={contest?.id}
        />
      ),
    },
    {
      title: t('videoEditModal:navBar.Einstellungen'),
      content: (
        <EntryPriceSettingsTab video={uploadedVideo} contestId={contest?.id} />
      ),
    },
    {
      title: t('videoEditModal:navBar.MRF'),
      content: <EntryModalSettingsConfirmTab />,
    },
  ];

  // Todo: Find a way to make this work without using hookForm.watch()
  React.useEffect(() => {
    const subscription = hookForm.watch((data) => {
      const albumId = uploadedVideo?.albumId;
      if (albumId) {
        actions.setUnsubmittedEdits((prev) => ({
          ...prev,
          input: { ...(data as InputVideo), umaId: albumId },
        }));
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [unsubmittedEdits, hookForm, uploadedVideo, actions]);

  const lastStep = tabActive === tabContent.length - 1;

  const onChangeTab = (newTab: number) => {
    const values = hookForm.getValues();
    const videoLibraryFieldNameListByTab =
      videoLibraryFieldNameByTab[tabActive as 0 | 1 | 2];
    videoLibraryFieldNameListByTab.forEach((name) => {
      hookForm.setValue(name, values[name], {
        shouldTouch: true,
      });
    });

    hookForm.trigger(videoLibraryFieldNameListByTab).then((isValid) => {
      if (isValid || newTab < tabActive) {
        setTabActive(newTab);
      }
    });
  };

  return (
    <>
      <VideoLibraryEntryModalNavbar
        tabActive={tabActive}
        setTabActive={onChangeTab}
        tabContent={tabContent}
      />
      {(!lastStep || !uploadedVideo) && (
        <>
          {uploadedVideo && uploadedVideo.url && (
            <AspectRatio ratio={16 / 9}>
              <VStack>
                <VideoPlayer options={videoJsOptions} formattedPrice={''} />
              </VStack>
            </AspectRatio>
          )}
          {uploadedVideo && !uploadedVideo.url && (
            <AspectRatio ratio={16 / 9}>
              <VStack
                backgroundColor={'steel'}
                color={'caribbeanGreen.500'}
                p={5}
              >
                <GradientSpinner
                  desiredSizeInPixel={50}
                  strokeWidthInPixel={5}
                />
                <Text textAlign={'center'}>
                  {t('videoEditModal:text.VideoWirdVerarbeitet')}
                </Text>
              </VStack>
            </AspectRatio>
          )}
          {/* Video currently uploading (currently used in Contest video upload) */}
          {!uploadedVideo && uploadingVideo && (
            <AspectRatio ratio={16 / 9}>
              <VStack backgroundColor={'steel'} color={'primary.500'}>
                <GradientSpinner
                  desiredSizeInPixel={50}
                  strokeWidthInPixel={5}
                />
                <Text>{Math.round(uploadingVideo.progress) + '%'}</Text>
              </VStack>
            </AspectRatio>
          )}
          <Text
            alignSelf={'end'}
            color={'darkSteel'}
            fontWeight={'normal'}
            fontSize={'sm'}
            pt={1}
            px={4}
          >
            {uploadedVideo?.albumId &&
              'uploadedVideo-ID: ' + uploadedVideo.albumId}
            {!uploadedVideo?.albumId &&
              uploadingVideo &&
              'Request-ID: ' + uploadingVideo!.uploadRequestId}
          </Text>
        </>
      )}
      <ResponsiveModalBodyBox bg={'transparent'} pt={!lastStep ? 6 : 0}>
        <FormProvider {...hookForm}>
          <form>
            <PreventImplicitFormSubmitOnEnter />
            <VStack gap={6}>
              {tabContent[tabActive].content}
              {!lastStep ? (
                <Button
                  mt={2}
                  variant={'solid'}
                  type={'button'}
                  isLoading={
                    hookForm.formState.isSubmitting || updateVideoLoading
                  }
                  onClick={() => {
                    const newActiveTab = tabActive + 1;
                    onChangeTab(newActiveTab);
                  }}
                >
                  {t('videoEditModal:button.Weiter')}
                </Button>
              ) : (
                <Button
                  mt={2}
                  variant={'solid'}
                  type={'button'}
                  isLoading={
                    hookForm.formState.isSubmitting || updateVideoLoading
                  }
                  isDisabled={!uploadedVideo}
                  onClick={(e) => {
                    hookForm.handleSubmit(onSubmit)(e);
                    onContestModalClose?.();
                  }}
                >
                  {!!uploadingVideo
                    ? t('general:button.save')
                    : t('videoEditModal:button.Aktualisieren')}
                </Button>
              )}
            </VStack>
          </form>
        </FormProvider>
      </ResponsiveModalBodyBox>
    </>
  );
};
