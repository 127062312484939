function isValidDate(dateString: any) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

// Format Date in German format (DD.MM.YYYY)
const formatDEDate = (date?: any) => {
  if (!isValidDate(date)) return date;
  const actualDate = new Date(date);
  return new Intl.DateTimeFormat('de', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(actualDate);
};

// Format Date in German format (DD.MM.YY)
const formatDEDateShort = (date?: any) => {
  if (!isValidDate(date)) return date;
  const actualDate = new Date(date);
  return new Intl.DateTimeFormat('de', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  }).format(actualDate);
};

// Format UTC Date in German format (DD.MM.YYYY)
const formatDEDateUsingUTC = (date?: any) => {
  if (!isValidDate(date)) return date;
  const actualDate = new Date(date);
  return new Intl.DateTimeFormat('de', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  }).format(actualDate);
};

// Format Date in German format (DD)
const formatDEDateDay = (date?: any) => {
  if (!isValidDate(date)) return date;
  const actualDate = new Date(date);
  return new Intl.DateTimeFormat('de', {
    day: '2-digit',
  }).format(actualDate);
};

// Format Date and Time in German format (DD.MM.YYYY HH:MM)
const formatDEDateTimeShort = (date?: any) => {
  if (!isValidDate(date)) return date;
  const actualDateTime = new Date(date);
  return new Intl.DateTimeFormat('de', {
    dateStyle: 'short',
    timeStyle: 'short',
  }).format(actualDateTime);
};

// Format time in German format (HH:MM)
export const formatDETimeShort = (date?: any) => {
  if (!isValidDate(date)) return date;
  const actualTime = new Date(date);
  return new Intl.DateTimeFormat('de', {
    timeStyle: 'short',
  }).format(actualTime);
};

// Format percentage values in German format (e.g. 50%)
const formatPercent = (value?: any) => {
  return new Intl.NumberFormat('de', { style: 'percent' }).format(value);
};

// Format currency values in German format (e.g. 1.000,00 €)
const formatCurrency = (value?: any) => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumSignificantDigits: 1,
  }).format(value);
};

const getDEMonth = (month: string | number) => {
  switch (month) {
    case 'January':
    case 0:
      return 'Januar';
    case 'February':
    case 1:
      return 'Februar';
    case 'March':
    case 2:
      return 'März';
    case 'April':
    case 3:
      return 'April';
    case 'May':
    case 4:
      return 'Mai';
    case 'June':
    case 5:
      return 'Juni';
    case 'July':
    case 6:
      return 'Juli';
    case 'August':
    case 7:
      return 'August';
    case 'September':
    case 8:
      return 'September';
    case 'October':
    case 9:
      return 'Oktober';
    case 'November':
    case 10:
      return 'November';
    case 'December':
    case 11:
      return 'Dezember';
    default:
      return month;
  }
};

// Custom Translation Formatting for i18n
export const customTranslationFormatting = (value: any, format?: string) => {
  if (format === 'DEDate') {
    return formatDEDate(value);
  }
  if (format === 'DEDateShort') {
    return formatDEDateShort(value);
  }
  if (format === 'DEDateUsingUTC') {
    return formatDEDateUsingUTC(value);
  }
  if (format === 'DEDateDay') {
    return formatDEDateDay(value);
  }
  if (format === 'DEDateTimeShort') {
    return formatDEDateTimeShort(value);
  }
  if (format === 'DETimeShort') {
    return formatDETimeShort(value);
  }
  if (format === 'DEMonth') {
    return getDEMonth(value);
  }
  if (format === 'percent') {
    return formatPercent(value);
  }
  if (format === 'currency') {
    return formatCurrency(value);
  }
  return value;
};
