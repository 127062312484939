import { SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import { PrivacyTip } from '@campoint/odi-ui-icons';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Container,
  VStack,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { FluentPageLayout } from '../../components/Layout/FluentPageLayout';
import { LayoutHintsVStack } from '../../components/Layout/LayoutHintsVStack';
import ScrollToLastKnownPositionOnMount from '../../components/Layout/ScrollToLastKnownPositionOnMount';
import { UpdateTaxInformationExpiredHintSlot } from '../../components/shared/UpdateTaxInformationExpiredHintSlot/UpdateTaxInformationExpiredHintSlot';
import { EmailSection } from './section/EmailSection/EmailSection';
import { LanguageSection } from './section/LanguageSection/LanguageSection';
import { MobileNumberSection } from './section/MobileNumberSection/MobileNumberSection';
import { PasswordSection } from './section/PasswordSection/PasswordSection';
import { PersonalSectionHookForm } from './section/PersonalSection/PersonalSectionHookForm';
import { SocialLinksSection } from './section/SocialLinksSection/SocialLinksSection';

const AccountPage: React.FC = () => {
  const { t } = useTranslation(['general', 'accountPage']);

  const spacing = { base: 4, lg: 8 };

  return (
    <FluentPageLayout>
      <SubHeader>
        <Container maxW="container.xl" p={0}>
          <SubHeaderTitle>{t('accountPage:headerText')}</SubHeaderTitle>
        </Container>
      </SubHeader>
      <ScrollToLastKnownPositionOnMount identifier={'/account'} />
      <Container maxW="container.xl" p={0}>
        <VStack w={'full'} spacing={spacing} mb={spacing}>
          <LayoutHintsVStack>
            <UpdateTaxInformationExpiredHintSlot
              outerStackProps={{ pt: spacing }}
            />
            <Box px={spacing} pt={spacing} w={'full'}>
              <Alert
                maxW={'container.xl'}
                bg={'steel'}
                borderColor={'caribbeanGreen.500'}
                color={'caribbeanGreen.500'}
                mx={'auto'}
              >
                <AlertIcon
                  as={PrivacyTip}
                  color={'caribbeanGreen.500'}
                  alignSelf={'start'}
                />
                <AlertTitle color={'caribbeanGreen.500'} fontWeight={'medium'}>
                  {t('privacy.message')}
                </AlertTitle>
              </Alert>
            </Box>
          </LayoutHintsVStack>

          <PersonalSectionHookForm />
          <EmailSection />
          <PasswordSection />
          <MobileNumberSection />
          <SocialLinksSection />
          <LanguageSection />
        </VStack>
      </Container>
    </FluentPageLayout>
  );
};

export default AccountPage;
