import { ExpandLess, ExpandMore, Info } from '@campoint/odi-ui-icons';
import {
  HStack,
  Icon,
  Img,
  ListItem,
  Text,
  UnorderedList,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import DocumentsNeeded from '../../../../../assets/images/videoLibrary/documentsNeeded.svg';
import { CheckboxSingleControl } from '../../../../../components/shared/HookFormForms/CheckboxSingleControl/CheckboxSingleControl';
import { useVideoLibraryEntryModalContext } from '../../../../../provider/VideoLibraryProvider/VideoLibraryEntryModalProvider/VideoLibraryEntryModalProvider';
import { videoLibraryFieldName } from '../EntryModalContentBox';

export const EntryModalSettingsConfirmTab: React.FC = () => {
  const { t } = useTranslation(['videoEditModal']);
  const documentsNeededDisc = useDisclosure();
  // get video from the context
  const { video } = useVideoLibraryEntryModalContext();

  return (
    <VStack alignItems={'stretch'} w={'full'}>
      <VStack
        border={'1px'}
        borderColor={'steel'}
        borderRadius={'8px'}
        p={0}
        mb={4}
      >
        <HStack
          w={'full'}
          borderBottom={documentsNeededDisc.isOpen ? '1px' : 'unset'}
          borderColor={'steel'}
          p={1}
          px={2}
          onClick={documentsNeededDisc.onToggle}
        >
          <HStack flex={1}>
            <Icon as={Info} />
            <Text fontWeight={'bold'}>
              {t('videoEditModal:inputs.text.BenotigteDokumente')}
            </Text>
          </HStack>
          {documentsNeededDisc.isOpen ? (
            <Icon as={ExpandLess} boxSize={'icon.md'} />
          ) : (
            <Icon as={ExpandMore} boxSize={'icon.md'} />
          )}
        </HStack>
        {documentsNeededDisc.isOpen && (
          <VStack px={4} pb={2}>
            <HStack gap={4}>
              <Img
                src={DocumentsNeeded}
                alt={t('videoEditModal:inputs.text.BenotigteDokumente')}
                minW={'65px'}
                minH={'75px'}
              />
              <UnorderedList
                fontSize={'xs'}
                fontWeight={'normal'}
                lineHeight={'20px'}
              >
                <ListItem>{t('videoEditModal:inputs.text.Ausweis')}</ListItem>
                <ListItem>{t('videoEditModal:inputs.text.IDShot')}</ListItem>
                <ListItem>
                  {t('videoEditModal:inputs.text.ModelReleaseFormMRF')}
                </ListItem>
              </UnorderedList>
            </HStack>
            <Text
              textAlign={'center'}
              pt={2}
              fontSize={'xs'}
              fontWeight={'normal'}
            >
              {t(
                'videoEditModal:inputs.text.JeweilsVomAccountInhaberModelHochzuladenUberDeinAccountmenu'
              )}
            </Text>
          </VStack>
        )}
      </VStack>
      <CheckboxSingleControl
        checkBoxProps={{ isDisabled: video?.allModelReleaseFormsUploaded }}
        name={videoLibraryFieldName.confirmedOwndershipOfRequiredDocuments}
        label={t(
          'videoEditModal:inputs.text.JaAlleAbgebildetenPersonenSindVolljaehrigUndIhreDokumenteSowieDasMRFLiegenVor'
        )}
      />
      <CheckboxSingleControl
        checkBoxProps={{ isDisabled: video?.allIDCardsUploaded }}
        name={videoLibraryFieldName.confirmedPublicationConsend}
        label={t(
          'videoEditModal:inputs.text.IchGarantiereDassAlleAbgebildetenPersonenMitIhrerDarstellungImContentSeinerVeroeffentlichungImInternetUndDerMoeglichkeitZumDownloadEinverstandenSindEineEntsprechendeVereinbarungLiegtVor'
        )}
      />
      <Text
        textStyle={'caption'}
        fontSize={'xs'}
        color={'darkSteel'}
        fontWeight={'medium'}
      >
        {t(
          'videoEditModal:inputs.text.PflichtangabeUmGesetzlichenAnforderungenZuEntsprechen'
        )}
      </Text>
    </VStack>
  );
};
