import { Movie } from '@campoint/odi-ui-icons';
import {
  Box,
  Divider,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { ReactNode, createContext } from 'react';
import { useTranslation } from 'react-i18next';

import avatarPlaceholder from '../../../../../assets/images/feed/AvatarPlaceholder.svg';
import { AbsoluteFullCenterLoadingSpinner } from '../../../../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../../../../components/Layout/ResponsiveModal';
import {
  StatisticsDetailedCategoryEnum,
  UserListPurchaseDetailsFragment,
  UserListPurchasesFragment,
  useGetUserListPurchasesDetailsLazyQuery,
} from '../../../../../generated/graphql';
import { getFormattedAmount } from '../../../../../utils/formattedAmount';
import {
  StatisticsDetailedCategoryExtEnum,
  useTabsContext,
} from '../../../provider/TabContext';

type UserListDetailsModalProps = {
  isInfoModalOpen: boolean;
  onInfoModalOpen: (purchase: Maybe<UserListPurchasesFragment>) => void;
  onInfoModalClose: () => void;
  purchaseDetails: Maybe<UserListPurchaseDetailsFragment>;
  loading?: boolean;
};

const UserListDetailsModalContext = createContext<
  UserListDetailsModalProps | undefined
>(undefined);

const UserListDetailsModalProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { tabIds, tabIndex } = useTabsContext();
  const tabId = tabIds[tabIndex];
  const [purchase, setPurchase] =
    React.useState<Maybe<UserListPurchasesFragment>>(null);

  const {
    isOpen: isInfoModalOpen,
    onOpen,
    onClose: onInfoModalClose,
  } = useDisclosure();

  const onInfoModalOpen = (purchase: Maybe<UserListPurchasesFragment>) => {
    setPurchase(purchase);
    onOpen();
  };

  const [getUserListItemDetails, { data, loading }] =
    useGetUserListPurchasesDetailsLazyQuery();

  React.useEffect(() => {
    if (isInfoModalOpen && purchase) {
      const filteredTabId = (tabId as StatisticsDetailedCategoryEnum) ?? null;

      if (!filteredTabId) {
        return;
      }
      getUserListItemDetails({
        variables: {
          transactionId: purchase.transactionId,
          guestId: purchase.guestId,
          statisticsCategory: filteredTabId,
        },
      });
    }
  }, [getUserListItemDetails, isInfoModalOpen, purchase, tabId]);

  const purchaseDetails = React.useMemo(() => {
    return data?.finances.userListOfPurchaseDetail;
  }, [data]);

  return (
    <UserListDetailsModalContext.Provider
      value={{
        isInfoModalOpen,
        onInfoModalOpen,
        onInfoModalClose,
        purchaseDetails,
      }}
    >
      {children}
      <UserListDetailsModal
        isInfoModalOpen={isInfoModalOpen}
        onInfoModalOpen={onInfoModalOpen}
        onInfoModalClose={onInfoModalClose}
        purchaseDetails={purchaseDetails}
        loading={loading}
      />
    </UserListDetailsModalContext.Provider>
  );
};

const useUserListDetailsModal = () => {
  const context = React.useContext(UserListDetailsModalContext);
  if (context === undefined) {
    throw new Error(
      'useUserListDetailsModal must be used within a UserListDetailsModalProvider'
    );
  }
  return context;
};

export { useUserListDetailsModal, UserListDetailsModalProvider };

const UserListDetailsModal: React.FC<UserListDetailsModalProps> = ({
  isInfoModalOpen,
  onInfoModalClose,
  purchaseDetails,
  loading,
}) => {
  const { t } = useTranslation(['statisticsPage']);
  const { tabIds, tabIndex, tabIdToName } = useTabsContext();
  const tabId = tabIds[tabIndex];
  const tabName = tabIdToName(tabId);
  const formatter = new Intl.DateTimeFormat('de', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
  const formattedDate = purchaseDetails?.date
    ? formatter.format(new Date(purchaseDetails.date))
    : '';
  const noSrc =
    !purchaseDetails?.avatarUrl || purchaseDetails?.avatarUrl === '';

  const showDuration =
    tabId.includes(StatisticsDetailedCategoryExtEnum.LiveChat) ||
    tabId.includes(StatisticsDetailedCategoryExtEnum.PhoneService);

  const showVideoTitle = tabId.includes(
    StatisticsDetailedCategoryExtEnum.VideoLibrary
  );

  return (
    <ResponsiveModal
      isOpen={isInfoModalOpen}
      onClose={onInfoModalClose}
      preferredSize="xl"
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalStickyHeaderBox>
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        <ResponsiveModalBodyBox bg={'surface'} p={4} minH={'500px'}>
          {loading ? (
            <AbsoluteFullCenterLoadingSpinner />
          ) : (
            <VStack p={4} gap={6}>
              <Text fontWeight={700} fontSize={'18px'} lineHeight={'20px'}>
                {t('header.Verdienstdetails')}
              </Text>
              <Box
                border={'1px'}
                borderColor={'steel'}
                borderRadius={'8px'}
                w={'full'}
              >
                <VStack p={4} gap={4} w={'full'} alignItems={'flex-start'}>
                  <Text
                    textColor={'darkSteel'}
                    fontSize={'sm'}
                    lineHeight={'12px'}
                    textAlign={'center'}
                    w={'full'}
                  >
                    {formattedDate}
                  </Text>
                  <HStack gap={2}>
                    <Image
                      src={
                        noSrc ? avatarPlaceholder : purchaseDetails?.avatarUrl
                      }
                      alt={''}
                      htmlWidth={'30px'}
                      htmlHeight={'30px'}
                      borderRadius={'full'}
                      bgColor={'steel'}
                      boxSize={'30px'}
                      userSelect={'none'}
                      draggable={false}
                    />
                    <Text
                      fontWeight={'medium'}
                      fontSize={'md'}
                      lineHeight={'20px'}
                    >
                      {purchaseDetails?.guestName}
                    </Text>
                    {purchaseDetails?.advertisedGuest && (
                      <Text
                        borderRadius={'8px'}
                        backgroundColor={'caribbeanGreen.300'}
                        px={'6px'}
                        fontSize={'sm'}
                        fontWeight={'semibold'}
                        lineHeight={'20px'}
                      >
                        {t('text.GeworbenerUser')}
                      </Text>
                    )}
                  </HStack>
                  <VStack w={'full'} alignItems={'flex-start'}>
                    <HStack w={'full'}>
                      <Text flex={1} fontSize={'14px'} lineHeight={'20px'}>
                        {tabName}
                      </Text>
                      {showDuration && purchaseDetails?.duration && (
                        <UserListDetailsDuration
                          duration={purchaseDetails?.duration}
                        />
                      )}
                      {!showDuration && purchaseDetails?.count && (
                        <Text fontSize={'14px'} lineHeight={'20px'}>
                          {purchaseDetails?.count}
                        </Text>
                      )}
                    </HStack>
                    {showVideoTitle && (
                      <HStack>
                        <Icon as={Movie} color={'gray.300'} />
                        <Text
                          color={'gray.300'}
                          fontSize={'14px'}
                          lineHeight={'20px'}
                        >
                          {purchaseDetails?.contentTitle}
                        </Text>
                      </HStack>
                    )}
                  </VStack>
                </VStack>
              </Box>
              <VStack
                w={'full'}
                alignItems={'flex-start'}
                gap={3}
                divider={<Divider color={'darkSteel'} />}
              >
                {/* Verdienst */}
                <HStack w={'full'}>
                  <Text
                    flex={1}
                    fontWeight={'normal'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                  >
                    {t('text.Verdienst')}
                  </Text>
                  <Text
                    flex={1}
                    fontWeight={'normal'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                  >
                    {purchaseDetails?.incomeCoins} VXC
                  </Text>
                  <Text
                    fontWeight={'normal'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                  >
                    {getFormattedAmount(purchaseDetails?.income ?? 0)}
                  </Text>
                </HStack>
                {/* Bonus-VXCoins* */}
                <HStack w={'full'}>
                  <Text
                    flex={1}
                    fontWeight={'normal'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                  >
                    {t('text.BonusVXCoins')}
                  </Text>
                  <Text
                    flex={1}
                    fontWeight={'normal'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                  >
                    {purchaseDetails?.bonusIncomeCoins} VXC
                  </Text>
                  <Text
                    fontWeight={'normal'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                  >
                    {getFormattedAmount(purchaseDetails?.bonusIncome ?? 0)}
                  </Text>
                </HStack>
                {/* Geworbene User (VXCash)** */}
                <HStack w={'full'}>
                  <Text
                    flex={1}
                    fontWeight={'normal'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                  >
                    {t('text.GeworbeneUserVXCash')}
                  </Text>
                  <Text
                    fontWeight={'normal'}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                  >
                    {getFormattedAmount(
                      purchaseDetails?.incomeAdvertisedGuest ?? 0
                    )}
                  </Text>
                </HStack>
              </VStack>
              <Divider color={'darkSteel'} />
              <Text
                fontWeight={400}
                lineHeight={'15px'}
                fontSize={'12px'}
                textAlign={'center'}
                color={'gray.500'}
              >
                {t(
                  'text.BonusguthabenZahltInDeinenStufenfortschrittEinWirdAberNichtVergutet'
                )}
              </Text>
              <Text
                fontWeight={400}
                lineHeight={'15px'}
                fontSize={'12px'}
                textAlign={'center'}
                color={'gray.500'}
              >
                *{t('text.AuszahlungErfolgtUberVXCash')}
              </Text>
            </VStack>
          )}
        </ResponsiveModalBodyBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};

const UserListDetailsDuration: React.FC<{ duration: number }> = ({
  duration,
}) => {
  const minutes = Math.floor((duration ?? 0) / 60)
    .toString()
    .padStart(2, '0');
  const seconds = ((duration ?? 0) % 60).toString().padStart(2, '0');
  const formattedMinutesSeconds = `${minutes}m ${seconds}s`;
  return (
    <Text fontSize={'14px'} lineHeight={'20px'}>
      {formattedMinutesSeconds}
    </Text>
  );
};
