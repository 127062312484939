import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useFlagsProviderV2 } from '../../../../provider/FlagsProviderV2';
import { useFormattedAmount } from '../../../../utils/formattedAmount';

type PriceSliderProps = {
  name: string;
  steps: number[];
  isDisabled?: boolean;
};

export const PriceSliderControlParent: React.FC<PriceSliderProps> = ({
  name,
  steps,
  isDisabled,
}) => {
  const { coinsEnabled } = useFlagsProviderV2();
  return coinsEnabled ? (
    <PriceSliderControlCoin name={name} steps={steps} isDisabled={isDisabled} />
  ) : (
    <PriceSliderControl name={name} steps={steps} isDisabled={isDisabled} />
  );
};

export const PriceSliderControl = ({
  name,
  steps,
  isDisabled,
}: PriceSliderProps) => {
  const { setValue } = useFormContext();

  const value: number = useWatch({ name });

  const possiblePrices = steps;
  const formattedEuroAmount = useFormattedAmount(value);
  const minFormatted = useFormattedAmount(possiblePrices[0]);
  const maxFormatted = useFormattedAmount(
    possiblePrices[possiblePrices.length - 1]
  );
  const thumbSize = 30;
  const min = 0;
  const max = possiblePrices.length - 1;
  const curIndex = Math.max(
    Math.min(possiblePrices.indexOf(value), possiblePrices.length - 1),
    0
  );

  function handleChange(value: number) {
    setValue(
      name,
      possiblePrices[Math.max(Math.min(value, possiblePrices.length - 1), 0)],
      { shouldTouch: true }
    );
  }

  const thumbMargin = (): string => {
    const range = (curIndex - min) / (max - min);
    const thumbRadius = `${thumbSize / 2}px`;
    return `calc(${
      range * 100
    }% - ${thumbRadius} - ${thumbRadius} * ${range} + ${thumbRadius} * ${
      1 - range
    })`;
  };

  return (
    <Box pb={'6'} pt={'12'}>
      <Slider
        name={name}
        value={curIndex}
        isDisabled={isDisabled}
        min={min}
        max={max}
        focusThumbOnChange={false}
        onChange={handleChange}
      >
        <Box mx={'8'} position={'relative'}>
          <SliderMark
            value={curIndex}
            w={'16'}
            mt={'-10'}
            ml={'-8'}
            borderRadius={'sm'}
            fontSize={'sm'}
            textAlign={'center'}
            color={'whiteAlpha.900'}
            bg={isDisabled ? 'gray.500' : 'primary.500'}
          >
            {formattedEuroAmount}
          </SliderMark>
        </Box>
        <SliderMark value={min} mt={'4'} fontSize={'sm'}>
          {minFormatted}
        </SliderMark>
        <SliderMark value={max} mt={'4'} ml={'-14'} fontSize={'sm'}>
          {maxFormatted}
        </SliderMark>
        <SliderTrack
          height={'2'}
          borderRadius={'10px'}
          bg={'coldGray.100'}
          _disabled={{ bg: 'coldGray.100' }}
        >
          <SliderFilledTrack bg={isDisabled ? 'gray.500' : 'primary.500'} />
        </SliderTrack>
        <SliderThumb
          boxSize={`${thumbSize}px`}
          border={'4px'}
          borderColor={isDisabled ? 'gray.500' : 'primary.500'}
          _active={{ transform: 'translateY(-50%)' }}
          left={`${thumbMargin()}!important`}
        />
      </Slider>
    </Box>
  );
};
export const PriceSliderControlCoin = ({
  name,
  steps,
  isDisabled,
}: PriceSliderProps) => {
  const { setValue } = useFormContext();

  const value: number = useWatch({ name });

  const possiblePrices = steps;
  const formattedAmount = `${value} VXC`;
  const min = 0;
  const max =
    possiblePrices.length - 1 < min ? min + 1 : possiblePrices.length - 1;
  const curIndex = Math.max(
    Math.min(possiblePrices.indexOf(value), possiblePrices.length - 1),
    0
  );

  function handleChange(value: number) {
    //workaround so we disable the discount slider if the price is changed again
    if (name === 'price') {
      setValue('discountEnabled', false);
      setValue('discount', 1);
    }
    setValue(
      name,
      possiblePrices[Math.max(Math.min(value, possiblePrices.length - 1), 0)],
      { shouldTouch: true }
    );
  }

  const thumbSize = 60;
  const thumbMargin = (): string => {
    const range = (curIndex - min) / (max - min);
    const thumbRadius = `${thumbSize / 2}px`;
    return `calc(${
      range * 100
    }% - ${thumbRadius} - ${thumbRadius} * ${range} + ${thumbRadius} * ${
      1 - range
    })`;
  };

  return (
    <Box pb={3} pt={8}>
      <Slider
        name={name}
        value={curIndex}
        isDisabled={isDisabled}
        min={min}
        max={max}
        focusThumbOnChange={false}
        onChange={handleChange}
      >
        <SliderTrack
          height={'2'}
          borderRadius={'10px'}
          bg={'coldGray.100'}
          _disabled={{ bg: 'coldGray.100' }}
        >
          <SliderFilledTrack
            bg={isDisabled ? 'gray.500' : undefined}
            bgGradient={
              isDisabled ? undefined : 'linear(to-r, primary.to, primary.from)'
            }
          />
        </SliderTrack>
        <SliderThumb
          borderRadius={100}
          bgGradient={'linear(to-r, primary.from, primary.to)'}
          w={thumbSize + 'px'}
          minH={'26px'}
          whiteSpace="nowrap"
          overflow="visible"
          left={`${thumbMargin()}!important`}
        >
          <Text
            px={'6px'}
            fontSize={'sm'}
            color={'surface'}
            fontWeight={'medium'}
            whiteSpace="nowrap"
          >
            {formattedAmount}
          </Text>
        </SliderThumb>
      </Slider>
    </Box>
  );
};

//beachte dass es für coins und euro unterschiedliche slider gibt
//bei dem einen werden prozente angezeigt und bei dem anderen nicht

type DiscountSliderControlType = {
  name: string;
  min: number;
  max: number;
  step: number;
  isDisabled?: boolean;
};

export const DiscountSliderControl = ({
  name,
  min,
  max,
  step,
  isDisabled,
}: DiscountSliderControlType) => {
  const { setValue } = useFormContext();

  const value: number = useWatch({ name });

  const formattedAmount = `${(value ?? min ?? 0).toFixed(0)} %`;
  const minSliderMark = `${(min ?? 0).toFixed(0)} %`;
  const maxSliderMark = `${(max ?? 0).toFixed(0)} %`;
  const thumbSize = 30;

  const thumbMargin = (): string => {
    const range = (value - min) / (max - min);
    const thumbRadius = `${thumbSize / 2}px`;
    return `calc(${
      range * 100
    }% - ${thumbRadius} - ${thumbRadius} * ${range} + ${thumbRadius} * ${
      1 - range
    })`;
  };

  return (
    <Box pb={'6'} pt={'12'}>
      <Slider
        isDisabled={isDisabled}
        defaultValue={value}
        min={min as any}
        max={max as any}
        step={step}
        focusThumbOnChange={false}
        onChange={(newValue) => {
          setValue(name, newValue, { shouldTouch: true });
        }}
      >
        <Box mx={'8'} position={'relative'}>
          <SliderMark
            value={value}
            w={'16'}
            mt={'-10'}
            ml={'-8'}
            borderRadius={'4px'}
            fontSize={'sm'}
            textAlign={'center'}
            color={'whiteAlpha.900'}
            bg={isDisabled ? 'gray.500' : 'primary.500'}
          >
            {formattedAmount}
          </SliderMark>
        </Box>
        <SliderMark value={min} mt={'4'} fontSize={'sm'}>
          {minSliderMark}
        </SliderMark>
        <SliderMark value={max} mt={'4'} ml={'-12'} fontSize={'sm'}>
          {maxSliderMark}
        </SliderMark>
        <SliderTrack
          height={'2'}
          borderRadius={'10px'}
          bg={'coldGray.100'}
          _disabled={{ bg: 'coldGray.100' }}
        >
          <SliderFilledTrack bg={isDisabled ? 'gray.500' : 'primary.500'} />
        </SliderTrack>
        <SliderThumb
          boxSize={`${thumbSize}px`}
          border={'4px'}
          borderColor={isDisabled ? 'gray.500' : 'primary.500'}
          _active={{ transform: 'translateY(-50%)' }}
          left={`${thumbMargin()}!important`}
        />
      </Slider>
    </Box>
  );
};
