import {
  Button,
  ButtonProps,
  Heading,
  Icon,
  Image,
  UseDisclosureProps,
  VStack,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyFooterBox,
  ResponsiveModalStickyHeaderBox,
} from '../../components/Layout/ResponsiveModal';
import Markdown from '../../components/shared/Markdown/Markdown';
import { getGtagEventParamsFromNotification } from '../../components/shared/NewsCenterPopper/NewsCenterPopper';
import { getIconAndColor } from '../../components/shared/NewsCenterPopper/utils';
import { NotificationFragment } from '../../generated/notifications';
import { useNewsCenterActionHandler } from '../../hooks/useNewsCenterActionHandler';
import { gtag } from '../../utils/gtag';
import { useMarkRead } from './hooks';

type NewsCenterAnnouncementModalProps = {
  selectedNotification: NotificationFragment | null;
} & UseDisclosureProps;

export const NewsCenterAnnouncementModal: React.FC<
  NewsCenterAnnouncementModalProps
> = ({ isOpen, onClose, selectedNotification }) => {
  const { t } = useTranslation('newsCenter');

  const markRead = useMarkRead(selectedNotification);
  const { iconType, color } = getIconAndColor(selectedNotification?.icon?.src);

  return (
    <ResponsiveModal
      isOpen={isOpen ?? false}
      onClose={() => {
        onClose?.();
      }}
      preferredSize="xl"
      isVCentered={false}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent maxW={'567px'} bgColor={'surface'}>
        <ResponsiveModalStickyHeaderBox bg={'surface'}>
          <Heading
            as="h1"
            children={selectedNotification?.shortInfo}
            size={'sm'}
          />
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        <ResponsiveModalBodyBox bg={'transparent'}>
          {selectedNotification?.picture && (
            <Image
              w={'full'}
              h={'140px'}
              flexShrink={0}
              alignSelf={'start'}
              fit={'cover'}
              src={selectedNotification?.picture?.src}
              srcSet={selectedNotification?.picture?.srcset}
              sizes={'500px'}
              alt={selectedNotification?.picture?.alt ?? ''}
            />
          )}
          <VStack p={5} textAlign={'start'} align={'start'} flexGrow={1}>
            <Heading size={'lg'}>
              {iconType && color && (
                <Icon
                  as={iconType}
                  color={color}
                  boxSize={5}
                  mr={'6px'}
                  mb={'4px'} // align icon with text
                />
              )}
              <span>{selectedNotification?.title}</span>
            </Heading>
            {selectedNotification?.subtitle && (
              <Markdown
                markdown={selectedNotification?.subtitle}
                containerProps={{
                  css: {
                    flexGrow: 1,
                  },
                }}
              />
            )}
          </VStack>
        </ResponsiveModalBodyBox>
        <ResponsiveModalStickyFooterBox>
          <ActionButton
            variant={'primary'}
            notification={selectedNotification}
            markRead={markRead}
            onClose={onClose}
            ml={1}
            mr={1}
          />
          <ActionButton
            variant={'secondary'}
            notification={selectedNotification}
            markRead={markRead}
            onClose={onClose}
            ml={1}
            mr={1}
          />
          <Button
            variant={'solid'}
            onClick={() => {
              markRead();
              onClose?.();
            }}
            ml={1}
            mr={1}
          >
            {t('button.AlsGelesenMarkieren')}
          </Button>
        </ResponsiveModalStickyFooterBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};

const ActionButton: React.FC<
  ButtonProps & {
    variant: 'primary' | 'secondary';
    notification: Maybe<NotificationFragment>;
    markRead: () => void;
    onClose?: () => void;
  }
> = ({ notification, variant, markRead, onClose, ...buttonProps }) => {
  const buttonName = `${variant}Button` as 'primaryButton' | 'secondaryButton';
  const button = notification ? notification[buttonName] : undefined;
  const actionOnClick = useNewsCenterActionHandler({
    actionUri: button?.action,
  });

  return notification && button ? ( // normally `button` should be defined if `notification` is defined, but otherwise typescript would complain at getGtagEventParamsFromNotification
    <Button
      isLoading={!actionOnClick}
      onClick={() => {
        gtag(
          'event',
          `Click_Notification_${
            variant.charAt(0).toUpperCase() + variant.slice(1)
          }`,
          getGtagEventParamsFromNotification(notification)
        );
        markRead();
        actionOnClick?.();
        onClose?.();
      }}
      {...buttonProps}
    >
      {button.label}
    </Button>
  ) : null;
};
