import { BoxProps, VisuallyHidden, forwardRef } from '@chakra-ui/react';
import * as React from 'react';

/**
 * Lists ids of `scrollToElement` targets that can be used as `#fragment` in a URL
 * @see UrlFragmentScrollToTarget
 * @see ScrollToLastKnownPositionOnMount
 */
export enum UrlFragment {
  /**
   * {@link TaskSection}
   */
  Tasks = 'tasks',
  /**
   * {@link TipSection}
   */
  Tips = 'tips',

  /**
   * {@link PersonalSectionHookForm}
   */
  PersonalSection = 'personalSection',
  /**
   * {@link EmailSection}
   */
  EmailSection = 'emailSection',
  /**
   * {@link MobileNumberSection}
   */
  MobileNumberSection = 'mobileNumberSection',
  /**
   * {@link SocialLinksSection}
   */
  SocialLinksSection = 'socialLinksSection',
  /**
   * {@link PasswordSection}
   */
  PasswordSection = 'passwordSection',
  /**
   * {@link LanguageSection}
   */
  LanguageSection = 'languageSection',

  /**
   * {@link PayoutDocumentsTaxSection}
   */
  TaxEditSection = 'taxEditSection',
  /**
   * {@link PayoutDocumentsPayoutSection}
   */
  PayoutSection = 'payoutDataSection',
  /**
   * {@link PayoutDocumentsAddressSection}
   */
  BillingAddressSection = 'billingAddressSection',

  IdentityProofFrontOrEntireDocument = 'identityProofFrontOrEntireDocument',
  IdentityProofBackDocument = 'identityProofBackDocument',
  IdShotDocument = 'idShotDocument',
  MrfDocument = 'mrfDocument',
  PayoutAssistant = 'payoutAssistant',
  UploadItem = 'uploadItem',
}

export interface ScrollToTargetProps {
  id: UrlFragment | `${UrlFragment}-${string}`;
  /** leave undefined to use browser scrollIntoView behaviour */
  duration?: number;
  children?: React.ReactNode;
}

/**
 * Visually hidden element that has an {@link UrlFragment} `id`
 * which locates a place which can be scrolled to by a `#fragment` Url entry
 *
 * @see ScrollToLastKnownPositionOnMount
 */
export const UrlFragmentScrollToTarget = forwardRef<
  BoxProps & { id: UrlFragment | `${UrlFragment}-${string}` },
  'span'
>((props, ref) => <VisuallyHidden {...props} ref={ref} />);
