import { DateTime } from 'luxon';
import React from 'react';

import { useMinuteStamp } from '../../../provider/MinuteStampProvider';

export const useVideoLibraryUploadListEntryScheduableTimeframe = () => {
  const { minuteStamp } = useMinuteStamp();

  return React.useMemo(() => {
    const currentMinute = DateTime.fromISO(minuteStamp);
    return {
      timezone: currentMinute.zone,
      min: currentMinute
        .plus({ minutes: 5 })
        .toISO({ includeOffset: false, suppressSeconds: true }),
      minEdit: currentMinute.plus({ minutes: 5 }).toISO({
        includeOffset: false,
        suppressSeconds: true,
      }),
      max: currentMinute
        .plus({ months: 3 })
        .toISO({ includeOffset: false, suppressSeconds: true }),
      checkDateEditable: (isoTimeStamp: string) => {
        return (
          (DateTime.fromISO(isoTimeStamp).diff(currentMinute, 'hours')?.hours ??
            0) > 1
        );
      },
    };
  }, [minuteStamp]);
};
