import {
  Section,
  SectionBody,
  SectionFooter,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import { Divider, HStack, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { SubmitErrorHandler } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { ScrollToTargetInline } from '../../../../components/Layout/ScrollToTargetInline';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../../components/Layout/UrlFragmentScrollToTarget';
import { EnumSelectFieldHookForm } from '../../../../components/shared/FormElements/EnumSelectFieldHookForm/EnumSelectFieldHookForm';
import { PromotingOptionList } from '../../../../components/shared/FormikFormElements';
import { ClearableInputControl } from '../../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import {
  StatusDone,
  StatusError,
  StatusPending,
} from '../../../../components/shared/StatusIndicator/StatusIndicator';
import { TipBanner } from '../../../../components/shared/cards/TipBanner/TipBanner';
import {
  GetPayoutDocumentsTaxSectionDocument,
  InputPaymentTaxData,
  TaxCountryEnum,
  useGetPayoutDocumentsTaxSectionQuery,
  useUpdatePayoutDocumentsTaxSectionMutation,
} from '../../../../generated/graphql';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';
import Logger from '../../../../utils/Logger';
import { createStringValidationSchema } from '../../../../utils/validation';
import { getFieldInitialErrors } from '../../../ModelProfileV2Page/ModelProfilePage/utils';
import { SubmitChangesButtonWithConfirmation } from '../components/SubmitChangesButtonWithConfirmation/SubmitChangesButtonWithConfirmation';

const fieldName = {
  isTaxable: 'paymentTaxDataIsTaxable',
  taxNumber: 'paymentTaxDataTaxNumber',
  tin: 'paymentTaxDataTin',
  tinCountry: 'paymentTaxDataTinCountry',
  document: 'paymentTaxDataDocument',
} as const;

interface FormValues {
  [fieldName.tin]: string;
  [fieldName.tinCountry]: TaxCountryEnum | '';
}

const PayoutDocumentsTaxSection: React.FC = () => {
  const { t } = useTranslation([
    'general',
    'imagePicker',
    'payout',
    'wizardPayout',
    'validation',
    'document',
  ]);

  const issueSuccesToast = React.useCallback(() => {
    issueChakraToast({
      description: t('general:toast.AnderungenWurdenGespeichert'),
      status: 'success',
    });
  }, [t]);

  const issueErrorToast = React.useCallback(() => {
    issueChakraToast({
      description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
      status: 'error',
    });
  }, [t]);

  const {
    action: { registerDirtyFlag },
  } = useNavigationBlock();

  const { data, loading } = useGetPayoutDocumentsTaxSectionQuery();
  const [updatePayoutDocumentsTaxSection] =
    useUpdatePayoutDocumentsTaxSectionMutation({
      refetchQueries: [GetPayoutDocumentsTaxSectionDocument],
    });

  const initialValues = React.useMemo<FormValues>(() => {
    const fields = data?.payment?.taxData;
    return {
      [fieldName.tin]: fields?.tin?.value ?? '',
      [fieldName.tinCountry]:
        ((fields?.tinCountry?.value ?? '') as TaxCountryEnum) || '',
    };
  }, [data]);

  const validationSchema = React.useMemo(() => {
    const fields = data?.payment?.taxData;

    return Yup.object().shape({
      [fieldName.tin]: createStringValidationSchema({
        ...fields?.tin,
      }).when(fieldName.isTaxable, (isTaxableValue, prevSchema) => {
        if (isTaxableValue !== 'yes') {
          return prevSchema;
        }
        return Yup.string();
      }),
      [fieldName.tinCountry]: createStringValidationSchema({}).when(
        fieldName.isTaxable,
        (isTaxableValue, prevSchema) => {
          if (isTaxableValue !== 'yes') {
            return prevSchema;
          }
          return Yup.string();
        }
      ),
    });
  }, [data]);

  const hookForm = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const onOmitChanges = React.useCallback(() => {
    hookForm.reset();
  }, [hookForm]);

  const isFormDirty =
    !!hookForm.formState.dirtyFields[fieldName.tin] ||
    !!hookForm.formState.dirtyFields[fieldName.tinCountry];

  React.useEffect(() => {
    return registerDirtyFlag(isFormDirty, onOmitChanges);
  }, [isFormDirty, onOmitChanges, registerDirtyFlag]);

  React.useEffect(() => {
    hookForm.reset(initialValues);
  }, [hookForm, initialValues]);

  const onInvalidSubmit: SubmitErrorHandler<FormValues> = (errors) => {
    Logger.error(errors);
  };

  const onValidSubmit: SubmitHandler<FormValues> = React.useCallback(
    async (data) => {
      try {
        const tinPart: Partial<InputPaymentTaxData> = {
          tin: data[fieldName.tin],
          tinCountry: data[fieldName.tinCountry] as TaxCountryEnum,
        };

        const result = await updatePayoutDocumentsTaxSection({
          variables: {
            data: {
              isTaxable: false,
              ...tinPart,
            },
          },
        });

        const errors = getFieldInitialErrors(
          (result?.data?.payment?.taxData?.updateTaxData as any) ?? null,
          'unkown error'
        );

        if (!!errors) {
          Object.entries(errors).forEach(([key, entry]) => {
            hookForm.setError(key as any, {
              type: 'manual',
              message: entry as any,
            });
          });
          issueErrorToast();
        } else {
          issueSuccesToast();
        }
      } catch (error) {
        Logger.error(error);
        issueErrorToast();
      }
    },
    [
      updatePayoutDocumentsTaxSection,
      hookForm,
      issueSuccesToast,
      issueErrorToast,
    ]
  );

  const validValues = data?.payment?.taxData?.tinCountry?.validValues;
  const quickpickValues = data?.payment?.taxData?.tinCountry?.quickpickValues;
  const optionListProps = useMemo(
    () => ({
      options: validValues ?? [],
      groupLabel: t('general:optgroup.Lander'),
      unPromotedGroupLabel: t('general:optgroup.WeitereLander'),
      placeholder: t('payout:placeholder.AusstellungslandAuswahlen'),
      promotedValues: (quickpickValues ?? []).map(({ value }) => value),
    }),
    [t, validValues, quickpickValues]
  );

  const overallStatus = React.useMemo<string | undefined>(() => {
    if (isFormDirty) {
      return 'dirty';
    }
    return data?.payment?.taxData?.status ?? undefined;
  }, [data, isFormDirty]);

  const isSubmittingDisabled = !isFormDirty || !hookForm.formState.isValid;

  if (loading) {
    return null;
  }

  const statusPending = overallStatus === 'pending';
  const statusRejected = overallStatus === 'rejected';
  const isDisabled = loading || statusPending;

  const statusIndicator = statusPending ? (
    <StatusPending text={t('general:status.InPrufung')} />
  ) : statusRejected ? (
    <StatusError text={t('general:status.Abgelehnt')} />
  ) : overallStatus === 'accepted' ? (
    <StatusDone text={t('general:status.Verifiziert')} />
  ) : null;

  return (
    <Section>
      <ScrollToTargetInline id={UrlFragment.TaxEditSection} />
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <HStack w={'full'}>
              <SectionIcon as={icons.GppMaybe} />
              <SectionTitle>
                {t('payout:headline.SteuerlicheDaten')}
              </SectionTitle>
              {statusIndicator}
            </HStack>
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <FormProvider {...hookForm}>
        <form onSubmit={hookForm.handleSubmit(onValidSubmit, onInvalidSubmit)}>
          <SectionBody>
            <SectionCenterContainer spacing={4}>
              <VStack spacing={6} divider={<Divider />} alignItems={'stretch'}>
                <VStack spacing={6} alignItems={'stretch'}>
                  <TipBanner
                    text={t(
                      'payout:text.NurBeiNaturlichenPersonenMitWohnsitzInDerEU'
                    )}
                  />
                  <ClearableInputControl
                    label={t('payout:label.SteuerIdentifikationsnummerTIN')}
                    text={t(
                      'payout:text.ZuFindenObenRechtsAufDerLohnsteuerbescheinigungOderDemEinkommensteueXX'
                    )}
                    placeholder={t('payout:placeholder.SteuerIDEingeben')}
                    name={fieldName.tin}
                    isDisabled={isDisabled}
                  />
                  <EnumSelectFieldHookForm
                    label={t(
                      'payout:label.InWelchemLandWurdeDieSteuerIdentifikationsnummerTINAusgestellt'
                    )}
                    name={fieldName.tinCountry}
                    isDisabled={isDisabled}
                  >
                    <PromotingOptionList {...optionListProps} />
                  </EnumSelectFieldHookForm>
                </VStack>
              </VStack>
            </SectionCenterContainer>
          </SectionBody>
          <SectionDivider isWidthRestricted />
          <SectionFooter>
            <SectionCenterContainer>
              <SubmitChangesButtonWithConfirmation
                variant={'solid'}
                onConfirm={!isFormDirty}
                onConfirmedSubmit={(e) => {
                  hookForm.handleSubmit(onValidSubmit, onInvalidSubmit)(e);
                }}
                isDisabled={isSubmittingDisabled}
                isLoading={hookForm.formState.isSubmitting}
              />
            </SectionCenterContainer>
          </SectionFooter>
        </form>
      </FormProvider>
    </Section>
  );
};

export default PayoutDocumentsTaxSection;
