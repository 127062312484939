import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  HStack,
  Heading,
  Icon,
  IconButton,
  Img,
  List,
  ListItem,
  StackProps,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react';
import axios from 'axios';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import noBillings from '../../../../assets/images/finance/no-billings-blocker.svg';
import { AbsoluteFullCenterLoadingSpinner } from '../../../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { CurrencyAmount } from '../../../../components/shared/CurrencyAmount/CurrencyAmount';
import {
  FinancesBillingCompanyEnum,
  FinancesBillingFragment,
  FinancesBillingTypeEnum,
  useGetBillingsListQuery,
  useGetPayoutBillingsSectionQuery,
  useLanguageSectionQuery,
} from '../../../../generated/graphql';
import { useAuth } from '../../../../provider/AuthProvider';
import { useToday } from '../../../../provider/TodayProvider';
import { prepareYearBuckets } from '../../utils/prepareYearBuckets';

export const PayoutBillingSection: React.FC = () => {
  const { t } = useTranslation(['billing']);

  const { todayDayStamp } = useToday();
  const currentYear = DateTime.fromISO(todayDayStamp).year;
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const { data, loading } = useGetPayoutBillingsSectionQuery({
    fetchPolicy: 'no-cache',
  });

  const mappedData = React.useMemo(() => {
    const todayDate = DateTime.fromISO(todayDayStamp);

    const accountSignUpYear = DateTime.fromISO(
      data?.account.signupDate ?? todayDayStamp
    );

    const maxPayoutDate = DateTime.fromISO(todayDayStamp);
    const minPayoutDate = DateTime.fromISO(todayDayStamp);

    const yearValues = prepareYearBuckets(
      todayDate,
      accountSignUpYear.toJSDate(),
      minPayoutDate.toJSDate(),
      maxPayoutDate.toJSDate()
    );

    const newestYear = yearValues.at(-1);
    const oldestYear = yearValues.at(0);

    return {
      newestYear,
      oldestYear,
      yearValues,
      accountSignUpYear,
    };
  }, [data, todayDayStamp]);

  const selectNextYear = () => {
    const currentIndex = mappedData.yearValues.indexOf(selectedYear);
    const nextIndex = currentIndex + 1;
    const isValidIndex =
      nextIndex < mappedData.yearValues.length && nextIndex >= 0;

    if (isValidIndex) {
      setSelectedYear(mappedData.yearValues[nextIndex]);
    }
  };
  const selectPreviousYear = () => {
    const currentIndex = mappedData.yearValues.indexOf(selectedYear);
    const nextIndex = currentIndex - 1;
    const isValidIndex =
      nextIndex < mappedData.yearValues.length && nextIndex >= 0;

    if (isValidIndex) {
      setSelectedYear(mappedData.yearValues[nextIndex]);
    }
  };

  const isBillingDataLoading = loading;

  return (
    <Section>
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <VStack alignItems={'start'} flex={1}>
              <HStack>
                <SectionIcon as={icons.DescriptionOutlined} />
                <SectionTitle children={t('billing:heading.Abrechnungen')} />
              </HStack>
            </VStack>
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <SectionBody pos={'relative'} minH={'400px'}>
        <SectionCenterContainer>
          {isBillingDataLoading ? (
            <AbsoluteFullCenterLoadingSpinner />
          ) : (
            <Box minH={'32rem'}>
              <Box mb={4}>
                <VStack alignItems={'stretch'}>
                  <HStack>
                    <IconButton
                      aria-label={'Previous Year'}
                      icon={<Icon as={icons.ChevronLeft} boxSize="icon.md" />}
                      onClick={selectPreviousYear}
                      isDisabled={selectedYear === mappedData.oldestYear}
                    />
                    <Heading textAlign={'center'} flexGrow={1} size={'xl'}>
                      {selectedYear}
                    </Heading>
                    <IconButton
                      aria-label={'Next Year'}
                      icon={<Icon as={icons.ChevronRight} boxSize="icon.md" />}
                      onClick={selectNextYear}
                      isDisabled={selectedYear === mappedData.newestYear}
                    />
                  </HStack>
                </VStack>
              </Box>
              <BillingList year={selectedYear} currentYear={currentYear} />
            </Box>
          )}
        </SectionCenterContainer>
      </SectionBody>
    </Section>
  );
};

const BillingList: React.FC<{
  year: number;
  currentYear: number;
}> = ({ year, currentYear }) => {
  const { t } = useTranslation(['billing']);
  const { data, loading } = useGetBillingsListQuery({
    variables: {
      year,
    },
    fetchPolicy: 'no-cache',
  });

  const mappedData = React.useMemo(() => {
    const billingsList =
      data?.finances.billings ??
      ([].filter(Boolean) as FinancesBillingFragment[]);

    const isEmpty = billingsList.length <= 0;

    return {
      billingsList,
      isEmpty,
    };
  }, [data]);

  //we want to have the billings sorted by month and company
  const billingListByMonth: {
    [key: string]: { [key: string]: FinancesBillingFragment[] };
  } = React.useMemo(() => {
    const billingsList = mappedData.billingsList;
    let billingListByMonth = billingsList.reduce((acc, billing) => {
      if (!billing || !billing.issueDate) return acc;
      const month = DateTime.fromISO(billing.issueDate)
        .toFormat('LLLL')
        .toLowerCase();
      const company = billing.companyName;
      if (!acc[month]) {
        acc[month] = {};
      }
      if (!acc[month][company]) {
        acc[month][company] = [];
      }
      acc[month][company].push(billing);
      return acc;
    }, {} as { [key: string]: { [key: string]: FinancesBillingFragment[] } });

    //sort by month
    billingListByMonth = Object.entries(billingListByMonth)
      .sort(([monthA], [monthB]) => {
        const dateA = DateTime.fromFormat(monthA, 'LLLL');
        const dateB = DateTime.fromFormat(monthB, 'LLLL');
        return dateB.valueOf() - dateA.valueOf();
      })
      .reduce((acc, [month, billings]) => {
        acc[month] = billings;
        return acc;
      }, {} as { [key: string]: { [key: string]: FinancesBillingFragment[] } });

    return billingListByMonth;
  }, [mappedData.billingsList]);

  if (loading) {
    return (
      <Box position={'relative'} height={'250px'}>
        <AbsoluteFullCenterLoadingSpinner />
      </Box>
    );
  }
  if (mappedData.isEmpty) {
    return (
      <VStack gap={2}>
        <FinanceYourBillingsEmptyYear />
      </VStack>
    );
  }

  return (
    <VStack as={List} alignItems={'stretch'} spacing={3}>
      {Object.entries(billingListByMonth).map((billingMonth) => {
        /* Billings for issued month */
        const billingList = Object.entries(billingMonth[1]).map((billing) => {
          const companyName =
            billing[0] === 'Campoint' ? 'camPoint AG' : billing[0];
          /* Billing items for issued month */
          const billings = billing[1].map((billing) => {
            return (
              <BillingListItem
                billing={billing}
                key={`billing-${billing?.id}`}
              />
            );
          });
          return (
            <VStack
              w={'full'}
              alignItems={'start'}
              key={`company-${billing[0]}`}
            >
              <HStack mb={1}>
                <Icon as={icons.DescriptionOutlined} boxSize={'icon.sm'} />
                <Text fontSize={'14px'} lineHeight={'20px'}>
                  {/* Billing item company for issued month */}
                  {companyName}
                </Text>
              </HStack>
              {billings}
            </VStack>
          );
        });
        return (
          <VStack key={`month-${billingMonth[0]}`} alignItems={'start'}>
            <Text as={Heading} fontSize={'18px'} lineHeight={'40px'}>
              {/* Issued Month of Billing */}
              {t(`billing:heading.Monat`, {
                month:
                  billingMonth[0].charAt(0).toUpperCase() +
                  billingMonth[0].slice(1),
              })}
            </Text>
            {billingList}
          </VStack>
        );
      })}
    </VStack>
  );
};

const StyledEmptyState: React.FC<StackProps> = (props) => (
  <VStack
    alignItems="center"
    justifyItems="center"
    textAlign="center"
    p="4"
    {...props}
  />
);

export const FinanceYourBillingsEmptyYear: React.FC = () => {
  const { t } = useTranslation(['payout', 'financePage']);

  return (
    <StyledEmptyState>
      <Img
        src={noBillings}
        alt={t('financePage:img.NochKeineAbrechnungenErstellt')}
      />
      <Heading
        as="h4"
        size="xl"
        children={t(
          'payout:headline.InDiesemJahrWurdenKeineAbrechnungenErstellt'
        )}
      />
    </StyledEmptyState>
  );
};

type BillingListItemProps = {
  billing: FinancesBillingFragment;
};

const BillingListItem: React.FC<BillingListItemProps> = ({
  billing,
}: BillingListItemProps) => {
  const { t } = useTranslation(['billing']);
  const { data } = useLanguageSectionQuery();
  const { authUser, accessToken } = useAuth();

  const currentLanguage: string | undefined = React.useMemo(() => {
    return data?.account?.language?.toLowerCase() ?? 'de';
  }, [data?.account?.language]);

  const billingTypeToHeader = React.useMemo(() => {
    let header: { icon?: any; iconColor?: string; text?: string } = {};

    switch (billing.type) {
      case FinancesBillingTypeEnum.Billing:
        header = {
          icon: icons.CheckCircle,
          iconColor: 'lime.500',
          text: t('billing:text.Abrechnung'),
        };
        break;
      case FinancesBillingTypeEnum.Correction:
        header = {
          icon: icons.CheckCircleOutline,
          iconColor: 'lime.500',
          text: t('billing:text.Korrekturabrechnung'),
        };
        break;
      case FinancesBillingTypeEnum.Cancellation:
        header = {
          icon: icons.Close,
          iconColor: 'error.500',
          text: t('billing:text.Stornoabrechnung'),
        };
        break;
      case FinancesBillingTypeEnum.Invoice:
        header = {
          icon: icons.CheckCircle,
          iconColor: 'lime.500',
          text: t('billing:text.Auszahlungsgebuhr'),
        };
        break;
      default:
        break;
    }

    return header;
  }, [billing.type, t]);

  const downloadBilling = async () => {
    // Campoint Abrechnungen
    let type = 'statement';
    if (billing.type === FinancesBillingTypeEnum.Invoice) {
      type = 'invoice';
    }
    let billingUrl = `https://api.vxmodels.com/v1/camtool/user/${authUser?.userId}/finances/${type}/${currentLanguage}/${billing.id}`;

    // VXCash Abrechnungen
    if (billing.company === FinancesBillingCompanyEnum.Cfg) {
      billingUrl = `https://api.vxmodels.com/v1/camtool/user/${authUser?.userId}/vxcash/billing/${billing.id}`;
    }

    try {
      const response = axios.get(billingUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      });
      const blob = (await response)?.data;
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    } catch (e) {
      issueChakraToast({
        status: 'error',
        description: t('billing:text.DownloadFehlgeschlagen'),
      });
    }
  };

  // special case GMU: hide download button for 'withdrawal fees' issued after 2024-11-01
  const hideDownload =
    billing.type === FinancesBillingTypeEnum.Invoice &&
    DateTime.fromISO(billing.issueDate) >= DateTime.fromISO('2024-11-01');

  return (
    <ListItem
      w={'full'}
      p={2}
      h={'138px'}
      borderRadius={'6px'}
      bg={'lightBackground'}
      cursor={'pointer'}
    >
      <VStack h={'full'} gap={0}>
        <HStack alignItems={'start'} w={'full'} gap={1}>
          <Icon
            as={billingTypeToHeader.icon}
            boxSize={'icon.sm'}
            color={billingTypeToHeader.iconColor}
            mt={'2px'}
          />
          <VStack alignItems={'start'} gap={0}>
            <Text fontSize={'14px'} fontWeight={'medium'} lineHeight={'20px'}>
              {billingTypeToHeader.text}
              <chakra.span pl={0.5} fontWeight={'normal'}>
                {t('billing:text.Ausstelldatum', { date: billing.issueDate })}
              </chakra.span>
            </Text>
            <Text fontSize={'12px'} color={'gray.500'} lineHeight={'20px'}>
              {t('billing:text.Zeitraum', {
                start: billing.billingStartDate,
                end: billing.billingEndDate,
              })}
            </Text>
          </VStack>
        </HStack>
        <HStack flex={1}>
          <Text
            as={'span'}
            textOverflow={'ellipsis'}
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            fontSize={'20px'}
            fontWeight={'bold'}
            lineHeight={'24px'}
          >
            <CurrencyAmount amount={billing.amount} />
          </Text>
        </HStack>
        {!hideDownload && (
          <HStack
            alignItems={'end'}
            _hover={{ color: 'primary.300' }}
            onClick={() => {
              downloadBilling();
            }}
          >
            <Text
              overflow={'hidden'}
              fontSize={'sm'}
              lineHeight={'24px'}
              fontWeight={'semibold'}
            >
              <Icon as={icons.Download} boxSize={'icon.sm'} mr={1} />
              Download
            </Text>
          </HStack>
        )}
      </VStack>
    </ListItem>
  );
};
