import { ChevronLeft, ChevronRight } from '@campoint/odi-ui-icons';
import { Box, Flex, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HSnapScrollContainer } from '../../../../components/Layout/HSnapScrollContainer';
import { StepDotGroup } from '../../../../components/shared/StepDotGroup/StepDotGroup';
import { SharingStepFragment } from '../../../../generated/graphql';
import {
  InfoLevelSection,
  STEP_TILE_HEIGHT,
  STEP_TILE_WIDTH,
} from './DetailedStatisticsSharings';

let scrollLock = false;
export const SharingSectionMobile: React.FC<{
  currentStepSharingItem: JSX.Element;
  steps: Maybe<SharingStepFragment[]>;
  currentStep: number;
}> = ({ currentStepSharingItem, steps, currentStep }) => {
  const { t } = useTranslation(['statisticsPage']);
  const [tileIndex, setTileIndex] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (!scrollLock) {
      setTileIndex(Math.round(e.currentTarget.scrollLeft / STEP_TILE_WIDTH));
    }
  };
  const setTileIndexAndScroll = (index: number) => {
    if (ref && ref.current && !scrollLock) {
      scrollLock = true;
      ref.current.scrollTo({
        left: index * STEP_TILE_WIDTH,
        behavior: 'smooth',
      });
      setTimeout(() => {
        setTileIndex(index);
        scrollLock = false;
      }, 300);
    }
  };

  //go to currentStep
  React.useEffect(() => {
    setTileIndexAndScroll(currentStep - 1);
  }, [currentStep]);

  const isCurrentStep = currentStep === tileIndex + 1;
  const stepsLengthEqualOne = steps?.length === 1;

  return (
    <VStack gap={8}>
      <VStack gap={6}>
        {isCurrentStep ? (
          <Text
            fontWeight={'extrabold'}
            fontSize={'sm'}
            lineHeight={'20px'}
            bg={'caribbeanGreen.300'}
            px={'6px'}
            borderRadius={'6px'}
          >
            {isCurrentStep ? t('text.AktuelleStufe') : ''}
          </Text>
        ) : (
          <Box minH={'20px'}></Box>
        )}
        {stepsLengthEqualOne ? (
          <VStack
            gap={0}
            p={0}
            minW={STEP_TILE_WIDTH}
            minH={STEP_TILE_HEIGHT}
            alignItems={'stretch'}
            justifyContent={'stretch'}
          >
            {currentStepSharingItem}
          </VStack>
        ) : (
          <Flex width={'100vw'} scrollBehavior={'smooth'}>
            <HSnapScrollContainer
              ref={ref}
              scrollSnapStop={'always'}
              scrollBehavior={'smooth'}
              pr={`calc((100vw - ${STEP_TILE_WIDTH}px) / 2)`}
              py={0}
              gap={0}
              onScroll={onScroll}
            >
              {steps?.map((step, index) => {
                const isCurrentStep = step.step === (currentStep ?? 0);
                const sharingStep = isCurrentStep ? (
                  currentStepSharingItem
                ) : (
                  <InfoLevelSection
                    step={step}
                    currentStep={currentStep ?? 0}
                  />
                );
                return (
                  <SharingTileBase
                    key={index}
                    step={sharingStep}
                    positionInStack={steps.indexOf(step) - tileIndex}
                    isMobile
                  />
                );
              })}
            </HSnapScrollContainer>
          </Flex>
        )}
      </VStack>
      {!stepsLengthEqualOne && (
        <StepDotGroup
          totalSteps={steps?.length ?? 0}
          currentStep={tileIndex + 1}
        />
      )}
    </VStack>
  );
};

export const SharingSectionDesktop: React.FC<{
  currentStepSharingItem: JSX.Element;
  steps: Maybe<SharingStepFragment[]>;
  currentStep: number;
}> = ({ currentStepSharingItem, steps, currentStep }) => {
  const { t } = useTranslation(['statisticsPage']);
  const [tileIndex, setTileIndex] = useState(0);
  const prevTile = () => {
    if (!steps) return;
    if (tileIndex === 0) return;
    setTileIndex((tileIndex - 1) % steps.length);
  };
  const nextTile = () => {
    if (!steps) return;
    if (tileIndex === steps.length - 1) return;
    setTileIndex((tileIndex + 1) % steps.length);
  };
  //go to currentStep
  React.useEffect(() => {
    setTileIndex(currentStep - 1);
  }, [currentStep]);

  const isCurrentStep = currentStep === tileIndex + 1;
  const stepsLengthEqualOne = steps?.length === 1;

  return (
    <VStack gap={8}>
      <VStack gap={6}>
        {isCurrentStep ? (
          <Text
            fontWeight={'extrabold'}
            fontSize={'sm'}
            lineHeight={'20px'}
            bg={'caribbeanGreen.300'}
            px={'6px'}
            borderRadius={'6px'}
          >
            {isCurrentStep ? t('text.AktuelleStufe') : ''}
          </Text>
        ) : (
          <Box minH={'20px'}></Box>
        )}
        {stepsLengthEqualOne ? (
          <VStack
            gap={0}
            p={0}
            minW={STEP_TILE_WIDTH}
            minH={STEP_TILE_HEIGHT}
            alignItems={'stretch'}
            justifyContent={'stretch'}
          >
            {currentStepSharingItem}
          </VStack>
        ) : (
          <HStack>
            {!stepsLengthEqualOne && (
              <IconButton
                aria-label="prev"
                as={ChevronLeft}
                color={'white'}
                onClick={prevTile}
                backgroundColor={'primary.200'}
                border={'none'}
                size={'xs'}
                cursor={'pointer'}
              />
            )}
            <VStack>
              <Box width={STEP_TILE_WIDTH} height={STEP_TILE_HEIGHT} m={2}>
                {steps?.map((step, index) => {
                  const isCurrentStep = step.step === (currentStep ?? 0);

                  const sharingStep = isCurrentStep ? (
                    currentStepSharingItem
                  ) : (
                    <InfoLevelSection
                      step={step}
                      currentStep={currentStep ?? 0}
                    />
                  );

                  let stackPosition = null;

                  if (index === tileIndex) {
                    stackPosition = 1;
                  } else if (Math.abs(index - tileIndex) === 1) {
                    stackPosition = 2;
                  } else {
                    stackPosition = null;
                  }

                  return (
                    <SharingTileBase
                      key={index}
                      step={sharingStep}
                      isMobile={false}
                      positionInStack={stackPosition}
                      tileIndex={tileIndex}
                      index={index}
                    />
                  );
                })}
              </Box>
            </VStack>
            {!stepsLengthEqualOne && (
              <IconButton
                ml={`${(steps?.length ?? 1) * 15}px`}
                aria-label="next"
                as={ChevronRight}
                color={'white'}
                onClick={nextTile}
                backgroundColor={'primary.200'}
                border={'none'}
                size={'xs'}
                cursor={'pointer'}
              />
            )}
          </HStack>
        )}
      </VStack>
      {!stepsLengthEqualOne && (
        <StepDotGroup
          totalSteps={steps?.length ?? 0}
          currentStep={tileIndex + 1}
        />
      )}
    </VStack>
  );
};

type SharingTileBaseProps = {
  positionInStack?: number | null;
  isMobile?: boolean;
  step: JSX.Element;
  tileIndex?: number;
  index?: number;
};

const SharingTileBase: React.FC<SharingTileBaseProps> = ({
  positionInStack,
  isMobile = false,
  step,
  tileIndex,
  index,
  ...props
}) => {
  const isPrevStep = (index ?? 0) < (tileIndex ?? 0);
  const firstElement = isPrevStep && positionInStack === 2;
  const isNextStep = (index ?? 0) > (tileIndex ?? 0);
  const lastElement = isNextStep && positionInStack === 2;
  const margin = firstElement ? '0px' : lastElement ? '60px' : '30px';

  const maxStackPosition = 10;
  const zIndex = positionInStack ? maxStackPosition - positionInStack + 1 : -1;

  const tileIndexIsCurrent = tileIndex === index;

  const transform = isMobile
    ? `scale(${1 - Math.abs(positionInStack ?? 1) * 0.05})`
    : tileIndexIsCurrent
    ? `scale(1)`
    : 'scale(0.9)';

  return (
    <VStack
      alignItems={'center'}
      justifyContent={'center'}
      ml={isMobile ? 'unset' : margin}
      minW={STEP_TILE_WIDTH}
      maxW={STEP_TILE_WIDTH}
      minH={STEP_TILE_HEIGHT}
      padding={0}
      gap={0}
      overflow={'hidden'}
      scrollSnapStop={'always'}
      position={isMobile ? 'unset' : 'absolute'}
      display={'flex'}
      scrollSnapAlign={'center'}
      transform={transform}
      zIndex={isMobile ? 'unset' : zIndex} // ensure zIndex is more than 0
      transition={'all 0.5s ease-out'}
      border={'none'}
      {...props}
    >
      {step}
    </VStack>
  );
};
