import { ChakraProvider } from '@chakra-ui/react';
import { FlagsProvider } from 'flagged';
import { setupWorker } from 'msw';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import ENV from './environments/environment';
import { features } from './flags/features';
import {
  accountStatusHandler,
  billingAddressEdit,
  createActorUploadHandlers,
  getFinancePageBillingsHandlers,
  getFinancePageInvoicesHandlers,
  handlers,
  notificationHandler,
  postSalesHandler,
  profilePictureStatusHandler,
  tippingGoalHandler,
} from './handlers';
import i18n from './i18n';
import { VXMChatbotProvider } from './pages/HelpCenterPage/Chatbot/VXMCatbotProvider';
import { WizardMRF } from './pages/Wizard/ModelReleaseForm/WizardMRF';
import { WizardPayout as WizardPayoutV1 } from './pages/Wizard/Payout/WizardPayout';
import { WizardPayout as WizardPayoutV2 } from './pages/Wizard/PayoutV2/WizardPayout';
import { WizardProfile } from './pages/Wizard/Profile/WizardProfile';
import { WizardVerification } from './pages/Wizard/Verification/WizardVerification';
import { AccountStatusProvider } from './provider/AccountStatusProvider/AccountStatusProvider';
import { ApolloMultiClientProvider } from './provider/ApolloMultiClientProvider';
import { AppVersionProvider } from './provider/AppVersionProvider';
import { AuthProvider } from './provider/AuthProvider';
import { ContestProvider } from './provider/ContestProvider';
import { CookieConsentProvider } from './provider/CookieConsentProvider';
import { FeedPostCreateModalProvider } from './provider/FeedPostCreateModalProvider';
import { FeedPostServiceProvider } from './provider/FeedPostService/FeedPostService';
import { FeedPostStatisticsProvider } from './provider/FeedPostStatisticsProvider';
import { FeedServiceProvider } from './provider/FeedService/FeedService';
import { FinanceServiceProvider } from './provider/FinanceService/FinanceService';
import { FlagsProviderV2Provider } from './provider/FlagsProviderV2';
import GoogleAnalyticsProvider from './provider/GoogleAnalyticsProvider';
import IntlI18nProvider from './provider/IntlI18nProvider';
import { MandatoryEmailVerificationProvider } from './provider/MandatoryEmailVerificationProvider';
import { MinuteStampProvider } from './provider/MinuteStampProvider';
import { NavigationBlockProvider } from './provider/NavigationBlockProvider';
import { NewsCenterProvider } from './provider/NewsCenterProvider/NewsCenterProvider';
import { OnboardingTaskStatusProvider } from './provider/OnboardingTaskStatusProvider';
import { PostCommentingProvider } from './provider/PostCommentingProvider';
import { RollbarProvider } from './provider/RollbarProvider';
import { TermsProvider } from './provider/TermsProvider';
import { TodayProvider } from './provider/TodayProvider';
import { TourProvider } from './provider/TourProvider/TourProvider';
import { UploadManagerProvider } from './provider/UploadManagerProvider';
import { VideoCommentingProvider } from './provider/VideoLibraryProvider/VideoLibraryCommentingProvider/VideoLibraryCommentingProvider';
import { PhotoCommentingProvider } from './provider/VideoLibraryProvider/VideoLibraryCommentingProvider/VideoLibraryPhotoCommentingProvider';
import { VideoLibraryEntryModalProvider } from './provider/VideoLibraryProvider/VideoLibraryEntryModalProvider/VideoLibraryEntryModalProvider';
import { VideoLibraryPhotoModalProvider } from './provider/VideoLibraryProvider/VideoLibraryPhotoModalProvider/VideoLibraryPhotoModalProvider';
import { VideoLibraryPhotoProvider } from './provider/VideoLibraryProvider/VideoLibraryPhotoProvider/VideoLibraryPhotoProvider';
import { VideoLibraryPhotoStatisticModalProvider } from './provider/VideoLibraryProvider/VideoLibraryStatisticModalProvider/VideoLibraryPhotoStatisticModalProvider';
import { VideoLibraryVideoStatisticModalProvider } from './provider/VideoLibraryProvider/VideoLibraryStatisticModalProvider/VideoLibraryVideoStatisticModalProvider';
import { UserWrappedUploadyProvider } from './provider/VideoLibraryProvider/VideoLibraryUploadyProvider/VideoLibraryUploadyProvider';
import { VideoLibraryVideoProvider } from './provider/VideoLibraryProvider/VideoLibraryVideoProvider/VideoLibraryVideoProvider';
import { VxLiveLinkProvider } from './provider/VxLiveLinkProvider';
import theme from './theme';

const MSW_HANDLER_SET_NAME = localStorage.getItem('MSW_HANDLER_SET_NAME');

if (ENV.ENVIRONMENT === 'local' && MSW_HANDLER_SET_NAME) {
  const foundHandlers = {
    'payout-v1': handlers,
    'tipping-goal-v1': tippingGoalHandler,
    'model-profile-v1': profilePictureStatusHandler,
    'post-sales-v1': postSalesHandler,
    'account-status-v1': accountStatusHandler,
    'billing-address-v1': billingAddressEdit,
    'create-actor-upload-v1': createActorUploadHandlers,
    'finance-page-invoices-v1': getFinancePageInvoicesHandlers,
    'finance-page-billings-v1': getFinancePageBillingsHandlers,
    'notification-v1': notificationHandler,
  }[MSW_HANDLER_SET_NAME];

  if (foundHandlers) {
    setupWorker(...foundHandlers).start({
      onUnhandledRequest: 'bypass',
    });
  }
}

const container = document.getElementById('react-root');
const root = createRoot(container!);

root.render(
  <RollbarProvider>
    <FlagsProvider features={features}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <IntlI18nProvider>
            <ChakraProvider theme={theme} resetCSS>
              <NavigationBlockProvider>
                <AppVersionProvider>
                  <ApolloMultiClientProvider>
                    <TodayProvider>
                      <MinuteStampProvider>
                        <AuthProvider>
                          {(userId) => (
                            <React.StrictMode>
                              <GoogleAnalyticsProvider>
                                <CookieConsentProvider>
                                  <FlagsProviderV2Provider>
                                    <MandatoryEmailVerificationProvider>
                                      <TermsProvider key={userId}>
                                        <OnboardingTaskStatusProvider>
                                          <AccountStatusProvider key={userId}>
                                            <WizardProfile>
                                              <WizardMRF>
                                                <WizardVerification>
                                                  <WizardPayoutV1>
                                                    <WizardPayoutV2>
                                                      <VxLiveLinkProvider>
                                                        <FinanceServiceProvider>
                                                          <UploadManagerProvider>
                                                            <UserWrappedUploadyProvider>
                                                              <ContestProvider>
                                                                <VideoLibraryEntryModalProvider>
                                                                  <FeedServiceProvider
                                                                    key={userId}
                                                                  >
                                                                    <FeedPostServiceProvider>
                                                                      <FeedPostCreateModalProvider>
                                                                        <NewsCenterProvider
                                                                          key={
                                                                            userId
                                                                          }
                                                                        >
                                                                          <FeedPostStatisticsProvider>
                                                                            <PostCommentingProvider>
                                                                              <TourProvider>
                                                                                <VideoLibraryVideoProvider>
                                                                                  <VideoLibraryPhotoProvider>
                                                                                    <VideoLibraryPhotoModalProvider>
                                                                                      <VideoLibraryVideoStatisticModalProvider>
                                                                                        <VideoLibraryPhotoStatisticModalProvider>
                                                                                          <VideoCommentingProvider>
                                                                                            <PhotoCommentingProvider>
                                                                                              <VXMChatbotProvider>
                                                                                                <App />
                                                                                              </VXMChatbotProvider>
                                                                                            </PhotoCommentingProvider>
                                                                                          </VideoCommentingProvider>
                                                                                        </VideoLibraryPhotoStatisticModalProvider>
                                                                                      </VideoLibraryVideoStatisticModalProvider>
                                                                                    </VideoLibraryPhotoModalProvider>
                                                                                  </VideoLibraryPhotoProvider>
                                                                                </VideoLibraryVideoProvider>
                                                                              </TourProvider>
                                                                            </PostCommentingProvider>
                                                                          </FeedPostStatisticsProvider>
                                                                        </NewsCenterProvider>
                                                                      </FeedPostCreateModalProvider>
                                                                    </FeedPostServiceProvider>
                                                                  </FeedServiceProvider>
                                                                </VideoLibraryEntryModalProvider>
                                                              </ContestProvider>
                                                            </UserWrappedUploadyProvider>
                                                          </UploadManagerProvider>
                                                        </FinanceServiceProvider>
                                                      </VxLiveLinkProvider>
                                                    </WizardPayoutV2>
                                                  </WizardPayoutV1>
                                                </WizardVerification>
                                              </WizardMRF>
                                            </WizardProfile>
                                          </AccountStatusProvider>
                                        </OnboardingTaskStatusProvider>
                                      </TermsProvider>
                                    </MandatoryEmailVerificationProvider>
                                  </FlagsProviderV2Provider>
                                </CookieConsentProvider>
                              </GoogleAnalyticsProvider>
                            </React.StrictMode>
                          )}
                        </AuthProvider>
                      </MinuteStampProvider>
                    </TodayProvider>
                  </ApolloMultiClientProvider>
                </AppVersionProvider>
              </NavigationBlockProvider>
            </ChakraProvider>
          </IntlI18nProvider>
        </I18nextProvider>
      </BrowserRouter>
    </FlagsProvider>
  </RollbarProvider>
);
