// PageHeaderContext.tsx
import React, { createContext, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import { useStatisticsPageStatistics } from './StatisticsPageProvider';
import {
  StatisticsDetailedCategoryExtEnum,
  useTabsContext,
} from './TabContext';

export type StatisticsPageHeaderType = { primary: string; secondary: string };

type PageHeaderContextType = StatisticsPageHeaderType;

const PageHeaderContext = createContext<PageHeaderContextType | undefined>(
  undefined
);

export const usePageHeaderContext = () => {
  const context = useContext(PageHeaderContext);
  if (!context) {
    throw new Error(
      'usePageHeaderContext must be used within a PageHeaderProvider'
    );
  }
  return context;
};

export const PageHeaderProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    data: barChartDetailedData,
    loading: barChartDataLoading,
    affiliateData,
  } = useStatisticsPageStatistics();
  const { tabIds, tabIndex } = useTabsContext();
  const tabId = tabIds[tabIndex];
  const locale = useIntl().locale;
  const { t } = useTranslation(['statisticsPage']);

  const pageHeaderData: StatisticsPageHeaderType = useMemo(() => {
    if (barChartDataLoading) return { primary: '-', secondary: '-' };
    let details;
    let primary, secondary;
    let onlineTime;
    switch (tabId) {
      case StatisticsDetailedCategoryExtEnum.LiveChat:
        details = barChartDetailedData?.liveChat;
        primary = details?.actualLivePlacing ?? 0;
        onlineTime = details?.onlineTimeActualMonth ?? 0;
        const minutes = Math.round(onlineTime / 60);
        const hours = Math.floor(minutes / 60); // must be rounded down!
        const minutesLeft = minutes % 60;
        secondary = `${hours} h ${minutesLeft} m`;
        break;
      case StatisticsDetailedCategoryExtEnum.Messenger:
        details = barChartDetailedData?.messenger;
        primary = details?.gifts;
        secondary = details?.contentSales;
        break;
      case StatisticsDetailedCategoryExtEnum.VideoLibrary:
        details = barChartDetailedData?.videoLibrary;
        primary = details?.sellings;
        secondary = details?.likesCount;
        break;
      case StatisticsDetailedCategoryExtEnum.Feed:
        details = barChartDetailedData?.feed;
        primary = details?.sellings;
        secondary = details?.tipping;
        break;
      case StatisticsDetailedCategoryExtEnum.Misc:
        details = barChartDetailedData?.misc;
        primary = details?.wins;
        secondary = details?.isContestActive
          ? t('text.aktiv')
          : t('text.inaktiv');
        break;
      case StatisticsDetailedCategoryExtEnum.Telegram:
        details = barChartDetailedData?.telegram;
        primary = details?.subs;
        secondary = details?.vips;
        break;
      case StatisticsDetailedCategoryExtEnum.Affiliate:
        primary = affiliateData?.modelAdvertisedUsers ?? 0;
        secondary = affiliateData?.modelAdvertisedModels ?? 0;
        break;
      case StatisticsDetailedCategoryExtEnum.PhoneService:
        details = barChartDetailedData?.phoneService;
        primary = details?.calls;
        secondary = `${Math.floor((details?.callTimes ?? 0) / 3600)} h`; // must be rounded down!
        break;
      case StatisticsDetailedCategoryExtEnum.OverAll:
        const overAllTurnover =
          barChartDetailedData.overAll?.totalTurnover ?? 0;
        const affiliateTurnover = affiliateData?.totalSharing ?? 0;
        const totalTurnover = overAllTurnover + affiliateTurnover;
        const overallTurnoverFormatted = (
          (overAllTurnover / totalTurnover) *
          100
        ).toFixed(2);
        const affiliateTurnoverFormatted = (
          (affiliateTurnover / totalTurnover) *
          100
        ).toFixed(2);

        if (overAllTurnover === 0) {
          primary = '0 %';
        } else {
          primary = `${
            locale === 'de'
              ? overallTurnoverFormatted.replace('.', ',')
              : overallTurnoverFormatted
          } %`;
        }
        if (affiliateTurnover === 0) {
          secondary = '0 %';
        } else {
          secondary = `${
            locale === 'de'
              ? affiliateTurnoverFormatted.replace('.', ',')
              : affiliateTurnoverFormatted
          } %`;
        }
        break;
      default:
        break;
    }
    return {
      primary: `${primary ?? 0}`,
      secondary: `${secondary ?? 0}`,
    };
  }, [
    locale,
    affiliateData?.modelAdvertisedModels,
    affiliateData?.modelAdvertisedUsers,
    affiliateData?.totalSharing,
    barChartDataLoading,
    barChartDetailedData?.feed,
    barChartDetailedData?.liveChat,
    barChartDetailedData?.messenger,
    barChartDetailedData?.misc,
    barChartDetailedData.overAll?.totalTurnover,
    barChartDetailedData?.phoneService,
    barChartDetailedData?.telegram,
    barChartDetailedData?.videoLibrary,
    t,
    tabId,
  ]);

  return (
    <PageHeaderContext.Provider value={pageHeaderData}>
      {children}
    </PageHeaderContext.Provider>
  );
};
