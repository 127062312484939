import { useBreakpointValue } from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';

import { ProfileFieldTypePictureFragment } from '../../../../../generated/graphql';
import { ImageRatings } from '../../../../../pages/ModelProfileV2Page/ModelProfilePage/types';
import { CommonMediaFlowModal } from '../../../../../pages/ModelProfileV2Page/components/CommonMediaFlowModal/CommonMediaFlowModal';
import { useModelProfile } from '../../../../../pages/ModelProfileV2Page/provider/ModelProfileProvider';
import { MediaFlowProvider } from '../../../../../provider/MediaFlowProvider';
import { MediaPropertiesProvider } from '../../../../../provider/MediaPropertiesProvider';
import { MediaProvider } from '../../../../../provider/MediaProvider';
import { MediaUploadProvider } from '../../../../../provider/MediaUploadProvider';
import { MediaPreviewFlowTriggerBox } from '../../../MediaPreviewBox/MediaPreviewBox';

const ProfilePictureMediaPreviewBox: React.FC = () => {
  const buttonBreakpointSizes = useBreakpointValue({ base: 'sm', lg: 'md' });

  return (
    <MediaPreviewFlowTriggerBox
      aspectRatioProps={{ w: { base: '100px', lg: '144px' } }}
      iconButtonProps={{ size: buttonBreakpointSizes }}
      imageProps={{ sizes: '200px' }}
      boxProps={{ backgroundColor: '#FFFFFF' }}
    />
  );
};

const ProfilePictureMediaFlowModal: React.FC = () => (
  <CommonMediaFlowModal
    mediaPickerPreviewBoxProps={{ imageProps: { sizes: '200px' } }}
  />
);

type ProfilePictureProps = {
  field?: Maybe<ProfileFieldTypePictureFragment>;
  isEditable?: boolean;
};

export const ProfilePicture: React.FC<ProfilePictureProps> = (props) => {
  const {
    actions: { saveProfileData },
  } = useModelProfile();

  const onPersist = React.useCallback(
    async (id: number) => {
      await saveProfileData({
        mediaData: {
          modelPictureFSK16Avatar: id,
        },
      });
    },
    [saveProfileData]
  );
  return (
    <MediaPropertiesProvider.ForProfilePicture imageRating={ImageRatings.FSK16}>
      <MediaProvider.FromMediaPicture
        picture={props.field?.value}
        isEditable={props.isEditable}
        initialSize={{ width: 520, height: 520 }}
      >
        <MediaFlowProvider>
          <MediaUploadProvider.BasedOnNamedProperty
            fieldName={props.field?.name}
            onPersist={onPersist}
          >
            <ProfilePictureMediaPreviewBox />
            <ProfilePictureMediaFlowModal />
          </MediaUploadProvider.BasedOnNamedProperty>
        </MediaFlowProvider>
      </MediaProvider.FromMediaPicture>
    </MediaPropertiesProvider.ForProfilePicture>
  );
};
