import { ApolloError } from '@apollo/client';
import { Maybe } from 'graphql/jsutils/Maybe';
import { DateTime } from 'luxon';
import React, { createContext, useCallback, useContext } from 'react';

import {
  FinancesTurnoverGroupEnum,
  UserListPurchasesFragment,
  useGetUserListPurchasesQuery,
} from '../../../generated/graphql';
import { useDateContext } from './DateContext';
import {
  StatisticsDetailedCategoryExtEnum,
  StatisticsDetailedCategoryExtEnumType,
  useTabsContext,
} from './TabContext';

type UserListContextType = {
  data: Maybe<UserListPurchasesFragment>[];
  loading: boolean;
  error: ApolloError | undefined;
  handleLoadMore: () => void;
};

const UserListContext = createContext<UserListContextType | undefined>(
  undefined
);

export const useUserListContext = () => {
  const context = useContext(UserListContext);
  if (!context) {
    throw new Error(
      'useUserListContext must be used within a UserListProvider'
    );
  }
  return context;
};

export const UserListProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { tabIds, tabIndex } = useTabsContext();
  const tabId = tabIds[tabIndex];
  const { startDate } = useDateContext();
  const startDateTime = DateTime.fromISO(startDate);
  const startOfMonth = startDateTime.startOf('month');
  const endOfMonth = startDateTime.endOf('month');

  const tabIdToFinancesTurnoverGroupEnum: Record<
    StatisticsDetailedCategoryExtEnumType,
    FinancesTurnoverGroupEnum
  > = {
    [StatisticsDetailedCategoryExtEnum.LiveChat]:
      FinancesTurnoverGroupEnum.Chat,
    [StatisticsDetailedCategoryExtEnum.Messenger]:
      FinancesTurnoverGroupEnum.Messenger,
    [StatisticsDetailedCategoryExtEnum.Feed]: FinancesTurnoverGroupEnum.Feed,
    [StatisticsDetailedCategoryExtEnum.VideoLibrary]:
      FinancesTurnoverGroupEnum.Shop,
    [StatisticsDetailedCategoryExtEnum.Misc]:
      FinancesTurnoverGroupEnum.Diverses,
    [StatisticsDetailedCategoryExtEnum.PhoneService]:
      FinancesTurnoverGroupEnum.Service0900,
    [StatisticsDetailedCategoryExtEnum.Telegram]:
      FinancesTurnoverGroupEnum.Telegram,
    //OverAll & Affiliate dont have a userlist yet -> skipRequestWhen
    [StatisticsDetailedCategoryExtEnum.OverAll]: FinancesTurnoverGroupEnum.Chat,
    [StatisticsDetailedCategoryExtEnum.Affiliate]:
      FinancesTurnoverGroupEnum.Chat,
  };

  const loadMorePurchases = 20;

  const turnoverGroup = tabId
    ? tabIdToFinancesTurnoverGroupEnum[
        tabId as StatisticsDetailedCategoryExtEnumType
      ]
    : FinancesTurnoverGroupEnum.Chat;

  const skipRequestWhen =
    !tabId ||
    tabId === StatisticsDetailedCategoryExtEnum.OverAll ||
    tabId === StatisticsDetailedCategoryExtEnum.Affiliate;

  const {
    data: userListData,
    loading: userListLoading,
    error: userListError,
    fetchMore,
  } = useGetUserListPurchasesQuery({
    variables: {
      startDate: startOfMonth,
      endDate: endOfMonth,
      turnoverGroup: turnoverGroup,
      offset: 0,
      limit: loadMorePurchases,
    },
    skip: skipRequestWhen,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const handleLoadMore = useCallback(() => {
    if (skipRequestWhen) return;

    if (
      userListData &&
      userListData?.finances.userListsOfPurchases?.length === 0
    )
      return;

    //now we know there must be more purchases to fetch
    fetchMore({
      variables: {
        offset: userListData?.finances?.userListsOfPurchases?.length ?? 0,
        limit: loadMorePurchases,
      },
    });
  }, [fetchMore, skipRequestWhen, userListData]);

  const userList = React.useMemo(() => {
    return userListData?.finances.userListsOfPurchases ?? [];
  }, [userListData?.finances.userListsOfPurchases]);

  const value = {
    data: userList,
    loading: userListLoading,
    error: userListError,
    handleLoadMore,
  };

  return (
    <UserListContext.Provider value={value}>
      {children}
    </UserListContext.Provider>
  );
};
