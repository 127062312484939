import {
  Section,
  SectionBody,
  SectionDescription,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { Momentum } from '@campoint/odi-ui-icons';
import { Button, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { ScrollToTargetInline } from '../../../../components/Layout/ScrollToTargetInline';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../../components/Layout/UrlFragmentScrollToTarget';
import { ClearableInputControl } from '../../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import {
  useSaveSocialMediaMutation,
  useSocialMediaSectionQuery,
} from '../../../../generated/graphql';
import { useNavigationBlock } from '../../../../provider/NavigationBlockProvider';
import LoadingPage from '../../../LoadingPage/LoadingPage';

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const fieldName = {
  facebook: 'facebook',
  instagram: 'instagram',
  twitter: 'twitter',
  tiktok: 'tiktok',
  youtube: 'youtube',
  whatsapp: 'whatsapp',
} as const;

interface FormValues {
  [fieldName.facebook]: string | null;
  [fieldName.instagram]: string | null;
  [fieldName.twitter]: string | null;
  [fieldName.tiktok]: string | null;
  [fieldName.youtube]: string | null;
  [fieldName.whatsapp]: string | null;
}

export const SocialLinksSection: React.FC = () => {
  const { t } = useTranslation(['accountPage', 'general']);
  const { loading, error, data } = useSocialMediaSectionQuery();
  const initialValues = React.useMemo<FormValues>(() => {
    const whatsapp = data?.account.socialLinks.whatsapp;
    return {
      [fieldName.facebook]: data?.account.socialLinks.facebook ?? '',
      [fieldName.instagram]: data?.account.socialLinks.instagram ?? '',
      [fieldName.twitter]: data?.account.socialLinks.twitter ?? '',
      [fieldName.tiktok]: data?.account.socialLinks.tiktok ?? '',
      [fieldName.youtube]: data?.account.socialLinks.youtube ?? '',
      [fieldName.whatsapp]: whatsapp
        ? whatsapp.country + ' ' + whatsapp.fullPhoneNumber
        : '',
    };
  }, [data]);

  const [saveSocialMedia, { loading: saveLoading }] =
    useSaveSocialMediaMutation();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        [fieldName.whatsapp]: Yup.string()
          .matches(phoneRegExp, t('accountPage:error.format'))
          .nullable()
          .transform((value) => (value === '' ? null : value)),
      }),
    [t]
  );

  const hookForm = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  React.useEffect(() => {
    hookForm.reset(initialValues);
  }, [hookForm, initialValues]);

  const formTouchedFields =
    Object.keys(hookForm.formState.touchedFields).length > 0;
  const isDisabled =
    (!hookForm.formState.isValid && !formTouchedFields) ||
    (hookForm.formState.isValid && !hookForm.formState.isDirty);

  const {
    action: { registerDirtyFlag },
  } = useNavigationBlock();
  const onOmitChanges = React.useCallback(() => {
    hookForm.reset();
  }, [hookForm]);
  React.useEffect(() => {
    return registerDirtyFlag(hookForm.formState.isDirty, onOmitChanges);
  }, [
    hookForm,
    hookForm.formState,
    hookForm.formState.isDirty,
    onOmitChanges,
    registerDirtyFlag,
  ]);

  const handleSubmit = async (data: any) => {
    const result = await saveSocialMedia({
      variables: {
        data: {
          facebook: data[fieldName.facebook],
          instagram: data[fieldName.instagram],
          twitter: data[fieldName.twitter],
          tiktok: data[fieldName.tiktok],
          youtube: data[fieldName.youtube],
          whatsapp: data[fieldName.whatsapp],
        },
      },
    });
    if (result.data?.account?.socialLinks?.update?.success === false) {
      issueChakraToast({
        status: 'error',
        description: result.data?.account?.socialLinks?.update?.message,
      });
    } else {
      issueChakraToast({
        status: 'success',
        description: t('accountPage:toast.SocialMediaErfolgreichGespeichert'),
      });
      hookForm.reset(data);
    }
  };

  return (
    <Section>
      <ScrollToTargetInline id={UrlFragment.SocialLinksSection} />
      <SectionHeader>
        <SectionCenterContainer>
          <SectionTitleRow>
            <SectionIcon as={Momentum} />
            <SectionTitle children={t('accountPage:headline.SocialMedia')} />
          </SectionTitleRow>
          <SectionDescription>
            {t('accountPage:text.HastDuReichweiteAufSocialMedia')}
          </SectionDescription>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider isWidthRestricted />
      <FormProvider {...hookForm}>
        <form
          onSubmit={(e) => {
            hookForm.handleSubmit(handleSubmit)(e);
          }}
        >
          <SectionBody>
            <SectionCenterContainer>
              {(loading || error) && <LoadingPage />}
              {!loading && (
                <VStack spacing={6}>
                  <ClearableInputControl
                    name={fieldName.facebook}
                    label={t('accountPage:label.Facebook')}
                    placeholder={t(
                      'accountPage:placeholder.FacebookLinkEinfugen'
                    )}
                  />
                  <ClearableInputControl
                    name={fieldName.instagram}
                    label={t('accountPage:label.Instagram')}
                    placeholder={t(
                      'accountPage:placeholder.InstagramLinkEinfugen'
                    )}
                  />
                  <ClearableInputControl
                    name={fieldName.twitter}
                    label={t('accountPage:label.Twitter')}
                    placeholder={t(
                      'accountPage:placeholder.TwitterLinkEinfugen'
                    )}
                  />
                  <ClearableInputControl
                    name={fieldName.tiktok}
                    label={t('accountPage:label.TikTok')}
                    placeholder={t(
                      'accountPage:placeholder.TikTokLinkEinfugen'
                    )}
                  />
                  <ClearableInputControl
                    name={fieldName.youtube}
                    label={t('accountPage:label.YouTube')}
                    placeholder={t(
                      'accountPage:placeholder.YouTubeLinkEinfugen'
                    )}
                  />
                  <ClearableInputControl
                    name={fieldName.whatsapp}
                    label={t('accountPage:label.WhatsApp')}
                    placeholder={t(
                      'accountPage:placeholder.WhatsAppNummerEinfugen'
                    )}
                    type="tel"
                    autoComplete="tel"
                  />

                  <Button
                    alignSelf={'center'}
                    children={t('accountPage:button.AnderungSpeichern')}
                    variant={'solid'}
                    type="submit"
                    isDisabled={isDisabled}
                    isLoading={saveLoading}
                  />
                </VStack>
              )}
            </SectionCenterContainer>
          </SectionBody>
        </form>
      </FormProvider>
    </Section>
  );
};
