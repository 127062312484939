import * as icon from '@campoint/odi-ui-icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  AspectRatio,
  AspectRatioProps,
  Box,
  BoxProps,
  Button,
  Divider,
  HStack,
  Heading,
  HeadingProps,
  Icon,
  IconButton,
  Spinner,
  Text,
  VStack,
  Wrap,
  WrapItem,
  chakra,
  useDisclosure,
} from '@chakra-ui/react';
import { useFeature } from 'flagged';
import { FormikProvider, useFormik } from 'formik';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import backgroundShape from '../../../assets/images/feed/background.svg';
import { FeatureName } from '../../../flags';
import { FskEnum, ModelPostTypesEnum } from '../../../generated/feed';
import useEventListener from '../../../hooks/useEventListener';
import { useFeedPostSchedulableTimeframe } from '../../../hooks/useFeedPostSchedulableTimeframe';
import useIsMounted from '../../../hooks/useIsMounted';
import { useCreateFeedPostContext } from '../../../provider/FeedPostCreateModalProvider';
import { useFlagsProviderV2 } from '../../../provider/FlagsProviderV2';
import {
  MediaInputProvider,
  useMediaInput,
} from '../../../provider/MediaInputProvider';
import {
  MediaPropertiesProvider,
  useMediaProperties,
} from '../../../provider/MediaPropertiesProvider';
import {
  MediaContext,
  MediaProvider,
  useMedia,
} from '../../../provider/MediaProvider';
import Logger from '../../../utils/Logger';
import { coinsToEuro } from '../../../utils/coinsToEuro';
import { getFormattedAmount } from '../../../utils/formattedAmount';
import { isBlobImage, isBlobUnknown, isBlobVideo } from '../../../utils/media';
import { loadVideoMetaData } from '../../../utils/media/mediaMetaData';
import {
  createDateValidationSchema,
  createStringValidationSchema,
} from '../../../utils/validation';
import { AbsoluteFullCenter } from '../../Layout/AbsoluteFullCenter';
import { RelativeBox } from '../../Layout/RelativeBox/RelativeBox';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyFooterBox,
  ResponsiveModalStickyHeaderBox,
} from '../../Layout/ResponsiveModal';
import { DialogModal } from '../DialogModal/DialogModal';
import { PriceSliderParent } from '../FormElements/PriceSlider/PriceSlider';
import SwitchInlineControl from '../FormElements/SwitchInlineControl/SwitchInlineControl';
import {
  InputControl,
  SelectControl,
  TextareaControl,
} from '../FormikFormElements';
import { MediaPickerBox } from '../MediaPickerButton/MediaPickerButton';
import {
  MediaStencilOverlay,
  MediaUploadCropperPortal,
} from '../MediaUpload/MediaUpload';
import VideoLengthFromSeconds from '../VideoLengthFromSeconds/VideoLengthFromSeconds';

export enum contentType {
  Free = 'free',
  PaidContent = 'paidContent',
  TippingGoal = 'tippingGoal',
}

const fieldName = {
  title: 'title',
  description: 'description',
  postShouldBeSold: 'postShouldBeSold',
  postPrice: 'postPrice',
  postShouldBePinned: 'postShouldBePinned',
  postIsGRated: 'postIsGRated',
  postIsScheduled: 'postIsScheduled',
  publicationScheduledFor: 'publicationScheduledFor',
  contentType: 'contentType',
  tippingGoal: 'tippingGoal',
} as const;

const VideoMetadataLoader: React.FC<{
  isVideoAllowed: boolean;
  onUnallowedVideoPick?: () => void;
}> = ({ isVideoAllowed, onUnallowedVideoPick }) => {
  const {
    action: { setReplacement },
  } = useMedia();
  const { mediaFile, clearMediaFile } = useMediaInput();
  const isMounted = useIsMounted();

  React.useEffect(() => {
    if (!mediaFile?.originalFile) {
      return;
    }

    const isFileTypeUnkown = mediaFile.originalFile.type === '';
    if (!isBlobVideo(mediaFile.originalFile) && !isFileTypeUnkown) {
      return;
    }

    if (!isVideoAllowed) {
      onUnallowedVideoPick?.();
      clearMediaFile();
      return;
    }

    const src = mediaFile.originalSrc;
    const blob = mediaFile.originalFile;

    loadVideoMetaData(mediaFile.originalFile)
      .then((result) => {
        if (isMounted()) {
          setReplacement({
            src,
            blob,
            filename: mediaFile?.originalFile?.name ?? null,
            duration: result?.duration,
            thumbnail: result?.thumbnail.blob ?? null,
            key: uuidv4(),
          });
        }
      })
      .catch((e) => {
        Logger.log(
          "Couldn't get video metadata, probably video type unknown",
          e
        );

        //still accept video but no duration / thumbnail available
        const exBlob = new File(
          [blob],
          mediaFile?.originalFile?.name ?? blob.name,
          {
            type: 'application/octet-stream',
          }
        );

        setReplacement({
          src,
          blob: isFileTypeUnkown ? exBlob : blob,
          filename: mediaFile?.originalFile?.name ?? null,
          duration: 0,
          key: uuidv4(),
          noMetadata: true,
        });

        //we need this so unknown types dont get added multiple times
        //todo find out why this is happening
        clearMediaFile();
      });
  }, [
    isMounted,
    mediaFile,
    setReplacement,
    isVideoAllowed,
    clearMediaFile,
    onUnallowedVideoPick,
  ]);

  return null;
};

const ErrorImage: React.FC<{
  setHasWrongDimension: (error: boolean) => void;
  resetAllErrors: () => void;
}> = ({ setHasWrongDimension, resetAllErrors }) => {
  const mediaInputCtx = useMediaInput();
  const { error } = mediaInputCtx;
  React.useEffect(() => {
    if (error) {
      resetAllErrors();
    }
    setHasWrongDimension(error);
  }, [error, resetAllErrors, setHasWrongDimension]);
  return null;
};

export const MediaFilePicked: React.FC<{
  onMedia: (media: MediaContext['media']) => void;
  children?: React.ReactNode;
}> = ({ onMedia, children }) => {
  const { hasReplacement, media, action } = useMedia();
  React.useEffect(() => {
    if (hasReplacement) {
      onMedia(media);
      action.clearReplacement();
    }
  }, [hasReplacement, media, action, onMedia]);

  return <>{children}</>;
};

const PostMediaAspectBox: React.FC<AspectRatioProps> = (props) => {
  const { aspectRatio } = useMediaProperties();
  return (
    <AspectRatio
      sx={{
        '--max-height': { base: '10rem', lg: '20rem' },
      }}
      ratio={aspectRatio}
      maxW={`calc(var(--max-height) * ${aspectRatio})`}
      w={'70px'}
      maxH={'var(--max-height)'}
      {...props}
    />
  );
};

export const PostMediaPreviewBox: React.FC<{
  media: MediaContext['media'];
  isInvalid?: boolean;
  onClear: () => void;
}> = (props) => {
  const { t } = useTranslation(['feed']);
  const { placeholderSrc } = useMediaProperties();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isVideo =
    isBlobVideo(props.media.blob) || isBlobUnknown(props.media.blob);

  const src = React.useMemo(() => {
    const fallbackSrc =
      !props.media?.blob || props.media.noMetadata
        ? placeholderSrc
        : URL.createObjectURL(props.media.blob);
    return props.media.thumbnail
      ? URL.createObjectURL(props.media.thumbnail)
      : fallbackSrc;
  }, [props.media, placeholderSrc]);

  return (
    <PostMediaAspectBox>
      <RelativeBox
        attachment={
          <>
            <IconButton
              size={'xs'}
              position={'absolute'}
              top={'1px'}
              right={'1px'}
              color={'whiteAlpha.900'}
              filter={'drop-shadow(0 0 2px rgba(0, 0, 0, 0.6))'}
              variant={'angel'}
              aria-label={t('feed:button.Entfernen')}
              onClick={!props.isInvalid ? onOpen : props.onClear}
              icon={<Icon as={icon.Close} boxSize={'icon.md'} />}
            />
            {!props.media?.duration ? null : (
              <Box position={'absolute'} bottom={'1px'} right={'4px'}>
                <VideoLengthFromSeconds
                  durationInSeconds={props.media.duration}
                />
              </Box>
            )}
          </>
        }
      >
        <Box
          w={'full'}
          h={'full'}
          backgroundImage={`url(${src})`}
          backgroundSize={'contain'}
          backgroundPosition={'center'}
          backgroundRepeat={'no-repeat'}
          backgroundColor={'#000'}
        />
        <DialogModal
          isOpen={isOpen}
          onConfirm={props.onClear}
          onClose={onClose}
          headline={
            isVideo
              ? t('feed:heading.MochtestDuDenClipWirklichEntfernen')
              : t('feed:heading.MochtestDuDasFotoWirklichEntfernen')
          }
          confirmButtonText={
            isVideo
              ? t('feed:button.ClipEntfernen')
              : t('feed:button.FotoEntfernen')
          }
          closeButtonText={t('feed:button.Abbrechen')}
        />
      </RelativeBox>
    </PostMediaAspectBox>
  );
};

export const PostMediaPickerPreviewBox: React.FC = () => {
  const { hasReplacement } = useMedia();
  const mediaInputCtx = useMediaInput();

  const isVideoBeingProcessed = React.useMemo(() => {
    return (
      !hasReplacement &&
      !!mediaInputCtx.mediaFile &&
      isBlobVideo(mediaInputCtx.mediaFile?.originalFile)
    );
  }, [hasReplacement, mediaInputCtx.mediaFile]);

  return (
    <PostMediaAspectBox>
      <RelativeBox>
        <MediaPickerBox
          position={'absolute'}
          // unfortunatly we can't use inset: '0' here yet
          left={0}
          right={0}
          top={0}
          bottom={0}
          cursor={'pointer'}
          isLoading={isVideoBeingProcessed}
          borderColor={'primary.500'}
          borderWidth={'1px'}
          backgroundColor={'surface'}
        >
          <AbsoluteFullCenter
            children={
              isVideoBeingProcessed ? (
                <Spinner />
              ) : (
                <Icon as={icon.Add} boxSize="icon.md" color={'primary.500'} />
              )
            }
          />
          <MediaStencilOverlay />
        </MediaPickerBox>
      </RelativeBox>
    </PostMediaAspectBox>
  );
};

const ContentHeaderHeading: React.FC<HeadingProps> = (props) => (
  <Heading size={'sm'} {...props} />
);

const ContentHeader: React.FC<
  {
    type: ModelPostTypesEnum | null;
    selectedMediaCount: number;
    showAsError?: boolean;
  } & BoxProps
> = ({ type, selectedMediaCount = 0, showAsError = false, ...boxProps }) => {
  const { t } = useTranslation(['feed']);
  if (showAsError) {
    return (
      <Box {...boxProps}>
        <ContentHeaderHeading color={'error.500'}>
          <HStack spacing={'1'}>
            <Icon as={icon.Warning} boxSize={'icon.sm'} />
            <span>{t('feed:heading.Fehler')}</span>
          </HStack>
        </ContentHeaderHeading>
      </Box>
    );
  }

  if (selectedMediaCount > 0) {
    return (
      <Box {...boxProps}>
        <ContentHeaderHeading>
          <HStack spacing={'1'}>
            <Icon as={icon.CheckCircle} boxSize={'icon.sm'} />
            <span>{t('feed:heading.ContentHinzugefugt')}</span>
            <chakra.span aria-hidden>{`(${selectedMediaCount.toFixed()}/${
              type === ModelPostTypesEnum.Clip ? 1 : 10
            })`}</chakra.span>
          </HStack>
        </ContentHeaderHeading>
      </Box>
    );
  }

  return (
    <Box {...boxProps}>
      <ContentHeaderHeading children={t('feed:heading.ContentHinzufugen')} />
      <Text
        color={'gray.500'}
        children={t('feed:text.LadeEinFotoOderEinenKurzenClip005230MinHoch')}
      />
    </Box>
  );
};

const CLIP_MIN_DURATION_IN_SECONDS = 5 as const;
const CLIP_MAX_DURATION_IN_SECONDS = 150 as const;

export const FeedPostCreateModal = () => {
  const { coinsEnabled: coinsEnabledFlag } = useFlagsProviderV2();
  const ctx = useCreateFeedPostContext();
  const { t } = useTranslation(['feed']);
  const locale = useIntl().locale;
  const [selectedMedia, setSelectedMedia] = React.useState<
    MediaContext['media'][]
  >([]);

  const scheduableTimeframe = useFeedPostSchedulableTimeframe();

  // todo: remove and refactor later to a new ErrorContextProvider
  // error handling
  const [hasWrongDimension, setHasWrongDimension] = React.useState(false);
  const [hasWrongDuration, setWrongDuration] = React.useState(false);
  const [hasTriedToMixMedia, setHasTriedToMixMedia] = React.useState(false);
  const [, setClipIsTooLong] = React.useState(false);
  const [isClipTooShort, setClipIsTooShort] = React.useState(false);

  const resetAllErrors = React.useCallback(() => {
    setHasWrongDimension(false);
    setWrongDuration(false);
    setClipIsTooLong(false);
    setClipIsTooShort(false);
    setHasTriedToMixMedia(false);
  }, []);

  const type = React.useMemo(() => {
    if (selectedMedia.length <= 0) {
      return ModelPostTypesEnum.Text;
    }

    if (
      selectedMedia.length === 1 &&
      (isBlobVideo(selectedMedia[0]?.blob) ||
        isBlobUnknown(selectedMedia[0]?.blob))
    ) {
      return ModelPostTypesEnum.Clip;
    }

    if (
      selectedMedia.length >= 1 &&
      selectedMedia.length <= 10 &&
      selectedMedia.every(
        (entry) => entry?.blob?.type && isBlobImage(entry.blob)
      )
    ) {
      return ModelPostTypesEnum.Photos;
    }

    return null;
  }, [selectedMedia]);

  React.useEffect(() => {
    setHasWrongDimension(false);
    if (
      selectedMedia.length >= 1 &&
      selectedMedia.length <= 10 &&
      selectedMedia.every(
        (entry) => entry?.blob?.type && isBlobImage(entry.blob)
      )
    ) {
      resetAllErrors();
    }
    if (
      selectedMedia.length >= 1 &&
      selectedMedia.every(
        (entry) => entry?.blob?.type && isBlobVideo(entry.blob)
      )
    ) {
      setWrongDuration(false);
    }
    if (!ctx.isOpen) {
      resetAllErrors();
    }
  }, [ctx.isOpen, resetAllErrors, selectedMedia]);

  const [selectedClipIsOutOfRange, selectedClipIsTooShort] =
    React.useMemo(() => {
      if (selectedMedia.length !== 1) {
        return [false, false, false];
      }

      const [firstEntry] = selectedMedia;

      if (!firstEntry || !firstEntry.blob) {
        return [false, false, false];
      }
      if (!isBlobVideo(firstEntry.blob)) {
        return [false, false, false];
      }

      const duration = firstEntry.duration ?? 0;
      const isTooShort = duration < CLIP_MIN_DURATION_IN_SECONDS;
      const isTooLong = duration > CLIP_MAX_DURATION_IN_SECONDS;
      if (firstEntry.noMetadata) return [false, false, false];
      return [isTooShort || isTooLong, isTooShort, isTooLong];
    }, [selectedMedia]);

  // cleanup effect for Clips too long or and too short
  React.useEffect(() => {
    if (selectedClipIsOutOfRange) {
      setSelectedMedia([]);
      setWrongDuration(true);
      setClipIsTooLong(true);
    }
    if (selectedClipIsTooShort) {
      setSelectedMedia([]);
      setWrongDuration(true);
      setClipIsTooShort(true);
    }
  }, [selectedClipIsOutOfRange, selectedClipIsTooShort]);

  const maxLength = 200;
  const initialPostPrice = coinsEnabledFlag
    ? ctx.feedConfig.priceCoins.min ?? 5
    : ctx.feedConfig.price.min ?? 1;
  const initalPostTippingGoalPrice = coinsEnabledFlag
    ? ctx.feedConfig.tippingGoalCoins.min ?? 10
    : ctx.feedConfig.tippingGoal.min ?? 5;

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      [fieldName.title]: createStringValidationSchema({
        isOptional: true,
        maxLength,
      }),
      [fieldName.description]: createStringValidationSchema({
        isOptional: true,
        maxLength,
      }),
      [fieldName.publicationScheduledFor]: createDateValidationSchema({
        isOptional: false,
        minDate: scheduableTimeframe.min,
        maxDate: scheduableTimeframe.max,
      }).when([fieldName.postIsScheduled], (postIsScheduled, schema) =>
        !postIsScheduled ? Yup.date() : schema
      ),
    }),
    initialValues: {
      [fieldName.title]: '',
      [fieldName.description]: '',
      [fieldName.postShouldBePinned]: false,
      [fieldName.postIsGRated]: false,
      [fieldName.postShouldBeSold]: false,
      [fieldName.postIsScheduled]: false,
      [fieldName.publicationScheduledFor]: scheduableTimeframe.min,
      [fieldName.postPrice]: initialPostPrice,
      [fieldName.contentType]: contentType.Free,
      [fieldName.tippingGoal]: initalPostTippingGoalPrice,
    },
    onSubmit: async (values, formikHelpers) => {
      if (!type) {
        return;
      }

      const publicationScheduledFor = !values[fieldName.postIsScheduled]
        ? null
        : DateTime.fromISO(values[fieldName.publicationScheduledFor]).toISO({
            includeOffset: true,
          }) || null;

      const shared = {
        title: values[fieldName.title],
        text: values[fieldName.description],
        fsk: values[fieldName.postIsGRated] ? FskEnum.Fsk18 : FskEnum.Fsk16,
        pinned: values[fieldName.postShouldBePinned],
        price:
          values[fieldName.postShouldBeSold] ||
          (values[fieldName.contentType] === contentType.PaidContent &&
            !coinsEnabledFlag)
            ? values[fieldName.postPrice]
            : null,
        priceCoins:
          values[fieldName.postShouldBeSold] ||
          (values[fieldName.contentType] === contentType.PaidContent &&
            coinsEnabledFlag)
            ? values[fieldName.postPrice]
            : null,
        publicationScheduledFor,
        tippingGoal:
          values[fieldName.contentType] === contentType.TippingGoal &&
          !coinsEnabledFlag
            ? values[fieldName.tippingGoal]
            : null,
        tippingGoalCoins:
          values[fieldName.contentType] === contentType.TippingGoal &&
          coinsEnabledFlag
            ? values[fieldName.tippingGoal]
            : null,
      };

      try {
        switch (type) {
          case ModelPostTypesEnum.Text:
            await ctx.action.createPost({
              type,
              ...shared,
            });
            break;
          case ModelPostTypesEnum.Photos:
            await ctx.action.createPost({
              type,
              ...shared,
              thumbnail: selectedMedia[0].blob,
              photos: selectedMedia
                .map((entry) => entry.blob)
                .filter((e) => !!e) as Blob[],
            });
            break;
          case ModelPostTypesEnum.Clip:
            if (!selectedMedia[0].filename) return;
            await ctx.action.createPost({
              type,
              ...shared,
              thumbnail: selectedMedia[0].thumbnail,
              clip: selectedMedia[0].blob as Blob,
              clipName: selectedMedia[0].filename,
            });
            break;
          default:
            formikHelpers.setSubmitting(false);
            return;
        }
      } catch (error) {
        Logger.error(error);
        formikHelpers.setSubmitting(false);
      }
    },
  });

  const handleBlur = (event: Event) => {
    if (event.target !== document.activeElement && document.activeElement) {
      const target = document.activeElement as HTMLFormElement;
      target.blur();
    }
  };

  useEventListener('touchmove', handleBlur);

  const { resetForm } = formik;
  const resetState = React.useCallback(() => {
    setSelectedMedia([]);
    resetForm();
  }, [resetForm, setSelectedMedia]);

  React.useEffect(
    () => ctx.action.registerOnReset(resetState),
    [resetState, ctx.action]
  );

  const isDirtyUpload = formik.dirty || selectedMedia.length > 0;
  const hasMediaOrTextForTextOnlyPost =
    selectedMedia.length > 0 ||
    formik.values[fieldName.title].length > 0 ||
    formik.values[fieldName.description].length > 0;

  const paidContentFlag = useFeature(FeatureName.feedPaidContent);
  const feedPostSchedulingFlag = useFeature(FeatureName.feedPostScheduling);
  const feedTippingGoalFlag = useFeature(FeatureName.feedTippingGoal);

  const { setValues, setFieldValue, values } = formik;
  // Reset price, pinned, gRated when there is no content anymore
  React.useEffect(() => {
    if (selectedMedia.length < 1) {
      setValues((values) => ({
        ...values,
        postIsGRated: false,
        postShouldBePinned: false,
        postShouldBeSold: false,
        contentType: contentType.Free,
      }));
    }
  }, [setValues, selectedMedia]);

  // Reset price when post should not be sold or has no tipping goal
  React.useEffect(() => {
    if (
      !values.postShouldBeSold ||
      values.contentType !== contentType.PaidContent
    ) {
      setFieldValue('postPrice', initialPostPrice);
    }

    if (values.contentType !== contentType.TippingGoal) {
      setFieldValue('tippingGoal', initalPostTippingGoalPrice);
    }
  }, [
    setFieldValue,
    values.postShouldBeSold,
    values.contentType,
    initialPostPrice,
    initalPostTippingGoalPrice,
  ]);

  const spacing = { base: 4, lg: 8 };

  return (
    <ResponsiveModal
      isOpen={ctx.isOpen}
      onClose={ctx.action.closeModal}
      preferredSize="xl"
      isVCentered={false}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent
        maxW={'567px'}
        bgColor={'surface'}
        bgImage={`url(${backgroundShape})`}
        bgPos={'top center'}
        bgSize={'auto 255px'}
        bgRepeat={'no-repeat'}
        color={'coldGray.900'}
      >
        <ResponsiveModalStickyHeaderBox bg={'surface'}>
          <Heading
            as="h1"
            children={t('feed:heading.WasMochtestDuPosten')}
            size={'sm'}
          />
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        <ResponsiveModalBodyBox bg={'transparent'}>
          <FormikProvider value={formik}>
            <VStack alignItems={'stretch'}>
              <VStack spacing={3} alignItems={'stretch'} mb={4}>
                {(hasWrongDimension ||
                  hasWrongDuration ||
                  hasTriedToMixMedia) && (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription fontSize="sm" fontWeight="semibold">
                      {hasTriedToMixMedia ? (
                        <>
                          {t(
                            'feed:error.EsIstNichtMoglichEinVideoUndBilderInEinemBeitragHochzuladen'
                          )}
                        </>
                      ) : hasWrongDimension ? (
                        <>{t('feed:error.PictureFalscheAufloesung')}</>
                      ) : (
                        <>
                          <span>
                            {isClipTooShort
                              ? t('feed:error.VideoZuKurz')
                              : t('feed:error.VideoZuLang')}
                          </span>
                          <span>{t('feed:error.ErlaubtSind005230Min')}</span>
                        </>
                      )}
                    </AlertDescription>
                  </Alert>
                )}
                <ContentHeader
                  type={type}
                  selectedMediaCount={selectedMedia.length}
                />
                <HStack>
                  <Wrap direction={'row'} flexWrap={'wrap'} spacing={'4'}>
                    <MediaPropertiesProvider.ForFeed
                      enforceMinimalDimension={true}
                    >
                      {selectedMedia.length < 1
                        ? null
                        : selectedMedia.map((media) => {
                            return (
                              <WrapItem key={media.key}>
                                <PostMediaPreviewBox
                                  media={media}
                                  isInvalid={selectedClipIsOutOfRange}
                                  onClear={() => {
                                    setSelectedMedia((prevState) =>
                                      prevState.filter(
                                        (prevMedia) =>
                                          prevMedia.key !== media.key
                                      )
                                    );
                                    setHasTriedToMixMedia(false);
                                    setHasWrongDimension(false);
                                    setWrongDuration(false);
                                  }}
                                />
                              </WrapItem>
                            );
                          })}
                      {selectedMedia.length < 10 &&
                      type !== ModelPostTypesEnum.Clip ? (
                        <MediaProvider>
                          <MediaFilePicked
                            onMedia={(m) => {
                              setHasTriedToMixMedia(false);
                              setSelectedMedia((prevState) => [
                                ...prevState,
                                m,
                              ]);
                            }}
                          >
                            <WrapItem>
                              <MediaInputProvider
                                issueWrongDimensionToast={false}
                                accept={
                                  selectedMedia.length < 1
                                    ? 'DEFAULT_FOR_PICTURE_AND_VIDEO'
                                    : 'DEFAULT_FOR_PICTURE'
                                }
                                capture={'user'}
                                onUnacceptedFileType={() => {
                                  setHasTriedToMixMedia(true);
                                }}
                              >
                                <PostMediaPickerPreviewBox />
                                <MediaUploadCropperPortal />
                                <VideoMetadataLoader
                                  isVideoAllowed={selectedMedia.length < 1}
                                  onUnallowedVideoPick={() => {
                                    setHasTriedToMixMedia(true);
                                  }}
                                />
                                <ErrorImage
                                  setHasWrongDimension={setHasWrongDimension}
                                  resetAllErrors={resetAllErrors}
                                />
                              </MediaInputProvider>
                            </WrapItem>
                          </MediaFilePicked>
                        </MediaProvider>
                      ) : null}
                    </MediaPropertiesProvider.ForFeed>
                  </Wrap>
                </HStack>
              </VStack>
              <Box mt={8}>
                <TextareaControl
                  maxCharacterCount={maxLength}
                  name={fieldName.title}
                  textareaProps={{
                    rows: 1,
                    placeholder: t('feed:placeholder.GibEinenTitelEin'),
                  }}
                  label={t('feed:label.Titel')}
                  minH={'unset'}
                />
              </Box>
              <Box pb={4}>
                <TextareaControl
                  maxCharacterCount={maxLength}
                  name={fieldName.description}
                  textareaProps={{
                    placeholder: t(
                      'feed:placeholder.ErzahleEtwasUberDeinenBeitrag'
                    ),
                  }}
                  label={t('feed:label.Beschreibung')}
                />
              </Box>
              {feedTippingGoalFlag && (
                <>
                  <SelectControl
                    isDisabled={selectedMedia.length <= 0}
                    name={fieldName.contentType}
                    optionListProps={{
                      options: [
                        {
                          label: t('feed:label.Kostenlos'),
                          value: contentType.Free,
                        },
                        {
                          label: t('feed:label.ContentVerkaufen'),
                          value: contentType.PaidContent,
                        },
                        {
                          label: t('feed:label.TippingGoal'),
                          value: contentType.TippingGoal,
                        },
                      ],
                    }}
                  />

                  {/* A negative margin top is used to counteract the
                    existing reserved error space of the select control */}
                  <Box px={'4'} mt={'-2'}>
                    {formik.getFieldProps(fieldName.contentType).value ===
                      contentType.PaidContent && (
                      <>
                        <PriceSliderParent
                          name={fieldName.postPrice}
                          steps={
                            coinsEnabledFlag
                              ? ctx.feedConfig.priceCoins.steps
                              : ctx.feedConfig.price.steps
                          }
                          isCoinPost={coinsEnabledFlag}
                        />
                        {coinsEnabledFlag && (
                          <Text
                            fontSize={'12px'}
                            lineHeight={'16px'}
                            color={'darkSteel'}
                            fontWeight={'light'}
                            pb={4}
                          >
                            <Trans
                              t={t}
                              i18nKey={'feed:text.EntsprichtCaXEuro'}
                              components={{
                                price: (
                                  <chakra.span fontWeight={'medium'}>
                                    {`${getFormattedAmount(
                                      coinsToEuro({
                                        coins: Number(
                                          formik.values['postPrice']
                                        ),
                                      }),
                                      locale
                                    )}`}
                                  </chakra.span>
                                ),
                              }}
                            />
                          </Text>
                        )}
                      </>
                    )}

                    {formik.getFieldProps(fieldName.contentType).value ===
                      contentType.TippingGoal && (
                      <>
                        {/* <Heading
                          mb={'1'}
                          fontWeight={'normal'}
                          fontSize={'md'}
                          lineHeight={'6'}
                          color={'coldGray.900'}
                          children={t('feed:label.TippingGoal')}
                        />
                        <Text
                          fontSize={'sm'}
                          lineHeight={'6'}
                          color={'gray.500'}
                          children={t('feed:switch.tippingGoal')}
                        /> */}
                        <PriceSliderParent
                          name={fieldName.tippingGoal}
                          steps={
                            coinsEnabledFlag
                              ? ctx.feedConfig.tippingGoalCoins.steps
                              : ctx.feedConfig.tippingGoal.steps
                          }
                          isCoinPost={coinsEnabledFlag}
                        />
                        {coinsEnabledFlag && (
                          <Text
                            fontSize={'12px'}
                            lineHeight={'16px'}
                            color={'darkSteel'}
                            fontWeight={'light'}
                            pb={4}
                          >
                            <Trans
                              t={t}
                              i18nKey={'feed:text.EntsprichtCaXEuro'}
                              components={{
                                price: (
                                  <chakra.span fontWeight={'medium'}>
                                    {`${getFormattedAmount(
                                      coinsToEuro({
                                        coins: Number(
                                          formik.values['tippingGoal']
                                        ),
                                      }),
                                      locale
                                    )}`}
                                  </chakra.span>
                                ),
                              }}
                            />
                          </Text>
                        )}
                        <Box pt={spacing} pb={4} w={'full'}>
                          <Alert
                            maxW={'container.xl'}
                            bg={'steel'}
                            borderColor={'caribbeanGreen.500'}
                            color={'caribbeanGreen.500'}
                            mx={'auto'}
                          >
                            <AlertIcon
                              as={icon.PrivacyTip}
                              color={'caribbeanGreen.500'}
                              alignSelf={'start'}
                            />
                            <AlertTitle
                              color={'caribbeanGreen.500'}
                              fontWeight={'medium'}
                            >
                              {t('feed:switch.tippingGoal')}
                            </AlertTitle>
                          </Alert>
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              )}
              {!feedTippingGoalFlag && paidContentFlag && (
                <>
                  <Divider />
                  <Box py={2}>
                    <SwitchInlineControl
                      name={fieldName.postShouldBeSold}
                      label={t('feed:label.ContentVerkaufen')}
                      labelProps={{
                        color: 'coldGray.900',
                      }}
                      description={t('feed:switch.paidContent')}
                      switchProps={{
                        isDisabled: selectedMedia.length > 0 ? false : true,
                      }}
                    />
                    {formik.getFieldProps('postShouldBeSold').value && (
                      <>
                        <PriceSliderParent
                          name={fieldName.postPrice}
                          steps={
                            coinsEnabledFlag
                              ? ctx.feedConfig.priceCoins.steps
                              : ctx.feedConfig.price.steps
                          }
                          isCoinPost={coinsEnabledFlag}
                        />
                        {coinsEnabledFlag && (
                          <Text
                            fontSize={'12px'}
                            lineHeight={'16px'}
                            color={'darkSteel'}
                            fontWeight={'light'}
                            pb={4}
                          >
                            <Trans
                              t={t}
                              i18nKey={'feed:text.EntsprichtCaXEuro'}
                              components={{
                                price: (
                                  <chakra.span fontWeight={'medium'}>
                                    {`${getFormattedAmount(
                                      coinsToEuro({
                                        coins: Number(
                                          formik.values['postPrice']
                                        ),
                                      }),
                                      locale
                                    )}`}
                                  </chakra.span>
                                ),
                              }}
                            />
                          </Text>
                        )}
                      </>
                    )}
                  </Box>
                </>
              )}
              <Divider />
              <Box py={2}>
                <SwitchInlineControl
                  name={fieldName.postShouldBePinned}
                  label={t('feed:label.BeitragPinnen')}
                  labelProps={{
                    color: 'coldGray.900',
                  }}
                  description={t('feed:switch.pin')}
                />
              </Box>
              {!feedPostSchedulingFlag ? (
                <></>
              ) : (
                <>
                  <Divider />
                  <Box py={2}>
                    <SwitchInlineControl
                      name={fieldName.postIsScheduled}
                      label={t('feed:label.BeitragPlanen')}
                      labelProps={{
                        color: 'coldGray.900',
                      }}
                      description={t(
                        'feed:text.DieZeitzoneRichtetSichNachDeinenBrowsereinstellungen'
                      )}
                    />
                    {!!formik.values[fieldName.postIsScheduled] && (
                      <InputControl
                        key={scheduableTimeframe.min}
                        name={fieldName.publicationScheduledFor}
                        isReadOnly={false}
                        inputProps={{
                          'aria-label': t(
                            'feed:label.Veroffentlichungzeitpunkt'
                          ),
                          type: 'datetime-local',
                          sx: {
                            '&::-webkit-date-and-time-value': {
                              textAlign: 'left',
                            },
                          },
                          min: scheduableTimeframe.min,
                          max: scheduableTimeframe.max,
                        }}
                      />
                    )}
                  </Box>
                </>
              )}
              <Divider />
              <Box py={2}>
                <SwitchInlineControl
                  name={fieldName.postIsGRated}
                  label={t('feed:label.Als18Kennzeichnen')}
                  labelProps={{
                    color: 'coldGray.900',
                  }}
                  description={t('feed:switch.switch18')}
                />
              </Box>
              <Divider />
            </VStack>
          </FormikProvider>
        </ResponsiveModalBodyBox>
        <ResponsiveModalStickyFooterBox>
          <Button
            variant={'solid'}
            isLoading={
              formik.isSubmitting || ctx.isCreateFeedPostMutationLoading
            }
            isDisabled={
              !formik.isValid ||
              selectedClipIsOutOfRange ||
              !isDirtyUpload ||
              !hasMediaOrTextForTextOnlyPost
            }
            onClick={formik.submitForm}
          >
            {t('feed:button.BeitragPosten')}
          </Button>
        </ResponsiveModalStickyFooterBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
