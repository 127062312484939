import {
  OdiCardDescription,
  OdiCardTitle,
  SubHeader,
  SubHeaderTitle,
} from '@campoint/odi-ui';
import {
  Section,
  SectionBody,
  SectionDescription,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import {
  AccountCircle,
  Check,
  FilterNone,
  Forum,
  Group,
  HowToReg,
  Info,
  LocalActivity,
  Momentum,
  Movie,
  Payments,
  PersonAdd,
  Star,
  Stars,
  Statistics,
  ThumbUp,
  TipsAndUpdates,
  ToyControl,
  TrendingUp,
  VolumeUp,
  VxLive,
  VxModelsLogo,
} from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  Center,
  Checkbox,
  CircularProgress,
  CircularProgressLabel,
  Container,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  ImageProps,
  Link,
  ListItem,
  Text,
  UnorderedList,
  VStack,
  chakra,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import bonusCode from '../../assets/images/featureRequest/affiliate/bonus-codes.svg';
import userReferer from '../../assets/images/featureRequest/affiliate/user-referer.svg';
import vxsocials from '../../assets/images/featureRequest/affiliate/vxsocials.svg';
import videoCall from '../../assets/images/featureRequest/earning/momentum-video-call.svg';
import ticketShow from '../../assets/images/featureRequest/earning/shows.svg';
import toyControl from '../../assets/images/featureRequest/earning/toy-control.svg';
import vxLive from '../../assets/images/featureRequest/earning/vxlive.svg';
import accounting from '../../assets/images/featureRequest/marketing/accounting.svg';
import vipVideos from '../../assets/images/featureRequest/marketing/exclusive-promotion.svg';
import topCam from '../../assets/images/featureRequest/marketing/top-cam-platzierung.svg';
import newsletterVX from '../../assets/images/featureRequest/marketing/vx-newsletter.svg';
import profilePicturePlaceholderFSK16 from '../../assets/images/imageUpload/avatar-fsk16-placeholder.svg';
import { AbsoluteFullCenterLoadingSpinner } from '../../components/Layout/AbsoluteFullCenterLoadingSpinner';
import { issueChakraToast } from '../../components/Layout/ChakraToastContainer';
import { FluentPageLayout } from '../../components/Layout/FluentPageLayout';
import {
  HSnapScrollContainer,
  SnapScrollContainerEnd,
} from '../../components/Layout/HSnapScrollContainer';
import { LayoutHintsVStack } from '../../components/Layout/LayoutHintsVStack';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../components/Layout/ResponsiveModal';
import { SectionDivider } from '../../components/Layout/SectionDivider';
import { ImageCard } from '../../components/shared/ExperienceIntentionImageCard/ExperienceIntentionImageCard';
import { ExperienceIntentionStatusIndicatorIncomeSources } from '../../components/shared/ExperienceIntentionStatusIndicator/ExperienceIntentionStatusIndicator';
import { UpdateTaxInformationExpiredHintSlot } from '../../components/shared/UpdateTaxInformationExpiredHintSlot/UpdateTaxInformationExpiredHintSlot';
import {
  HTipCard,
  ShieldCard,
} from '../../components/shared/cards/TipCard/TipCard';
import {
  ActorFeatureCategoryEnum,
  ActorFeatureFragment,
  ActorFeatureRequirementNumeric,
  ActorFeatureStatusEnum,
  ActorFeatureTypeEnum,
  Adlinks,
  useGetActorFeaturesQuery,
  useGetAutologinVxCashLazyQuery,
  useRequestFeatureMutation,
} from '../../generated/graphql';
import { useActiveApiLanguage } from '../../hooks/useActiveApiLanguage';
import { useBoolean } from '../../hooks/useBoolean';
import { useAuth } from '../../provider/AuthProvider';
import { useVxLiveLink } from '../../provider/VxLiveLinkProvider';
import { externalRoutes } from '../../routes/routesConfig';
import { extractFromUnion } from '../../utils/extractor';

const FeatureRequestPage: React.FC = () => {
  const { t } = useTranslation(['featureRequestPage']);
  const { data, loading } = useGetActorFeaturesQuery();
  const { authUser } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isReferralOpen,
    onOpen: onReferralOpen,
    onClose: onReferralClose,
  } = useDisclosure();
  const [featureType, setFeatureType] = React.useState<
    ActorFeatureTypeEnum | undefined
  >(undefined);

  const profileCollection = extractFromUnion(
    data?.profile?.collection,
    'ProfileCollectionAccountV1'
  );
  const username =
    authUser?.username ??
    profileCollection?.values.accountContactEmailAdress ??
    '';
  const mediaCollection = extractFromUnion(
    data?.profile?.mediaCollection,
    'ProfileCollectionMobileMediaV1'
  );
  const modelPictureFSK16Avatar =
    mediaCollection?.values?.modelPictureFSK16Avatar?.image.src;

  const featureCollection = React.useMemo(() => {
    return data?.actorFeatures?.featureCollection ?? [];
  }, [data?.actorFeatures?.featureCollection]);

  const filterAndSortFeatures = (
    features: Maybe<ActorFeatureFragment>[],
    category: ActorFeatureCategoryEnum
  ): Maybe<ActorFeatureFragment>[] => {
    return features
      .filter((feature) => feature?.category === category)
      .sort((a, b) => {
        if (
          a?.status === ActorFeatureStatusEnum.NotSet &&
          b?.status !== ActorFeatureStatusEnum.NotSet
        ) {
          return -1;
        }
        if (
          a?.status !== ActorFeatureStatusEnum.NotSet &&
          b?.status === ActorFeatureStatusEnum.NotSet
        ) {
          return 1;
        }
        return 0;
      });
  };

  const earningFeatures = filterAndSortFeatures(
    featureCollection,
    ActorFeatureCategoryEnum.EarningPotential
  );
  const affiliateFeatures = filterAndSortFeatures(
    featureCollection,
    ActorFeatureCategoryEnum.Affiliate
  );
  const marketingFeatures = filterAndSortFeatures(
    featureCollection,
    ActorFeatureCategoryEnum.Marketing
  );

  const spacing = { base: 4, lg: 8 };

  const onOpenFeatureRequestModal = (featureType: ActorFeatureTypeEnum) => {
    setFeatureType(featureType);
    onOpen();
  };

  const [requestFeature, { loading: requestFeatureLoading }] =
    useRequestFeatureMutation({ refetchQueries: ['GetActorFeatures'] });

  const onRequestFeature = (featureType: ActorFeatureTypeEnum) => {
    try {
      requestFeature({
        variables: {
          type: featureType,
        },
        refetchQueries: ['GetActorFeatures'],
      }).then(() => {
        issueChakraToast({
          description: t(
            'featureRequestPage:toast.FeatureErfolgreichBeantragt'
          ),
          status: 'success',
        });
      });
    } catch {
      issueChakraToast({
        description: t(
          'featureRequestPage:toast.FeatureKonnteNichtBeantragtWerden'
        ),
        status: 'error',
      });
    } finally {
      onClose();
    }
  };

  const currentFeature = featureCollection.find(
    (feature) => feature?.type === featureType
  );

  return (
    <FluentPageLayout isContentLoading={loading}>
      <Box>
        <SubHeader>
          <Container px={0} maxW={'container.xl'}>
            <SubHeaderTitle p={0}>
              {t('featureRequestPage:heading')}
            </SubHeaderTitle>
          </Container>
        </SubHeader>
        <Container maxW="container.lg" p={0}>
          <LayoutHintsVStack stackProps={{ spacing: 4 }}>
            <UpdateTaxInformationExpiredHintSlot
              outerStackProps={{ pt: spacing }}
            />
            {/* Avatar Section */}
            <FeatureRequestPageAvatarSection
              src={modelPictureFSK16Avatar}
              username={username}
            />
            {/* Sharing Section */}
            {/* Earning potential */}
            <FeatureRequestPageEarningSection
              features={earningFeatures}
              loading={loading}
              onOpen={onOpenFeatureRequestModal}
            />
            {/* Affiliate */}
            <FeatureRequestPageAffiliateSection
              features={affiliateFeatures}
              loading={loading}
              onOpen={onOpenFeatureRequestModal}
              onReferralOpen={onReferralOpen}
            />
            {/* Marketing */}
            <FeatureRequestPageMarketingSection
              features={marketingFeatures}
              loading={loading}
              onOpen={onOpenFeatureRequestModal}
            />
          </LayoutHintsVStack>
        </Container>
      </Box>
      <FeatureRequestModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        feature={currentFeature}
        onRequestFeature={onRequestFeature}
        loading={requestFeatureLoading}
      />
      <FeatureRequestReferralModal
        isOpen={isReferralOpen}
        onClose={onReferralClose}
        adlinks={data?.marketing.adlinks ?? undefined}
      />
    </FluentPageLayout>
  );
};

export default FeatureRequestPage;

const FeatureRequestPageAvatarSection: React.FC<
  ImageProps & { username: string }
> = ({ src, onClick, username, ...rest }) => {
  return (
    <Container maxW={'full'} p={4} gap={4} bgColor={'surface'}>
      <HStack maxW={'full'} alignItems={'flex-end'} gap={'7'} flexGrow={1}>
        <Box flexShrink={0}>
          <Image
            src={src ?? profilePicturePlaceholderFSK16}
            onClick={onClick}
            w={'80px'}
            h={'80px'}
            borderRadius={'full'}
            boxSize={'80px'}
            userSelect={'none'}
            draggable={false}
            {...rest}
          />
        </Box>
        <Flex overflowX={'hidden'} flexGrow={1} alignSelf={'center'}>
          <Heading fontSize={'2xl'} noOfLines={1} wordBreak={'break-word'}>
            {username}
          </Heading>
        </Flex>
      </HStack>
    </Container>
  );
};

const FeatureCard: React.FC<{
  feature: Maybe<ActorFeatureFragment>;
  onOpen: (featureName: ActorFeatureTypeEnum) => void;
  onReferralOpen?: () => void;
}> = ({ feature, onOpen, onReferralOpen }) => {
  const { t } = useTranslation(['featureRequestPage']);
  const {
    actions: { openVxLiveInNewTab },
  } = useVxLiveLink();
  const featureActive = feature?.status === ActorFeatureStatusEnum.Accepted;
  const featureRequested = feature?.status === ActorFeatureStatusEnum.Requested;
  const featureRejected = feature?.status === ActorFeatureStatusEnum.Rejected;
  const featureToBeRequested =
    feature?.isRequestAllowed &&
    feature.status === ActorFeatureStatusEnum.NotSet;

  const featureNameToAction: Record<
    ActorFeatureTypeEnum,
    { actionText: string; action: any } | undefined
  > = {
    [ActorFeatureTypeEnum.ToyControl]: {
      actionText: t('button.Einstellungen'),
      action: () => openVxLiveInNewTab('chats/all/--modal/settings/device'),
    },

    [ActorFeatureTypeEnum.MobileVideocall]: {
      actionText: t('button.ZurApp'),
      action: () =>
        window.open('https://momentumchat.app/?utm_medium=qrcode', '_blank'),
    },
    [ActorFeatureTypeEnum.Vxlive]: {
      actionText: t('button.OnlineGehen'),
      action: () => openVxLiveInNewTab(),
    },
    [ActorFeatureTypeEnum.BonusCodeCreation]: {
      actionText: t('button.Einstellungen'),
      action: () =>
        window.open(
          'https://app.vxmodels.com/camtool/vouchers/create',
          '_blank'
        ),
    },
    [ActorFeatureTypeEnum.TicketShow]: {
      actionText: t('button.OnlineGehen'),
      action: () =>
        openVxLiveInNewTab('chats/all/--modal/settings/ticket-live-show'),
    },
    //TODO implement Modal and open it
    [ActorFeatureTypeEnum.ReferCustomers]: {
      actionText: t('button.ZumLink'),
      action: () => {
        onReferralOpen?.();
      },
    },
    //TODO implement if info is available
    [ActorFeatureTypeEnum.VxsocialPage]: undefined,
    [ActorFeatureTypeEnum.InstagramAdlink]: undefined,
    [ActorFeatureTypeEnum.Accounting]: undefined,
    [ActorFeatureTypeEnum.NewsletterVx]: undefined,
    [ActorFeatureTypeEnum.TopCam]: undefined,
    [ActorFeatureTypeEnum.VipVideos]: undefined,
  };

  return !feature?.type ? (
    <></>
  ) : (
    <ImageCard
      imageProps={{
        src: featureNameToImg[feature?.type] ?? '',
        backgroundColor: 'steel',
        objectFit: 'contain',
        minH: '100px',
      }}
      isLabeledNew={false}
      minH={'320px'}
    >
      <VStack mt={4} alignItems={'center'} spacing={0} w={'full'} h={'full'}>
        {featureActive && (
          <ExperienceIntentionStatusIndicatorIncomeSources status={'active'} />
        )}
        {featureRequested && (
          <ExperienceIntentionStatusIndicatorIncomeSources status={'pending'} />
        )}
        {featureToBeRequested && (
          <ExperienceIntentionStatusIndicatorIncomeSources
            status={'available'}
          />
        )}
        <VStack>
          {feature?.type && (
            <Icon boxSize={'icon.md'} as={featureNameToIcon[feature?.type]} />
          )}
          <Heading mb={2} size={'md'} as={OdiCardTitle} textAlign={'start'}>
            {feature?.title}
          </Heading>
        </VStack>
        <OdiCardDescription justifyContent={'start'} textAlign={'start'}>
          <Text noOfLines={[3]} textAlign={'center'}>
            {feature?.description}
          </Text>
        </OdiCardDescription>
        <HStack
          flex={1}
          alignItems={'flex-end'}
          justifyContent={'center'}
          spacing={'2'}
          w={'full'}
          px={4}
          minH={'40px'}
        >
          {!featureActive && !featureRequested && !featureRejected && (
            <Button
              variant={!feature?.isRequestAllowed ? 'outline' : 'solid'}
              children={'Freischalten'}
              minH={'40px'}
              onClick={() => onOpen(feature?.type)}
            />
          )}
          {featureRequested && (
            <Text
              color={'orange.500'}
              fontWeight={'semibold'}
              fontSize={'md'}
              lineHeight={'24px'}
              minH={'40px'}
            >
              {t('text.InPrufung')}
            </Text>
          )}
          {featureActive && (
            <Button
              variant="link"
              color={'primary.500'}
              minH={'40px'}
              onClick={featureNameToAction[feature?.type]?.action}
              children={featureNameToAction[feature?.type]?.actionText}
            />
          )}
        </HStack>
      </VStack>
    </ImageCard>
  );
};

const FeaturesSelectRow: React.FC<{
  features: Maybe<ActorFeatureFragment>[];
}> = ({ features }) => {
  return (
    <HStack>
      {features.map((feature, index) => {
        const featureActive = feature?.status === 'accepted';
        const borderColor = featureActive ? 'caribbeanGreen.300' : 'steel';
        const bgColor = featureActive ? 'caribbeanGreen.300' : 'none';
        const color = featureActive ? 'caribbeanGreen.500' : 'steel';
        return (
          <Box
            key={index}
            w={'40px'}
            h={'40px'}
            borderRadius={'100px'}
            border={'1px'}
            borderColor={borderColor}
            bgColor={bgColor}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            position={'relative'}
          >
            {feature?.type && (
              <Icon
                as={featureNameToIcon[feature?.type]}
                color={color}
                boxSize={'icon.md'}
              />
            )}
            {featureActive && (
              <Box
                h={'16px'}
                w={'16px'}
                bgColor={'lime.500'}
                borderRadius={'20px'}
                position={'absolute'}
                top={'-4px'}
                right={'-4px'}
              >
                <Center>
                  <Icon as={Check} color={'surface'} />
                </Center>
              </Box>
            )}
          </Box>
        );
      })}
    </HStack>
  );
};

const featureNameToIcon: Partial<Record<ActorFeatureTypeEnum, EmotionIcon>> = {
  [ActorFeatureTypeEnum.ToyControl]: ToyControl,
  [ActorFeatureTypeEnum.TicketShow]: LocalActivity,
  [ActorFeatureTypeEnum.MobileVideocall]: Momentum,
  [ActorFeatureTypeEnum.Vxlive]: VxLive,
  [ActorFeatureTypeEnum.Accounting]: Group,
  [ActorFeatureTypeEnum.NewsletterVx]: VxModelsLogo,
  [ActorFeatureTypeEnum.TopCam]: Statistics,
  [ActorFeatureTypeEnum.VxsocialPage]: ThumbUp,
  [ActorFeatureTypeEnum.BonusCodeCreation]: Stars,
  [ActorFeatureTypeEnum.ReferCustomers]: PersonAdd,
  [ActorFeatureTypeEnum.VipVideos]: Movie,
};
const featureNameToImg: Partial<Record<ActorFeatureTypeEnum, string>> = {
  [ActorFeatureTypeEnum.ToyControl]: toyControl,
  [ActorFeatureTypeEnum.TicketShow]: ticketShow,
  [ActorFeatureTypeEnum.MobileVideocall]: videoCall,
  [ActorFeatureTypeEnum.Vxlive]: vxLive,
  [ActorFeatureTypeEnum.Accounting]: accounting,
  [ActorFeatureTypeEnum.NewsletterVx]: newsletterVX,
  [ActorFeatureTypeEnum.TopCam]: topCam,
  [ActorFeatureTypeEnum.VxsocialPage]: vxsocials,
  [ActorFeatureTypeEnum.BonusCodeCreation]: bonusCode,
  [ActorFeatureTypeEnum.ReferCustomers]: userReferer,
  [ActorFeatureTypeEnum.VipVideos]: vipVideos,
};

const FeatureRequestPageEarningSection: React.FC<{
  features: Maybe<ActorFeatureFragment>[];
  loading: boolean;
  onOpen: (featureName: ActorFeatureTypeEnum) => void;
}> = ({ features, loading, onOpen }) => {
  const { t } = useTranslation(['featureRequestPage']);
  const isMobileViewport = useBreakpointValue({ base: true, lg: false });

  return (
    <Section>
      <SectionHeader>
        <HStack pb={isMobileViewport ? 4 : 0}>
          <VStack flex={1} alignItems={'start'}>
            <SectionTitleRow>
              <SectionIcon as={Payments} />
              <SectionTitle sx={{ flexShrink: 1 }}>
                {t('header.Verdienstmoglichkeiten')}
              </SectionTitle>
            </SectionTitleRow>
            <SectionDescription>
              {t(
                'text.MitDiesenFeaturesWirdDeinLivechatSpannenderUndLukrativer'
              )}
            </SectionDescription>
          </VStack>
          {!isMobileViewport && <FeaturesSelectRow features={features} />}
        </HStack>
        {isMobileViewport && <FeaturesSelectRow features={features} />}
      </SectionHeader>
      <SectionDivider />
      <SectionBody minH={'400px'}>
        {!loading ? (
          <HSnapScrollContainer>
            {features.map((feature, index) => (
              <FeatureCard key={index} feature={feature} onOpen={onOpen} />
            ))}
            <SnapScrollContainerEnd />
          </HSnapScrollContainer>
        ) : (
          <AbsoluteFullCenterLoadingSpinner />
        )}
      </SectionBody>
    </Section>
  );
};
const FeatureRequestPageAffiliateSection: React.FC<{
  features: Maybe<ActorFeatureFragment>[];
  loading: boolean;
  onOpen: (featureName: ActorFeatureTypeEnum) => void;
  onReferralOpen?: () => void;
}> = ({ features, loading, onOpen, onReferralOpen }) => {
  const { t } = useTranslation(['featureRequestPage']);
  const isMobileViewport = useBreakpointValue({ base: true, lg: false });

  return (
    <Section>
      <SectionHeader>
        <HStack pb={isMobileViewport ? 4 : 0}>
          <VStack flex={1} alignItems={'start'}>
            <SectionTitleRow>
              <SectionIcon as={TrendingUp} />
              <SectionTitle sx={{ flexShrink: 1 }}>
                {t('header.AffiliateMarketing')}
              </SectionTitle>
            </SectionTitleRow>
            <SectionDescription>
              {t('text.VerdieneGeldAuchOhneVorDerCamZuSein')}
            </SectionDescription>
          </VStack>
          {!isMobileViewport && <FeaturesSelectRow features={features} />}
        </HStack>
        {isMobileViewport && <FeaturesSelectRow features={features} />}
      </SectionHeader>
      <SectionDivider />
      <SectionBody>
        {!loading ? (
          <HSnapScrollContainer>
            {features.map((feature, index) => (
              <FeatureCard
                key={index}
                feature={feature}
                onOpen={onOpen}
                onReferralOpen={onReferralOpen}
              />
            ))}
            <SnapScrollContainerEnd />
          </HSnapScrollContainer>
        ) : (
          <AbsoluteFullCenterLoadingSpinner />
        )}
      </SectionBody>
    </Section>
  );
};
const FeatureRequestPageMarketingSection: React.FC<{
  features: Maybe<ActorFeatureFragment>[];
  loading: boolean;
  onOpen: (featureName: ActorFeatureTypeEnum) => void;
}> = ({ features, loading, onOpen }) => {
  const { t } = useTranslation(['featureRequestPage']);
  const isMobileViewport = useBreakpointValue({ base: true, lg: false });

  return (
    <Section>
      <SectionHeader>
        <HStack pb={isMobileViewport ? 4 : 0}>
          <VStack flex={1} alignItems={'start'}>
            <SectionTitleRow>
              <SectionIcon as={TipsAndUpdates} />
              <SectionTitle sx={{ flexShrink: 1 }}>
                {t('header.Marketing')}
              </SectionTitle>
            </SectionTitleRow>
            <SectionDescription>
              {t(
                'text.MitDiesenFeaturesWirdDeinLivechatSpannenderUndLukrativer'
              )}
            </SectionDescription>
          </VStack>
          {!isMobileViewport && <FeaturesSelectRow features={features} />}
        </HStack>
        {isMobileViewport && <FeaturesSelectRow features={features} />}
      </SectionHeader>
      <SectionDivider />
      <SectionBody>
        {!loading ? (
          <HSnapScrollContainer>
            {features.map((feature, index) => (
              <FeatureCard key={index} feature={feature} onOpen={onOpen} />
            ))}
            <SnapScrollContainerEnd />
          </HSnapScrollContainer>
        ) : (
          <AbsoluteFullCenterLoadingSpinner />
        )}
      </SectionBody>
    </Section>
  );
};

type FeatureRequestModalProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  feature: Maybe<ActorFeatureFragment>;
  onRequestFeature: (featureType: ActorFeatureTypeEnum) => void;
  loading: boolean;
};

type ActorFeatureRequestContent = {
  title: string;
  description: string;
  requestSteps: React.ReactNode[];
  requestProgressSteps: React.ReactNode[];
  featureAdvantages?: string[];
  approvalText: string;
  buttonText?: string;
  infoText?: string;
  img?: {
    src: string;
    alt: string;
  };
};

const FeatureRequestModal: React.FC<FeatureRequestModalProps> = ({
  isOpen,
  onClose,
  feature,
  onRequestFeature,
  loading,
}) => {
  const lang = useActiveApiLanguage();
  const { t } = useTranslation(['featureRequestPage']);
  const [isChecked, setIsChecked] = React.useState(false);
  useEffect(() => {
    setIsChecked(false);
  }, [isOpen]);

  const featureNameToContent: Record<
    ActorFeatureTypeEnum,
    Partial<ActorFeatureRequestContent>
  > = {
    [ActorFeatureTypeEnum.ToyControl]: {
      img: {
        alt: t('featureRequestPage:text.ToyControlFreischalten'),
        src: featureNameToImg[ActorFeatureTypeEnum.ToyControl] ?? '',
      },
      title: t('featureRequestPage:header.ToyControl'),
      description: t(
        'featureRequestPage:text.KundenKonnenDeinToySteuernUndDuVerdienstProChatXXMehrCoins'
      ),
      requestProgressSteps: [
        <FeatureRequestModalRequestProgressStep
          text={t('featureRequestPage:text.Auszahlung')}
          description={t(
            'featureRequestPage:text.Ab50IstEineAuszahlungMoglich'
          )}
          successDescription={t(
            'featureRequestPage:text.AuszahlungErfolgreich'
          )}
          value={
            (
              feature?.requirements.find(
                (req) => req.type === 'payoutCountGte1'
              ) as ActorFeatureRequirementNumeric
            )?.currentValue ?? 0
          }
          targetValue={1}
        />,
      ],
      requestSteps: [
        <FeatureRequestModalRequestStep
          icon={VxLive}
          text={t('featureRequestPage:text.IchStreameInHD')}
        />,
        <FeatureRequestModalRequestStep
          icon={VolumeUp}
          text={t('featureRequestPage:text.IchSendeImmerMitTon')}
        />,
      ],
      approvalText: t(
        'featureRequestPage:text.IchErfulleDieseKriterienUndBeantrageDieToyControl'
      ),
      buttonText: t('button.Bewerben'),
      infoText: t(
        'featureRequestPage:text.BitteBeachteDasWirDichNurFreischaltenWennDeinAccountUnserenProfilstaXX'
      ),
    },
    [ActorFeatureTypeEnum.TicketShow]: {
      img: {
        alt: t('featureRequestPage:text.ShowsFreischalten'),
        src: featureNameToImg[ActorFeatureTypeEnum.TicketShow] ?? '',
      },
      title: t('featureRequestPage:header.Shows'),
      description: t(
        'featureRequestPage:text.BieteEineBesondereShowZuEinemTicketpreisAn'
      ),
      requestProgressSteps: [
        <FeatureRequestModalRequestProgressStep
          text={t('featureRequestPage:text.Auszahlung')}
          description={t(
            'featureRequestPage:text.Ab50IstEineAuszahlungMoglich'
          )}
          successDescription={t(
            'featureRequestPage:text.AuszahlungErfolgreich'
          )}
          value={
            (
              feature?.requirements?.find(
                (req) => req?.type === 'payoutCountGte1'
              ) as ActorFeatureRequirementNumeric
            )?.currentValue ?? 0
          }
          targetValue={1}
        />,
      ],
      requestSteps: [
        <FeatureRequestModalRequestStep
          icon={Statistics}
          text={t('featureRequestPage:text.MeinLiveRankingIstBesserAls100')}
        />,
        <FeatureRequestModalRequestStep
          icon={VxLive}
          text={t('featureRequestPage:text.IchStreameInHD')}
        />,
        <FeatureRequestModalRequestStep
          icon={VolumeUp}
          text={t('featureRequestPage:text.IchSendeImmerMitTon')}
        />,
      ],
      approvalText: t(
        'featureRequestPage:text.IchErfulleDieseKriterienUndBeantrageDieShows'
      ),
      buttonText: t('button.Bewerben'),
      infoText: t(
        'featureRequestPage:text.BitteBeachteDasWirDichNurFreischaltenWennDeinAccountUnserenProfilstaXX'
      ),
    },
    [ActorFeatureTypeEnum.MobileVideocall]: {
      img: {
        alt: t('featureRequestPage:text.MomentumVideoCallFreischalten'),
        src: featureNameToImg[ActorFeatureTypeEnum.MobileVideocall] ?? '',
      },
      title: t('featureRequestPage:text.MomentumVideoCall'),
      description: t(
        'featureRequestPage:text.FuhrePrivateVideocallsMitEinemKundenAufDeinemSmartphone'
      ),
      requestProgressSteps: [
        <FeatureRequestModalRequestProgressStep
          text={'h'}
          description={t(
            'featureRequestPage:text.OnlinezeitInsgesamtInDenLetzten6Monaten'
          )}
          successDescription={t('featureRequestPage:text.OnlinezeitErreicht')}
          value={
            (
              feature?.requirements?.find(
                (req) => req?.type === 'onlineTime180dGte300h'
              ) as ActorFeatureRequirementNumeric
            )?.currentValue ?? 0
          }
          targetValue={300}
        />,
      ],
      requestSteps: [
        <FeatureRequestModalRequestStep
          icon={Statistics}
          text={t('featureRequestPage:text.MeinLiveRankingIstBesserAls100')}
        />,
        <FeatureRequestModalRequestStep
          icon={VxLive}
          text={t('featureRequestPage:text.IchStreameInHD')}
        />,
        <FeatureRequestModalRequestStep
          icon={VolumeUp}
          text={t('featureRequestPage:text.IchSendeImmerMitTon')}
        />,
      ],
      approvalText: t(
        'featureRequestPage:text.IchErfulleDieseKriterienUndBeantrageDenMomentumVideoCall'
      ),
      buttonText: t('button.Bewerben'),
      infoText: t(
        'featureRequestPage:text.BitteBeachteDasWirDichNurFreischaltenWennDeinAccountUnserenProfilstaXX'
      ),
    },
    [ActorFeatureTypeEnum.Vxlive]: {
      img: {
        alt: t('featureRequestPage:text.VXLiveFreischalten'),
        src: featureNameToImg[ActorFeatureTypeEnum.Vxlive] ?? '',
      },
      title: t('featureRequestPage:header.VXLive'),
      description: t(
        'featureRequestPage:text.DeinToolUmLiveZuGehenUndZuChatten'
      ),
      //implement when BE is ready (tasks 0-3)
      // requestProgressSteps: [
      //   <FeatureRequestModalRequestProgressStep
      //     text={''}
      //     description={t('featureRequestPage:text.TasksAbgeschlossen')}
      //     successDescription={t('featureRequestPage:text.TasksAbgeschlossen')}
      //     value={
      //       0
      //     }
      //     targetValue={3}
      //   />,
      // ],
      requestSteps: [
        <FeatureRequestModalRequestStep
          icon={VxModelsLogo}
          text={t('featureRequestPage:text.DeinAccountIstVerifiziert')}
        />,
        <FeatureRequestModalRequestStep
          icon={VxLive}
          text={t('featureRequestPage:text.DuBesitztEineWebcamHandy')}
        />,
      ],
      infoText: t(
        'featureRequestPage:text.BitteBeachteDasDuNurOnlineGehenKannstWennDuDichAnUnsereHausChatregelXX'
      ),
    },
    [ActorFeatureTypeEnum.Accounting]: {
      img: {
        alt: t('featureRequestPage:text.AccountingFreischalten'),
        src: featureNameToImg[ActorFeatureTypeEnum.Accounting] ?? '',
      },
      title: t('featureRequestPage:header.Accounting'),
      description: t(
        'featureRequestPage:text.ErhalteEinenPersonlichenAnsprechpartnerAusUnseremModelDepartmentUndAXX'
      ),
      requestProgressSteps: [
        <FeatureRequestModalRequestProgressStep
          text={'h'}
          description={t(
            'featureRequestPage:text.OnlinezeitInsgesamtInDenLetzten6Monaten'
          )}
          successDescription={t('featureRequestPage:text.OnlinezeitErreicht')}
          value={
            (
              feature?.requirements?.find(
                (req) => req?.type === 'onlineTime180dGte300h'
              ) as ActorFeatureRequirementNumeric
            )?.currentValue ?? 0
          }
          targetValue={300}
        />,
      ],
      requestSteps: [
        <FeatureRequestModalRequestStep
          icon={Statistics}
          text={t('featureRequestPage:text.MeinLiveRankingIstBesserAls100')}
        />,
        <FeatureRequestModalRequestStep
          icon={VxLive}
          text={t('featureRequestPage:text.VISITXIstMeineHauptplattform')}
        />,
      ],
      approvalText: t(
        'featureRequestPage:text.IchErfulleDieseKriterienUndBeantrageEinenPersonlichenAccounter'
      ),
      buttonText: t('button.Bewerben'),
    },
    [ActorFeatureTypeEnum.NewsletterVx]: {
      img: {
        alt: t('featureRequestPage:text.VISITXNewsletterFreischalten'),
        src: featureNameToImg[ActorFeatureTypeEnum.NewsletterVx] ?? '',
      },
      title: t('featureRequestPage:header.VISITXNewsletter'),
      description: t(
        'featureRequestPage:text.DuMochtestNochMehrKundenErreichenUndDeineBekanntheitErhohenDannGibDeXX'
      ),
      requestProgressSteps: [
        <FeatureRequestModalRequestProgressStep
          text={'h'}
          description={t(
            'featureRequestPage:text.Onlinezeit100HLaufenderMonat'
          )}
          successDescription={t('featureRequestPage:text.OnlinezeitErreicht')}
          value={
            (
              feature?.requirements?.find(
                (req) => req?.type === 'onlineTime30dGte100h'
              ) as ActorFeatureRequirementNumeric
            )?.currentValue ?? 0
          }
          targetValue={100}
        />,
      ],
      requestSteps: [
        <FeatureRequestModalRequestStep
          icon={Statistics}
          text={t('featureRequestPage:text.MeinLiveRankingIstBesserAls100')}
        />,
        <FeatureRequestModalRequestStep
          icon={VxModelsLogo}
          text={t('featureRequestPage:text.IchHabeEineAktiveKooperation')}
        />,
      ],
      featureAdvantages: [
        t(
          'featureRequestPage:text.ProfitiereVonDemUmsatzplusDurchGezielteBewerbungDeinerVideosOderDeinXX'
        ),
        t(
          'featureRequestPage:text.ErhohteSichtbarkeitDeinesProfilsOderDeinesContents'
        ),
        t('featureRequestPage:text.DuMusstNurZustimmenDenRestMachenWir'),
      ],
      approvalText: t(
        'featureRequestPage:text.JaIchMochteImVISITXNewsletterBeworbenWerden'
      ),
      buttonText: t('button.Teilnehmen'),
    },
    [ActorFeatureTypeEnum.TopCam]: {
      img: {
        alt: t('featureRequestPage:text.TopCamPlatzierungAufVISITX'),
        src: featureNameToImg[ActorFeatureTypeEnum.TopCam] ?? '',
      },
      title: t('featureRequestPage:header.TopCamPlatzierungAufVISITX'),
      description: t(
        'featureRequestPage:text.WerdeUnserNeuesTopModelUndBewerbeDichFurDieTopPlatzierungAufVISITX'
      ),
      requestProgressSteps: [
        <FeatureRequestModalRequestProgressStep
          text={'h'}
          description={t(
            'featureRequestPage:text.Onlinezeit100HLaufenderMonat'
          )}
          successDescription={t('featureRequestPage:text.OnlinezeitErreicht')}
          value={
            (
              feature?.requirements?.find(
                (req) => req?.type === 'onlineTime30dGte100h'
              ) as ActorFeatureRequirementNumeric
            )?.currentValue ?? 0
          }
          targetValue={100}
        />,
      ],
      requestSteps: [
        <FeatureRequestModalRequestStep
          icon={Statistics}
          text={t('featureRequestPage:text.MeinLiveRankingIstBesserAls100')}
        />,
        <FeatureRequestModalRequestStep
          icon={VxModelsLogo}
          text={t('featureRequestPage:text.IchHabeEineAktiveKooperation')}
        />,
      ],
      featureAdvantages: [
        t(
          'featureRequestPage:text.ProfitiereVonDemUmsatzplusDurchGezielteBewerbungDeinerLivecam'
        ),
        t(
          'featureRequestPage:text.DeineChanceAufDerStartseiteBeworbenZuWerdenUndDieSichtbarkeitDeinerLXX'
        ),
      ],
      buttonText: t('button.Bewerben'),
      approvalText: t(
        'featureRequestPage:text.JaIchMochteMichFurEineTopCamPlatzierungBewerben'
      ),
    },
    [ActorFeatureTypeEnum.BonusCodeCreation]: {
      img: {
        alt: t('featureRequestPage:text.BonusCodeFreischalten'),
        src: featureNameToImg[ActorFeatureTypeEnum.BonusCodeCreation] ?? '',
      },
      title: t('featureRequestPage:header.BonusCode'),
      description: t(
        'featureRequestPage:text.LockeUserAufVISITXMitEigenenRabattenUndAktionen'
      ),
      requestProgressSteps: [
        <FeatureRequestModalRequestProgressStep
          text={t('featureRequestPage:text.EuroAuszahlung')}
          description={t('featureRequestPage:text.DieLetzten3Monate')}
          successDescription={t('featureRequestPage:text.DieLetzten3Monate')}
          value={
            (
              feature?.requirements?.find(
                (req) => req?.type === 'payout180dGte1000'
              ) as ActorFeatureRequirementNumeric
            )?.currentValue ?? 0
          }
          targetValue={1000}
        />,
      ],
      requestSteps: [
        <FeatureRequestModalRequestStep
          icon={Statistics}
          text={t('featureRequestPage:text.MeinLiveRankingIstBesserAls100')}
        />,
        <FeatureRequestModalRequestStep
          icon={VxLive}
          text={t('featureRequestPage:text.OnlineInDenLetzten90Tagen')}
        />,
      ],
      approvalText: t(
        'featureRequestPage:text.IchErfulleDieseKriterienUndBeantrageDieBonusCodes'
      ),
      buttonText: t('button.Bewerben'),
      infoText: t(
        'featureRequestPage:text.BitteBeachteDasWirDichNurFreischaltenWennDeinAccountUnserenProfilstaXX'
      ),
    },
    //TODO
    [ActorFeatureTypeEnum.VipVideos]: {
      img: {
        alt: t(
          'featureRequestPage:header.ExklusivePromotionDeinerVideosFurVIPKunden'
        ),
        src: featureNameToImg[ActorFeatureTypeEnum.VipVideos] ?? '',
      },
      title: t(
        'featureRequestPage:header.ExklusivePromotionDeinerVideosFurVIPKunden'
      ),
      description: t(
        'featureRequestPage:text.WirBietenDirDieMoglichkeitDeinenContentProminentAufVISITXZuBewerben'
      ),
      featureAdvantages: [
        t('featureRequestPage:text.ChanceAufTopPlatzierungInDerVideoStation'),
        t(
          'featureRequestPage:text.ErreicheMitVIPKundenEinenExklusivenKundenkreis'
        ),
        t('featureRequestPage:text.DuMusstNurZustimmenDenRestMachenWir'),
        t('featureRequestPage:text.KaufDesVideosWeiterhinMoglich'),
      ],
      approvalText: t(
        'featureRequestPage:text.JaIchMochteMeineVideosFurDieVISITXAktionenZurVerfugungStellen'
      ),
      buttonText: t('button.Teilnehmen'),
    },
    [ActorFeatureTypeEnum.VxsocialPage]: {},
    [ActorFeatureTypeEnum.ReferCustomers]: {},
    [ActorFeatureTypeEnum.InstagramAdlink]: {},
  };

  const featureName = feature?.type;
  const isRequestAllowed = feature?.isRequestAllowed;

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      preferredSize="xl"
      isVCentered={false}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalStickyHeaderBox p={0}>
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        {/* Img */}
        {featureName && featureNameToContent[featureName]?.img && (
          <Image
            src={featureNameToContent[featureName]?.img?.src}
            h={'142px'}
            w={'full'}
            userSelect={'none'}
            objectFit={'contain'}
            bgColor={'steel'}
          />
        )}
        {!featureName ? (
          <></>
        ) : (
          <>
            <ResponsiveModalBodyBox bg={'surface'} p={'unset'} display={'flex'}>
              <VStack p={4} gap={4} flex={1}>
                <VStack gap={1} mb={4}>
                  <Icon
                    boxSize={'icon.md'}
                    as={featureNameToIcon[featureName]}
                  />
                  <Text
                    fontWeight={'semibold'}
                    fontSize={'md'}
                    lineHeight={'20px'}
                  >
                    {featureNameToContent[featureName]?.title}
                  </Text>
                  <Text
                    textAlign={'center'}
                    fontWeight={'light'}
                    fontSize={'xs'}
                    lineHeight={'14px'}
                  >
                    {featureNameToContent[featureName]?.description}
                  </Text>
                </VStack>
                <VStack w={'full'} gap={4} mb={4}>
                  {/* Request Progress Steps for feature */}
                  {featureNameToContent[featureName]?.requestProgressSteps}
                  {/* Request Steps for feature */}
                  {featureNameToContent[featureName]?.requestSteps}
                  {/* Feature approval advantages */}
                </VStack>
                {featureNameToContent[featureName]?.featureAdvantages && (
                  <VStack w={'full'} gap={4} mb={4}>
                    <VStack alignItems={'start'} w={'full'} gap={4}>
                      <Text fontWeight={'normal'}>
                        {t('text.DeineVorteile')}
                      </Text>
                      <UnorderedList spacing={4}>
                        {featureNameToContent[
                          featureName
                        ]?.featureAdvantages?.map((advantage, index) => {
                          return (
                            <ListItem key={index}>
                              <Text fontWeight={'light'} fontSize={'sm'}>
                                {advantage}
                              </Text>
                            </ListItem>
                          );
                        })}
                      </UnorderedList>
                    </VStack>
                  </VStack>
                )}
                {/* Approval */}
                {featureNameToContent[featureName]?.approvalText && (
                  <HStack alignItems={'start'}>
                    <Checkbox
                      pt={1}
                      onChange={(e) => setIsChecked(e.target.checked)}
                      isChecked={isChecked}
                      isDisabled={!isRequestAllowed}
                      _disabled={{
                        '.chakra-checkbox__control': {
                          borderColor: 'gray.200',
                        },
                      }}
                    />
                    <Text color={isRequestAllowed ? 'black' : 'gray.200'}>
                      {featureNameToContent[featureName]?.approvalText}
                    </Text>
                  </HStack>
                )}

                {/* Request feature button */}
                {featureNameToContent[featureName]?.buttonText && (
                  <Button
                    onClick={() => {
                      onRequestFeature(featureName);
                    }}
                    isLoading={loading}
                    variant={'solid'}
                    isDisabled={!isChecked}
                  >
                    {featureNameToContent[featureName]?.buttonText}
                  </Button>
                )}
              </VStack>
              {/* Info */}
              {featureNameToContent[featureName]?.infoText && (
                <HStack p={4} color={'caribbeanGreen.200'} alignItems={'start'}>
                  <Icon boxSize={'icon.md'} as={Info} />
                  <Box>
                    <Trans
                      defaults={featureNameToContent[featureName]?.infoText}
                      components={{
                        underline: (
                          <Link
                            isExternal
                            href={
                              externalRoutes.helpCenter(lang) +
                              '/articles/12456418600722-Chat-Fair-Play-Regeln'
                            }
                          >
                            {t('text.Profilstandarts')}
                          </Link>
                        ),
                      }}
                    />
                  </Box>
                </HStack>
              )}
            </ResponsiveModalBodyBox>
          </>
        )}
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};

const FeatureRequestModalRequestStep: React.FC<{
  icon: EmotionIcon;
  text: string;
}> = ({ icon, text }) => {
  return (
    <HStack
      border={'1px'}
      borderRadius={'8px'}
      borderColor={'steel'}
      px={3}
      py={4}
      w={'full'}
    >
      <Icon boxSize={'icon.sm'} as={icon} />
      <Text fontWeight={'normal'} fontSize={'md'} lineHeight={'20px'}>
        {text}
      </Text>
    </HStack>
  );
};
const FeatureRequestModalRequestProgressStep: React.FC<{
  icon?: EmotionIcon;
  text: string;
  description: string;
  successDescription: string;
  value: number;
  targetValue: number;
}> = ({
  icon = VxModelsLogo,
  text,
  description,
  successDescription,
  value,
  targetValue,
}) => {
  const { t } = useTranslation(['featureRequestPage']);
  const stepFulfilled = value >= targetValue;

  return (
    <HStack
      border={'1px'}
      borderRadius={'8px'}
      borderColor={'steel'}
      px={3}
      py={4}
      w={'full'}
    >
      <Box w={'50px'} h={'50px'}>
        <CircularProgress
          value={value}
          min={0}
          max={targetValue}
          color={'caribbeanGreen.200'}
        >
          <CircularProgressLabel>
            <Icon as={icon} color={'steel'} />
          </CircularProgressLabel>
        </CircularProgress>
      </Box>
      <VStack alignItems={'flex-start'} h={'full'} flex={1}>
        <HStack>
          <Text fontWeight={'semibold'} lineHeight={'22px'} fontSize={'xl'}>
            <Trans
              t={t}
              i18nKey={'text.XXVonXXText'}
              values={{ current: value }}
              components={{
                targetValue: (
                  <chakra.span
                    fontWeight={'light'}
                    lineHeight={'22px'}
                    fontSize={'13px'}
                  >
                    {`${targetValue} ${text}`}
                  </chakra.span>
                ),
              }}
            />
          </Text>
        </HStack>
        <Text fontWeight={'light'} fontSize={'xs'} lineHeight={'14px'}>
          {stepFulfilled ? successDescription : description}
        </Text>
      </VStack>
    </HStack>
  );
};

type FeatureRequestReferralModalProps = {
  isOpen: boolean;
  onClose: () => void;
  adlinks?: Adlinks;
};

const FeatureRequestReferralModal: React.FC<
  FeatureRequestReferralModalProps
> = ({ isOpen, onClose, adlinks }) => {
  const { authUser } = useAuth();
  const [referUser, { on, off }] = useBoolean(true);
  const { t } = useTranslation(['featureRequestPage']);

  const [fetchAutologinVXCash, { loading: loadingAutologinVXCash }] =
    useGetAutologinVxCashLazyQuery();

  const inactiveColor = 'lightBackground';

  const sections: React.ReactNode[] = referUser
    ? [
        <FeatureRequestReferralModalSection
          icon={Star}
          linkIcon={HowToReg}
          title={t('header.SocialMediaAffiliateLink')}
          linkTitle={t('text.PromoLink')}
          description={t('text.WerbeUberDeineSocialsNeueKunden')}
          secondaryDescription={
            <ShieldCard
              text={t(
                'text.SicherKundenAufSocialMediaWerbenOhneProfilsperrenZuRiskieren'
              )}
            />
          }
          link={adlinks!.vxsocials}
          onClose={onClose}
        />,
        <FeatureRequestReferralModalSection
          icon={Forum}
          linkIcon={VxModelsLogo}
          title={t('header.VISITXPromolink')}
          linkTitle={t('text.VISITX')}
          description={t(
            'text.WerbeNeueKundenFurVISITXUndErhalteWerbeprovisionen'
          )}
          secondaryDescription={
            <ShieldCard
              text={t(
                'text.LeiteUserDirektZuDeinemVISITXProfilIdealFurDeineEigeneWebsiteOderFurXX'
              )}
            />
          }
          link={adlinks!.visitx ?? `https://myvx.tv/${authUser?.username}`}
          onClose={onClose}
        />,
      ]
    : [
        <FeatureRequestReferralModalSection
          icon={AccountCircle}
          linkIcon={VxModelsLogo}
          title={t('header.ModelsWerben')}
          linkTitle={t('text.ModelWerden')}
          description={t(
            'text.WirbNeueModelsFurVISITXUndSichereDir100Vermittlungsprovision'
          )}
          secondaryDescription={
            <HTipCard
              contentInsteadOfText={
                <VStack alignItems={'flex-start'}>
                  <Text fontWeight={'semibold'}>
                    {t('text.AnforderungenFurNeueModels')}
                  </Text>
                  <UnorderedList>
                    <ListItem>{t('text.Weiblich')}</ListItem>
                    <ListItem>{t('text.FliessendDeutschSprechen')}</ListItem>
                    <ListItem>
                      {t('text.InhaberIhresEigenenAccountsSein')}
                    </ListItem>
                    <ListItem>{t('text.IhrProfilZu50Ausfullen')}</ListItem>
                    <ListItem>{t('text.Min1AuszahlungErhaltenHaben')}</ListItem>
                  </UnorderedList>
                </VStack>
              }
            />
          }
          link={adlinks!.vxmodels}
          onClose={onClose}
        />,
      ];

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      preferredSize="xl"
      isVCentered={false}
    >
      <ResponsiveModalOverlay />
      <ResponsiveModalContent>
        <ResponsiveModalStickyHeaderBox>
          &nbsp;
          <ResponsiveModalCloseButton />
        </ResponsiveModalStickyHeaderBox>
        <VStack bgColor={inactiveColor} w={'full'}>
          {/* Switch */}
          <Container
            flexShrink={1}
            w={'min-content'}
            px={4}
            py={6}
            centerContent
            bgColor={inactiveColor}
          >
            <HStack
              bgColor={inactiveColor}
              border={'1px'}
              borderColor={'primary.500'}
              borderRadius={'100px'}
              gap={0}
            >
              <Button
                minW={'144px'}
                maxH={'36px'}
                color={referUser ? 'surface' : 'gray.9000'}
                variant={referUser ? 'solid' : 'outline'}
                border={'none'}
                onClick={on}
                children={t('button.KundenWerben')}
              />
              <Button
                minW={'144px'}
                maxH={'36px'}
                color={!referUser ? 'surface' : 'gray.9000'}
                variant={!referUser ? 'solid' : 'outline'}
                border={'none'}
                onClick={off}
                children={t('button.ModelsWerben')}
              />
            </HStack>
          </Container>
          {/* Img */}
          <Image
            src={referUser ? userReferer : bonusCode}
            alt={referUser ? t('text.KundenWerben') : t('text.ModelsWerben')}
            h={'142px'}
            w={'full'}
            userSelect={'none'}
            objectFit={'contain'}
          />
        </VStack>
        <ResponsiveModalBodyBox
          bg={inactiveColor}
          p={'unset'}
          display={'flex'}
          gap={6}
        >
          <VStack bgColor={'surface'}>
            <VStack gap={4} p={4}>
              <Text fontWeight={'semibold'} fontSize={'lg'} lineHeight={'20px'}>
                {referUser ? t('text.KundenWerben') : t('text.ModelsWerben')}
              </Text>
              <Text
                textAlign={'center'}
                fontWeight={'light'}
                fontSize={'xs'}
                lineHeight={'14px'}
              >
                {referUser
                  ? t(
                      'text.DuTeilstDeinenPersonlichenWerbelinkUndSobaldSichEinNeukundeDaruberAnXX'
                    )
                  : t(
                      'text.DuKennstModelsDieDenEinstiegInsCamGeschaftAnstrebenDannWirbSieUberDeXX'
                    )}
              </Text>

              <Button
                onClick={async () => {
                  const result = await fetchAutologinVXCash();
                  const link =
                    result.data?.app.autologinLinkVXCash ??
                    externalRoutes.vxcash();
                  window.open(link, '_blank');
                }}
                isLoading={loadingAutologinVXCash}
                variant={'outline'}
              >
                {t('button.StatistikenAnsehen')}
              </Button>
            </VStack>
          </VStack>
          {sections.map((section, index) => {
            return (
              <VStack
                key={index}
                bgColor={'surface'}
                p={4}
                gap={4}
                flex={1}
                alignItems={'start'}
              >
                {section}
              </VStack>
            );
          })}
        </ResponsiveModalBodyBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};

const FeatureRequestReferralModalSection: React.FC<{
  icon: EmotionIcon;
  linkIcon: EmotionIcon;
  title: string;
  linkTitle: string;
  description: string;
  secondaryDescription?: React.ReactNode;
  link: string;
  onClose: () => void;
}> = ({
  icon,
  linkIcon,
  title,
  linkTitle,
  description,
  secondaryDescription,
  link,
  onClose,
}) => {
  const { t } = useTranslation(['featureRequestPage']);
  return (
    <VStack w={'full'} bg={'surface'} p={4} gap={8}>
      <VStack gap={2} alignSelf={'start'} alignItems={'start'}>
        <HStack>
          <Icon boxSize={'icon.md'} as={icon} />
          <Text fontWeight={'semibold'} fontSize={'lg'} lineHeight={'20px'}>
            {title}
          </Text>
        </HStack>
        <Text
          color={'gray.500'}
          fontWeight={'normal'}
          fontSize={'md'}
          lineHeight={'20px'}
        >
          {description}
        </Text>
      </VStack>
      <Box
        border={'1px'}
        borderRadius={'32px'}
        color={'darkSteel'}
        px={4}
        py={'14px'}
      >
        <HStack color={'gray.900'}>
          <Icon boxSize={'icon.sm'} as={linkIcon} />
          <Text>{linkTitle}</Text>
          <Button
            alignSelf={'center'}
            variant={'link'}
            rightIcon={<Icon as={FilterNone} boxSize={'icon.sm'} />}
            onClick={() => {
              navigator.clipboard.writeText(link);
              onClose();
              issueChakraToast({
                status: 'success',
                description: t('toast.LinkInZwischenablageKopiert'),
              });
            }}
          >
            {t('button.LinkKopieren')}
          </Button>
        </HStack>
      </Box>
      {secondaryDescription}
    </VStack>
  );
};
