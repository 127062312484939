import {
  OdiCard,
  OdiCardBody,
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
  SubHeader,
  SubHeaderTitle,
} from '@campoint/odi-ui';
import { Payments, Settings } from '@campoint/odi-ui-icons';
import {
  Container,
  Flex,
  Heading,
  Icon,
  IconButton,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FluentPageLayout } from '../../components/Layout/FluentPageLayout';
import { LayoutCenteringVStack } from '../../components/Layout/LayoutCenteringVStack';
import ScrollToLastKnownPositionOnMount from '../../components/Layout/ScrollToLastKnownPositionOnMount';
import { SectionCenterContainer } from '../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../components/Layout/SectionDivider';
import { CurrencyAmount } from '../../components/shared/CurrencyAmount/CurrencyAmount';
import PayoutDataCheckPendingCard from '../../components/shared/Payout/PayoutDataCheckPendingCard/PayoutDataCheckPendingCard';
import PayoutDataCheckRejectedCard from '../../components/shared/Payout/PayoutDataCheckRejectedCard/PayoutDataCheckRejectedCard';
import { UpdateTaxInformationExpiredHintSlot } from '../../components/shared/UpdateTaxInformationExpiredHintSlot/UpdateTaxInformationExpiredHintSlot';
import { UpdateTaxInformationExpiresSoonHintSlot } from '../../components/shared/UpdateTaxInformationExpiresSoonHintSlot/UpdateTaxInformationExpiresSoonHintSlot';
import { FeatureName, useFeature } from '../../flags';
import { ProfileStatusEnum } from '../../generated/graphql';
import useHistoryWithStorage from '../../hooks/useHistoryWithStorage';
import { useAuth } from '../../provider/AuthProvider';
import { useFinanceService } from '../../provider/FinanceService/FinanceService';
import { routes } from '../../routes/routesConfig';
import { NextPayoutCardSection } from './components/NextPayoutSection/NextPayoutCardSection';
import { OldNextPayoutCardSection } from './components/NextPayoutSection/OldNextPayoutCardSection';
import { OldPayoutOverviewTabSection } from './components/PayoutOverviewTabSection/OldPayoutOverviewTabSection';
import { PayoutOverviewTabSection } from './components/PayoutOverviewTabSection/PayoutOverviewTabSection';
import { PriorToPayoutSetupSection } from './components/PriorToPayoutSetupSection/PriorToPayoutSetupSection';
import { OldPayoutWithdrawalsSection } from './section/PayoutWithdrawalsSection/OldPayoutWithdrawalsSection';

const IncomeStatsSubTile: React.FC<{
  platform: string;
  from: Date | null;
  amount: number;
  subtitle: string;
}> = ({ platform, from, amount, subtitle }) => {
  const { t } = useTranslation(['financePage']);
  return (
    <OdiCard flex={1}>
      <OdiCardBody>
        <VStack textAlign={'center'} gap={0}>
          <Tag variant={'outline'} borderRadius={'20px'} mb={2}>
            {platform}
          </Tag>
          <Text>
            {t('financePage:cards.incomeStats.seitBisHeute', {
              date: from,
            })}
          </Text>
          <Heading size={'lg'}>
            <CurrencyAmount amount={amount} />
          </Heading>
          <Text color={'gray.300'} fontSize={'12px'} lineHeight={'16px'} my={2}>
            {subtitle}
          </Text>
        </VStack>
      </OdiCardBody>
    </OdiCard>
  );
};

const FinancePage: React.FC = () => {
  const { t } = useTranslation(['financePage', 'statistic', 'billing']);
  const historyWithStorage = useHistoryWithStorage();
  const { isMasterAccount } = useAuth();
  const documentPreferredForFinanceDocumentFlag = useFeature(
    FeatureName.documentPreferredForFinanceDocument
  );
  const newFinancePageActive = useFeature(FeatureName.financePageNew);
  const documentFlag = useFeature(FeatureName.document);
  const {
    loading,
    payableAmount,
    isFirstPayout,
    nextPaymentDate,
    payoutStatus,
    hasEnoughAmountForPayout,
    minimalPayableAmount,
    payoutWizardStatus,
    billingFromDate,
    affiliateStatus,
    action: {
      triggerRefetch,
      triggerInvoiceRefetch,
      requestWithdrawal,
      cancelWithdrawal,
    },
  } = useFinanceService();

  React.useEffect(() => {
    triggerRefetch();
    triggerInvoiceRefetch();
  }, [triggerRefetch, triggerInvoiceRefetch]);

  const incomeStatsTile = (
    <Section>
      <SectionCenterContainer>
        <SectionHeader>
          <SectionTitleRow>
            <SectionIcon as={Payments} />
            <SectionTitle
              children={t('financePage:cards.incomeStats.aktuellerVerdients')}
            />
          </SectionTitleRow>
        </SectionHeader>
        <SectionDivider />
        <SectionBody flexDirection={'row'} gap={2}>
          <IncomeStatsSubTile
            platform={'VISIT-X'}
            from={billingFromDate}
            amount={payableAmount}
            subtitle={t(
              'financePage:cards.incomeStats.auszahlungUberCamPointAG'
            )}
          />
          <IncomeStatsSubTile
            platform={'VXCash'}
            from={new Date(affiliateStatus.startdate)}
            amount={affiliateStatus.totalSharing}
            subtitle={t(
              'financePage:cards.incomeStats.auszahlungUberCapitalForGrowth'
            )}
          />
        </SectionBody>
      </SectionCenterContainer>
    </Section>
  );

  const isPayoutWizardAccepted =
    payoutWizardStatus === ProfileStatusEnum.Accepted;
  const isPayoutWizardActiveOverride = payableAmount >= 0.5;
  const spacing = { base: 4, lg: 8 };
  return (
    <FluentPageLayout isContentLoading={loading}>
      <ScrollToLastKnownPositionOnMount identifier={'/finance'} />
      <SubHeader>
        <Container px={0} maxW={'container.xl'}>
          <Flex alignItems={'center'}>
            <SubHeaderTitle flex={1}>
              {t('financePage:headline.DeineFinanzen')}
            </SubHeaderTitle>
            {isMasterAccount &&
              documentFlag &&
              documentPreferredForFinanceDocumentFlag && (
                <IconButton
                  variant="unstyled"
                  aria-label={'BackButtonEditPage'}
                  icon={<Icon as={Settings} boxSize="6" />}
                  onClick={() => {
                    historyWithStorage.push(routes.documentsPayout.path);
                  }}
                />
              )}
          </Flex>
        </Container>
      </SubHeader>
      <Container maxW="container.xl" p={0}>
        <VStack spacing={spacing} w={'full'} my={spacing}>
          {isMasterAccount ? (
            <>
              <UpdateTaxInformationExpiredHintSlot />
              <UpdateTaxInformationExpiresSoonHintSlot />
              {incomeStatsTile}
              {payoutWizardStatus === ProfileStatusEnum.Incomplete && (
                <PriorToPayoutSetupSection
                  isSetupPayoutWizardActive={isPayoutWizardActiveOverride}
                />
              )}
              {payoutWizardStatus === ProfileStatusEnum.Pending && (
                <LayoutCenteringVStack>
                  <PayoutDataCheckPendingCard />
                </LayoutCenteringVStack>
              )}
              {payoutWizardStatus === ProfileStatusEnum.Rejected && (
                <LayoutCenteringVStack>
                  <PayoutDataCheckRejectedCard reason={''} />
                </LayoutCenteringVStack>
              )}
              {isPayoutWizardAccepted &&
                (newFinancePageActive ? (
                  <NextPayoutCardSection
                    isFirstPayout={isFirstPayout}
                    nextPaymentDate={nextPaymentDate}
                    status={payoutStatus}
                    onRequestWithdrawal={requestWithdrawal}
                    onCancelWithdrawal={cancelWithdrawal}
                    hasEnoughAmountForPayout={hasEnoughAmountForPayout}
                    minimalPayableAmount={minimalPayableAmount}
                  />
                ) : (
                  <OldNextPayoutCardSection
                    isFirstPayout={isFirstPayout}
                    nextPaymentDate={nextPaymentDate}
                    status={payoutStatus}
                    onRequestWithdrawal={requestWithdrawal}
                    onCancelWithdrawal={cancelWithdrawal}
                    hasEnoughAmountForPayout={hasEnoughAmountForPayout}
                    minimalPayableAmount={minimalPayableAmount}
                  />
                ))}

              {isPayoutWizardAccepted ? (
                newFinancePageActive ? (
                  <PayoutOverviewTabSection />
                ) : documentPreferredForFinanceDocumentFlag ? (
                  <OldPayoutWithdrawalsSection />
                ) : (
                  <OldPayoutOverviewTabSection />
                )
              ) : null}
            </>
          ) : (
            //permission $userData->canViewFinanceAsNotMaster();
            incomeStatsTile
          )}
        </VStack>
      </Container>
    </FluentPageLayout>
  );
};
export default FinancePage;
