import {
  Section,
  SectionBody,
  SectionDescription,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import { AssignmentInd } from '@campoint/odi-ui-icons';
import {
  Divider,
  Flex,
  HStack,
  Image,
  Progress,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import type { History } from 'history';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import shield from '../../../../assets/images/shared/shield.svg';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import {
  UrlFragment,
  UrlFragmentScrollToTarget,
} from '../../../../components/Layout/UrlFragmentScrollToTarget';
import { FeatureName, useFeature } from '../../../../flags';
import { ActorFragment } from '../../../../generated/graphql';
import { useAccountStatus } from '../../../../provider/AccountStatusProvider/AccountStatusProvider';
import { RouteReferrerState, routes } from '../../../../routes/routesConfig';
import { useWizardProfile } from '../../../Wizard/Profile/WizardProfileContext';
import { useWizardVerification } from '../../../Wizard/Verification/WizardVerification';
import HomeTaskCardItem, {
  HomeTaskCardItemProps,
} from '../../components/Home/TaskCard/HomeTaskCardItem';
import { EmailVerificationRouterState } from '../../components/Home/VerifyEmailModal/VerifyEmailModal';
import { MRFModal } from './MRFModal';

export const clickableTaskItemStates: HomeTaskCardItemProps['state'][] = [
  'todo',
  'error',
];

type TaskItemProps = {
  state: HomeTaskCardItemProps['state'];
  active?: boolean;
  actor?: ActorFragment | null;
  refetchHome?: () => void;
};

type HistoryState = RouteReferrerState | EmailVerificationRouterState;

export const useHistoryHandler = (
  fn: (history: History<HistoryState>) => void,
  deps: React.DependencyList = []
) => {
  const history = useHistory<HistoryState>();
  return React.useCallback(() => fn(history), [fn, history, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps
};

const referrer = routes.home.path;

const TaskItemAge: React.FC<TaskItemProps> = ({ state, refetchHome }) => {
  const { t } = useTranslation(['home']);
  const documentFlag = useFeature(FeatureName.document);
  const verificationWizard = useWizardVerification();
  const { actions } = useAccountStatus();
  const openVerificationWizard = () => {
    // pass a function of a function because something is weird
    // with the verification wizard, we couldn't figure it out
    verificationWizard.setOnFinished(() => () => {
      actions.refresh();
      refetchHome?.();
    });
    verificationWizard.setVisible(true);
  };

  const navigateToDocumentPage = useHistoryHandler((history) =>
    history.push(routes.documentsActors.path, { referrer })
  );

  const shouldNavigateToDocumentPage = documentFlag && state !== 'todo';

  return (
    <HomeTaskCardItem
      category="age"
      state={state}
      isClickable={clickableTaskItemStates.includes(state)}
      onClick={
        shouldNavigateToDocumentPage
          ? navigateToDocumentPage
          : openVerificationWizard
      }
      onErrorClick={navigateToDocumentPage}
      text={t(`home:cards.tasks.text.Ausweis`)}
    />
  );
};

const TaskItemProfile: React.FC<TaskItemProps> = ({ state }) => {
  const profileWizard = useWizardProfile();
  const { actions } = useAccountStatus();
  const { t } = useTranslation(['home']);

  return (
    <HomeTaskCardItem
      category="profile"
      state={state}
      isClickable={clickableTaskItemStates.includes(state)}
      onClick={() => {
        profileWizard.setOnFinished(() => {
          actions.refresh();
        });
        profileWizard.setVisible!(true);
      }}
      text={t(`home:cards.tasks.text.ModelprofilBasis`)}
    />
  );
};

const TaskItemModelReleaseForm: React.FC<TaskItemProps> = ({
  state,
  active = true,
  actor,
}) => {
  const { t } = useTranslation(['home']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  const onErrorClick = () => {
    history.push({
      pathname: routes.documentsActors.path,
    });
  };

  return (
    <>
      <HomeTaskCardItem
        active={active}
        category="modelReleaseForm"
        state={state}
        isClickable={clickableTaskItemStates.includes(state)}
        onClick={() => {
          if (actor?.userId && active) {
            onOpen();
          }
        }}
        onErrorClick={onErrorClick}
        text={t(`home:cards.tasks.text.ModelReleaseForm`)}
      />
      <MRFModal isOpen={isOpen} onClose={onClose} userId={actor?.userId} />
    </>
  );
};

export type TaskSectionProps = {
  isAccountEmailVerified: boolean;
  steps: {
    [key in HomeTaskCardItemProps['category']]: HomeTaskCardItemProps['state'];
  };
  stepsDone: number;
  actor: Maybe<ActorFragment>;
  refetchHome: () => void;
};

const TaskSection: React.FC<TaskSectionProps> = ({
  steps,
  stepsDone,
  actor,
  refetchHome,
}) => {
  const { t } = useTranslation(['home']);

  const maxSteps = Object.values(steps).length;
  return (
    <Section>
      <SectionHeader pb={[3, 0]}>
        <SectionCenterContainer>
          <UrlFragmentScrollToTarget id={UrlFragment.Tasks} />
          <SectionTitleRow>
            <SectionIcon as={AssignmentInd} />
            <SectionTitle>{t('home:heading.ModelVerifizieren')}</SectionTitle>
          </SectionTitleRow>
          <SectionDescription>
            {t(
              'home:cards.tasks.subtitles.UmGeldVerdienenZuKonnenBenotigenWirInfosZumModel'
            )}
          </SectionDescription>
          <HStack pt={'12px'}>
            <Progress flex={1} value={stepsDone} min={0} max={maxSteps} />
            <Text
              lineHeight={'20px'}
              fontWeight={'medium'}
              fontSize={'md'}
            >{`${stepsDone}/${maxSteps}`}</Text>
          </HStack>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionBody
        as={Flex}
        gap={4}
        flexDir={{ base: 'column' }}
        flexWrap={{ base: 'nowrap', xl: 'wrap' }}
        w={'full'}
        maxW={['container.lg', 'full']}
      >
        <SectionCenterContainer gap={4}>
          <TaskItemAge state={steps.age} refetchHome={refetchHome} />
          <TaskItemModelReleaseForm
            state={steps.modelReleaseForm}
            active={steps.age !== 'todo' && !!actor}
            actor={actor}
          />
        </SectionCenterContainer>
      </SectionBody>
      <SectionCenterContainer px={{ base: '16px', md: '0px' }}>
        <Divider />
      </SectionCenterContainer>
      <SectionHeader pb={[3, 0]}>
        <SectionCenterContainer>
          <UrlFragmentScrollToTarget id={UrlFragment.Tasks} />
          <SectionTitleRow>
            <SectionIcon as={AssignmentInd} />
            <SectionTitle>{t('home:heading.ModelprofilAnlegen')}</SectionTitle>
          </SectionTitleRow>
          <SectionDescription>
            {t('home:cards.tasks.subtitles.WerMochtestDuFurDeineKundenSein')}
          </SectionDescription>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionBody
        as={Flex}
        gap={4}
        flexDir={{ base: 'column' }}
        flexWrap={{ base: 'nowrap', xl: 'wrap' }}
        w={'full'}
        maxW={['container.lg', 'full']}
      >
        <SectionCenterContainer>
          <TaskItemProfile state={steps.profile} />
        </SectionCenterContainer>
      </SectionBody>
      <VStack
        minH={'70px'}
        gap={0}
        w={'full'}
        maxW={['container.lg', 'full']}
        pb={6}
      >
        <Image w={'30px'} h={'35px'} src={shield} m={1} />
        <Text
          color={'caribbeanGreen.500'}
          fontWeight={'normal'}
          fontSize={'sm'}
          lineHeight={'14px'}
        >
          {t('home:cards.tasks.info.DeineDatenSindSicher')}
        </Text>
        <Text
          color={'caribbeanGreen.500'}
          fontWeight={'normal'}
          fontSize={'sm'}
          lineHeight={'14px'}
        >
          {t('home:cards.tasks.info.OhneKompromiss')}
        </Text>
      </VStack>
    </Section>
  );
};

export default TaskSection;
