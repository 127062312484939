import { SubHeader, SubHeaderTitle } from '@campoint/odi-ui';
import {
  Container,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import usp5 from '../../assets/images/shared/feed-preview.svg';
import usp3 from '../../assets/images/shared/go-online-chatting.svg';
import usp4 from '../../assets/images/shared/payout.svg';
import usp1 from '../../assets/images/usp/usp1.svg';
import usp2 from '../../assets/images/usp/usp2.svg';
import { FluentPageLayout } from '../../components/Layout/FluentPageLayout';
import { LayoutCenteringVStack } from '../../components/Layout/LayoutCenteringVStack';
import ScrollToLastKnownPositionOnMount from '../../components/Layout/ScrollToLastKnownPositionOnMount';
import PayoutDataCheckPendingCard from '../../components/shared/Payout/PayoutDataCheckPendingCard/PayoutDataCheckPendingCard';
import PayoutDataCheckRejectedCard from '../../components/shared/Payout/PayoutDataCheckRejectedCard/PayoutDataCheckRejectedCard';
import {
  USPModal,
  USPModalSteps,
} from '../../components/shared/USPModal/USPModal';
import { UpdateTaxInformationExpiredHintSlot } from '../../components/shared/UpdateTaxInformationExpiredHintSlot/UpdateTaxInformationExpiredHintSlot';
import { UpdateTaxInformationRejectedCardSlot } from '../../components/shared/UpdateTaxInformationRejectedCardSlot/UpdateTaxInformationRejectedCardSlot';
import {
  AuthUserPermissionEnum,
  FormManagerPaperlessForm,
  FormStatusEnum,
  GenderEnum,
  HelpcenterFaqEntry,
  ModelReleaseFormLanguageEnum,
  ModelReleaseFormRequestContextEnum,
  ProfileStatusEnum,
  useDocumentDetailPageMrfLazyQuery,
  useGetHomeDataQuery,
} from '../../generated/graphql';
import { useAccountStatus } from '../../provider/AccountStatusProvider/AccountStatusProvider';
import { useAuth } from '../../provider/AuthProvider';
import { useContest } from '../../provider/ContestProvider';
import { useFinanceService } from '../../provider/FinanceService/FinanceService';
import { useFlagsProviderV2 } from '../../provider/FlagsProviderV2';
import { useEmailVerify } from '../../provider/MandatoryEmailVerificationProvider';
import { useOnboardingTaskStatusContext } from '../../provider/OnboardingTaskStatusProvider';
import { useTour } from '../../provider/TourProvider/TourProvider';
import { extractFromUnion } from '../../utils/extractor';
import { extractDefinedEntries } from '../../utils/utils';
import { InfoPayoutWillBeBlockedAlert } from '../FinancePage/section/PayoutTaxSection/PayoutTaxSection';
import {
  MRFHash,
  useWizardMRF,
} from '../Wizard/ModelReleaseForm/WizardMRFContext';
import { PayoutV2ContinueHash } from '../Wizard/PayoutV2/WizardPayout.util';
import { useWizardPayout } from '../Wizard/PayoutV2/WizardPayoutContext';
import EmailNotVerifiedHint from './components/Home/EmailNotVerifiedHint/EmailNotVerifiedHint';
import { GoOnlineCard } from './components/Home/GoOnlineCard/GoOnlineCard';
import { OnboardingTaskState } from './components/Home/TaskCard/HomeTaskCardItem';
import { ContestSection } from './section/ContestSection/ContestSection';
import { HelpCenterSection } from './section/HelpCenterSection/HelpCenterSection';
import { IncomeSourceSection } from './section/IncomeSourceSection/IncomeSourceSection';
import StatisticSection from './section/StatisticSection/StatisticSection';
import { IncomeStatsTile } from './section/StatisticSection/StatsTile/StatsTile';
import TaskSection, {
  TaskSectionProps,
} from './section/TaskSection/TaskSection';
import { TipSection } from './section/TipSection/TipSection';

type DisplayData = {
  faqEntries: HelpcenterFaqEntry[];
  payableAmount: number;
};

type VisibilitySwitches = {
  emailNotVerifiedHint: boolean;
  faq: boolean;
  goOnlineCard: boolean;
  contestSection: boolean;
  incomeCard: boolean;
  taskCard: boolean;
  statisticsSection: boolean;
};

const steps: TaskSectionProps['steps'] = {
  age: 'todo',
  profile: 'todo',
  modelReleaseForm: 'todo',
};

const Home: React.FC = () => {
  const { authUser, isMasterAccount } = useAuth();
  const financePermission = authUser?.permissions.includes(
    AuthUserPermissionEnum.FinanceView
  );
  const { coinsEnabled } = useFlagsProviderV2();
  const {
    loading: isFinanceServiceLoading,
    isFirstPayout,
    payableAmount,
    hasEnoughAmountForPayout,
    payoutWizardStatus,
    action: { triggerRefetch: triggerFinanceServiceRefetch },
  } = useFinanceService();

  const {
    isAccountStatusLoading,
    hasAllOnboardingTasksCompleted,
    verificationTourStatus,
    hasSetUsername,
    profileTourStatus,
    isAllowedToGoOnlineOnVXLive,
    isAllowedToGoOnlineOnVXLiveButWasNotYet,
    wasOnlineOnVXLive,
    actions: { refresh: triggerAccountStatusRefetch },
  } = useAccountStatus();

  const { t } = useTranslation(['home', 'general', 'usp', 'payout']);
  const history = useHistory();
  const { search, hash } = useLocation();

  const {
    data,
    loading: isDataLoading,
    refetch: refetchHome,
  } = useGetHomeDataQuery({
    variables: {
      actorId: Number(authUser?.userId) ?? 0,
    },
  });

  const refetchHomeCB = React.useCallback(() => {
    refetchHome();
  }, [refetchHome]);

  const breakpointValueLoaded = useBreakpointValue({ base: true });
  const contestContext = useContest();

  const isContentLoading =
    isDataLoading ||
    isAccountStatusLoading ||
    isFinanceServiceLoading ||
    contestContext.isContestLoading ||
    breakpointValueLoaded === undefined;

  const { isEmailVerified, differences: onboardingStatusDifferences } =
    useOnboardingTaskStatusContext();

  const { isEmailVerificationRequiredToProceed } = useEmailVerify();

  const {
    isOpen: isUspModalOpen,
    onOpen: openUspModal,
    onClose: closeUspModal,
  } = useDisclosure();

  // Handle first login and the usps
  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const isFirstLoginQueryParamKey = 'isFirstLogin';

    if (!queryParams.get(isFirstLoginQueryParamKey)) {
      return;
    }

    // Added in order to have the email verification modal be on top when the user is not verified.
    if (!isEmailVerificationRequiredToProceed) {
      openUspModal();
      queryParams.delete(isFirstLoginQueryParamKey);
      history.replace({ search: queryParams.toString() });
    }
  }, [history, openUspModal, isEmailVerificationRequiredToProceed, search]);

  useEffect(() => {
    if (onboardingStatusDifferences.length > 0) {
      triggerAccountStatusRefetch();
      refetchHome().then();
    }
  }, [onboardingStatusDifferences, refetchHome, triggerAccountStatusRefetch]);

  useEffect(() => {
    triggerAccountStatusRefetch();
    triggerFinanceServiceRefetch();
  }, [triggerFinanceServiceRefetch, triggerAccountStatusRefetch]);

  const tourCtx = useTour();

  useEffect(() => {
    if (wasOnlineOnVXLive) {
      return;
    }
    if (hasAllOnboardingTasksCompleted) {
      tourCtx.actions.startWelcomeTour({ skipIfAlreadFinishedOnce: true });
    }
  }, [tourCtx, wasOnlineOnVXLive, hasAllOnboardingTasksCompleted]);

  const displayData: DisplayData = {
    faqEntries: extractDefinedEntries(data?.helpcenter?.faq?.entries),
    payableAmount: data?.finances.payoutStatus.payableAmount ?? 0,
  };

  const mappedEmailData = React.useMemo(() => {
    const email = data?.account.contact.currentEmailAddress ?? '';
    const isNewEmailVerified =
      data?.account.contact.isCurrentEmailAddressVerified ?? false;

    return {
      email,
      isNewEmailVerified,
    };
  }, [data]);

  const gender = extractFromUnion(
    data?.profile?.collection,
    'ProfileCollectionMobileAboutMeV1'
  )?.fields.modelGender?.value;

  const actor = React.useMemo(() => {
    return data?.account?.actors?.at(0);
  }, [data?.account?.actors]);

  const visibilitySwitches: VisibilitySwitches = {
    emailNotVerifiedHint: !mappedEmailData.isNewEmailVerified,
    faq: isFirstPayout || !hasEnoughAmountForPayout,
    incomeCard: payableAmount > 0,
    taskCard: !isAllowedToGoOnlineOnVXLive,
    goOnlineCard: isAllowedToGoOnlineOnVXLiveButWasNotYet,
    statisticsSection: isAllowedToGoOnlineOnVXLive,
    contestSection: isAllowedToGoOnlineOnVXLive && gender === GenderEnum.Female,
  };

  const incomeCardHeadline = isFirstPayout
    ? t('home:cards.incomeCard.headline1')
    : t('home:cards.incomeCard.headline2');

  const uspSteps: USPModalSteps[] = [
    {
      image: {
        src: usp1,
        alt: t('usp:img.ModelPosiertMitWehendemSuperheldenCape'),
      },
      heading: t('usp:heading.WillkommenBeiVXModels'),
      text: t('usp:text.UndHerzlichenGluckwunschAbHeuteBistDuDeinEigenerBoss'),
    },
    {
      image: {
        src: usp2,
        alt: t('usp:img.ModelStehtVergleichendNebenIhremModelprofil'),
      },
      heading: t('usp:heading.VerwalteDeinModelprofil'),
      text: t(
        'usp:text.PflegeDeinVISITXModelprofilUmEinenKundenstammAufzubauen'
      ),
    },
    {
      image: {
        src: usp3,
        alt: t('usp:img.ModelChattetMitKunde'),
      },
      heading: t('usp:heading.GeheVonUberallOnlineUndVerdieneDeinGeld'),
      text: t(
        'usp:text.EgalWoDuGeradeBistChatteOderCameMitDeinenKundenVonUberall'
      ),
    },
    {
      image: {
        src: usp4,
        alt: t('usp:img.DiagrammSteigenderEinnahmen'),
      },
      heading: t('usp:heading.BehalteDenUberblickUberDeinenVerdienst'),
      text: t('usp:text.CheckeDeinenVerdienstRundUmDieUhr'),
    },
    {
      image: {
        src: usp5,
        alt: t('usp:img.FeedBildAlt'),
      },
      heading: t('usp:heading.TeileDieSchoenstenMomenteDeinesTages'),
      text: t('usp:text.FeedErstelleBeitraegeMitBildernoderClipsUndHalteDeine'),
    },
  ];

  const [getMRFData, { data: mrfData, refetch }] =
    useDocumentDetailPageMrfLazyQuery();

  const requestContext = React.useMemo(() => {
    const originator = data?.account.originator;
    const isOriginator = originator?.userId === authUser?.userId;
    if (isOriginator) return ModelReleaseFormRequestContextEnum.Originator;

    const actor = data?.account.actor;
    const isActor = actor?.userId === authUser?.userId;
    if (isActor) return ModelReleaseFormRequestContextEnum.Actor;

    return ModelReleaseFormRequestContextEnum.Model;
  }, [authUser?.userId, data?.account.actor, data?.account.originator]);

  useEffect(() => {
    if (authUser?.userId) {
      getMRFData({
        variables: {
          userId: Number(authUser?.userId),
          lang: ModelReleaseFormLanguageEnum.De,
          requestContext,
        },
      });
    }
  }, [authUser?.userId, getMRFData, requestContext]);

  const mrfStatusToTourStatus = React.useCallback(
    (mrf: Maybe<FormManagerPaperlessForm>): OnboardingTaskState => {
      //mrf is not directly there when we come back from paperless
      if (hash === MRFHash && !mrf) return 'pending';
      if (mrf?.status === FormStatusEnum.Pending) return 'pending';
      if (mrf?.status === FormStatusEnum.Rejected) return 'error';
      if (mrf?.status === FormStatusEnum.Accepted) return 'done';
      else return 'todo';
    },
    [hash]
  );

  // redirected from paperless
  const wizardMRF = useWizardMRF();
  const wizardPayoutV2 = useWizardPayout();
  useEffect(() => {
    if (hash === PayoutV2ContinueHash) {
      wizardPayoutV2.setVisible(true);
    }
  }, [
    hash,
    mrfData?.documents.modelReleaseForm,
    refetch,
    wizardMRF,
    wizardPayoutV2,
  ]);
  const spacing = { base: 4, lg: 8 };

  const stepsMemo = React.useMemo(() => {
    steps.age = verificationTourStatus;
    steps.profile = profileTourStatus;
    steps.modelReleaseForm = mrfStatusToTourStatus(
      mrfData?.documents.modelReleaseForm
    );
    return steps;
  }, [
    mrfData?.documents.modelReleaseForm,
    mrfStatusToTourStatus,
    profileTourStatus,
    verificationTourStatus,
  ]);

  const stepsDone = Object.values(stepsMemo).filter(
    (status) => status !== 'todo'
  ).length;

  return (
    <>
      <USPModal
        isOpen={isUspModalOpen}
        onClose={closeUspModal}
        closeButtonText={t('usp:button.CoolAllesKlar')}
        continueButtonText={t('general:button.Weiter')}
        steps={uspSteps}
      />

      <FluentPageLayout isContentLoading={isContentLoading}>
        <ScrollToLastKnownPositionOnMount identifier={'/home'} />
        <SubHeader maxH={'56px'}>
          <Container px={0} maxW={'container.xl'}>
            <SubHeaderTitle>
              {!hasSetUsername
                ? t('home:headline.WillkommenbeiVXModels')
                : t('home:headline.WillkommenZuruck', {
                    name: authUser?.username,
                  })}
            </SubHeaderTitle>
          </Container>
        </SubHeader>
        <Container maxW="container.xl" p={0}>
          <VStack spacing={spacing} w={'full'} mb={spacing} mt={spacing}>
            {visibilitySwitches.emailNotVerifiedHint && (
              <LayoutCenteringVStack>
                <EmailNotVerifiedHint email={mappedEmailData.email} />
              </LayoutCenteringVStack>
            )}

            <InfoPayoutWillBeBlockedAlert />
            <UpdateTaxInformationExpiredHintSlot />
            <UpdateTaxInformationRejectedCardSlot />

            {financePermission &&
              visibilitySwitches.incomeCard &&
              !coinsEnabled && (
                <LayoutCenteringVStack>
                  <IncomeStatsTile
                    headline={incomeCardHeadline}
                    payableAmount={displayData.payableAmount}
                    showIncomeIndicator={false}
                  />
                </LayoutCenteringVStack>
              )}

            {isMasterAccount &&
              payoutWizardStatus === ProfileStatusEnum.Pending && (
                <LayoutCenteringVStack>
                  <PayoutDataCheckPendingCard />
                </LayoutCenteringVStack>
              )}
            {isMasterAccount &&
              payoutWizardStatus === ProfileStatusEnum.Rejected && (
                <LayoutCenteringVStack>
                  <PayoutDataCheckRejectedCard reason={''} />
                </LayoutCenteringVStack>
              )}

            {visibilitySwitches.taskCard && (
              <TaskSection
                isAccountEmailVerified={isEmailVerified}
                steps={stepsMemo}
                stepsDone={stepsDone}
                actor={actor}
                refetchHome={refetchHomeCB}
              />
            )}

            {visibilitySwitches.goOnlineCard && (
              <LayoutCenteringVStack>
                <GoOnlineCard />
              </LayoutCenteringVStack>
            )}

            {visibilitySwitches.contestSection === true &&
              contestContext.videoContest !== null && (
                <ContestSection contest={contestContext.videoContest} />
              )}
            {visibilitySwitches.contestSection === true &&
              contestContext.photoContest !== null && (
                <ContestSection contest={contestContext.photoContest} />
              )}

            {/* The statistics section should only be shown if the model is ready to go only
            (-> has finished all onboarding tasks) */}
            {visibilitySwitches.statisticsSection && <StatisticSection />}

            {/* TODO implement if decision is made */}
            {/* {!isMasterAccount && coinsEnabled && <SharingNoMasterSection />} */}

            <TipSection />

            <IncomeSourceSection />

            {visibilitySwitches.faq && (
              <HelpCenterSection entries={displayData.faqEntries} />
            )}
          </VStack>
        </Container>
      </FluentPageLayout>
    </>
  );
};

export default Home;
