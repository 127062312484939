import { Button, Heading, Img, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';

import mrfImage from '../../../../assets/images/wizard/model-release-form-welcome.svg';
import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import {
  ResponsiveModal,
  ResponsiveModalBodyBox,
  ResponsiveModalCloseButton,
  ResponsiveModalContent,
  ResponsiveModalOverlay,
  ResponsiveModalStickyHeaderBox,
} from '../../../../components/Layout/ResponsiveModal';
import ENV from '../../../../environments/environment';
import {
  ApiLangEnum,
  ModelReleaseFormLanguageEnum,
  useInitModelReleaseFormMutation,
} from '../../../../generated/graphql';
import { routes } from '../../../../routes/routesConfig';
import Logger from '../../../../utils/Logger';
import { windowOpen } from '../../../../utils/utils';
import { MRFHash } from '../../../Wizard/ModelReleaseForm/WizardMRFContext';

export const MRFModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  userId?: number | undefined;
}> = ({ isOpen, onClose, userId }) => {
  const [loading, setloading] = React.useState(false);

  const locale = useIntl().locale;
  const { t } = useTranslation(['language', 'mrfModal', 'wizardMRF']);
  const [addMRFMutation] = useInitModelReleaseFormMutation({});
  const getOrigin = () => window.location?.origin ?? ENV.HOST;

  const toPaperless = React.useCallback(async () => {
    setloading(true);

    const lang =
      locale === ApiLangEnum.De
        ? ModelReleaseFormLanguageEnum.De
        : ModelReleaseFormLanguageEnum.En;
    const redirectUrl = `${getOrigin()}${routes.home.path}${MRFHash}`;
    if (!lang) return;

    try {
      const resp = await addMRFMutation({
        variables: {userId, lang, redirectUrl},
      });
      const url = resp.data?.documents?.initModelReleaseForm.formUrl;

      if (!url) return;
      windowOpen(url, '_self');
    } catch (error) {
      Logger.error(error);
      issueChakraToast({
        status: 'error',
        description: t(
          'mrfModal:toast.DokumenteBefindenSichInPrufungBitteZuEinemSpaterenZeitpunktErneutProXX'
        ),
      });
    } finally {
      setloading(false);
      onClose();
    }
  }, [userId, addMRFMutation, locale, onClose, t]);

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <ResponsiveModalOverlay onClick={onClose} />
      <ResponsiveModalContent
        maxW={'567px'}
        bgColor={'surface'}
        color={'coldGray.900'}
      >
        <ResponsiveModalStickyHeaderBox bg={'surface'}>
          <ResponsiveModalCloseButton onClick={onClose} />
        </ResponsiveModalStickyHeaderBox>
        <ResponsiveModalBodyBox bg={'transparent'} mt={'20px'}>
          <VStack alignItems={'stretch'} textAlign={'center'}>
            <Img
              src={mrfImage}
              alt={t('mrfModal:img.HandschlagNachVertragsunterzeichnung')}
              maxH={'150px'}
            />
            <Heading size={'lg'}>
              {t('mrfModal:text.ModelReleaseFormAusfullen')}
            </Heading>
            <Text
              color={'gray.500'}
              fontSize={'sm'}
              lineHeight={'20px'}
              marginBottom={'15px'}
              mb={3}
            >
              {t(
                'mrfModal:text.UmDeineInhalteVeroffentlichenZuKonnenBenotigenWirDeinEinverstandnisDXX'
              )}
            </Text>
            <Text
              color={'gray.500'}
              fontSize={'sm'}
              lineHeight={'20px'}
              marginBottom={'15px'}
              mb={3}
            >
              {t(
                'mrfModal:text.DuWirstZuUnseremPartnerPaperlessWeitergeleitet'
              )}
            </Text>
            <Button
              isLoading={loading}
              alignSelf={'center'}
              onClick={() => toPaperless()}
              mb={2}
              variant={'solid'}
            >
              {t('mrfModal:button.ModelReleaseFormAusfullen')}
            </Button>
          </VStack>
        </ResponsiveModalBodyBox>
      </ResponsiveModalContent>
    </ResponsiveModal>
  );
};
