import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  Circle,
  Heading,
  Icon,
  Image,
  Spinner,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import UploadButton from '@rpldy/upload-button';
import { t } from 'i18next';

import { DialogModal } from '../../../../../components/shared/DialogModal/DialogModal';
import {
  MediaContestTypeEnum,
  VideoTypeEnum,
} from '../../../../../generated/graphql';
import { useContestVideoModalContext } from '../../../../../provider/VideoLibraryProvider/VideoLibraryEntryModalProvider/VideoContestModalProvider';
import { useUploadyService } from '../../../../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/VideoLibraryUploadyProvider';
import { getSrcSet } from '../../../../../utils/utils';
import {
  ContestTileBase,
  ContestTileBaseProps,
  ContestTileHeaderImage,
} from './ContestTileBase';

const ContestTileUploadStatus: React.FC<
  ContestTileBaseProps & {
    disclosure: ReturnType<typeof useDisclosure>;
  }
> = ({ children, disclosure, ...props }) => {
  const contest = props.contest;
  const { setContest, videoModalDisclosure } = useContestVideoModalContext();
  const { tusUploadDataLoading } = useUploadyService();

  if (tusUploadDataLoading) {
    children = <Spinner />;
  }

  return (
    <ContestTileBase {...props}>
      <DialogModal
        isOpen={disclosure.isOpen}
        headline={t('contest:heading.MochtestDuDasVideoWirklichErsetzen', {
          type:
            contest.type === MediaContestTypeEnum.Photo
              ? t('contest:label.Photo')
              : t('contest:label.Video'),
        })}
        text={t('contest:text.DieseAktionKannNichtRuckgangigGemachtWerden')}
        confirmButtonText={t('document:button.namedErsetzen', {
          named:
            contest.type === MediaContestTypeEnum.Photo
              ? t('contest:label.Photo')
              : t('contest:label.Video'),
        })}
        confirmButtonProps={{
          as: UploadButton,
          params: { type: VideoTypeEnum.VideoContest },
          variant: 'solid',
        }}
        closeButtonText={t('general:button.Verwerfen')}
        onConfirm={() => {
          disclosure.onClose();
          videoModalDisclosure.onOpen();
          setContest?.(contest);
        }}
        onClose={disclosure.onClose}
      />
      <ContestTileHeaderImage contest={contest} />
      <VStack
        p={3}
        textAlign={'center'}
        flex={1}
        justifyContent={'center'}
        lineHeight={1.3}
        gap={0}
      >
        {children}
      </VStack>
    </ContestTileBase>
  );
};

export const ContestTileUploadRejected: typeof ContestTileBase = ({
  contest,
  ...props
}) => {
  const disclosure = useDisclosure();
  const srcSet = getSrcSet(contest);
  return (
    <ContestTileUploadStatus
      contest={contest}
      disclosure={disclosure}
      {...props}
    >
      <Heading size="sm">
        {t('contest:heading.DeinVideoWurdeAbgelehnt', {
          type:
            contest.type === MediaContestTypeEnum.Photo
              ? t('contest:label.Photo')
              : t('contest:label.Video'),
        })}
      </Heading>
      <Text fontWeight={'500'} color={'gray.300'} mx={5}>
        {t('contest:text.LeiderWurdeDeinVideoAbgelehntProbiereEsGerneErneut', {
          type:
            contest.type === MediaContestTypeEnum.Photo
              ? t('contest:label.Photo')
              : t('contest:label.Video'),
        })}
      </Text>
      <Box
        width={16 * 12 + 'px'}
        height={9 * 12 + 'px'}
        borderRadius={10}
        overflow={'hidden'}
        backgroundColor={'black'}
        justifyContent={'center'}
        textAlign={'center'}
        my={3}
      >
        <Image
          width={'inherit'}
          height={'inherit'}
          srcSet={srcSet}
          opacity={srcSet !== undefined ? 0.3 : 0}
        />
        <Box color={'white'} marginTop={'-80px'} position={'relative'}>
          <Circle
            backgroundColor={'red.500'}
            p={1}
            mb={1}
            display={'inline-block'}
          >
            <Icon as={icons.ErrorOutline} boxSize={'icon.lg'} />
          </Circle>
          <Text>{t('general:status.Abgelehnt')}</Text>
        </Box>
      </Box>
      <Button onClick={disclosure.onOpen}>
        {t('document:button.namedErsetzen', {
          named:
            contest.type === MediaContestTypeEnum.Photo
              ? t('contest:label.Photo')
              : t('contest:label.Video'),
        })}
      </Button>
    </ContestTileUploadStatus>
  );
};

export const ContestTileUploadPending: typeof ContestTileBase = ({
  contest,
  ...props
}) => {
  const disclosure = useDisclosure();
  const srcSet = getSrcSet(contest);
  return (
    <ContestTileUploadStatus
      contest={contest}
      disclosure={disclosure}
      {...props}
    >
      <Heading size="sm">
        {t('contest:heading.DeinVideoWirdGepruft', {
          type:
            contest.type === MediaContestTypeEnum.Photo
              ? t('contest:label.Photo')
              : t('contest:label.Video'),
        })}
      </Heading>
      <Text fontWeight={'500'} color={'gray.300'} mx={5}>
        {t(
          'contest:text.SolangeDeinVideoGepruftWordKannstDuDeineTeilnahmeFurDeineFansBekanntXX',
          {
            type:
              contest.type === MediaContestTypeEnum.Photo
                ? t('contest:label.Photo')
                : t('contest:label.Video'),
          }
        )}
      </Text>
      <Box
        width={16 * 12 + 'px'}
        height={9 * 12 + 'px'}
        borderRadius={10}
        overflow={'hidden'}
        backgroundColor={'black'}
        justifyContent={'center'}
        textAlign={'center'}
        my={3}
      >
        <Image
          width={'inherit'}
          height={'inherit'}
          srcSet={srcSet}
          opacity={srcSet !== undefined ? 0.3 : 0}
        />
        <Box color={'white'} marginTop={'-80px'} position={'relative'}>
          <Circle
            backgroundColor={'warning.500'}
            p={1}
            mb={1}
            display={'inline-block'}
          >
            <Icon as={icons.Schedule} boxSize={'icon.lg'} />
          </Circle>
          <Text>{t('general:status.Prufung')}</Text>
        </Box>
      </Box>
      <Button onClick={disclosure.onOpen}>
        {t('document:button.namedErsetzen', {
          named:
            contest.type === MediaContestTypeEnum.Photo
              ? t('contest:label.Photo')
              : t('contest:label.Video'),
        })}
      </Button>
    </ContestTileUploadStatus>
  );
};
