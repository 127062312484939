import {
  Section,
  SectionBody,
  SectionHeader,
  SectionIcon,
  SectionTitle,
  SectionTitleRow,
} from '@campoint/odi-ui';
import * as icons from '@campoint/odi-ui-icons';
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  Text,
  VStack,
  forwardRef,
  useDisclosure,
} from '@chakra-ui/react';
import { EmotionIcon } from '@emotion-icons/emotion-icon';
import { useRetry } from '@rpldy/retry-hooks';
import { FILE_STATES } from '@rpldy/uploady';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ListEmptyState from '../../../../assets/images/shared/list-empty-state.svg';
import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import {
  ResponsiveModal,
  ResponsiveModalOverlay,
} from '../../../../components/Layout/ResponsiveModal';
import { ScrollToTargetBlock } from '../../../../components/Layout/ScrollToTargetBlock';
import { SectionCenterContainer } from '../../../../components/Layout/SectionCenterContainer';
import { SectionDivider } from '../../../../components/Layout/SectionDivider';
import { UrlFragment } from '../../../../components/Layout/UrlFragmentScrollToTarget';
import { AnimatedVXLogo } from '../../../../components/shared/AnimatedVXLogo/AnimatedVXLogo';
import { BlockerCard } from '../../../../components/shared/BlockerCard/BlockerCard';
import { FetchMoreIndicator } from '../../../../components/shared/FetchMoreIndicator/FetchMoreIndicator';
import { PercentProgressSpinner } from '../../../../components/shared/GradientSpinner/GradientSpinner';
import { VideoFragment, VideoStatusEnum } from '../../../../generated/graphql';
import { useVideoLibraryEntryModalContext } from '../../../../provider/VideoLibraryProvider/VideoLibraryEntryModalProvider/VideoLibraryEntryModalProvider';
import { useUploadyService } from '../../../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/VideoLibraryUploadyProvider';
import { TusUploadEntry } from '../../../../provider/VideoLibraryProvider/VideoLibraryUploadyProvider/videoUploadEnhancer';
import { ErrorModalContent } from '../../../FeedPage/components/modal/component/ErrorModalContent';
import { VideoLibraryEntryMenu } from '../VideoLibraryEntryMenu/VideoLibraryEntryMenu';

export const VideoLibraryPageTabSectionUnused = () => {
  const { t } = useTranslation(['videoLibrary']);
  const {
    uploads,
    videos,
    initialLoading,
    videosLoading,
    videosError,
    unusedMaxCount,
    unusedTotalCount,
    isUploading,
    loadMore,
  } = useUploadyService();

  const isUploadsEmpty = (uploads?.length ?? 0) === 0;
  const isVideosEmpty = (videos?.length ?? 0) === 0;

  return (
    <Section minH={'300px'}>
      <SectionHeader>
        <SectionCenterContainer maxW={'unset'}>
          <SectionTitleRow alignItems={'center'}>
            <SectionIcon as={icons.Edit} />
            <SectionTitle fontSize={{ base: '18px', lg: '24px' }}>
              {t('videoLibrary:heading.Entwurfe')}
            </SectionTitle>
            <Text
              as={'span'}
              px={4}
              fontSize={'md'}
              fontWeight={'semibold'}
              color={'steel'}
            >
              {t('videoLibrary:text.UnusedTotalCountUnusedMaxCountVideos', {
                unusedTotalCount,
                unusedMaxCount,
              })}
            </Text>
          </SectionTitleRow>
        </SectionCenterContainer>
      </SectionHeader>
      <SectionDivider />
      <SectionBody>
        <VStack gap={2} pt={0}>
          {initialLoading && (
            <Center>
              <Box w={'96px'} h={'96px'}>
                <AnimatedVXLogo />
              </Box>
            </Center>
          )}
          {isVideosEmpty && isUploadsEmpty && (
            <BlockerCard
              image={ListEmptyState}
              heading={t('videoLibrary:heading.NochKeineVideosVorhanden')}
            />
          )}
          {isUploading && (
            <HStack
              w={'full'}
              minH={'44px'}
              border={'1px'}
              borderColor={'caribbeanGreen.500'}
              borderRadius={'6px'}
              gap={2}
              p={2}
              mb={2}
            >
              <Icon
                alignSelf={'start'}
                as={icons.Info}
                color={'caribbeanGreen.500'}
                boxSize={'icon.md'}
              />
              <Text fontWeight={'medium'}>
                {t(
                  'videoLibrary:text.BitteHalteVXModelsWaehrendDesUploadsGeoeffnet'
                )}
              </Text>
            </HStack>
          )}
          {!initialLoading && (!isVideosEmpty || !isUploadsEmpty) && (
            <VStack
              w={'full'}
              as={List}
              divider={<Divider />}
              alignItems={'stretch'}
            >
              {!isUploadsEmpty &&
                uploads.map((upload, index) => {
                  return index === 0 ? (
                    <ScrollToTargetBlock
                      id={UrlFragment.UploadItem}
                      key={upload.id}
                    >
                      <UploadListItem entry={upload} />
                    </ScrollToTargetBlock>
                  ) : (
                    <UploadListItem entry={upload} key={upload.id} />
                  );
                })}

              {!isVideosEmpty && (
                <>
                  {videos?.map((mediaAlbum: VideoFragment) => {
                    return (
                      <UploadHistoryListItem
                        video={mediaAlbum}
                        key={mediaAlbum.videoId}
                      />
                    );
                  })}
                  <FetchMoreIndicator
                    mt={{ base: '8', lg: '12' }}
                    loading={videosLoading}
                    error={videosError}
                    onIsVisible={loadMore}
                  />
                </>
              )}
            </VStack>
          )}
        </VStack>
      </SectionBody>
    </Section>
  );
};

const UploadListItem: React.FC<{ entry: TusUploadEntry }> = ({ entry }) => {
  const retry = useRetry();
  const metaData = entry?.metaData;
  const progress = entry?.progress ?? 0;

  const thumbnailSrc = React.useMemo(() => {
    if (metaData?.type !== 'video') {
      return undefined;
    }
    return URL.createObjectURL(metaData.thumbnail?.blob);
  }, [metaData]);

  return (
    <HStack as={ListItem} minH={'44px'} gap={0} spacing={0}>
      <UploadListItemPreview thumbnailSrc={thumbnailSrc} />
      <Box flexGrow={1}>
        <UploadListItemStatus
          progress={progress}
          status={entry.status}
          error={entry.error}
        />
      </Box>
      {entry.status === FILE_STATES.ERROR && (
        <IconButton
          color={'coldGray.900'}
          variant={'link'}
          display={'block'}
          aria-label={'retry'}
          onClick={() => {
            retry(entry.id);
          }}
          icon={<Icon as={icons.Refresh} boxSize={'icon.md'} />}
        />
      )}
    </HStack>
  );
};

const UploadListItemStatus: React.FC<{
  progress: number;
  status?: any;
  error?: string;
}> = ({ progress, status, error }) => {
  const { t } = useTranslation(['videoLibrary']);
  if (
    status === FILE_STATES.ERROR ||
    status === FILE_STATES.CANCELLED ||
    status === FILE_STATES.ABORTED
  ) {
    return (
      <HStack>
        <Icon color={'error.500'} as={icons.Error} />
        <Text color={'error.500'}>{error}</Text>
      </HStack>
    );
  }

  return (
    <HStack ml={'-2px'}>
      <UploadListItemStatusProgressSpinner spinnerType={'uploading'} />
      <Text>{t('videoLibrary:videoStatus.Upload')}</Text>
      <Text>{progress.toFixed(0)}%</Text>
    </HStack>
  );
};

const UploadHistoryListItem: React.FC<{
  video: VideoFragment;
}> = ({ video }) => {
  const { actions } = useVideoLibraryEntryModalContext();
  const { onDelete } = useUploadyService();
  const { t } = useTranslation(['videoLibrary']);

  return (
    <HStack as={ListItem} py={4} minH={'44px'} gap={0} spacing={0}>
      <UploadListItemPreview
        thumbnailSrc={
          video.previewPicture16?.image.src ?? video.previewPicture18?.image.src
        }
      />
      <Box flexGrow={1} wordBreak={'break-all'} pr={1}>
        <UploadHistoryListItemStatus
          plannedReleaseDate={video?.plannedReleaseDate}
          status={video?.status}
          rejectionReason={video?.rejectionReason}
          albumId={Number(video?.albumId)}
          originalFileName={video?.originalFileName}
        />
      </Box>
      {video.status !== VideoStatusEnum.Transcoding && (
        <Text color={'darkSteel'} fontWeight={'normal'} fontSize={'sm'}>
          ({video.albumId})
        </Text>
      )}
      <VideoLibraryEntryMenu
        ml={2}
        mediaType={'video'}
        onClickOnDelete={() => {
          onDelete(video?.albumId);
        }}
        onClickOnEdit={() => {
          if (!video.isEditable) {
            issueChakraToast({
              status: 'error',
              description: t(
                'videoLibrary:toast.DasVideoKannGeradeNichtBearbeitetWerden'
              ),
            });
            return;
          }
          actions.openModal(video?.albumId ?? null);
        }}
        directDelete={video?.status === VideoStatusEnum.Rejected}
      />
    </HStack>
  );
};

const UploadHistoryListItemStatus: React.FC<{
  plannedReleaseDate?: any;
  status?: VideoStatusEnum | null | undefined;
  originalFileName?: String | null | undefined;
  rejectionReason?: String | null | undefined;
  albumId: number;
}> = ({
  plannedReleaseDate,
  status = '',
  rejectionReason = '',
  albumId,
  originalFileName,
}) => {
  const { t } = useTranslation(['videoLibrary', 'general']);
  const videoRejectedDisclosure = useDisclosure();
  const { onDelete } = useUploadyService();

  switch (status) {
    case VideoStatusEnum.New:
      return (
        <HStack>
          <Icon color={'caribbeanGreen.500'} as={icons.Sparkle} />
          <Text>{originalFileName ?? t('general:status.Neu')}</Text>
        </HStack>
      );
    case VideoStatusEnum.Online:
      return (
        <HStack>
          <Icon as={icons.Visibility} />
          <Text>{t('general:status.Online')}</Text>
        </HStack>
      );
    case VideoStatusEnum.Offline:
      return (
        <HStack>
          <Icon as={icons.VisibilityOff} />
          <Text>{t('general:status.Offline')}</Text>
        </HStack>
      );

    case VideoStatusEnum.Rating:
      return (
        <HStack>
          <Icon as={icons.Schedule} color={'warning.500'} />
          <Text>{t('general:status.Prufung')}</Text>
        </HStack>
      );
    case VideoStatusEnum.Plannend:
      return (
        <HStack>
          <Icon as={icons.Schedule} color={'warning.500'} />
          {/* plannedReleaseDate */}
          <Text>
            {t('general:status.Geplant', { date: plannedReleaseDate })}
          </Text>
        </HStack>
      );

    case VideoStatusEnum.Transcoding:
      return (
        <HStack ml={'-2px'}>
          <UploadListItemStatusProgressSpinner spinnerType={'transcoding'} />
          <Text>{t('videoLibrary:videoStatus.Optimieren')}</Text>
        </HStack>
      );

    case VideoStatusEnum.Rejected:
      return (
        <HStack spacing={1}>
          <Box
            as={HStack}
            cursor={'pointer'}
            onClick={videoRejectedDisclosure.onOpen}
          >
            <Icon color={'error.500'} as={icons.Error} />
            <Text color={'error.500'}>{t('general:status.Abgelehnt')}</Text>
            <Icon color={'error.500'} as={icons.ChevronRight} />
          </Box>
          <ResponsiveModal
            preferredSize="sm"
            preferredSizeBreakpoint="sm"
            isOpen={videoRejectedDisclosure.isOpen}
            onClose={videoRejectedDisclosure.onClose}
          >
            <ResponsiveModalOverlay />
            <ErrorModalContent
              heading={t('videoLibrary:heading.DeinVideoWurdeAbgelehnt')}
              text={t('videoLibrary:text.DerGrundReason', {
                reason: rejectionReason,
              })}
              footerContent={
                <Button
                  onClick={() => {
                    onDelete(albumId);
                  }}
                >
                  {t('videoLibrary:button.VideoLoschen')}
                </Button>
              }
            />
          </ResponsiveModal>
        </HStack>
      );

    default:
      return <Text>{status}</Text>;
  }
};

const UploadListItemPreview: React.FC<{ thumbnailSrc?: string }> = ({
  thumbnailSrc,
}) => {
  return thumbnailSrc ? (
    <Center
      mr={4}
      bg={`black`}
      bgImage={`url(${thumbnailSrc})`}
      bgSize={'contain'}
      w={'50px'}
      h={'28px'}
      borderRadius={'4px'}
      flexShrink={0}
    >
      <Icon as={icons.PlayCircleOutline} boxSize={'icon.md'} color={'white'} />
    </Center>
  ) : (
    <Center
      mr={4}
      bg={'steel'}
      w={'50px'}
      h={'28px'}
      borderRadius={'4px'}
      flexShrink={0}
    >
      <Icon as={icons.Movie} boxSize={'icon.md'} color={'primary.500'} />
    </Center>
  );
};

export const UploadHistoryListItemMenuButton = forwardRef<
  Partial<IconButtonProps & { eicon: EmotionIcon }>,
  'button'
>((props, ref) => {
  return (
    <IconButton
      color={'coldGray.900'}
      variant={'link'}
      display={'block'}
      aria-label={'menu'}
      icon={<Icon as={props.eicon} boxSize={'icon.md'} />}
      ref={ref}
      {...props}
    />
  );
});

type UploadListItemStatusProgressSpinnerType = 'uploading' | 'transcoding';

export const UploadListItemStatusProgressSpinner: React.FC<{
  spinnerType: UploadListItemStatusProgressSpinnerType;
}> = ({ spinnerType }) => {
  const spinnerColors: {
    [key in UploadListItemStatusProgressSpinnerType]: string;
  } = {
    uploading: 'primary.500',
    transcoding: 'caribbeanGreen.500',
  };

  return (
    <Center color={spinnerColors[spinnerType]}>
      <VStack>
        <PercentProgressSpinner
          desiredSizeInPixel={6}
          strokeWidthInPixel={0.4}
        />
      </VStack>
    </Center>
  );
};
