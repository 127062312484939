import * as Apollo from '@apollo/client';

import { getGtagEventParamsFromNotification } from '../../components/shared/NewsCenterPopper/NewsCenterPopper';
import {
  GetAllNotificationsDocument,
  GetImportantNotificationsDocument,
  GetNewsNotificationsDocument,
  GetNotificationsCountDocument,
  MarkReadMutation,
  NotificationFragment,
  useMarkReadMutation,
} from '../../generated/notifications';
import Logger from '../../utils/Logger';
import { gtag } from '../../utils/gtag';

export const useMarkRead = (
  notification: NotificationFragment | null
): (() => Promise<Apollo.FetchResult<MarkReadMutation>> | null) => {
  const { id, readAt } = notification || {};
  const [markReadMutation] = useMarkReadMutation({
    variables: { id: id || '' },
    // @todo implement a more sophisticated refetching strategy to only refetch the necessary queries
    refetchQueries: [
      GetNotificationsCountDocument,
      GetImportantNotificationsDocument,
      GetAllNotificationsDocument,
      GetNewsNotificationsDocument,
    ],
    onError: (error) => Logger.error(error),
    onCompleted: () => {
      if (notification) {
        gtag(
          'event',
          'Notification_MarkAsRead',
          getGtagEventParamsFromNotification(notification)
        );
      }
    },
  });

  // refetch only if the notification was not read before
  return () => (notification && readAt === null ? markReadMutation() : null);
};
