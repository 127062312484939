import { Divider, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { SubmitErrorHandler } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ButtonStack } from '../../../../components/Layout/ButtonStack';
import { issueChakraToast } from '../../../../components/Layout/ChakraToastContainer';
import { EnumSelectFieldHookForm } from '../../../../components/shared/FormElements/EnumSelectFieldHookForm/EnumSelectFieldHookForm';
import { PromotingOptionList } from '../../../../components/shared/FormikFormElements/PromotingSelect';
import { ClearableInputControl } from '../../../../components/shared/HookFormForms/ClearableInputControl/ClearableInputControl';
import {
  InputTourPayoutV1,
  ProfileFieldsEnum,
  TaxCountryEnum,
  useGetWizardPayoutTaxTinExtrasQuery,
} from '../../../../generated/graphql';
import Logger from '../../../../utils/Logger';
import { createStringValidationSchema } from '../../../../utils/validation';
import { WizardParentModalStepLayout } from '../../components/WizardParentStepLayout/WizardParentModalStepLayout';
import { PrimaryButton } from '../../components/styled';
import { useWizardPayout } from '../WizardPayoutContext';

const fieldName = {
  isTaxable: ProfileFieldsEnum.PayoutIsTaxable,
  taxNumber: ProfileFieldsEnum.PayoutTaxId,
  tin: 'payoutTaxTin',
  tinCountry: 'payoutTaxTinCountry',
  document: ProfileFieldsEnum.PayoutTaxDocument,
} as const;

interface FormValues {
  [fieldName.tin]: string;
  [fieldName.tinCountry]: TaxCountryEnum | '';
}

export const WizardPayoutTaxDetails: React.FC = () => {
  const { t } = useTranslation([
    'general',
    'wizardPayout',
    'payout',
    'document',
  ]);

  const wizardPayoutCtx = useWizardPayout();

  const { data: taxData, loading: isTaxDataLoading } =
    useGetWizardPayoutTaxTinExtrasQuery();

  const showTin = React.useMemo(() => {
    return taxData?.payment?.taxData?.showTin ?? true;
  }, [taxData]);

  const initialValues = React.useMemo<FormValues>(() => {
    const fields = taxData?.payment?.taxData;
    return {
      [fieldName.tin]: fields?.tin?.value ?? '',
      [fieldName.tinCountry]:
        ((fields?.tinCountry?.value ?? '') as TaxCountryEnum) || '',
    };
  }, [taxData]);

  const validationSchema = React.useMemo(() => {
    const fields = taxData?.payment?.taxData;

    return Yup.object().shape({
      [fieldName.tin]: createStringValidationSchema({
        ...fields?.tin,
      }).when(fieldName.isTaxable, (isTaxableValue, prevSchema) => {
        if (showTin || isTaxableValue !== 'yes') {
          return prevSchema;
        }
        return Yup.string();
      }),
      [fieldName.tinCountry]: createStringValidationSchema({}).when(
        fieldName.isTaxable,
        (isTaxableValue, prevSchema) => {
          if (showTin || isTaxableValue !== 'yes') {
            return prevSchema;
          }
          return Yup.string();
        }
      ),
    });
  }, [taxData, showTin]);

  const hookForm = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const { currentFieldError } = wizardPayoutCtx;

  const { wizardNextStepCallback } = wizardPayoutCtx;

  React.useEffect(() => {
    if (!showTin) {
      wizardNextStepCallback({ stepName: 'PayoutV1Finish' });
    }
  }, [showTin, wizardNextStepCallback]);

  React.useEffect(() => {
    if (Object.keys(currentFieldError).length > 0) {
      Object.entries(currentFieldError).forEach(([key, entry]) => {
        hookForm.setError(key as any, {
          type: 'manual',
          message: entry as any,
        });
      });
    }
  }, [currentFieldError, hookForm]);

  const onInvalidSubmit: SubmitErrorHandler<FormValues> = (errors) => {
    Logger.error(errors);
  };

  const issueErrorToast = React.useCallback(() => {
    issueChakraToast({
      description: t('general:toast.DatenKonntenNichtGespeichertWerden'),
      status: 'error',
    });
  }, [t]);

  const onValidSubmit: SubmitHandler<FormValues> = React.useCallback(
    async (data) => {
      try {
        const tinPart: Partial<InputTourPayoutV1> = {
          [fieldName.tin]: data[fieldName.tin],
          [fieldName.tinCountry]: data[fieldName.tinCountry] as TaxCountryEnum,
        };

        wizardNextStepCallback({
          [fieldName.isTaxable]: false,
          ...tinPart,
        });
      } catch (error) {
        Logger.error(error);
        issueErrorToast();
      }
    },
    [wizardNextStepCallback, issueErrorToast]
  );

  const validValues = taxData?.payment?.taxData?.tinCountry?.validValues;
  const quickpickValues =
    taxData?.payment?.taxData?.tinCountry?.quickpickValues;
  const optionListProps = useMemo(
    () => ({
      options: validValues ?? [],
      groupLabel: t('general:optgroup.Lander'),
      unPromotedGroupLabel: t('general:optgroup.WeitereLander'),
      placeholder: t('payout:placeholder.AusstellungslandAuswahlen'),
      promotedValues: (quickpickValues ?? []).map(({ value }) => value),
    }),
    [t, validValues, quickpickValues]
  );

  // Whether the continue button is disabled
  const disableContinueButton =
    !hookForm.formState.isValid || hookForm.formState.isSubmitting;

  const isReadOnly = false;

  return (
    <WizardParentModalStepLayout
      isContentLoading={isTaxDataLoading}
      contentSection={
        <FormProvider {...hookForm}>
          <form
            onSubmit={hookForm.handleSubmit(onValidSubmit, onInvalidSubmit)}
          >
            <VStack spacing={6} divider={<Divider />} alignItems={'stretch'}>
              {showTin && (
                <VStack spacing={6} alignItems={'stretch'}>
                  <ClearableInputControl
                    label={t('payout:label.SteuerIdentifikationsnummerTIN')}
                    text={t(
                      'payout:text.ZuFindenObenRechtsAufDerLohnsteuerbescheinigungOderDemEinkommensteueXX'
                    )}
                    placeholder={t('payout:placeholder.SteuerIDEingeben')}
                    name={fieldName.tin}
                    isReadOnly={isReadOnly}
                  />
                  <EnumSelectFieldHookForm
                    label={t(
                      'payout:label.InWelchemLandWurdeDieSteuerIdentifikationsnummerTINAusgestellt'
                    )}
                    name={fieldName.tinCountry}
                    isReadOnly={isReadOnly}
                  >
                    <PromotingOptionList {...optionListProps} />
                  </EnumSelectFieldHookForm>
                </VStack>
              )}
            </VStack>
          </form>
        </FormProvider>
      }
      footerSection={
        <ButtonStack>
          <PrimaryButton
            isLoading={hookForm.formState.isSubmitting}
            isDisabled={disableContinueButton}
            children={t('wizardPayout:button.Weiter')}
            onClick={hookForm.handleSubmit(onValidSubmit, onInvalidSubmit)}
          />
        </ButtonStack>
      }
    />
  );
};
